import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import styled from 'styled-components';

import { Spacer } from 'components/utils';

import EventsList from './components/EventsList';

const HeaderRow = styled.div`
  display: flex;
  align-items: center;

  h3 {
    margin: 0;
    margin-right: 20px;
  }

  .btn {
    font-size: 0.9rem;
    padding: 10px 30px;
  }
`;

const Events = () => {
  return (
    <Container>
      <HeaderRow>
        <h3>On-going Events</h3>
        <Link to="/admin/events/create">
          <Button variant="primary">Create Event</Button>
        </Link>
      </HeaderRow>
      <EventsList status={'ongoing'} />

      <Spacer height={'20px'} />

      <HeaderRow>
        <h3>Upcoming Events</h3>
      </HeaderRow>
      <EventsList status={'upcoming'} />

      <Spacer height={'20px'} />

      <HeaderRow>
        <h3>Past Events</h3>
      </HeaderRow>
      <EventsList status={'past'} />

      <Spacer height={'20px'} />
    </Container>
  );
};

export default Events;
