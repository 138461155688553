import React from 'react'

const Lycopene = () => {
  return (<div>
    <h5>Lycopene Rich Foods</h5>
    <p>
      Please note that your genetic SNP testing with Ingeneous is NOT able to tell you if you have an allergy or intolerance to any of these lycopene rich foods. If you know that you have an allergy or intolerance to a suggested food on this list, please choose an alternative. On the bright side, as your inflammation coordination improves as you follow your Essential Start programme you may find that existing sensitivities may no longer trigger symptoms in the future!
    </p>

    <p>
      This recommendation is particularly helpful for coordinating your Essential Start system, which includes inflammation, detoxification, and vitamin D receptor.
    </p>

    <p>
      Here are some great ingredients to increase the lycopene in your diet:
    </p>

    <ul>
      <li>Sun-dried tomatoes, ¼ cup = 6.18mg</li>
      <li>Tomato purée, ½ cup = 24.5mg</li>
      <li>Guava, chopped ½ cup 4.53mg</li>
      <li>Watermelon, chopped 1 cup = 7.23mg</li>
      <li>Fresh tomatoes, chopped 1 cup = 4.47mg</li>
      <li>Canned tomatoes, chopped 1 cup = 6.07mg</li>
      <li>Papaya, chopped ½ cup = 2.18mg</li>
      <li>Pink grapefruit, chopped ½ cup = 1.33mg</li>
    </ul>
  </div>)
}

export default Lycopene
