import React, { useEffect, useContext, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import styled, { createGlobalStyle } from 'styled-components';
import Sticky from 'react-stickynode';
import { isMobile } from 'react-device-detect';

import { IEssentialsReport, getEssentials } from 'api/reports';
import { AuthContext } from 'stores/auth';
import { Spacer } from 'components/utils';
import { Page, PageHeader, PageBody } from 'components/Page';

import Reports from 'routes/Dashboard/components/Reports';
import ReportBody from './components/ReportBody';

const UserRow = styled(Row)`
  h2,
  h3,
  p {
    color: #ffffff;
  }

  p {
    font-size: 0.8rem;
  }
`;

const UserCol = styled(Col)`
  text-align: center;
`;

const GlobalStyle = createGlobalStyle`
  @page {
    margin: 0;
`;

const PrintableBodyWrapper = styled(Container)`
  @media print {    
    margin: 0;
    max-width: 100%;

    & > div {
      display: block;
      margin: 0 auto;

      & > div:first-child {
        display: none;
      }

      & > div:nth-child(2) {
        margin: 0 auto;
      }
    }
    .content-wrapper {      
      padding: 0 50px;
      max-width: 100%;
      width: 100%;

      & > div {        
        border-left: 0px;
        border-right: 0px;
        border-bottom: 0px;
        padding-bottom: 10px;
        padding-top: 25px;
      }

      & h2 + p {
        margin-bottom: 5px;
      }
    }
  }
`;

const EssentialReport = () => {
  const authStore = useContext(AuthContext);
  const [results, setResults] = useState<IEssentialsReport>();
  const [addPad, setAddPad] = useState(false);

  const handleStateChange = (status: Sticky.Status) => {
    setAddPad(status.status === Sticky.STATUS_FIXED);
  };

  useEffect(() => {
    getEssentials().then((res) => {
      if (res.data.status === 'complete') {
        setResults(res.data);
      }
    });
  }, []);

  return useObserver(() => {
    const reportStatus = authStore.reports;
    if (reportStatus?.['essential'] !== 'complete') {
      return <Redirect to="/" />;
    }

    return (
      <Page>
        <PageHeader className="no-print">
          {!isMobile && <Spacer height={'30px'} />}
          <Container>
            <UserRow>
              <UserCol xs={12} md={3}>
                <Spacer height={'30px'} />
                {/* <UserImage /> */}
                {authStore.user.first_name ? (
                  <h3>
                    {authStore.user.first_name + ' ' + authStore.user.last_name}
                  </h3>
                ) : (
                  <h3>{authStore.user.email}</h3>
                )}
              </UserCol>
            </UserRow>
          </Container>
        </PageHeader>
        <PageBody>
          <GlobalStyle />
          <PrintableBodyWrapper>
            <Spacer height={'30px'} />
            <Row>
              <Col xs={12} md={3} className="no-print">
                {isMobile ? (
                  <Reports active={'essential'} pad={addPad} />
                ) : (
                  <Sticky onStateChange={handleStateChange}>
                    <Reports active={'essential'} pad={addPad} />
                  </Sticky>
                )}
              </Col>
              <Col xs={12} md={9}>
                <ReportBody results={results} />
              </Col>
            </Row>
            <Spacer height={'30px'} />
          </PrintableBodyWrapper>
        </PageBody>
      </Page>
    );
  });
};

export default EssentialReport;
