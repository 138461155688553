import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { Form, Alert, Button, Spinner } from 'react-bootstrap';
import styled from 'styled-components';

import { resetPassword as resetPasswordApi } from 'api/auth';

import { Spacer } from 'components/utils';

const Wrapper = styled.div`
  color: #ffffff;

  .btn-secondary {
    border-color: #ffffff;
  }
`

const FormTitle = styled.h3`
  display: block;
  color: #ffffff;
`

const FormActions = styled.div`
  display: flex;
  justify-content: space-between;
`

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ResetPasswordForm () {
  let query = useQuery()
  const { register, handleSubmit, errors, setError, clearErrors, reset, getValues } = useForm({
    defaultValues: {'token': query.get('token')}
  })
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState();
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true)
    await resetPasswordApi(data).then((res) => {
      setSuccess(true);
      setLoading(false)
      reset();
    }).catch(() => {
      setLoading(false)
      setMessage('Error updating password. Try again.')
    });
  };

  const checkPassword = (e) => {
    if (getValues('password') !== getValues('confirm_password')) {
      setError("confirm_password", {
        type: 'manual',
        message: 'Password does not match.'
      });
      return false;
    } else {
      clearErrors('confirm_password');
      return true;
    };
  };

  return (<Wrapper>
    <FormTitle>Reset Password</FormTitle>
    <Spacer height={'30px'} />

    {message && (<Alert variant='info'>{message}</Alert>)}
    {success && (<Alert variant='info'>Password changed. <Link to='/login'>Click here to login.</Link></Alert>)}

    <Form className="form" onSubmit={handleSubmit(onSubmit)}>
      <input name='token'
        type='hidden'
        ref={register({ required: true })} />
      <Form.Group controlId="password">
        <Form.Label>
          New Password
          &nbsp;
          <small onClick={() => setShowPassword(!showPassword)}>{showPassword ? "Hide Password" : "Show Password"}</small>
        </Form.Label>
        <Form.Control
          name="password"
          type={showPassword ? "text" : "password"}
          ref={register({ required: true })}
          onChange={checkPassword}
          isInvalid={!!errors?.password}
        />
        {errors.password && (<Form.Control.Feedback type="invalid">
          {errors.password.type === 'required' ? 'This field is required' : 'Invalid input'}
        </Form.Control.Feedback>)}
      </Form.Group>

      <Form.Group controlId="confirm_password">
        <Form.Label>
          Confirm New Password
          &nbsp;
          <small onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}>{showPasswordConfirm ? "Hide Password" : "Show Password"}</small>
        </Form.Label>
        <Form.Control
          name="confirm_password"
          type={showPasswordConfirm ? "text" : "password"}
          ref={register({ required: true })}
          onChange={checkPassword}
          isInvalid={!!errors?.confirm_password}
        />
        {errors.confirm_password && (<Form.Control.Feedback type="invalid">
          {errors.confirm_password.type === 'required' && 'This field is required'}
          {errors.confirm_password.type === 'manual' && errors.confirm_password.message}
          {errors.confirm_password.type !== 'required' && errors.confirm_password.type !== 'manual' && 'Invalid input'}
        </Form.Control.Feedback>)}
      </Form.Group>

      <FormActions>
        <Link to="/login"><Button variant="outline" type="button">
          Back to Login
        </Button></Link>

        <Button
          variant="secondary"
          type="submit"
          disabled={loading}
        >
          {!loading
            ? 'Update Password'
            : <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          }
        </Button>
      </FormActions>
    </Form>
  </Wrapper>);
}

export default ResetPasswordForm;
