import React from 'react';

import EmailConfirmationCard from './components/EmailConfirmationCard';
import { Spacer } from 'components/utils'

const EmailConfirmation = () => (
  <div>
    <Spacer height={'60px'} />
    <EmailConfirmationCard />
  </div>
);

export default EmailConfirmation;

