import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import Icon from 'components/Icon';
import {
  TR,
  Collapse,
  CollapseBtn,
  DataWrapper,
  Score,
  getColor,
} from './TableRow';

const Multivitamin = ({ geneData }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [genes, setGenes] = useState();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setGenes({
      rs4680: geneData?.find((e) => 'rs4680' === e.snp), // COMT
      rs1801131: geneData?.find((e) => 'rs1801131' === e.snp), // MTHFR
      rs1801133: geneData?.find((e) => 'rs1801133' === e.snp), // MTHF
      rs602662: geneData?.find((e) => 'rs602662' === e.snp), // FUT2
      rs1801198: geneData?.find((e) => 'rs1801198' === e.snp), // TCN2
      rs7946: geneData?.find((e) => 'rs7946' === e.snp), // PEMT
      rs3733890: geneData?.find((e) => 'rs3733890' === e.snp), // BHMT
      rs234706: geneData?.find((e) => 'rs234706' === e.snp), // CBS
      rs1805087: geneData?.find((e) => 'rs1805087' === e.snp), // MTR
      rs1801394: geneData?.find((e) => 'rs1801394' === e.snp), // MTRR
    });
  }, [geneData]);

  useEffect(() => {
    if (genes) {
      let tempShow = true;
      // eslint-disable-next-line no-unused-vars
      for (const [key, gene] of Object.entries(genes)) {
        if(gene){
          if (gene.score === 2) {
            tempShow = false;
          }
        }
        
      }

      if (tempShow) {
        if (genes?.rs1801131?.score === 1 && genes?.rs1801133?.score === 1) {
          tempShow = false;
        }
      }

      setShow(tempShow);
    }
  }, [genes]);

  if (!genes || !show) {
    return null;
  }

  return (
    <>
      <TR>
        <Col md={11}>
          <Row>
            <Col md={12}>
              {show ? (
                'Low Dose Methylated Multivitamin x1 capsule in the am (as opposed to the 3 recommended on the packaging). Thorne Methylguard Plus or equivalent'
              ) : (
                <>&nbsp;</>
              )}
            </Col>
          </Row>
        </Col>
        <Col md={1}>
          {show ? (
            <CollapseBtn
              variant="secondary"
              onClick={() => setCollapsed(!collapsed)}
            >
              <Icon
                name={collapsed ? 'caret-down' : 'caret-up'}
                color="#dedede"
              />
            </CollapseBtn>
          ) : (
            <>&nbsp;</>
          )}
        </Col>
      </TR>
      {!collapsed && show && (
        <Collapse>
          <DataWrapper>
            <h4 style={{ color: '#707070' }}>
              Alternative Supplement Scenario – Multivitamin
            </h4>
            <p>
              <Score color={getColor(2)}>
                If the customer has received all or mostly yellows and no reds
                (excluding 2 yellow MTHFR’s)
              </Score>
              &nbsp;
              <strong>
                = Low Dose Methylated Multivitamin x1 capsule in the am (as
                opposed to the 3 recommended on the packaging). Thorne
                Methylguard Plus or equivalent
              </strong>
            </p>
            <p>
              By compensating for your underactive methylation system in general
              you are going to help improve your quality of sleep, balance your
              slow and low personality and improve your ability to manage
              stress.
            </p>
            <h4>Supplement Timing</h4>
            <p>
              The recommendation is that all supplements (except magnesium)
              should be taken in the morning as it will provide energy to boost
              your day and could make it difficult to sleep if taken too late in
              the day.{' '}
            </p>
          </DataWrapper>
        </Collapse>
      )}
    </>
  );
};

export default Multivitamin;
