import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Spacer } from 'components/utils';
import ReadMore from '../../ReadMore';
import ReadLess from '../../ReadLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  position: ${isMobile ? 'static' : 'absolute'};
  right: 0;
  transform: ${isMobile ? 'translateX(0)' : 'translateX(-10%)'};
  margin-top: ${isMobile ? '10px' : '-10px'};
  text-align: ${isMobile ? 'center' : 'right'};
  // display: ${isMobile ? 'flex' : 'inherit'};
  // justify-content: ${isMobile ? 'center' : 'unset'};
  img {
    max-width: 60%;
  }
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

interface ResistanceProps {
  results: any;
}
const Resistance = ({ results }: ResistanceProps) => {
  const [show, setShow] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row className="page-br">
        <Col md={12}>
          <h3>Resistance</h3>
          <ImageCol md={4}>
            <img
              src="/assets/images/recommendations/WeightReportresistance.png"
              alt="Exercise"
            />
          </ImageCol>
          <Col md={8}>
            <Alert>
              <p>Increase your level of resistance training exercise.</p>
            </Alert>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div style={{ marginTop: 50 }}>
              <p>
                Resistance training exercise which will help you build muscle,
                is also particularly powerful for you. (3)
              </p>
              <p>
                You can increase the energy (calories) you burn by increasing
                your level of activity with resistance training exercise. By
                following your recommended diet and increasing your number of
                calories burnt by muscle you can work to reach your optimal
                weight.
              </p>
              <p>
                Resistance training can include many different exercises such
                as: weight lifting, isometric exercise, pilates, sit ups, lunges
                and push ups. Isometric exercise, where you focus on repeatedly
                contracting specific muscle groups, may be a good starting place
                to build up strength at any fitness level.
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
    </>
  );
};

export default Resistance;
