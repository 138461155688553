import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 0;
  position: absolute;
  right: 4%;
  transform: translateX(-4%);
  color: #6DA6CA;
`;

interface ViewLessProps {
  value: boolean;
  setValue: (value: boolean) => void;
}

const ViewLess = ({ value, setValue }: ViewLessProps) => {
  return (
    <Button onClick={() => setValue(!value)}>
      Close
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
        width={15}
      >
        <path
          fillRule="evenodd"
          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
          clipRule="evenodd"
        />
      </svg>
    </Button>
  );
};

export default ViewLess;
