import React from 'react';
import Cytokine from './Cytokine';
import Resveratrol from './Resveratrol';
import Lychee from './Lychee';
import Sereniten from './Sereniten';
import TriMetabolic from './TriMetabolic';
import Acetyl from './Acetyl';
import Lacto from './Lacto';
import Caralluma from './Caralluma';
import Capcicum from './Capcicum';
import styled from 'styled-components';

interface SupplementProps {
  results: any;
}
const WrapperResult = styled.div`
  position: relative;
`;
const Supplement = ({ results }: SupplementProps) => {
  let supplementType;
  if (results.supplement === 'mungbean') {
    supplementType = <Cytokine results={results.supplement} />;
  } else if (results.supplement === 'lychee') {
    supplementType = <Lychee results={results.supplement} />;
  } else if (results.supplement === 'sereniten') {
    supplementType = <Sereniten results={results.supplement} />;
  } else if (results.supplement === 'caraluma fimbriata') {
    supplementType = <Caralluma results={results.supplement} />;
  } else if (results.supplement === 'tri-met-control') {
    supplementType = <TriMetabolic results={results.supplement} />;
  } else if (results.supplement === 'l-carnitine') {
    supplementType = <Acetyl results={results.supplement} />;
  } else if (results.supplement === 'capsaicin') {
    supplementType = <Capcicum results={results.supplement} />;
  } else if (results.supplement === 'resveritrol') {
    supplementType = <Resveratrol results={results.supplement} />;
  } else {
    supplementType = <Lacto results={results.supplement} />;
  }
  return (
    <>
      <WrapperResult className="supplement">
        <h3>Supplement</h3>

        {supplementType}

        <hr className="no-print" />
      </WrapperResult>
    </>
  );
};

export default Supplement;
