import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import Icon from 'components/Icon';
import {
  TR,
  Collapse,
  CollapseBtn,
  DataWrapper,
  Score,
  getColor,
} from './TableRow';

import CircleDot from './Circle';

const COMT = ({ geneData }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [gene, setGene] = useState();  


  useEffect(() => {
    setGene(geneData?.find((e) => 'rs4680' === e.snp));
  }, [geneData]);

  if (!gene) {
    return null;
  }    

  return (
    <>
      <TR>
        <Col md={11}>
          <Row>
            <Col md={2}>COMT</Col>
            <Col md={1}>
              <CircleDot color={getColor(gene?.score)} tooltipColor={gene?.score}/>
            </Col>
            <Col md={2}>{gene?.snp?.toUpperCase()}</Col>
            <Col md={7}>200 - 300 mg magnesium citrate 1 hour before sleep</Col>
          </Row>
        </Col>
        <Col md={1}>
          <CollapseBtn
            variant="secondary"
            onClick={() => setCollapsed(!collapsed)}
          >
            <Icon
              name={collapsed ? 'caret-down' : 'caret-up'}
              color="#dedede"
            />
          </CollapseBtn>
        </Col>
      </TR>      
        <Collapse style={{ display: collapsed ? "none": "block"}} className='print-only'>
          <DataWrapper>
            <h4 style={{ color: '#707070' }}>COMT - Magnesium</h4>
            <p>
              <Score color={getColor(2)}>Red result for the RS number</Score>
              &nbsp;
              <strong>
                = 200 - 300 mg magnesium citrate 1 hour before sleep.
              </strong>
            </p>
            <p>
              By compensating for your underactive COMT receptor you are going
              to help improve your ability to fall asleep, balance your wired
              and tired personality, and improve your ability to manage stress.
            </p>
            <p>
              <i>Of note.</i> This customer should NOT take any methylated
              vitamin B products. This could be written as methylated or
              activated versions of any of the Vitamin B’s or folic acid, which
              is a B Vitamin. Common activated B names are: methylcobalamin,
              5MTHF, methylfolate, activated folic acid, active B12, active
              riboflavin, P5P (pyridoxal 5’ phosphate) etc.
            </p>
          </DataWrapper>
        </Collapse>
      
    </>
  );
};

export default COMT;
