/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import styled from "styled-components";

import { AuthContext } from 'stores/auth';
import { artilectLogin } from 'api/practitioner';
import { Spacer } from 'components/utils';
import { Page, PageHeader, PageBody } from 'components/Page';

import { getAuth, signInWithCustomToken } from 'firebase/auth';

const ContentWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  color:white;
  p,
  label {
    color: #ffffff !important;
  }
  h1 {
    text-align: center;
  }
`;

const Artilect = () => {
  const auth = getAuth();
  const authStore = useContext(AuthContext);
  let params = useParams();
  let history = useHistory();

  const [loadError, setLoadError] = useState(null)
  const [kitId, setKitId] = useState()

  useEffect(() => {
    let isMounted = true;
    if(isMounted){
      artilectLogin(params?.token, 'practitioner').then((res) => {
        localStorage.setItem('consultKitId', res.data?.sample_id?.code);
        setKitId(res.data?.sample_id?.code)
        signInWithCustomToken(auth, res.data?.access)
          .then((userCredential) => {
            const user = userCredential.user;
            if(!authStore.isLoggedIn()){
              authStore.loginArtilect(user).then(() => {
                authStore.setLoggedIn(true)
              }).catch((error) => {
                console.log(error)
                setLoadError("Error loading practitioner data")
              });
            }
          }).catch((error) => {
            console.log(error);
            setLoadError("Error logging in to Firebase")
          });
      }).catch((error) => {
        console.log(error);
        setLoadError("Error loading sample")
      });
    }
    return () => {
      isMounted = false;
    };
  }, []);

  if(authStore.isLoggedIn()){
    let storeKitId = localStorage.getItem('consultKitId')
    if(storeKitId === kitId){ //Ensure kitId matches before redirecting
      return (
        <Redirect to={'/consult'}/>
      )
    }
    
  }

  const loadMessage = () => {
    if(loadError === null || loadError === undefined){
      return "Loading Results. . ."
    }
    return loadError
  }

  return (
    <>
      <Page>
        <PageHeader>
          {!isMobile && <Spacer height={'30px'} />}
          <Container>
            <Spacer height="120px" />
            <Row>
              <Col xs={12} md={3}>
                {/* <h3 style={{ 'color': 'white' }}>{authS?.display_name}</h3> */}
              </Col>
            </Row>
          </Container>
        </PageHeader>
        <PageBody>
          <ContentWrapper><h1>{loadMessage()}</h1></ContentWrapper>
        </PageBody>
      </Page>
    </>
  );
};

export default Artilect;
