import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { parseISO, format } from 'date-fns';

import Icon from 'components/Icon';

const Name = styled(Link)`
  font-size: 1.1rem;
  font-weight: 600;
`;

const Date = styled.span`
  font-size: 1rem;
`;

const SpanLink = styled.span`
  color: #67a78d;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Event = ({ event, status, handleDelete, handleMakeFeatured }) => {
  const startDate = parseISO(event.start_date);
  const endDate = parseISO(event.end_date);

  return (
    <tr>
      <td>
        <Name to={`/admin/events/review/${event.pk}`}>{event.name}</Name>
      </td>
      <td>
        <Icon
          name={event.featured ? 'check-circle' : 'times-circle'}
          color={event.featured ? '#1AA88C' : '#af2e34'}
        />
        &nbsp;
        <span>{event.featured ? 'Yes' : 'No'}</span>
      </td>
      <td>{event.users === 0 ? 'N/A' : event.users}</td>
      <td>
        <Date>{format(startDate, 'MMMM dd, yyyy hh:mm a')}</Date>
        &nbsp;to&nbsp;
        <Date>{format(endDate, 'MMMM dd, yyyy hh:mm a')}</Date>
      </td>
      <td>
        <SpanLink onClick={() => handleMakeFeatured(event.pk)}>
          {event.featured ? 'Remove Featured' : 'Make Featured'}
        </SpanLink>
        <br />
        <Link to={`/admin/events/review/${event.pk}`}>View</Link>
        <br />
        {event?.users === 0 && (
          <SpanLink href="#" onClick={() => handleDelete(event.pk)}>
            Delete
          </SpanLink>
        )}
      </td>
    </tr>
  );
};

export default Event;
