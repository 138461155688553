import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Form, Col, Button } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css';

import { updateAddress as updateAddressAPI } from 'api/auth';
import { AuthContext } from 'stores/auth';
import { Spacer } from 'components/utils';

import { REGIONS } from './regions';

const Wrapper = styled.div`
  background-color: #F5FFFD;
  border-radius: 25px;
  padding: 50px;
`

const FormTitle = styled.h2`
  color: #45B79F;
`

const FormActions = styled.div`
  button {
    margin-right: 10px;
  }
`

const LabelHelp = styled.span`
  color: #6c757d;
  font-size: 10px;
  display: inline-block;
  margin-left: 10px;
`

const PhoneWrapper = styled(PhoneInput)`
  display: flex;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 3px;
  padding: 5px 15px;
  width: 100%;

  .PhoneInputInput {
    border: 0;
    outline: none !important;
  }

  .PhoneInput--focus {
    outline: none;
  }
`

const getRegion = (val) => {
  return REGIONS.filter(add => add.value === val)[0];
}

const AddressForm = ({ defaultValues, addressType }) => {
  let history = useHistory();
  const authStore = useContext(AuthContext);
  const { register, handleSubmit, setValue, errors } = useForm({
    defaultValues
  });

  const [saving, setSaving] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(defaultValues.phone_number);

  const handlePhoneNumber = (data) => {
    setPhoneNumber(data);
    setValue('phone_number', data);
  };

  const handleRegionSelect = (data) => {
    setValue('state', data.value)
  };

  const onSubmit = async data => {
    setSaving(true);
    const toastId = 'addressUpdate';
    toast("Updating address...", {
      containerId: 'full',
      toastId: toastId
    });
    let payload = Object.assign({}, data);
    payload['country'] = 'NZ'
    payload['address_type'] = addressType;

    await updateAddressAPI(payload).then(() => {
      setSaving(false);
      toast.dismiss(toastId);
      toast("Address updated.", {
        containerId: 'full',
        toastId: toastId,
        autoClose: 3000
      });
      history.push('/');
      authStore.fetchUser();
    }).catch((err) => {
      toast.dismiss(toastId);
      toast("An error occurred while updating the address.", {
        containerId: 'full',
        toastId: toastId,
        autoClose: 3000
      });
    });
  };

  return (<Wrapper>
    <FormTitle>{addressType === 'delivery' ? 'Delivery' : 'Billing'} Address</FormTitle>
    <Spacer height={'30px'} />

    <Form className="form" onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" name="pk" ref={register} />
      <Form.Row>
        <Form.Group as={Col} controlId="line1">
          <Form.Label>Street</Form.Label>
          <Form.Control
            name="line1"
            type="text"
            ref={register({ required: true })}
            isInvalid={!!errors.line1}
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} controlId="line2">
          <Form.Label>Suburb</Form.Label>
          <Form.Control
            name="line2"
            type="text"
            ref={register({ required: true })}
            isInvalid={!!errors.city}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="city">
          <Form.Label>City</Form.Label>
          <Form.Control
            name="city"
            type="text"
            ref={register({ required: true })}
            isInvalid={!!errors.state}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="state">
          <Form.Label>Region</Form.Label>
          <input
            type="hidden"
            name="state"
            ref={register({ required: true })}
          />
          <Select
            options={REGIONS}
            defaultValue={getRegion(defaultValues.state)}
            onChange={handleRegionSelect}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="postal_code">
          <Form.Label>Postal Code</Form.Label>
          <Form.Control
            name="postal_code"
            type="text"
            ref={register({ required: true })}
            isInvalid={!!errors.postal_code}
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} controlId="phone_number">
          <Form.Label>
            Phone Number
            <LabelHelp>
              9-digit phone number
            </LabelHelp>
          </Form.Label>
          <input
            type="hidden"
            name="phone_number"
            ref={register({ required: true })}
          />
          <PhoneWrapper
            defaultCountry="NZ"
            value={phoneNumber}
            onChange={handlePhoneNumber}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="country_code">
          <Form.Label>Country</Form.Label>
          <Form.Control
            name="country"
            type="text"
            value="New Zealand"
            readOnly
            disabled
          />

        </Form.Group>
      </Form.Row>

      <FormActions>
        <Button
          variant="primary"
          type="submit"
          disabled={saving}
        >
          {saving ? 'Saving...' : 'Save Changes'}
        </Button>
        <Link to="/"><Button variant="secondary" type="button">
          Back
        </Button></Link>
      </FormActions>
    </Form>
  </Wrapper>);
}

export default AddressForm;
