/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import styled from 'styled-components';
import { format } from 'date-fns';

import { downloadResults } from 'api/admin';
import { Spacer } from 'components/utils';
import { primary } from 'styles/theme';

const PageTitle = styled.h3`
  display: inline-block;
  margin-right: 10px;
`;

const Downloads = () => {
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState();
  const [filename, setFilename] = useState('export-ingeneous.csv');

  const handleDownloadResults = async (downloadType) => {
    setLoading(true);
    setCsvData(null);
    await downloadResults([], downloadType)
      .then((res) => {
        setLoading(false);
        const dt = format(new Date(), 'yyyyMMddHHmmss');
        if (downloadType === 'Test Results') {
          setFilename('ingeneous-test-results-' + dt + '.csv');
        } else if (downloadType === 'AMY Results') {
          setFilename('ingeneous-amy-results-' + dt + '.csv');
        } else if (downloadType === 'Essentials') {
          setFilename('ingeneous-essentials-' + dt + '.csv');
        } else if (downloadType === 'LifePlus') {
          setFilename('ingeneous-lifeplus-' + dt + '.csv');
        } else if (downloadType === 'Users') {
          setFilename('ingeneous-users-' + dt + '.csv');
        } else if (downloadType === 'Weight Management') {
          setFilename('ingeneous-wm-' + dt + '.csv');
        }
        setCsvData(res.data?.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Container>
      <Row>
        <Col>
          <PageTitle>Downloads</PageTitle>
        </Col>
      </Row>

      {loading && (
        <>
          <Spacer height={'20px'} />
          <Alert variant="primary">Preparing export...</Alert>
        </>
      )}

      {/* <Row>
        <Col lg={3} md={4} xs={6}>
          <DownloadItem
            title='Users'
            slug='all-users'
            description=''
            fields={[
              {'label': 'Email Address', 'value': 'email', 'default': true},
              {'label': 'First Name', 'value': 'first_name', 'default': true},
              {'label': 'Last Name', 'value': 'last_name', 'default': true},
              {'label': 'Sample ID', 'value': 'sample_id', 'default': false}
            ]}
            loading={loading}
            handleDownloadResults={handleDownloadResults}
          />
        </Col>
        <Col lg={3} md={4} xs={6}>
          <DownloadItem
            title='Sample IDs'
            slug='all-orders'
            description=''
            fields={[
              {'label': 'User', 'value': 'name', 'default': true},
              {'label': 'Sample/Kit ID', 'value': 'created', 'default': true},
              {'label': 'Status', 'value': 'financial_status', 'default': true},
            ]}
            loading={loading}
            handleDownloadResults={handleDownloadResults}
          />
        </Col>
        <Col lg={3} md={4} xs={6}>
          <DownloadItem
            title='Orders'
            slug='all-orders'
            description=''
            fields={[
              {'label': 'Order Number', 'value': 'name', 'default': true},
              {'label': 'Order Date', 'value': 'created', 'default': true},
              {'label': 'Financial Status', 'value': 'financial_status', 'default': true},
              {'label': 'Payment Method', 'value': 'payment_method', 'default': false},
              {'label': 'Tags', 'value': 'tags', 'default': false},
              {'label': 'Line Items', 'value': 'line_item', 'default': false}
            ]}
            loading={loading}
            handleDownloadResults={handleDownloadResults}
          />
        </Col>
        <Col lg={3} md={4} xs={6}>
          <DownloadItem
            title='Results'
            slug='results'
            description=''
            fields={[
              {'label': 'Sample ID', 'value': 'sample_id', 'default': true},
              {'label': 'Test Results', 'value': 'essentials', 'default': false},
              {'label': 'AMY Results', 'value': 'essentials', 'default': false},
              {'label': 'Essentials', 'value': 'essentials', 'default': false},
              {'label': 'LifePlus', 'value': 'life_plus', 'default': false},
              {'label': 'Weight Management', 'value': 'weight_management', 'default': false},
            ]}
            loading={loading}
            handleDownloadResults={handleDownloadResults}
          />
        </Col>
      </Row> */}

      {/* <Row>
        <Col>
          <StyledButton
            variant="primary"
            type="button"
            size="sm"
            disabled={loading}
            onClick={() => handleDownloadResults('Test Results')}
          >
            Test Results
          </StyledButton>
          {['AMY Results', 'Essentials', 'LifePlus'].map(
            (dType) => {
              return (
                <StyledButton
                  key={dType}
                  variant="primary"
                  type="button"
                  size="sm"
                  disabled={loading}
                  onClick={() => handleDownloadResults(dType)}
                >
                  {dType}
                </StyledButton>
              );
            }
          )}
          <StyledButton
            variant="primary"
            type="button"
            size="sm"
            disabled={loading}
            onClick={() => handleDownloadResults('Weight Management')}
          >
            Weight Management
          </StyledButton>
          {csvData && (
            <CSVLink data={csvData} filename={filename} target="_blank">
              <StyledButton variant="secondary" type="button" size="sm">
                Download CSV
              </StyledButton>
            </CSVLink>
          )}
        </Col>
      </Row> */}

      <Spacer height={'20px'} />
    </Container>
  );
};

export default Downloads;
