import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { isMobile } from 'react-device-detect';

import { primary, white, alert } from 'styles/theme';

export const Wrapper = styled.div`
  background-color: transparent;
  padding: 0;
`;

export const FormSection = styled.div`
  padding: ${isMobile ? '15px' : '0 4rem 40px 0'};
`;

export const DarkFormSection = styled(FormSection)`
  background-color: ${primary};

  .form-label,
  .invalid-feedback,
  h3,
  p {
    color: ${white};
  }
  border-radius: 5px;
`;

export const FormTitle = styled.span`
  color: #45b79f;
  font-size: 40px;
  font-weight: 500;
  display: block;
  text-align: center;
`;

export const CardWrapper = styled.div`
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 3px;
  padding: 10px 15px;
`;

export const PhoneWrapper = styled(PhoneInput)`
  display: flex;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 3px;
  padding: 5px 15px;
  width: 100%;

  .PhoneInputInput {
    border: 0;
    outline: none !important;
  }

  .PhoneInput--focus {
    outline: none;
  }
`;

export const SubSectionHeader = styled.h4`
  display: flex;
  padding: 5px 0;
  margin-bottom: 10px;
`;

export const Label = styled.span`
  display: inline-block;
`;

export const LabelHelp = styled.span`
  color: #6c757d;
  font-size: 10px;
  display: inline-block;
  margin-left: 10px;
`;

export const Checkbox = styled.div`
  margin-left: 5px;

  .form-group {
    margin-bottom: 0;
  }
`;

export const FormCheck = styled(Form.Check)`
  margin-right: ${(props) => (props.logo ? '5px' : '15px')};
`;

export const FormFooter = styled.div``;

export const FormFooterContainer = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
  align-items: center;
  justify-content: flex-end;

  .flex-1 {
    flex: 1;

    .form-group {
      display: ${isMobile ? 'block' : 'inline-block'};
      margin-right: ${isMobile ? '0' : '15px'};
      margin-bottom: 0;
    }
  }
`;

export const FormActions = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
  align-items: center;
`;

export const Price = styled.span`
  color: ${white};
  display: ${isMobile ? 'block' : 'inline-block'};
  margin-right: ${isMobile ? '0' : '30px'};
  margin-top: ${isMobile ? '10px' : '0'};
  margin-bottom: ${isMobile ? '10px' : '0'};
  font-size: 1.5rem;

  small {
    font-size: 0.75rem;
    margin-left: 10px;
  }
`;

export const Alert = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid ${alert};
  margin: 15px 0;

  strong {
    color: ${alert};
  }
`;

export const FormCheckBody = styled.div`
  padding-left: 20px;
`;

export const AfterPay = styled.div`
  display: inline-block;

  img {
    height: 15px;
    width: auto;
    margin-top: -5px;
  }
`;

export const Interest = styled.span`
  display: inline-block;
  color: white;
  margin-left: 30px;
  font-size: 0.8rem;

  a {
    color: white;
    text-decoration: underline;
  }
`;

export const RadioBox = styled.div`
  display: flex;
  vertical-align: middle;
  align-items: center;
`;

export const Option = styled.div`
  display: flex;
  margin-right: 20px;
  cursor: pointer;
`;

export const RadioCircle = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 3px solid white;
  border-radius: 9999px;
`;

export const RadioLabel = styled.div`
  color: white;

  img {
    height: 20px;
    margin-right: 5px;
  }
`;

export const StripeWrapper = styled.div`
  padding: 15px 20px;
  background-color: #52bda7;
  border: 1px solid #72c9b7;
  border-radius: 5px;
  margin-left: 20px;

  .form-group {
    margin-bottom: 0;
  }

  .title {
    color: white;
    font-size: 1rem;
    margin-bottom: 10px;
    display: block;
  }

  .stripe-help {
    font-size: 0.8rem;
    margin: 5px 0;
  }

  .btn {
    float: right;
  }
`;

export const HealthNowWrapper = styled.div`
  padding: 10px 20px;
  background-color: transparent;
  border: 1px solid #cbcac9;
  border-radius: 5px;

  .title {
    color: white;
    font-size: 16px;
  }

  p {
    font-size: 16px;
    margin-top: 10px;
  }
`;

export const HealthNowBtn = styled.div`
  cursor: pointer;
  img {
    width: 100%;
    height: auto;
  }
`;

export const OR = styled.div`
  padding: 15px 40px;
  color: white;
`;
