import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Form, Col, Button, Spinner } from 'react-bootstrap';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';

import { checkEmail as checkEmailAPI } from 'api/auth';
import { Spacer } from 'components/utils';

import { registerPracOrg } from 'api/auth';

const Wrapper = styled.div`
  color: #ffffff;

  .checkbox-form-group {
    margin-bottom: 5px;
  }

  .btn-secondary {
    border-color: #ffffff;
  }
`;

const FormTitle = styled.h3`
  display: block;
  color: #ffffff;
`;

const FormActions = styled.div`
  text-align: right;
`;

const CheckboxWrapper = styled.div`
  color: #ffffff;
`;

const CustomFormCheck = styled(Form.Check)`
  display: inline-block;
`;

const CustomLabel = styled.span`
  a {
    color: #ffffff;
    cursor: pointer;
    text-decoration: underline;
  }
`;

const PracOrgRegisterForm = ({ defaultValues, advisory=null, copy=null, title=null}) => {
  const auth = getAuth();
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    setValue,
    getValues,
  } = useForm({
    defaultValues,
  });
  const [saving, setSaving] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  let history = useHistory();

  const onSubmit = async (data) => {
    setSaving(true);
    let payload = Object.assign({}, data);
    if (!defaultValues['email']) {
      payload['country_code'] = 'NZ';
    }

    await createUserWithEmailAndPassword(
      auth,
      payload['email'],
      payload['password']
    )
      .then((userCredential) => {
        const user = userCredential.user;

        registerPracOrg({
          firebase_uid: user['uid'],
          email: payload['email'],
          first_name: payload['first_name'],
          last_name: payload['last_name'],
          organization_name: payload['organization_name']
        })
          .then(() => {
            setSaving(false);
            history.push('/consult/register/success/');
          })
          .catch(() => {
            setSaving(false);
          });
      })
      .catch((error) => {
        setSaving(false);
      });
  };

  const checkEmail = (e) => {
    const emailPattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
    clearErrors('email');
    async function validate(e) {
      await checkEmailAPI(e.target.value)
        .then((res) => {
          if (res.data.exists) {
            setError('email', {
              type: 'manual',
              message: 'Email exists',
            });
          } else {
            clearErrors('email');
          }
        })
        .catch(() => {
          return false;
        });
    }
    return emailPattern.test(e.target.value) ? validate(e) : false;
  };

  const checkPassword = (e) => {
    if (getValues('password') !== getValues('password_confirm')) {
      setError('password_confirm', {
        type: 'manual',
        message: 'Password does not match.',
      });
      return false;
    } else {
      clearErrors('password_confirm');
      return true;
    }
  };

  return (
    <Wrapper>
      <FormTitle>{title !== null ? title : 'Sign-Up'}</FormTitle>
      <p>{advisory !== null ? advisory : ''}</p>
      <Spacer height={'30px'} />
      <p>
        {copy !== null ?  copy : ''}
      </p>
      <p>It might take a while for our admins to review your application before you can fully use your account</p>

      <Form
        className="form form-dark"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Form.Control name="pk" type="hidden" ref={register} />
        <Form.Row>
          <Form.Group as={Col} controlId="organization_name">
            <Form.Label>Organization Name</Form.Label>
            <Form.Control
              name="organization_name"
              type="text"
              ref={register({ required: true })}
              isInvalid={!!errors?.organization_name}
            />
            {errors.organization_name && (
              <Form.Control.Feedback type="invalid">
                {errors.organization_name.type === 'required'
                  ? 'This field is required'
                  : 'Invalid input'}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="first_name">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              name="first_name"
              type="text"
              ref={register({ required: true })}
              isInvalid={!!errors?.first_name}
            />
            {errors.first_name && (
              <Form.Control.Feedback type="invalid">
                {errors.first_name.type === 'required'
                  ? 'This field is required'
                  : 'Invalid input'}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="last_name">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              name="last_name"
              type="text"
              ref={register({ required: true })}
              isInvalid={!!errors?.last_name}
            />
            {errors.last_name && (
              <Form.Control.Feedback type="invalid">
                {errors.last_name.type === 'required'
                  ? 'This field is required'
                  : 'Invalid input'}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Col>
            <Form.Group controlId="formEmail">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                name="email"
                type="email"
                ref={register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
                onChange={checkEmail}
                readOnly={defaultValues['email']}
                isInvalid={!!errors?.email}
              />
              {errors.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email.type === 'required' && 'This field is required'}
                  {errors.email.type === 'manual' && errors.email.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="password">
            <Form.Label>
              Password &nbsp;
              <small onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? 'Hide Password' : 'Show Password'}
              </small>
            </Form.Label>
            <Form.Control
              name="password"
              type={showPassword ? 'text' : 'password'}
              ref={register({ required: true })}
              onChange={checkPassword}
              isInvalid={!!errors?.password}
            />
            {errors.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password.type === 'required'
                  ? 'This field is required'
                  : 'Invalid input'}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="confirmPassword">
            <Form.Label>
              Confirm Password &nbsp;
              <small
                onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
              >
                {showPasswordConfirm ? 'Hide Password' : 'Show Password'}
              </small>
            </Form.Label>
            <Form.Control
              name="password_confirm"
              type={showPasswordConfirm ? 'text' : 'password'}
              ref={register({ required: true })}
              onChange={checkPassword}
              isInvalid={!!errors?.password_confirm}
            />
            {errors.password_confirm && (
              <Form.Control.Feedback type="invalid">
                {errors.password_confirm.type === 'required' &&
                  'This field is required'}
                {errors.password_confirm.type === 'manual' &&
                  errors.password_confirm.message}
                {errors.password_confirm.type !== 'required' &&
                  errors.password_confirm.type !== 'manual' &&
                  'Invalid input'}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Form.Row>

        <FormActions>
          <Button
            variant="secondary"
            type="submit"
            disabled={saving || !!errors.email}
          >
            {!saving ? (
              'Submit'
            ) : (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </FormActions>
      </Form>
    </Wrapper>
  );
};

export default PracOrgRegisterForm;
