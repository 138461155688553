import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { gray, primary } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import View from '../View';
import Close from '../ViewLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const AlertRef = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
`;

const SubtitleGray = styled.h5`
  color: ${gray};
`;

const WrapperRef = styled.div`
  margin-top: 5px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const ListRef = styled.li`
  font-weight: 300;
  padding-left: 20px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    word-break: break-all;
  }
`;

const WrapperTitle = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
`;

interface SpicyProps {
  results: any;
}
const SpicyFood = ({ results }: SpicyProps) => {
  const [show, setShow] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/WeightReport-spicy-food.png"
            alt="food"
            width={'60%'}
          />
        </ImageCol>
        <Col md={8}>
          <WrapperTitle>
            {isMobile ? (
              <>
                <SubtitleGray>Your superfood is</SubtitleGray>
                <Subtitle>Spicy Foods (Capsaicin Rich)</Subtitle>
              </>
            ) : (
              <>
                <SubtitleGray>Your superfood is</SubtitleGray>
                &nbsp;
                <Subtitle>Spicy Foods (Capsaicin Rich)</Subtitle>
              </>
            )}
          </WrapperTitle>
          <Alert>
            <p>
              Try to include spicy (capsaicin rich) foods in your diet daily.
              The more the better!
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <p>
                Capsaicin rich foods will be particularly powerful for helping
                you lose weight. They can help with satiety after eating and
                keep your blood sugar levels lower.
              </p>
              <p>
                Capsaicin is found in spicy foods made from hot peppers. The
                hotter a pepper tastes the more capsaicin it contains.
                Incorporating more of these spicy peppers into your diet has the
                possibility of helping to lower your blood pressure, and
                decrease blood glucose levels after eating, helping you to feel
                full for longer (1,2). These spicy foods are your superfood when
                it comes to weight loss.
              </p>
              <p>
                Capsaicin rich foods include chilli peppers such as: jalapenos,
                tabasco, cayenne, carolina reaper and ghost peppers (3). You can
                try incorporating them into your diet by adding them to curries,
                stews, chili or to increase the spice of any of your regular go
                to dishes.
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
      <Row className="no-print">
        <Col>
          <AlertRef>
            <span>References</span>
            {!showReferences && (
              <View value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <Close value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <WrapperRef>
                <ListGroup>
                  <ListRef>
                    Tremblay, A., Arguin, H. & Panahi, S. Capsaicinoids: a spicy
                    solution to the management of obesity?. Int J Obes 40,
                    1198–1204 (2016). &nbsp;
                    <a href="https://doi.org/10.1038/ijo.2015.253">
                      https://doi.org/10.1038/ijo.2015.253
                    </a>
                    <a href="https://www.nature.com/articles/ijo2015253">
                      {' '}
                      https://www.nature.com/articles/ijo2015253
                    </a>
                  </ListRef>
                  <ListRef>
                    Whiting S, Derbyshire EJ, Tiwari B. Could capsaicinoids help
                    to support weight management? A systematic review and
                    meta-analysis of energy intake data. Appetite. 2014 Feb
                    1;73:183-8. &nbsp;
                    <a href="https://www.sciencedirect.com/science/article/abs/pii/S0195666313004492">
                      https://www.sciencedirect.com/science/article/abs/pii/S0195666313004492
                    </a>
                  </ListRef>
                  <ListRef>
                    <a href="https://www.livestrong.com/article/408453-foods-that-have-capsaicin/">
                      https://www.livestrong.com/article/408453-foods-that-have-capsaicin/
                    </a>
                  </ListRef>
                </ListGroup>
              </WrapperRef>
            )}
          </AlertRef>
        </Col>
      </Row>
    </>
  );
};

export default SpicyFood;
