import React from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import { Spacer } from 'components/utils';
// import Header from './components/Header'
// import MyVitamins from './components/MyVitamins'
// import MyHealthTracker from './components/MyHealthTracker'

import EssentialReport from './EssentialReport';
import LifePlusReport from './LifePlusReport';
import WeightManagementReport from './WeightManagementReport';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

function ReportsRouter() {
  return (
    <>
      <Wrapper>
        <Switch>
          {/*<Route exact path="/reports/vitamins/" component={MyVitamins} />*/}
          {/*<Route exact path="/reports/health-tracker/" component={MyHealthTracker} />*/}

          <Route exact path="/reports/essential/" component={EssentialReport} />
          <Route exact path="/reports/lifeplus/" component={LifePlusReport} />
          <Route
            exact
            path="/reports/weight-management/"
            component={WeightManagementReport}
          />
        </Switch>
        <Spacer height={'50px'} />
      </Wrapper>
    </>
  );
}

export default ReportsRouter;
