/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Button, Modal, Form, Spinner, Alert } from 'react-bootstrap';
import Icon from '../../../../components/Icon';

import { checkSyncRecommendations, syncRecommendations } from '../../../../api/core';

import CustomModal, { ModalTitle, Title, ModalWrapper } from '../../../../components/Modal';

import { resetPassword as resetPasswordApi } from '../../../../api/admin';

const Checkbox = styled.span`
  .form-check {
    padding: 0;

    .form-check-input {
      margin: 0;
    }
  }
`;

const UserRow = ({ user, handleInputChange, selectedUsers }) => {
  const { handleSubmit, reset, errors, register } = useForm();
  const [syncing, setSyncing] = useState(false);
  const [synced, setSynced] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const [showNotif, setShowNotif] = useState(false);

  useEffect(() => {
    checkSyncRecommendations(user.id).then((res) => {
      if (res.data?.synced) {
        setSynced(true);
      }
    });
  }, []);

  const handleSyncRecommendations = async (userId) => {
    if (!syncing) {
      setSyncing(true);
      await syncRecommendations(userId)
        .then((res) => {
          setSynced(true);
          setSyncing(false);
        })
        .catch(() => {
          setSynced(false);
          setSyncing(false);
        });
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseNotif = () => setShowNotif(false);

  const onSubmit = async (data) => {
    setLoading(true);
    await resetPasswordApi({
      email: user.email,
      password: data.password,
    })
      .then((res) => {
        setLoading(false);
        reset();
        setShow(false);
        setShowNotif(true);
        setFailed(false);
      })
      .catch((err) => {
        setShowNotif(true);
        setFailed(true);
        setLoading(false);
        setShow(false);
      });
  };

  return (
    <>
      <tr>
        <td>
          <Checkbox>
            <Form.Check
              type="checkbox"
              id={`user-${user.id}`}
              value={user.id}
              onChange={handleInputChange}
              defaultChecked={selectedUsers?.find(
                (e) => parseInt(e) === user.id
              )}
            />
          </Checkbox>
        </td>
        <td>
          <h6>
            {(user.last_name ? user.last_name + ', ' : '') + user.first_name}
          </h6>
          <span>{user.email}</span>
          <br />
          <small>Last Login: {user.last_login || 'N/A'}</small>
        </td>
        <td>{user.sample_id || 'N/A'}</td>
        <td>
          <div>
            {user.sample_id && !synced && (
              <button onClick={() => handleSyncRecommendations(user.id)}>
                {syncing ? 'Uploading...' : 'Sync Recommendations To Pukeko'}
              </button>
            )}
            {user.sample_id && synced && (
              <span>Recommendations synced to Pukeko</span>
            )}
          </div>
        </td>
        <td>
          <div>
            <Button variant="primary" onClick={handleShow}>
              Reset Password
            </Button>
          </div>
        </td>
      </tr>

      {/* Starts Modal Reset Password */}
      <CustomModal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <ModalTitle>
              <Title>Reset password for {user.email}</Title>
            </ModalTitle>
          </Modal.Header>
          <Modal.Body>
            <ModalWrapper>
              <p>
                This will reset the user's password in the API and on Firebase,
                please double check!
              </p>
              <Form.Group className="mb-3" controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="password"
                  name="password"
                  autoFocus
                  ref={register({ required: true })}
                  isInvalid={!!errors.password}
                />
                {errors.password && (
                  <Form.Control.Feedback type="invalid">
                    {errors.password.type === 'required'
                      ? 'This field is required'
                      : 'Invalid input'}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </ModalWrapper>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              className="btn-danger"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit" disabled={loading}>
              {!loading ? (
                'Reset Password'
              ) : (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </CustomModal>
      {/* Ends Modal Reset Password */}

      {/* Starts Modal Notification Reset Password */}
      <CustomModal show={showNotif} onHide={handleCloseNotif}>
        {failed ? (
          <>
            <Modal.Body style={{ textAlign: 'center' }}>
              <div>
                <Icon
                  name="exclamation-triangle"
                  brand={undefined}
                  color="#dedede"
                  size={'80px'}
                />
              </div>
              <Title style={{ fontSize: 18 }}>Something went wrong. </Title>
              <p>Cannot update the password for the selected customer.</p>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'center' }}>
              <Button variant="danger" onClick={handleCloseNotif}>
                Dismiss
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <>
          <Modal.Body style={{ textAlign: 'center' }}>
            <div>
              <Icon
                name="check-square"
                brand={undefined}
                color="#1AA88C"
                size={'80px'}
              />
            </div>
            <Title style={{ fontSize: 18 }}>Update Successfull </Title>
            <p>The password has been reseted for the selected customer.</p>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: 'center' }}>
            <Button variant="secondary" onClick={handleCloseNotif}>
              Go Back
            </Button>
          </Modal.Footer>
        </>
        )}
      </CustomModal>
      {/* Ends Modal Notification Reset Password */}
    </>
  );
};

export default UserRow;
