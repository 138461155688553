import React, { useRef, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import { primary } from '../../styles/theme';
import { isMobile } from 'react-device-detect';
import { getUserProduct, getProducts } from '../../api/core';
import { AuthContext } from '../../stores/auth';
import { useObserver } from 'mobx-react-lite';

import ItemTile from './ItemTile';
import { any } from 'prop-types';

const Wrapper = styled.div<{
  isSelected?: boolean;
  isPurchased?: boolean;
  isPending?: boolean;
}>`
  padding: ${isMobile ? '15px 5px' : '30px 40px'};
  border-radius: 5px;
  border: 1px solid #bbb;
  cursor: pointer;
  margin: 0 0 20px 0;
  position: relative;

  background-color: #ffffff;
  &.is-selected,
  &.is-purchased {
    background-color: #dcefe2;
    border-color: ${primary};
  }
  &.is-pending {
    background-color: lighten(0.2, yellow);
    border-color: yellow;
  }
`;

const WrapperContent = styled(Row)`
  position: ${isMobile ? 'static' : 'relative'};
`;

const prereqMap = [
  {
    parent: process.env.REACT_APP_ESSENTIAL_PRODUCT_ID ?? '',
    child: process.env.REACT_APP_LIFE_PLUS_PRODUCT_ID ?? '',
  },
  {
    parent: process.env.REACT_APP_ESSENTIAL_PRODUCT_ID ?? '',
    child: process.env.REACT_APP_CONSULTATION_PRODUCT_ID ?? '',
  },
  {
    parent: process.env.REACT_APP_ESSENTIAL_PRODUCT_ID ?? '',
    child: process.env.REACT_APP_WEIGHT_MANAGEMENT_PRODUCT_ID ?? '',
  },
  {
    parent: process.env.REACT_APP_ESSENTIAL_PRODUCT_ID ?? '',
    child:
      process.env.REACT_APP_WEIGHT_MANAGEMENT_CONSULTATION_PRODUCT_ID ?? '',
  },
  {
    parent: process.env.REACT_APP_LIFE_PLUS_PRODUCT_ID ?? '',
    child: process.env.REACT_APP_CONSULTATION_PRODUCT_ID ?? '',
  },
  {
    parent: process.env.REACT_APP_LIFE_PLUS_PRODUCT_ID ?? '',
    child: process.env.REACT_APP_WEIGHT_MANAGEMENT_PRODUCT_ID ?? '',
  },
  {
    parent: process.env.REACT_APP_LIFE_PLUS_PRODUCT_ID ?? '',
    child:
      process.env.REACT_APP_WEIGHT_MANAGEMENT_CONSULTATION_PRODUCT_ID ?? '',
  },
  {
    parent: process.env.REACT_APP_CONSULTATION_PRODUCT_ID ?? '',
    child: process.env.REACT_APP_WEIGHT_MANAGEMENT_PRODUCT_ID ?? '',
  },
  {
    parent: process.env.REACT_APP_CONSULTATION_PRODUCT_ID ?? '',
    child:
      process.env.REACT_APP_WEIGHT_MANAGEMENT_CONSULTATION_PRODUCT_ID ?? '',
  },
  {
    parent: process.env.REACT_APP_WEIGHT_MANAGEMENT_PRODUCT_ID ?? '',
    child:
      process.env.REACT_APP_WEIGHT_MANAGEMENT_CONSULTATION_PRODUCT_ID ?? '',
  },
];

const CartItems = ({ updateProductSelected, eventDiscounts, isEvent, loggedIn, ignoreStockout=false }: any) => {
  const authStore = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const componentIsMounted = useRef(true);
  const [products, setProducts] = useState([] as any);
  const [productCollection, setProductCollection] = useState([] as any);
  const [flagSelection, setFlagselection] = useState(false);
  // eslint-disable-next-line
  const [productPurchasedID, setProductPurchasedID] = useState([] as any);
  // eslint-disable-next-line
  const [productPendingID, setProductPendingID] = useState([] as any);
  const [selectedproduct, setSelectedproduct] = useState([] as any);

  let isLoggedIn = false;

  const itemIdIsOutOfStock = (itemId:number) => {
    let outOfStocks = process.env.REACT_APP_OUTOFSTOCK_PRODUCTS ? process.env.REACT_APP_OUTOFSTOCK_PRODUCTS : '' 
    outOfStocks.split(",")
    return outOfStocks.includes(itemId.toString())
  }

  useEffect(() => {
    setLoading(true);
    getProducts()
      .then((res) => {
        if (!componentIsMounted.current) return;
        setProducts(res.data?.products);
        res.data.products.map((itemProduct: any) => {
          if (isLoggedIn) {
            getUserProduct(itemProduct.id).then((res) => {
              let theproduct = {
                id: '',
                is_purchased: false,
                is_pending: false,
                is_selected: false,
                title: 'Lifetime',
                prereq: [] as any,
              };
              theproduct['id'] = itemProduct.id;
              // START OF test for is_purchased data
              //
              // if (
              //   itemProduct.id.toString() ===
              //   process.env.REACT_APP_ESSENTIAL_PRODUCT_ID
              // ) {
              //   theproduct['is_purchased'] = true;
              // } else {
              //   theproduct['is_purchased'] = res.data?.is_purchased;
              // }
              //
              // END OF test for is_purchased data
              // ==========================================================
              theproduct['is_purchased'] = res.data?.is_purchased;
              theproduct['is_pending'] = res.data?.is_pending;
              theproduct['title'] = itemProduct.title;

              if (theproduct['is_purchased']) {
                setProductPurchasedID((productPurchased: any) => [
                  ...productPurchased,
                  theproduct['id'],
                ]);
              }
              if (theproduct['is_pending']) {
                setProductPendingID((productPending: any) => [
                  ...productPending,
                  theproduct['id'],
                ]);
              }

              setProductCollection((productcollection: any) => [
                ...productcollection,
                theproduct,
              ]);
            });
          } else {
            console.log('User is not logged in');
          }
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });

    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    chooseProduct();
  }, [productCollection]);

  useEffect(() => {
    // set final product data to parent component
    const productsToSent = products.filter((id1: any) =>
      selectedproduct.some((id2: any) => id2.toString() === id1.id.toString())
    );
    updateProductSelected(productsToSent);
    // set final product data to parent component
    // ====================================================================
  }, [selectedproduct]);

  useEffect(() => {
    if (flagSelection) {
      // by default to only run preselect script when "flagSelection" is TRUE
      preselection(selectedproduct, false);
    }
  }, [selectedproduct, flagSelection]);

  const chooseProduct = (itemId?: any) => {
    // if no "products" then return
    if (!products) return;

    if (itemId) {
      // IF state has minimum 1 data, then
      //  * validate if this is a second click from same ID to has the unclick effect
      //  * if it's new item then insert to the state
      if (selectedproduct.length >= 1) {
        const isSameIDExist = selectedproduct?.find(
          (e: { toString: () => any }) => e.toString() === itemId.toString()
        );
        if (isSameIDExist) {
          // handle unselect
          const newState = selectedproduct?.filter(
            (e: { toString: () => any }) => e.toString() !== itemId.toString()
          );
          setSelectedproduct(newState);
          // don't check the preselection data
          // but instead run the unselected data validation
          setFlagselection(false);

          // if "parent" data from "prereqMap" unselected
          // then the relation "child" data must be unselected, too
          let itemsToRemove: { toString: () => any }[] = [];
          const getUnecessaryItems = prereqMap.filter(
            (e: any) => e.parent?.toString() === itemId.toString()
          );
          getUnecessaryItems.forEach(
            (e: { child: { toString: () => any } }) => {
              itemsToRemove.push(e.child);
            }
          );
          const tempProducts = newState.filter(
            (e: { toString: () => any }) =>
              !itemsToRemove.includes(e.toString())
          );
          // end prereq parent & child relation check
          // ==========================================================

          // set correct selected data
          setSelectedproduct(tempProducts);
        } else {
          setSelectedproduct((prevArray: any) => [...prevArray, itemId]);
          // check the preselection data
          let newselectedData: { toString: () => any }[] = [];

          newselectedData.push(itemId);
          preselection(newselectedData, true);
          setFlagselection(false);
        }
      } else {
        // IF state is null, insert the first selected item directly to the state
        setSelectedproduct((prevArray: any) => [...prevArray, itemId]);
        // check the preselection data
        setFlagselection(true);
      }
    }
  };

  const preselection = (selectedproductLocal: any, isAddition?: any) => {
    // if item has prereq data
    // then programatically select them

    //  if the "child" element is selected from "prereqMap" data,
    // then the "parent" element must either be purchased already or be selected also
    if (flagSelection || isAddition) {
      let itemsToAdd = new Array();
      let getPrerequiredItems = prereqMap.filter((e: any) => {
        try {
          const current = productCollection.find(
            (product: any) => e.parent.toString() === product.id.toString()
          );
          if (current.is_purchased || current.is_pending) return false;
        } catch (err) {
          console.log('not logged in');
        }
        return e.child?.toString() === selectedproductLocal.toString();
      });
      getPrerequiredItems.forEach((e: { parent: { toString: () => any } }) => {
        itemsToAdd.push(e.parent);
      });
      if (itemsToAdd.length) {
        setFlagselection(true);
      }
      if (flagSelection || isAddition) {
        itemsToAdd.forEach((e: { parent: any }) => {
          setSelectedproduct((prevArray: any) => [...prevArray, Number(e)]);
        });
        setFlagselection(false);
      }
    }
  };

  const computePrice = (variant: any, discountedItem: any, final: any) => {
    let finalPrice = variant?.price;
    let comparePrice = 0;
    let discount = discountedItem ? discountedItem[0] : null;

    if (variant?.compare_at_price) {
      comparePrice = variant.compare_at_price;
    }

    if (discount?.discount_type) {
      if (discount.discount_type === 'price') {
        finalPrice = discount.discount_value / 100;
        comparePrice = variant.price;
      } else if (discount.discount_type === 'amount') {
        const amount_off = discount.discount_value / 100;
        finalPrice = variant.price - amount_off;
        comparePrice = variant.price;
      } else if (discount.discount_type === 'percent') {
        const percent = discount.discount_value / 10000;
        const percent_off = variant.price * percent;
        finalPrice = variant.price - percent_off;
        comparePrice = variant.price;
      } else {
        finalPrice = discount.discount_value / 100;
        comparePrice = variant.price;
      }
    }

    return final ? finalPrice : comparePrice;
  };

  return useObserver(() => {
    if (authStore?.loggedIn) {
      isLoggedIn = true;
    } else {
      isLoggedIn = false;
      // return <Redirect to="/" />;
    }
    let curProducts = products.map((item: any) =>{
      return Object.assign({}, item)
    })
    return (
      <>
        {curProducts &&
          !loading &&
          curProducts.map(
            (item: {
              id: number;
              title: string;
              body_html: any;
              image: { src: string };
              variants: any;
              notPurchasable?:boolean;
            }, index:number, array:any) => {
              let variant = item ? item.variants[0] : null;
              // console.log(variant)
              const regex = /(<([^>]+)>)/gi;
              let body_html = item ? item.body_html.replace(regex, '') : null;

              // get is_purchased information
              let is_purchased = productCollection.some(
                (obj: { id: number; is_purchased: Boolean }) => {
                  return obj.id == item.id && obj.is_purchased == true;
                }
              );
              // END OF get is_purchased information
              // ===============================================================

              // get is_pending information
              let is_pending = productCollection.some(
                (obj: { id: number; is_pending: Boolean }) => {
                  return obj.id == item.id && obj.is_pending == true;
                }
              );
              // END OF get is_purchased information
              // ===============================================================

              // get "selected" information
              let selected = selectedproduct.some((f: any) => {
                return f == item.id;
              });
              // END OF get "selected" information
              // ===============================================================

              // set requirement info hardcoded first
              let requirementInfo = [];
              if (
                item.id ==
                Number(process.env.REACT_APP_WEIGHT_MANAGEMENT_PRODUCT_ID)
              ) {
                requirementInfo = [
                  'Essential Start',
                  'LifeStyle Plus',
                  'Methylation Consulation',
                ];
              } else if (
                item.id ==
                Number(
                  process.env
                    .REACT_APP_WEIGHT_MANAGEMENT_CONSULTATION_PRODUCT_ID
                )
              ) {
                requirementInfo = [
                  'Essential Start',
                  'LifeStyle Plus',
                  'Methylation Consulation',
                  'Weight Management',
                ];
              } else {
                requirementInfo = ['Essential Start'];
              }
              let requirement = requirementInfo.join(', ');
              // END OF set requirement & PreReq info hardcoded first
              // ===============================================================

              // filtered eventDiscounts if exist
              const productDiscounts = eventDiscounts.filter(
                (discountItem: {
                  product_id: number;
                  discount_value: number;
                  discount_type: string;
                }) => discountItem.product_id.toString() === item.id.toString()
              );
              // console.log(productDiscounts)
              // END OF filtered eventDiscounts if exist
              // ===============================================================

              let outOfStock = itemIdIsOutOfStock(item.id)
              let prevItemPurchased = true
              let prevItemIsPending = false
              let prevItemOutOfStock = false
              let prevItemIsNotPurchasable = false
              let curItemIsNotPurchasable = false
              // console.log(productCollection)
              if (index > 0){
                let prevItem = array[index-1]
                // console.log(prevItem)
                prevItemPurchased = productCollection.some(
                  (obj: { id: number; is_purchased: Boolean }) => {
                    return obj.id == prevItem.id && obj.is_purchased == true;
                  }
                )
                prevItemIsPending = productCollection.some(
                  (obj: { id: number; is_pending: Boolean }) => {
                    return obj.id == prevItem.id && obj.is_pending == true;
                  }
                );
                prevItemOutOfStock = itemIdIsOutOfStock(prevItem.id)
                prevItemIsNotPurchasable = prevItem.notPurchasable !== undefined ? prevItem.notPurchasable : false
                // console.log("prev item is not purchasable")
                // console.log(prevItem.notPurchasable)
                // console.log("----------------------------")

              }

              const itemDisabledRule = (
                isOutOfStock:boolean, 
                requiredItemIsOos:boolean,
                requiredItemIsPurchased:boolean,
                requredItemIsPending:boolean,
                requiredItemIsNotPurchasable:boolean) => {
                  // console.log(outOfStock)
                  // console.log(requiredItemIsPurchased)
                  // console.log(requredItemIsPending)
                  // console.log(!(requiredItemIsPurchased || requredItemIsPending))
                  if(isOutOfStock){
                    return true
                  }
                  if(!(requiredItemIsPurchased || requredItemIsPending || !requiredItemIsNotPurchasable)){
                    return true
                  }
                  return false
                }

              let itemDisabled = itemDisabledRule(outOfStock, prevItemOutOfStock, prevItemPurchased, prevItemIsPending, prevItemIsNotPurchasable)
              if(itemDisabled){
                curItemIsNotPurchasable = true
                // console.log("disabling " + item.title)
              }
              array[index].notPurchasable = curItemIsNotPurchasable

              let reason = outOfStock ? 'TEMPORARILY OUT OF STOCK' : ''

              if(isEvent && curItemIsNotPurchasable && prevItemOutOfStock && !loggedIn){
                reason = 'You need Essential Start results already to purchase '+ item.title +' Please login to proceed.'
              }

              return (
                <Wrapper
                  className={`${
                    is_purchased
                      ? ` is-purchased`
                      : `${
                          is_pending
                            ? ` is-pending`
                            : `${selected ? ` is-selected` : ''}`
                        }`
                  }`}
                  key={item.id}
                >
                  {/* <Wrapper className={`${is_purchased ? ` is-purchased` : `${is_pending ? ` is-pending` : '' }`}`}> */}
                  {/* <WrapperContent
                    onClick={() =>
                      !is_purchased && !is_pending
                        ? chooseProduct(item.id)
                        : console.log('Purchased/Pending product.')
                    }
                  > */}
                  <WrapperContent>
                    <ItemTile
                      title={item.title}
                      description={body_html}
                      price={computePrice(variant, productDiscounts, true)}
                      discount={computePrice(variant, productDiscounts, false)}
                      imageSrc={item.image.src}
                      requirement={requirement}
                      status={
                        is_purchased
                          ? 'purchased'
                          : is_pending
                          ? 'pending'
                          : selected
                          ? 'selected'
                          : null
                      }
                      disabled={!ignoreStockout ? itemDisabled : false}
                      reason={reason}
                      selectClickHandler={chooseProduct}
                      itemId={item.id}
                    />
                  </WrapperContent>
                </Wrapper>
              );
            }
          )}
        {loading && <span>Loading product info...</span>}
      </>
    );
  });
};

CartItems.defaultProps = {
  isEvent: false,
  loggedIn: false
};

export default CartItems;
