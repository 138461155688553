import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Container, Button } from 'react-bootstrap';

import { Spacer } from 'components/utils';

const Section = styled.section`
  background-color: #E4EDEB;
  padding: 40px 0;
`

const Title = styled.span`
  display: block;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  color: #45B79F;
`

const Question = styled.p`
  display: block;
  font-size: 18px;
  text-align: center;
  color: #3A3A3A;
`

const ButtonGroup = styled.div`
  text-align: center;

  .btn {
    margin: 0 20px;
    font-size: 24px;
    padding-left: 32px;
    padding-right: 32px;
  }
`

const Terms = () => {
  return (<Section>
    <Container>
      <Title>Please Read our Terms and Conditions</Title>
      <Spacer height='30px' />
      <Question>al Product Outsourcing may, in its sole discretion, modify the terms of this Agreement at any time, by posting such modifications to the Website. Medical Product Outsourcing shall announce any such modifications on the Medical Product Outsourcing  Public Site and Member’s use of the Medical Product Outsourcing  Member Only Site after such announcement shall be deemed Member’s acceptance of such modifications.
      </Question>
      <ButtonGroup>
        <Link to='/'><Button variant="secondary" size="lg">I Disagree</Button></Link>
        <Link to='/register'><Button variant="primary" size="lg">I Agree</Button></Link>
      </ButtonGroup>
    </Container>
  </Section>);
}

export default Terms;
