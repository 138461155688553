import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Spacer } from 'components/utils';
import ReadMore from '../../ReadMore';
import ReadLess from '../../ReadLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  position: ${isMobile ? 'static' : 'absolute'};
  right: 0;
  transform: ${isMobile ? 'translateX(0)' : 'translateX(-10%)'};
  margin-top: ${isMobile ? '10px' : '-10px'};
  text-align: ${isMobile ? 'center' : 'right'};
  // display: ${isMobile ? 'flex' : 'inherit'};
  // justify-content: ${isMobile ? 'center' : 'unset'};
  img {
    max-width: 60%;
  }
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

interface CardioProps {
  results: any;
}
const Cardio = ({ results }: CardioProps) => {
  const [showInflammation, setShowInflammation] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row className='page-br'>
        <Col md={12}>
          <h3>Cardio</h3>
          <ImageCol md={4}>
            <img
              src="/assets/images/recommendations/WeightReport-cardio.png"
              alt="Exercise"
            />
          </ImageCol>
          <Col md={8}>
            <Alert>
              <p>Increase your level of cardiovascular / aerobic exercise.</p>
            </Alert>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!showInflammation && !printing && (
            <ReadMore value={showInflammation} setValue={setShowInflammation} />
          )}
          {(showInflammation || printing) && (
            <div style={{ marginTop: 30 }}>
              <p>
                Cardiovascular exercise is particularly powerful for you, also
                known as aerobic exercise it increases your heart rate to a fat
                burning zone.
              </p>
              <p>
                You can increase the energy (calories) you burn by increasing
                your level of activity with cardiovascular exercise. By
                following your recommended diet and increasing cardiovascular
                exercise you can hope to reach your optimal weight through fat
                loss.
              </p>
              <p>
                Aerobic exercise can come from doing activities such as:
                running, swimming, walking, dancing, biking, or tramping.
              </p>
            </div>
          )}
          {showInflammation && !printing && (
            <ReadLess value={showInflammation} setValue={setShowInflammation} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Cardio;
