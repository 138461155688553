import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { primary, white, borderGray } from 'styles/theme';

const Wrapper = styled.div`
  position: relative;
  background-color: ${primary};
  color: ${white};
  min-height: 200px;
  border-radius: 5px;
  border: 1px solid ${borderGray};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;

  h4,
  p {
    color: ${white};
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  bottom: ${isMobile ? '-45%' : '-40%'};
  right: -20%;
  overflow: hidden;
  z-index: 0;
`;

const Pad = styled.div`
  padding: ${(props) =>
    props.pad ? props.pad : isMobile ? '20px 10px' : '30px'};
`;

const ImageCol = styled(Col)`
  img {
    max-width: 80%;
  }
`;

const WhatsNext = () => {
  return (
    <Wrapper>
      <Row>
        <Col md={9}>
          <Pad>
            <h4>What's Next?</h4>
            <p>
              We hope the information that you have received in this report has
              simplified the choices you make each day, and helps you enjoy the
              benefits of living your own personal definition of wellbeing – all
              based on your body's makeup.
              <br />
              <br />
              We appreciate there may have been some interesting insights to
              take on board, we are certain though that a greater understanding
              of how your body interacts with certain influences will help you
              to live a life with less inflammation.
              <br />
              <br />
              It is truly exciting for us to be here to guide you on your
              journey, thank you for choosing us.
              <br />
              <br />
              As always, we adore all feedback – if you have any questions or
              would even just like to chat, please reach out to us on either
              0800 464 363 (Gerry will pickup!) or on email to
              caroline@ingeneous.co.nz, or hello@ingeneous.co.nz
              <br />
              <br />
              To your health.
              <br />
              <br />
              Dr. Lindsay and the Ingeneous team
            </p>
          </Pad>
        </Col>
        <ImageCol md={3}>
          <Pad>
            {/* <img src={process.env.PUBLIC_URL + '/assets/images/illustration-1.png'} /> */}
          </Pad>
        </ImageCol>
      </Row>
      <BackgroundImage
        src={
          process.env.PUBLIC_URL + '/assets/images/lifeplus/ing-bg-signoff.png'
        }
      />
    </Wrapper>
  );
};

export default WhatsNext;
