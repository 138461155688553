import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Router from 'routes/Router';
import { AuthContext } from 'stores/auth';

// Importing firebase initialises the client
// eslint-disable-next-line no-unused-vars
import firebase from 'firebase';

import 'styles/custom.scss';
import 'styles/reports.scss';
import 'react-day-picker/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';

function App() {
  const authStore = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    if (authStore.hasToken()) {
      authStore
        .fetchUser()
        .then(() => {
          if (isMounted) setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [authStore]);

  return (
    <BrowserRouter>
      <Router loading={loading} />
    </BrowserRouter>
  );
}

export default App;
