import React from 'react'

const Toxins = () => {
  return (<div>
    <h5>Avoid environmental toxins, car fumes, insecticides, strong cleaning products and even some makeups and personal products</h5>
    <p>
      You are much more sensitive to chemicals and pollutants in the environment than the average person.  Some people might refer to you as the ‘canary in the coalmine’. You will be negatively affected by these substances long before a majority of other people will.
    </p>
    <p>
      Did you know that the average woman applies an average of 168 chemicals on their body each day? The amount of chemicals in our cleaning products, makeup, perfume and cologne is surprising.
    </p>
    <p>
      Please try to limit your exposure to these chemicals when possible and be very selective in the choice of products that you use in your home and beauty regime.
    </p>
  </div>)
}

export default Toxins
