import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import ReadMore from '../../ReadMore';
import ReadLess from '../../ReadLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  position: ${isMobile ? 'static' : 'absolute'};
  right: 0;
  transform: ${isMobile ? 'translateX(0)' : 'translateX(-10%)'};
  margin-top: ${isMobile ? '10px' : '-10px'};
  text-align: ${isMobile ? 'center' : 'right'};
  // display: ${isMobile ? 'flex' : 'inherit'};
  // justify-content: ${isMobile ? 'center' : 'unset'};
  img {
    max-width: 60%;
  }
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const LinkA = styled.a`
  cursor: pointer;
  text-decoration: underline !important;
  color: #6da6ca !important;
`;

interface KetoProps {
  results: any;
}
const Ketogenic = ({ results }: KetoProps) => {
  const [showInflammation, setShowInflammation] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <Col md={12}>
          <h3>Ketogenic</h3>
          <ImageCol md={4}>
            <img src="/assets/images/recommendations/Diet.png" alt="Diet" />
          </ImageCol>
          <Col md={8}>
            <Alert>
              <p>
                Follow a ketogenic diet with about 70% of your calories coming
                from fat and less than 10% from carbohydrates.
              </p>
            </Alert>
            <Alert>
              <p>Maintain BMR calories.</p>
            </Alert>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!showInflammation && !printing && (
            <ReadMore value={showInflammation} setValue={setShowInflammation} />
          )}
          {(showInflammation || printing) && (
            <div>
              <p>
                Your gene SNPs indicate that your body will benefit the most
                from a ketogenic diet during your 3 weeks period fat loss.
              </p>
              <p>
                To lose fat you need to use more energy than you take in. A
                calorie (or kilojoule) is a unit that describes how much energy
                your body gets from the food and drinks you ingest. Your basal
                metabolic rate (BMR) is the energy your body burns to perform
                basic life sustaining functions such as breathing. For you,
                keeping your calories at BMR while reducing your carbohydrates
                with the use of a ketogenic diet will be the most powerful.
              </p>
              <p>
                To enter ketosis and get your body to burn fat rather than
                carbohydrates you must strictly decrease your carbohydrates. It
                may even be helpful to measure your blood ketone level to
                confirm you have entered ketosis. A ketogenic diet typically
                consists of about 70% or your calories from fat, 20% from
                protein, and under 10% from carbs.
              </p>
              <p>
                You can calculate your required BMR calories here:{' '}
                <LinkA
                  href="https://www.calculator.net/calorie-calculator.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.calculator.net/calorie-calculator.html{' .'}
                </LinkA>
                If you want to monitor calories to make sure you are maintaining
                BMR calories and you are getting the correct amount of carbs,
                fats, and protein you can use a tracker app such as
                MyFitnessPal.
              </p>
              <p>
                While simply cutting calories may be helpful for some people,
                your genes suggest a ketogenic diet will be a more powerful fat
                burning tool for your body. Please only follow this diet for 3
                weeks, and then switch to your Lifestyle Plus recommendations
                for at least 3 weeks between periods of fat loss. Once you have
                reached your optimal fat level continue to monitor your
                maintenance calories and follow your Lifestyle Plus
                recommendations to ensure you do not overeat in the weeks
                following restriction.
              </p>
            </div>
          )}
          {showInflammation && !printing && (
            <ReadLess value={showInflammation} setValue={setShowInflammation} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Ketogenic;
