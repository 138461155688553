/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import { Redirect, Link, useLocation } from 'react-router-dom';
import { useObserver } from 'mobx-react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { isBrowser } from 'react-device-detect';

import { getUserByEmail } from 'api/users';
import { AuthContext } from 'stores/auth';
import { Spacer } from 'components/utils';
import Form from './components/Form';
import { primary, blue } from 'styles/theme';

const StyledRow = styled(Row)`
  background-color: ${primary};
  min-height: 100%;
  width: 100%;
  position: absolute;
`;

const ImageCol = styled(Col)`
  background: url(${(props) => props.src}) center center no-repeat;
  background-size: cover;
`;

const LogoWrapper = styled.div`
  padding: ${isBrowser ? '30px 15%' : '15px'};
`;

const Wrapper = styled.div`
  padding: ${isBrowser ? '30px 15%' : '15px'};
  p,
  label {
    color: #ffffff;
  }
`;

const Logo = styled.img`
  max-height: 50px;
`;

const StyledLink = styled(Link)`
  color: ${blue};

  &:hover {
    color: #ffffff;
  }
`;

function Register() {
  const authStore = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState({
    country: 'NZ',
    gender: 'm',
    receive_marketing_communications: true,
  });
  let location = useLocation();

  const q = new URLSearchParams(location.search);
  const email = q.get('email');

  const image =
    process.env.PUBLIC_URL +
    '/assets/images/bg/ING-00' +
    Math.floor(Math.random() * 8 + 1) +
    '.jpg';

  useEffect(() => {
    if (email) {
      setLoading(true);
      getUserByEmail(email).then((res) => {
        setDefaultValues({ ...res.data.user, gender: 'm' });
        setLoading(false);
      });
    }
  }, []);

  return useObserver(() => {
    if (authStore.isAuthenticated()) {
      return <Redirect to="/" />;
    } else {
      return (
        <StyledRow>
          <Col md={6}>
            <LogoWrapper>
              <Link to="/">
                <Logo
                  src={
                    process.env.PUBLIC_URL +
                    '/assets/images/ingeneous_white_r.png'
                  }
                />
              </Link>
            </LogoWrapper>
            <Wrapper>
              {!loading && (
                <>
                  <div>
                    <Form defaultValues={defaultValues} />
                  </div>
                </>
              )}
              <Spacer height={'100px'} />
              <p>
                Already have an account?
                <br />
                <StyledLink to="/login">Login here</StyledLink>
              </p>
            </Wrapper>
          </Col>
          {isBrowser && <ImageCol md={6} src={image} />}
        </StyledRow>
      );
    }
  });
}

export default Register;
