import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { Spacer } from 'components/utils';
import Main from './Main';
import Results from './Results';
import WhatsNext from './WhatsNext';
import { ILifePlusReport } from 'api/reports';

const Wrapper = styled.div`
  margin-top: ${isMobile ? '0' : '-150px'};
`;

interface ReportBodyProps {
  results?: ILifePlusReport;
}

const ReportBody = ({ results }: ReportBodyProps) => {
  return (
    <Wrapper>
      <Main />
      <Spacer height={'30px'} />
      {results && <Results results={results} />}
      <Spacer height={'30px'} />
      <WhatsNext />
    </Wrapper>
  );
};

export default ReportBody;
