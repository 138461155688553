import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';

import { Spacer } from 'components/utils';
import { Page, PageHeader, PageTitle, PageBody } from 'components/Page';
import { AuthContext } from 'stores/auth';

const Wrapper = styled.div`
  padding: 50px 20%;
`;

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
`;

const StepCol = styled(Col)`
  h4 {
    font-weight: 600;
    color: #646464;
  }

  p {
    font-size: 1rem;
  }
`;

const SendKit = () => {
  const authStore = useContext(AuthContext);
  let history = useHistory();

  const redirect = () => {
    authStore.fetchUser();
    history.push('/dashboard');
  };

  return (
    <Page>
      <PageHeader>
        <Spacer height={'30px'} />
        <Container>
          <PageTitle small>Register Kit</PageTitle>
        </Container>
      </PageHeader>
      <PageBody>
        <Container>
          <Wrapper>
            <Row>
              <ImageCol md={3}>
                <img
                  src={
                    process.env.PUBLIC_URL + '/assets/images/illustration-4.png'
                  }
                  alt="Regist kit illistration"
                />
              </ImageCol>
              <StepCol md={9}>
                <Spacer height={'10px'} />
                <h3>Your journey starts here</h3>
                <Spacer height={'20px'} />
                <h4>Step 3 - Send In Your Sample Kit</h4>
                <p>
                  To return your sample simply place the tube into the prepaid
                  courier bag provided and post at any NZ post or national
                  mailbox.
                </p>
                <br />
                <p>
                  To protect your privacy, receiving personnel will remove and
                  discard any identifying information (e.g. name, address)
                  included with your saliva sample before laboratory testing
                  personnel receive the samples for genotyping. Receiving
                  personnel do not perform testing, and testing personnel only
                  handle samples labelled with the unique barcode.
                </p>
                <Button variant="primary" onClick={() => redirect()}>
                  Done
                </Button>
              </StepCol>
            </Row>
          </Wrapper>
        </Container>
      </PageBody>
    </Page>
  );
};

export default SendKit;
