import React from 'react'

const OrganicFood = () => {
  return (<div>
    <h5>Eat predominantly organic foods</h5>
    <p>
      Please note that your genetic SNP testing with Ingeneous is NOT able to tell you if you have an allergy or intolerance to any specific chemicals or pollutants.
    </p>
    <p>
      Your entire detoxification system is much more sensitive to chemicals and pollutants than the average person, and thus you will be impacted more significantly by chemicals used on foods.
    </p>

    <p>Ingeneous tip:</p>
    <p>
      Organic foods can be both difficult to find and expensive to purchase, however organic frozen fruits and foods are often much more affordable and available - plus offer the same amount of nutrition!<br />
      If you would like to “decontaminate” commercially grown produce at home, you can add a cap full of white vinegar to a sink of water for 15 minutes then cook or store these foods as usual.
    </p>

    <p>Shopping guidance:</p>
    <p>
      To help you shop smartly, here are a list of the top 12 most chemically impacted foods that should be your highest priority to buy organically.
    </p>

    <p><strong>The “Dirty Dozen” (in ranked order)</strong></p>
    <ol>
      <li>Strawberries</li>
      <li>Spinach</li>
      <li>Kale</li>
      <li>Nectarines</li>
      <li>Apples</li>
      <li>Grapes</li>
      <li>Peaches</li>
      <li>Cherries</li>
      <li>Pears</li>
      <li>Tomatoes</li>
      <li>Celery</li>
      <li>Potatoes</li>
      <li>Hot/chilli peppers</li>
    </ol>

    <p>
      Here is a list of foods that you will not need to buy organically because the pesticide residue is typically lower than 10%:
    </p>

    <p><strong>“Consistently Clean” (in ranked order)</strong></p>
    <ol>
      <li>Avocadoes</li>
      <li>Sweet corn</li>
      <li>Pineapple</li>
      <li>Onions</li>
      <li>Papaya</li>
      <li>Baby peas (frozen)</li>
      <li>Eggplant</li>
      <li>Asparagus</li>
      <li>Cauliflower</li>
      <li>Rockmelon</li>
      <li>Broccoli</li>
      <li>Mushrooms</li>
    </ol>
  </div>)
}

export default OrganicFood
