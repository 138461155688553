import React from 'react'

const Allium = () => {
  return (<div>
    <h5>Allium rich foods</h5>
    <p>
      Please note that your genetic SNP testing with Ingeneous is NOT able to tell you if you have an allergy or intolerance to any of these allium rich foods. If you know that you have a sensitivity to a suggested food on this list, please choose an alternative.
    </p>
    <p>
      * If you are currently on a FODMAP diet and unsure whether to utilise this recommendation, we recommend that you seek advice from an Ingeneous practitioner.
    </p>
    <p>
      This recommendation is particularly helpful for improving your detoxification function. To increase the allium in your diet you can cook or prepare foods including these ingredients:
    </p>
    <ul>
      <li>Onion</li>
      <li>Garlic</li>
      <li>Leeks</li>
      <li>Scallions</li>
      <li>Chives</li>
      <li>Shallots</li>
    </ul>
  </div>)
}

export default Allium
