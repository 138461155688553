import { Spacer } from 'components/utils';
import React from 'react';

const Cryotherapy = () => {
  return (
    <div>
      <h5>Cryotherapy - 2 to 3 minutes at between minus 110 to 140°C.</h5>
      <p>
        Duration of recommendation: complete 6 sessions to get maximum benefit.
      </p>
      <p>
        This is a particularly powerful recommendation for boosting your body’s
        ability to coordinate inflammation, initiate resolution and communicate
        with the detoxification pathways and vitamin D.
      </p>
      <p className="print-padding-top">
        Cryotherapy involves brief exposure to extremely cold temperatures as
        opposed to cold therapy that involves longer periods of time is cold
        water. You enter a room at a specialty clinic where a machine blasts
        your body with cold temperatures for a short period of time, 2-3 minutes
        maximum at extremely cold temperatures.
      </p>
      <p>
        Cryotherapy is now a therapy that can be found in some locations spread
        across NZ. The evidence for beneficial gene expression changes are based
        on cryotherapy alone in safe controlled short environments.
      </p>
    </div>
  );
};

export default Cryotherapy;
