/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import Select from 'react-select';
import { format, parse } from 'date-fns';
import { isMobile } from 'react-device-detect';

import { userPartialUpdate } from 'api/auth';
import { AuthContext } from 'stores/auth';
import { Spacer } from 'components/utils';
import SelectDatePicker from 'components/form/SelectDatePicker';
import { white, borderGray, gray } from 'styles/theme';

const Wrapper = styled.div`
  background-color: ${white};
  padding: ${isMobile ? '10px' : '30px'};
  border-radius: 5px;
  border: 1px solid ${borderGray};
  color: ${gray};

  h4 {
    color: ${gray};
  }

  p {
    font-size: 1rem;
  }

  form {
    .form-label {
      color: ${gray};
      text-transform: uppercase;
    }
  }
`;

const FormActions = styled.div`
  text-align: right;
`;

const GENDER_OPTIONS = [
  { value: 'm', label: 'Male' },
  { value: 'f', label: 'Female' },
];

const ProfileCompletion = () => {
  const authStore = useContext(AuthContext);
  const { control, register, handleSubmit, setValue, errors, setError } =
    useForm();
  const [gender, setGender] = useState();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    await userPartialUpdate(data)
      .then((res) => {
        setLoading(false);
        authStore.fetchUser();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleGenderSelect = (data) => {
    setValue('gender', data.value);
  };

  useEffect(() => {
    if (authStore.user?.gender) {
      setValue('gender', authStore.user?.gender);
      setGender(
        GENDER_OPTIONS.find((elem) => elem.value === authStore.user.gender)
      );
    }

    if (authStore.user?.birthdate) {
      const dt = parse(authStore.user.birthdate, 'yyyy-MM-dd', new Date());
      setValue('birthdate', format(dt, 'MM/dd/yyyy'));
    }
  }, []);

  return (
    <div>
      <h3>To Be Completed</h3>
      <Wrapper>
        <Row>
          <Col>
            <h4>We Need To Know More About You</h4>
            <p>
              In order to provide personalised information in your reports,
              please update the information below.
            </p>
            <Form className="form" onSubmit={handleSubmit(onSubmit)}>
              <Form.Row>
                <Col xs={12} md={4}>
                  <Form.Group controlId="gender">
                    <Form.Label>Gender</Form.Label>
                    <input
                      type="hidden"
                      name="gender"
                      ref={register({ required: true })}
                    />
                    <Select
                      options={GENDER_OPTIONS}
                      defaultValue={gender}
                      onChange={handleGenderSelect}
                    />
                    {errors.gender && (
                      <Form.Control.Feedback type="invalid">
                        {errors.gender.type === 'required' &&
                          'This field is required'}
                        {errors.gender.type === 'manual' &&
                          errors.gender.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Group controlId="birthdate">
                    <Form.Label>Date Of Birth</Form.Label>
                    <Controller
                      control={control}
                      name="birthdate"
                      render={(props) => (
                        <SelectDatePicker
                          {...props}
                          seterror={setError}
                          format={'dd/MM/yyyy'}
                          onChange={(e) => {
                            props.onChange(e);
                          }}
                        />
                      )}
                    />
                    {errors.birthdate && (
                      <Form.Control.Feedback type="invalid">
                        {errors.birthdate.type === 'required' &&
                          'This field is required'}
                        {errors.birthdate.type === 'manual' &&
                          errors.birthdate.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Form.Row>

              <Spacer height={'10px'} />

              <FormActions>
                <Button variant="primary" type="submit" disabled={loading}>
                  {!loading ? (
                    'Save'
                  ) : (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
              </FormActions>
            </Form>
          </Col>
        </Row>
      </Wrapper>
    </div>
  );
};

export default ProfileCompletion;
