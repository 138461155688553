import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';

import { Spacer } from 'components/utils';
import { Page, PageHeader, PageTitle, PageBody } from 'components/Page';
import { AuthContext } from 'stores/auth';

const BannerWrapper = styled.div``;

const Banner = styled.div`
  background-color: #f2f2f2;
  border: 1px solid #e5864b;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 16px;

  p {
    margin-bottom: 0;
  }
`;

const Wrapper = styled.div`
  padding: 50px 20%;
`;

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
`;

const StepCol = styled(Col)`
  h4 {
    font-weight: 600;
    color: #646464;
  }

  p {
    font-size: 1rem;
  }
`;

const Swab = () => {
  const authStore = useContext(AuthContext);
  let history = useHistory();

  const redirect = () => {
    authStore.fetchUser();
    history.push('/register-kit/send');
  };

  return (
    <Page>
      <PageHeader>
        <Spacer height={'30px'} />
        <Container>
          <PageTitle small>Register Kit</PageTitle>
        </Container>
      </PageHeader>
      <PageBody>
        <Container>
        <BannerWrapper>
            <Container>
              <Banner>
                <p>
                If you currently have cold, flu, or Covid-19 symptoms please do 
                not submit an Ingeneous DNA swab.
                </p>
                <Spacer height={'15px'} />
                <p>
                Also, if you have been deemed a 
                close contact, self-monitor for Covid-19 symptoms for 7 days 
                after you were exposed, do not submit a DNA swab until these 
                7 days are complete with no symptoms or having had a negative RAT test.
                </p>
                <Spacer height={'15px'} />
                <p>
                Please also ensure that the person doing this Ingeneous DNA swab doesn’t have clinical 
                signs or symptoms of the following: Monkeypox virus; Middle East respiratory syndrome; 
                plague; severe acute respiratory syndrome (SARS); smallpox; viral hemorrhagic fevers; 
                yellow fever; Prion protein infection (including PRNP, PrPc and PrPsc) or any other 
                agent of transmissible spongiform encephalopathy from any other species; Poliovirus; 
                or Pathogens of animal biosecurity concern (as published by the Australian Department 
                of Agriculture, Water and the Environment 2020).
                </p>
                <Spacer height={'15px'} />
                <p>
                We appreciate your understanding of these safety measures to help us prevent 
                the spread of disease. Please email us at hello@ingeneous.co.nz if you have any queries.
                </p>
              </Banner>
            </Container>
          </BannerWrapper>
          <Wrapper>
            <Row>
              <ImageCol md={3}>
                <img
                  src={
                    process.env.PUBLIC_URL + '/assets/images/illustration-3.png'
                  }
                  alt="Swab illustration"
                />
              </ImageCol>
              <StepCol md={9}>
                <Spacer height={'10px'} />
                <h3>Your journey starts here</h3>
                <Spacer height={'20px'} />
                <h4>Step 2 - Swab Your Mouth</h4>
                <ul>
                  <li>
                    Do not eat, drink, smoke, brush your teeth or chew gum for
                    15 minutes prior to collecting your saliva sample.
                  </li>
                  <li>
                    Insert the swab into your mouth and rub firmly against the
                    inside of your cheek or underneath your lower lip. You
                    should see the imprint on the outside of your cheek.
                  </li>
                  <li>
                    For maximum DNA collection apply reasonable pressure (it
                    shouldn't hurt though) and rub for 30 seconds on each side
                    of your mouth.
                  </li>
                  <li>
                    Gently shake off any excess saliva and place the swab back
                    into the tube, being careful not to touch it with your
                    fingers, and replace the cap.
                  </li>
                </ul>
                <Button variant="primary" onClick={() => redirect()}>
                  Next
                </Button>
              </StepCol>
            </Row>
          </Wrapper>
        </Container>
      </PageBody>
    </Page>
  );
};

export default Swab;
