import React from 'react';
import { Row } from 'react-bootstrap';
import Chart from 'react-google-charts';
import styled from 'styled-components';

import { BodyCol } from './utils';

const Proportions = styled.div`
  text-align: center;
  width: 100%;
`;

const NutritionChart = ({ results }) => {
  return (
    <Row>
      <BodyCol xs={12}>
        <h4>Nutritional Overview</h4>
      </BodyCol>
      <BodyCol xs={12} md={7}>
        <p>
          We have incorporated all the findings from your genetic test, to
          create an overall summary view.
        </p>
        <p>
          Please hover over the pie chart graphic to see your recommended
          percentages to follow.
        </p>
        <p>
          This approach is the most complementary style of eating for your genes
          and your body. It helps your body to digest and process the foods
          optimally – in effect, working with inflammation rather than against
          it.
        </p>
      </BodyCol>
      <BodyCol xs={12} md={5}>
        <Proportions>Your Proportions</Proportions>
        <Chart
          width={'100%'}
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={[
            ['Type', 'Percentage'],
            ['Fat', results.fat],
            ['Non Starchy Carbs', results.non_starch_carbs],
            ['Starch Carbs', results.starch_carbs],
            ['Protein', results.protein],
          ]}
          options={{
            colors: ['#F7DEDF', '#22785e', '#43b290', '#C1C1C1'],
            legend: {
              alignment: 'start',
              position: 'top',
              maxLines: 4,
            },
            textStyle: {
              fontSize: 10,
            },
            pieSliceText: 'none',
          }}
          rootProps={{ 'data-testid': '1' }}
        />
      </BodyCol>
    </Row>
  );
};

export default NutritionChart;
