import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { darken } from 'polished'

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  h2 {
    color: #ffffff;
  }

  img {
    display: inline-block;
    height: 40px;
  }
`

const QRWrapper = styled.div`
  max-width: 400px;
  border-radius: 15px;

  .qr-reader {
    border-radius: 15px;

    section {
      border-radius: 15px;
    }

    section > div {
      border: 20px solid rgba(0, 0, 0, 0.5) !important;
      box-shadow: ${props => props.valid ? 'rgba(0, 255, 0, 0.5)' : 'rgba(255, 0, 0, 0.5)'} 0px 0px 0px 5px inset !important;
    }
  }
`

const RadioWrapper = styled.div`
  color: #ffffff;
`

const CheckboxWrapper = styled.div`
  color: #ffffff;
`

const FormActions = styled.div`
  display: flex;
  align-items: center;

  ${Button} {
    margin-right: 10px;
  }
`

const LabelHelp = styled.span`
  color: ${darken(0.1, '#ffffff')};
  font-size: 10px;
  display: inline-block;
  margin-left: 10px;
`

const FormMessage = styled.div`
  margin-left: 10px;
  color: #ffffff;
`

export { Header, QRWrapper, RadioWrapper, CheckboxWrapper, FormActions, LabelHelp, FormMessage }
