import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { primary } from 'styles/theme'

const NavWrapper = styled.div`
  background-color: ${props => props.userLoggedIn ? primary : '#ffffff'};

  img {
    height: ${props => props.userLoggedIn ? '30px' : '50px'};
  }
`

const CustomNavbar = styled(Navbar)`
  padding: 15px 0;
`

const CustomContainer = styled(Container)`
  display: block !important;
`

const Wrapper = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
  align-items: center;
  text-align: ${isMobile ? 'center' : 'left'};
  width: ${isMobile ? '100%' : ''};
`

const CustomCollapse = styled(Navbar.Collapse)`
  justify-content: ${props => props.rightalign ? 'flex-end' : 'flex-start'};
  display: ${isMobile ? 'block' : 'flex'};
`

const CustomNav = styled(Nav)`
  display: ${isMobile ? 'block' : 'flex'};

  span {
    color: #ffffff;
    text-transform: uppercase;
  }
`

const Text = styled(Link)`
  display: inline-block;
  margin-right: 20px;
  color: #ffffff !important;
  font-size: 0.9rem;
  text-transform: uppercase;
`

const Header = () => {
  return (<NavWrapper userLoggedIn={true}>
    <CustomNavbar>
      <CustomContainer>
        <Row>
          <Col xs={12} sm={3}>
            <Wrapper className={isMobile ? 'mb-4': ''}>
              <Link to='/'>
                <Navbar.Brand><img src={process.env.PUBLIC_URL + '/assets/images/ingeneous_white_r.png'} alt='' /></Navbar.Brand>
              </Link>
            </Wrapper>
            <Navbar.Toggle aria-controls="header-nav" />
          </Col>
          <Col xs={12} sm={9}>
            <CustomCollapse id="header-nav"><Wrapper>
              <CustomNav>
                <Navbar.Text>
                  <Text to='/consultation'>Consult</Text>
                </Navbar.Text>
              </CustomNav>
            </Wrapper></CustomCollapse>
          </Col>
        </Row>
      </CustomContainer>
    </CustomNavbar>
  </NavWrapper>)
}

export default Header
