import React from 'react';
import { Form, Col } from 'react-bootstrap';
import Select from 'react-select';
import styled from 'styled-components';

import { REGIONS } from '../../../../Order/components/regions';

const Wrapper = styled.div`
  padding: 20px 0;

  h5 {
    display: inline-block;
    margin-right: 1rem;
  }
`;

const EventAddress = ({ formProps }) => {
  const { register, setValue, errors, clearErrors } = formProps;

  return (
    <Wrapper>
      <div>
        <h5>Address</h5>
        <span className="muted">Optional</span>
      </div>
      <Form.Row>
        <Form.Group as={Col} controlId="line1">
          <Form.Label>Street</Form.Label>
          <Form.Control
            name="line1"
            type="text"
            ref={register}
            isInvalid={!!errors?.line1}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="city">
          <Form.Label>City</Form.Label>
          <Form.Control
            name="city"
            type="text"
            ref={register}
            isInvalid={!!errors?.city}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="region">
          <Form.Label>Region</Form.Label>
          <input type="hidden" name="region" ref={register} />
          <Select
            options={REGIONS}
            isClearable={true}
            onChange={(data) => {
              setValue('region', data?.value);
              if (data?.value) {
                clearErrors(['region']);
              }
            }}
            styles={{
              control: (provided, state) => ({
                ...provided,
                borderColor:
                  errors?.region?.type === 'required' ? 'red' : '#ced4da',
              }),
            }}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="postal_code">
          <Form.Label>Postal Code</Form.Label>
          <Form.Control
            name="postal_code"
            type="text"
            ref={register}
            isInvalid={!!errors?.postal_code}
          />
        </Form.Group>
      </Form.Row>
    </Wrapper>
  );
};

export default EventAddress;
