/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Page, PageHeader, PageTitle, PageBody } from 'components/Page';
import { Spacer } from 'components/utils';
import { Row, Col, Card, Container, Button, Form } from 'react-bootstrap';
import styled from 'styled-components';
import { white, gray, primary, alert } from 'styles/theme';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useObserver } from 'mobx-react-lite';
import { AuthContext } from 'stores/auth';

import { device } from 'components/breakpoints';
import { getPractionerProducts } from 'api/practitioner';
import { Product } from 'api/core';
import { CommonContext } from 'stores/common';

const Wrapper = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
  align-items: center;
  padding: 15px 0;

  .title {
    flex: 1;
    display: ${isMobile ? 'block' : 'flex'};
    align-items: center;
    justify-content: space-between;

    h1 {
      margin: 0;
      margin-right: 20px;
    }
  }
`;

const FormLayoutPosition = styled.div`
  & > .container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .container > .row > div[class^='col']:first-child {
    padding-right: 2rem;
  }
  .container > .row > div[class^='col']:nth-last-child(1) {
    padding-left: 2.5rem;
    background: transparent;
    padding-bottom: 5rem;
  }

  ${device.xl`
    & > .container {
      position: static;
      max-width: 100%;
    }
    .container > .row > div[class^='col']:nth-last-child(1) {
      background: #fff;
      padding-right: 40px;
    }
    .container > .row > div[class^='col']:first-child {
      padding-left: 40px;
    }
  `}
`;

const Subtitle = styled.p`
  color: ${gray};
  font-size: 26px;
  font-weight: 700;
`;

const DescriptionArea = styled.p`
  color: ${gray};
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 1rem;
`;

const CardStyle = styled.button<{ selected: boolean }>`
  ${({ selected }) =>
    selected &&
    `
    outline: solid !important;
    outline-color: ${primary} !important;
    border: none;
    background-color: ${white} !important;

    .text-muted {
      color: ${primary} !important;
    }`}
  border-radius: 5px;
  border: 1px solid #aeaeae;
`;

const Title = styled.p`
  color: #949494;
  font-size: ${isMobile ? '12px' : '14px'};
  text-align: left;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
`;
const InputWrapper = styled.div`
  margin: 0 0 20px;

  & > input {
    max-width: 260px;
  }

  /* remove spin */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const Alert = styled.div`
  background-color: #f2f2f2;
  padding: 15px 10px;
  text-align: center;
  border: 1px solid ${alert};
  span {
    margin-bottom: 0;
    color: ${alert};
  }
`;

interface Participants {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  profession: string;
  storeTradingName: string;
}

const Checkout = () => {
  const authStore = useContext(AuthContext);
  let history = useHistory();
  const commonStore = useContext(CommonContext);

  // eslint-disable-next-line
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [products, setProducts] = useState<Product[]>([]);

  const [participants, setParticipants] = useState<any[]>([]);
  const [participantDetails, setParticipantDetails] = useState<Participants[]>(
    []
  );
  const [participantsNumber, setParticipantsNumber] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const [sortedProducts, setSortedProducts] = useState<any[]>([]);

  let productsExisting = [];

  const fetchProducts = useCallback(async () => {
    try {
      let res = await getPractionerProducts();

      if (res.status === 200) {
        setProducts(
          res.data.products.filter((product) => product.status === 'active')
        );

        let getDates = res.data.products.map((item) => {
          let itemCleanup = item.title.split('-')[1].replace(/ /g, '');
          let itemFormat = itemCleanup.replaceAll('/', '-');
          return new Date(
            itemFormat.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3')
          );
        });

        productsExisting = res.data.products.filter(
          (product) => product.status === 'active'
        );
        productsExisting.forEach((object, key) => {
          Object.assign(object, { session_date: getDates[key] });
        });

        const sortFunction = (a: any,b: any) => {
          var dateA = new Date(a.session_date).getTime();
          var dateB = new Date(b.session_date).getTime();
          return dateA < dateB ? 1 : dateA > dateB ? -1 : 0;  
        }

        setSortedProducts(productsExisting.sort(sortFunction));

      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const onChangeNumberParticipants = (e: { target: { value: any } }) => {
    const numberOfParticipant = e.target.value;
    setParticipantsNumber(parseInt(e.target.value));

    setParticipants(numberOfParticipant);
    if (numberOfParticipant > 0 && numberOfParticipant <= 10) {
      const generateArrays = Array.from(Array(Number(e.target.value)).keys());
      setParticipants(generateArrays);
    }

    participantDetails.splice(0, numberOfParticipant);
    updateParticipantDetails();
  };

  const updateParticipantDetails = () => {
    commonStore.setPractitionerCheckout({
      ...commonStore.getPractitionerCheckout(),
      participants: participantDetails,
    });
  };

  const submit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      history.push('/practitioners/payment');
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    const price = parseFloat(selectedProduct?.variants[0].price ?? '0.00') ?? 0;
    // Keeping this just in case
    // if (participantsNumber > 1 && participantsNumber <= 10) {
    //   // const fee = price + (participantsNumber - 1) * (price * 0.5);
    //   const fee =
    //     Math.ceil(participantsNumber / 2) * price +
    //     Math.floor(participantsNumber / 2) * price * 0.5;
    //   setGrandTotal(fee);
    // } else if (participantsNumber === 1) {
    //   setGrandTotal(price);
    // } else {
    //   setGrandTotal(0);
    // }
    setGrandTotal(price * participantsNumber)

    commonStore.setPractitionerCheckout({
      ...commonStore.getPractitionerCheckout(),
      product_id: selectedProduct?.id,
      price: grandTotal,
    });
  }, [
    selectedProduct,
    participants,
    participantsNumber,
    // participantDetails,
    commonStore,
    grandTotal,
  ]);

  useEffect(() => {
    fetchProducts();
  }, []);

  return useObserver(() => {
    const billingAddresses = authStore.addresses
      ? authStore.addresses.filter(
          (item: { address_type: string }) => item.address_type === 'billing'
        )
      : [];
    const billingAddress = billingAddresses[0];
    const shippingAddresses = authStore.addresses
      ? authStore.addresses.filter(
          (item: { address_type: string }) => item.address_type === 'delivery'
        )
      : [];
    const shippingAddress = shippingAddresses[0];

    let defaultValues = {
      delivery: { ...shippingAddress },
      billing: null,
      first_name: null,
      last_name: null,
    };

    if (billingAddress) {
      defaultValues.billing = { ...billingAddress };
    } else if (!billingAddress && shippingAddress) {
      defaultValues.billing = { ...shippingAddress };
    }

    defaultValues.first_name = authStore?.user?.first_name;
    defaultValues.last_name = authStore?.user?.last_name;

    return (
      <Page>
        <PageHeader>
          <Container>
            <Wrapper>
              <div className="title">
                <PageTitle>
                  <img
                    src="/assets/images/ingeneous_white_r.png"
                    alt="ingeneous"
                    width={190}
                  />
                </PageTitle>
              </div>
            </Wrapper>
          </Container>
        </PageHeader>

        <PageBody style={{ position: 'relative' }}>
          <FormLayoutPosition>
            <Container>
              <Form onSubmit={submit}>
                <Row>
                  <Col xs={12} sm={6}>
                    <Spacer height={'40px'} />
                    <h2>
                      Ingeneous <br /> Education Course
                    </h2>
                    <Spacer height={'10px'} />
                    <DescriptionArea>
                      The future of medicine is personalised, and genetic
                      testing allows for this like never before. We all want
                      better outcomes for our customers, and Ingeneous strives
                      to do this by making personalised nutrigenomic
                      recommendations.
                    </DescriptionArea>
                    <DescriptionArea>
                      Becoming an Ingeneous Practitioner will add an extra layer
                      of personalisation, upskill your genetic knowledge and
                      help patients not just feel healthy but well!
                    </DescriptionArea>
                    <DescriptionArea>
                      The Ingeneous Education Course includes everything you
                      need to become a Certified Ingeneous Practitioner and also
                      includes your own personal DNA testing. With this testing
                      we unlock your first three reports: Essential Start,
                      LifeStyle Plus, and Methylation Practitioner Consultation
                      so you can experience the Ingeneous Program.
                    </DescriptionArea>
                    <Subtitle>Participants</Subtitle>
                    <InputWrapper>
                      <label htmlFor="number">
                        How many people are joining us?{' '}
                      </label>
                      {/* <input
                      type="number"
                      min="1"
                      max="10"
                      className="form-control"
                      name="number"
                      onChange={onChangeNumberParticipants}
                    /> */}
                      <select
                        className="form-control"
                        defaultValue={'0'}
                        onChange={onChangeNumberParticipants}
                      >
                        <option disabled value="0">
                          Select a value..
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </InputWrapper>
                    {participants.length ? (
                      <>
                        {participants.map((part: number, index: number) => (
                          <div key={index}>
                            <AddParticipant
                              index={index}
                              participantDetails={participantDetails}
                              setParticipantDetails={setParticipantDetails}
                              updateParticipantDetails={
                                updateParticipantDetails
                              }
                            />
                          </div>
                        ))}
                      </>
                    ) : null}

                    {participants.length >= 10 ? (
                      <Alert>
                        <span>
                          For large groups please contact hello@ingeneous.co.nz
                        </span>
                      </Alert>
                    ) : (
                      ''
                    )}
                  </Col>
                  <Col xs={12} sm={6}>
                    <Spacer height={'40px'} />

                    <Subtitle>Select Training</Subtitle>
                    {sortedProducts.map((item, index) => (
                      <div key={index}>
                        {item.variants[0] && (
                          <CardStyle
                            type="button"
                            style={{
                              width: '100%',
                              background: '#F5F5F5',
                              marginTop: '15px',
                            }}
                            onClick={() =>
                              setSelectedProduct((selected) =>
                                selected === item ? null : item
                              )
                            }
                            selected={selectedProduct === item}
                          >
                            <Spacer height={'20px'} />
                            <TextWrapper>
                              <Card.Subtitle className="mb-2 text-muted">
                                {item.title.split('-')[0]}
                              </Card.Subtitle>
                              <Card.Subtitle className="mb-2 text-muted">
                                {item.title.split('-')[1]}
                              </Card.Subtitle>
                            </TextWrapper>
                            <hr />
                            <Card.Body style={{ padding: 0 }}>
                              <TextWrapper>
                                <div style={{ marginRight: 20 }}>
                                  <Title>{item.body_html}</Title>
                                </div>
                                <Title>${item.variants[0].price}</Title>
                              </TextWrapper>
                            </Card.Body>
                          </CardStyle>
                        )}
                      </div>
                    ))}
                    <Row>
                      {participantsNumber > 0 && (
                        <Col xs={12} sm={12}>
                          <Spacer height={'40px'} />
                          <Subtitle>Summary</Subtitle>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <span>Participant 1</span>
                            <span>
                              $
                              {parseFloat(
                                selectedProduct?.variants[0].price ?? '0'
                              )}
                            </span>
                          </div>
                          {participantsNumber > 1
                            ? participants.map(
                                (item, index) =>
                                  item > 0 && (
                                    <div
                                      key={item}
                                      style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <span>
                                        Participant {item + 1}{' '}
                                      </span>
                                      <span>
                                        $
                                        {parseFloat(
                                          selectedProduct?.variants[0].price ??
                                            '0.00'
                                        )}
                                      </span>
                                    </div>
                                  )
                              )
                            : null}
                        </Col>
                      )}
                      <Col xs={12} sm={12}>
                        <div
                          style={{
                            textAlign: 'right',
                            margin: '45px 0 20px',
                            borderTop: '1px solid #AEAEAE',
                            paddingTop: '15px',
                          }}
                        >
                          <p
                            style={{
                              marginBottom: '0',
                              fontWeight: 400,
                              fontSize: '2.125rem',
                            }}
                          >
                            $ {grandTotal}
                          </p>
                          <p
                            style={{
                              marginBottom: '0',
                              fontWeight: 400,
                              fontSize: '0.875rem',
                            }}
                          >
                            Total inc. GST (NZD)
                          </p>
                        </div>
                        <div
                          style={{
                            textAlign: 'right',
                            margin: '0',
                          }}
                        >
                          <Button
                            type="submit"
                            variant="primary"
                            disabled={
                              selectedProduct === null ||
                              participantsNumber === 0
                            }
                          >
                            Next
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Container>
          </FormLayoutPosition>
        </PageBody>
      </Page>
    );
  });
};

export default Checkout;

interface IAddParticipantProps {
  index: number;
  participantDetails: Participants[];
  setParticipantDetails: React.Dispatch<React.SetStateAction<Participants[]>>;
  updateParticipantDetails: any;
}
const AddParticipant = ({
  index,
  participantDetails,
  setParticipantDetails,
  updateParticipantDetails,
}: IAddParticipantProps) => {
  const [state, setState] = useState({
    emailAddress: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    profession: '',
    storeTradingName: '',
  });

  const handleInput = (e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    participantDetails[index] = state;
    updateParticipantDetails();
  }, [state, participantDetails, index, setParticipantDetails]);

  return (
    <>
      <Form.Row>
        <Form.Group as={Col} controlId={`storeTradingName`}>
          <Form.Label>Participant {index + 1} Store Trading Name</Form.Label>
          <Form.Control
            name="storeTradingName"
            type="text"
            placeholder="enter store trading name"
            onChange={handleInput}
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="firstName">
          <Form.Label>Participant {index + 1} First Name</Form.Label>
          <Form.Control
            name="firstName"
            type="text"
            placeholder="enter first name"
            onChange={handleInput}
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="lastName">
          <Form.Label>Participant {index + 1} Last Name</Form.Label>
          <Form.Control
            name="lastName"
            type="text"
            placeholder="enter last name"
            onChange={handleInput}
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="emailAddress">
          <Form.Label>Participant {index + 1} Email Address</Form.Label>
          <Form.Control
            name="emailAddress"
            type="email"
            placeholder="enter email address"
            onChange={handleInput}
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="phoneNumber">
          <Form.Label>Participant {index + 1} Phone Number</Form.Label>
          <Form.Control
            name="phoneNumber"
            type="text"
            placeholder="enter phone number"
            onChange={handleInput}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="profession">
          <Form.Label>
            Participant {index + 1} Health profession (with indemnity insurance)
            or insured practitioner partner
          </Form.Label>
          <Form.Control
            name="profession"
            type="text"
            placeholder="enter profession"
            onChange={handleInput}
            required
          />
        </Form.Group>
      </Form.Row>

      <hr />
    </>
  );
};
