import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { gray, primary } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import View from '../View';
import Close from '../ViewLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const AlertRef = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
`;

const SubtitleGray = styled.h5`
  color: ${gray};
`;

const WrapperRef = styled.div`
  margin-top: 5px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const ListRef = styled.li`
  font-weight: 300;
  padding-left: 20px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    word-break: break-all;
  }
`;

const WrapperTitle = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
`;

interface VitaminAProps {
  results: any;
}
const VitaminA = ({ results }: VitaminAProps) => {
  const [show, setShow] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/WeightReport-vitA.png"
            alt="food"
            width={'60%'}
          />
        </ImageCol>
        <Col md={8}>
          <WrapperTitle>
            {isMobile ? (
              <>
                <SubtitleGray>Your superfood is</SubtitleGray>
                <Subtitle>Vitamin A Rich Foods</Subtitle>
              </>
            ) : (
              <>
                <SubtitleGray>Your superfood is</SubtitleGray>
                &nbsp;
                <Subtitle>Vitamin A Rich Foods</Subtitle>
              </>
            )}
          </WrapperTitle>
          <Alert>
            <p>
              Try to include vitamin A rich foods in your diet daily. The more
              the better!
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <p>
                Your superfood is Vitamin A rich foods. It can help support your
                weight loss by assisting other systems and organs, boosting your
                metabolism (1).
              </p>
              <p>
                Vitamin A is an antioxidant, is important to heart health and
                can balance your immune system (1,2). This essential vitamin
                also plays a key role in eyes health, skin quality and
                preventing acne, lowering your risk of some cancers, bones
                health, and your growth and development (2). A study that
                studied Vitamin A in obese animal modles found it had the power
                to help regulate obesity and related symptoms making it more
                managage and less damaging to health (3).
              </p>
              <p>
                Foods rich in vitamin A include: sweet potato, liver, pumpkin,
                spinach, carrots, cantaloupe, herring, and mangos (4). Vitamin A
                is beneficial to many areas of your health and can support your
                systems to function the best allowing you to succeed at losing
                weight!
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
      <Row className="no-print">
        <Col>
          <AlertRef>
            <span>References</span>
            {!showReferences && (
              <View value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <Close value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <WrapperRef>
                <ListGroup>
                  <ListRef>
                    <a href="https://vitagene.com/blog/vitamin-a-for-weight-loss/#:~:text=Vitamin%20A%20isn">
                      https://vitagene.com/blog/vitamin-a-for-weight-loss/#:~:text=Vitamin%20A%20isn't%20a,body%20on%20a%20metabolic%20l
                      evel.
                    </a>
                  </ListRef>
                  <ListRef>
                    <a href="https://www.healthline.com/nutrition/vitamin-a-benefits">
                      https://www.healthline.com/nutrition/vitamin-a-benefits
                    </a>
                  </ListRef>
                  <ListRef>
                    <a href="https://onlinelibrary.wiley.com/doi/full/10.1038/oby.2006.7">
                      https://onlinelibrary.wiley.com/doi/full/10.1038/oby.2006.7
                    </a>
                  </ListRef>
                  <ListRef>
                    <a href="https://ods.od.nih.gov/factsheets/VitaminA-HealthProfessional/">
                      https://ods.od.nih.gov/factsheets/VitaminA-HealthProfessional/
                    </a>
                  </ListRef>
                </ListGroup>
              </WrapperRef>
            )}
          </AlertRef>
        </Col>
      </Row>
    </>
  );
};

export default VitaminA;
