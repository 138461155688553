import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { gray, primary } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import View from '../View';
import Close from '../ViewLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const AlertRef = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
  margin-bottom: 30px;
`;

const SubtitleGray = styled.h5`
  color: ${gray};
  margin-top: 10px;
`;

const WrapperRef = styled.div`
  margin-top: 5px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const ListRef = styled.li`
  font-weight: 300;
  padding-left: 20px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    word-break: break-all;
  }
`;

interface TriMetabolicProps {
  results: any;
}
const TriMetabolic = ({ results }: TriMetabolicProps) => {
  const [show, setShow] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/Supplement.png"
            alt="supplement"
            width={'60%'}
          />
        </ImageCol>
        <Col md={8}>
          <SubtitleGray>
            Your most powerful supplement for weight loss is
          </SubtitleGray>
          <Subtitle>Tri-Metabolic Control</Subtitle>
          <Alert>
            <p>
              Suggested Supplement: Tri-Metabolic Control, Douglas labs (2
              capsules before meals)
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <p>
                Taking Tri-Metabolic Control will support your metabolic
                hormones to assist satiety signals and could help you in
                reaching your optimal weight.
              </p>
              <p>
                This supplement contains Piper betle leaf, biflorus seed, and
                acetyl-L-carnitine which together can support metabolic hormones
                (1). It supports leptin which signals satiety, adiponectin which
                stimulates glucose uptake with increased insulin sensitivity,
                and ghrelin which signals hunger within the gut (2,3,4).
                Tri-Metabolic Control can help you reach your optimal weight
                along with your other recommendations by influencing healthy
                appetite, satiety, and fat metabolism.
              </p>
              <p style={{ fontWeight: 'bold' }}>
                This supplement is best for your genes. If you have any concerns
                this may not suit your current state of health or medications
                check with your doctor.
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
      <Row className="no-print">
        <Col>
          <AlertRef>
            <span>References</span>
            {!showReferences && (
              <View value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <Close value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <WrapperRef>
                <ListGroup>
                  <ListRef>
                    <a href="https://www.douglaslabs.com/tri-metabolic-controltm.html">
                      https://www.douglaslabs.com/tri-metabolic-controltm.html
                    </a>
                  </ListRef>
                  <ListRef>
                    <a href="https://www.hormone.org/your-health-and-hormones/glands-and-hormones-a-to-z/hormones/leptin">
                      https://www.hormone.org/your-health-and-hormones/glands-and-hormones-a-to-z/hormones/leptin
                    </a>
                  </ListRef>
                  <ListRef>
                    Takashi Kadowaki, Toshimasa Yamauchi, Adiponectin and
                    Adiponectin Receptors, Endocrine Reviews, Volume 26, Issue
                    3, 1 May 2005, Pages 439–451,&nbsp;
                    <a href="https://doi.org/10.1210/er.2005-0005">
                      https://doi.org/10.1210/er.2005-0005
                    </a>
                  </ListRef>
                  <ListRef>
                    Pradhan G, Samson SL, Sun Y. Ghrelin: much more than a
                    hunger hormone. Curr Opin Clin Nutr Metab Care.
                    2013;16(6):619-624. doi:10.1097/MCO.0b013e328365b9be &nbsp;
                    <a
                      href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4049314/#:~:text=Ghrelin%20is%20a%20multifaceted%20gut,as%20the%20
                      %E2%80%9Chunger%20hormone%E2%80%9D"
                      style={{ fontSize: 12 }}
                    >
                      https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4049314/#:~:text=Ghrelin%20is%20a%20multifaceted%20gut,as%20the%20
                      %E2%80%9Chunger%20hormone%E2%80%9D{' '}
                    </a>
                  </ListRef>
                </ListGroup>
              </WrapperRef>
            )}
          </AlertRef>
        </Col>
      </Row>
    </>
  );
};

export default TriMetabolic;
