import React from 'react';
import styled from 'styled-components';

import Header from './components/Header';
import Footer from './components/Footer';
import Navigation from './components/Navigation';

import { footerHeight } from 'styles/theme';

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
`

const Container = styled.div`
  padding-bottom: ${footerHeight};    /* Footer height */
  position: relative;
`

const ChildWrapper = styled.div`
  padding: 20px 40px;
`

function AdminWrapper ({ children }) {
  return (
    <Wrapper>
      <Container>
        <Header />
        <Navigation />
        <ChildWrapper>
          {children}
        </ChildWrapper>
      </Container>
      <Footer />
    </Wrapper>
  );
};

export default AdminWrapper;
