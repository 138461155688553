import axios from 'axios'

const buildUrl = (url) => process.env.REACT_APP_API_ROOT + url

const PublicAPI = {
  get: (url, query) => {
    return axios({
      method: 'get',
      url: buildUrl(url),
    })
  },

  post: (url, payload) => {
    return axios({
      method: 'post',
      url: buildUrl(url),
      data: payload
    })
  }
}

export default PublicAPI
