import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import Supplement from './Supplement';
import { gray } from 'styles/theme';
import { Spacer } from 'components/utils';

import Calcium from 'content/Recommendations/KeyNutrient/Calcium';
import Fibre from 'content/Recommendations/KeyNutrient/Fibre';
import Omega from 'content/Recommendations/KeyNutrient/Omega';

import Cryotherapy from 'content/Recommendations/LifeStyle/Cryotherapy';
import Cigarette from 'content/Recommendations/LifeStyle/Cigarette';
import OrganicFood from 'content/Recommendations/LifeStyle/OrganicFood';
import Sauna from 'content/Recommendations/LifeStyle/Sauna';
import SmokedFood from 'content/Recommendations/LifeStyle/SmokedFood';
import Sun from 'content/Recommendations/LifeStyle/Sun';
import Toxins from 'content/Recommendations/LifeStyle/Toxins';

import Allium from 'content/Recommendations/Superfood/Allium';
import BetaCarotene from 'content/Recommendations/Superfood/BetaCarotene';
import FruitsVegetables from 'content/Recommendations/Superfood/FruitsVegetables';
import Lycopene from 'content/Recommendations/Superfood/Lycopene';
import OliveOil from 'content/Recommendations/Superfood/OliveOil';
import SesameParsley from 'content/Recommendations/Superfood/SesameParsley';
import VitaminE from 'content/Recommendations/Superfood/VitaminE';
import Zinc from 'content/Recommendations/Superfood/Zinc';

const Title = styled.h5`
  color: ${gray};
  border-bottom: 5px;
`;

const Wrapper = styled.div`
  border-bottom: 1px solid #dedede;
  /* word-break: break-all; */
`;

const Recommendation = ({ title, name, component }) => {
  return (
    <Wrapper>
      <Row>
        <Col xs={12} md={12}>
          <Title>{title}</Title>
        </Col>
        <Spacer height={'10px'} />
        <Col xs={12} md={12}>
          {name.toLowerCase() === '17g of fibre' && <Fibre />}
          {name.toLowerCase() === 'calcium' && <Calcium />}
          {name.toLowerCase() === '33 fruit and veggie' && <FruitsVegetables />}
          {name.toLowerCase() === 'omega ratio' && <Omega />}

          {name.toLowerCase() === 'cryotherapy' && <Cryotherapy />}
          {name.toLowerCase() === 'sauna' && <Sauna />}
          {name.toLowerCase() === 'no bbq' && <SmokedFood />}
          {name.toLowerCase() === 'organic foods' && <OrganicFood />}
          {name.toLowerCase() === 'avoid environmental toxins' && <Toxins />}
          {name.toLowerCase() === 'avoid cigarette smoke' && <Cigarette />}
          {name.toLowerCase() === 'sun exposure' && <Sun />}

          {/* {name.toLowerCase() === 'oysters' && <Cryotherapy />} */}
          {name.toLowerCase() === 'allium' && <Allium />}
          {name.toLowerCase() === 'zinc' && <Zinc />}
          {name.toLowerCase() === 'sesame and parsley' && <SesameParsley />}
          {name.toLowerCase() === 'lycopene' && <Lycopene />}
          {name.toLowerCase() === 'beta carotene' && <BetaCarotene />}
          {name.toLowerCase() === 'vitamin e' && <VitaminE />}
          {name.toLowerCase() === 'olive oil' && <OliveOil />}
        </Col>
      </Row>
    </Wrapper>
  );
};

const Recommendations = ({ results }) => {
  return (
    <div>
      <Recommendation
        title={'1. Nutritional Boost'}
        name={results.nutrition_boost}
      />
      <Spacer height={'20px'} />
      <Recommendation title={'2. Superfood'} name={results.superfood} />
      <Spacer height={'20px'} />
      <Recommendation title={'3. Lifestyle'} name={results.lifestyle} />
      <Spacer height={'20px'} />
      <Supplement
        title={'4. Foundation Supplement'}
        name={results.foundation_supplement}
      />
      <Spacer height={'20px'} />
      <Supplement
        title={'5. Booster Supplement'}
        name={results.booster_supplement}
      />
    </div>
  );
};

export default Recommendations;
