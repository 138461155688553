
export const REGIONS = [
  { 'value': 'AUK', 'label': 'Auckland' },
  { 'value': 'BOP', 'label': 'Bay of Plenty' },
  { 'value': 'CAN', 'label': 'Canterbury' },
  { 'value': 'GIS', 'label': 'Gisborne' },
  { 'value': 'HKB', 'label': 'Hawke’s Bay' },
  { 'value': 'MWT', 'label': 'Manawatu-Wanganui' },
  { 'value': 'MBH', 'label': 'Marlborough' },
  { 'value': 'NSN', 'label': 'Nelson' },
  { 'value': 'NTL', 'label': 'Northland' },
  { 'value': 'OTA', 'label': 'Otago' },
  { 'value': 'STL', 'label': 'Southland' },
  { 'value': 'TKI', 'label': 'Taranaki' },
  { 'value': 'TAS', 'label': 'Tasman' },
  { 'value': 'WKO', 'label': 'Waikato' },
  { 'value': 'WGN', 'label': 'Wellington' },
  { 'value': 'WTC', 'label': 'West Coast' },
]
