import React from 'react';

import NoFast from './NoFast';
import OkayFast from './OkayFast';
import GoodFast from './GoodFasting';
import styled from 'styled-components';

interface FastingProps {
  results: any;
}

const WrapperResult = styled.div`
  position: relative;
`;

const Fasting = ({ results }: FastingProps) => {
  let fastingType;
  if (results.fasting === 'bad') {
    fastingType = <NoFast results={results.fasting} />;
  } else if (results.fasting === 'neutral') {
    fastingType = <OkayFast results={results.fasting} />;
  } else {
    fastingType = <GoodFast results={results.fasting} />;
  }
  return (
    <>
      <WrapperResult className="fasting page-br">
        <h3>Fasting</h3>
        {fastingType}

        <hr className="no-print" />
      </WrapperResult>
    </>
  );
};

export default Fasting;
