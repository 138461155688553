import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import ReactSpeedometer from 'react-d3-speedometer';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { AuthContext } from 'stores/auth';
import { Spacer } from 'components/utils';
import { gray, borderGray } from 'styles/theme';
import useDetectPrint from 'react-detect-print';

const Wrapper = styled.div`
  background-color: #ffffff;
  min-height: 200px;
  border-radius: 5px;
  border: 1px solid ${borderGray};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

  @media print {
    border: none;
  }
`;

const Pad = styled.div`
  padding: ${(props) =>
    props.pad ? props.pad : isMobile ? '20px 10px' : '30px'};
`;

const GraySection = styled.div`
  padding: ${isMobile ? '20px 10px' : '30px'};
  background-color: #edf3f4;

  h4 {
    color: ${gray};
  }
`;

const Results = ({ results }) => {
  const authStore = useContext(AuthContext);
  const printing = useDetectPrint();

  // Values range from 0 to 1. 0 = good, 1 = bad.
  // We display from Bad to Good, so transform via 1 - val
  const getSpeedometer = (val) => {
    return (1 - val) * 1000;
  };

  const getValueText = (val) => {
    let inverse = 1 - val;
    if (inverse < 0.333) {
      return 'You are in the red.';
    } else if (inverse >= 0.333 && inverse < 0.666) {
      return 'You are in the yellow.';
    } else {
      return 'You are in the green.';
    }
  };

  return (
    <Wrapper>
      <Row>
        <Col md={12}>
          <Pad>
            <h2>{authStore.user.first_name}'s Results</h2>
            <p>
              At Ingeneous we believe that there are three systems within your
              body where every health journey should begin, and science agrees
              with us! These systems include inflammation, detoxification, and
              vitamin D.
              <br />
              <br />
              Inflammation is arguably the most important system in the human
              body. It is your protection system; it recognises and responds to
              threats from invaders like infections and heals you from injuries.
              It is very powerful, vigilant and proactive. Some of us have
              hyper-competent inflammatory responses that can be likened to a
              very sensitive accelerator. To prevent inflammation from
              overacting, we can make modifications to help our body respond
              best to the challenges which come our way.
            </p>
          </Pad>
        </Col>
      </Row>

      <Row>
        <Col>
          <Pad>
            <h3>Inflammatory System</h3>
            <p>
              Your body responds to thousands of threats each day, foods,
              foreign bodies, chemicals, allergies, and potential infections.
              Your inflammation system assesses the level of threat and responds
              appropriately.
              <br />
              <br />
              <p className="page-br">
                If one of those threats is big enough to warrant it, your
                pro-inflammatory system kicks in a domino effect called the
                cytokine cascade, that lasts for example in the mouth for 4
                days. At the end of this period, the response will have done its
                job to defend your body, and this inflammatory response should
                now be turned off by the anti-inflammatory system.
              </p>
              <br />
              <br />
              If inflammation is not turned off, due to an inefficient
              anti-inflammatory effort, an unhealthy situation called chronic
              inflammation can occur. This is when you can start to head off in
              a direction of less optimal wellbeing.
              <br />
              <br />
              Here are the results of your genetic test:
            </p>
          </Pad>
        </Col>
      </Row>

      <GraySection>
        <h4>Pro-inflammatory Genes</h4>
        <Spacer height={'30px'} />
        <Row>
          <Col xs={12} md={6}>
            <ReactSpeedometer
              value={getSpeedometer(results.pro_inflammation)}
              height={200}
              width={isMobile ? 250 : 300}
              currentValueText={getValueText(results.pro_inflammation)}
              paddingVertical={20}
              segments={3}
              customSegmentStops={[0, 333, 666, 1000]}
              needleColor={'#707070'}
              needleHeightRatio={0.4}
              segmentColors={['#DEB4B6', '#EED698', '#ABDCD6']}
              customSegmentLabels={[
                {
                  text: 'Poor',
                  position: 'INSIDE',
                  color: '#FFFFFF',
                  fontSize: '16px',
                },
                {
                  text: 'Moderate',
                  position: 'INSIDE',
                  color: '#FFFFFF',
                  fontSize: '16px',
                },
                {
                  text: 'Good',
                  position: 'INSIDE',
                  color: '#FFFFFF',
                  fontSize: '16px',
                },
              ]}
            />
          </Col>
          <Col md={6}>
            <p>
              <strong>Result: Red</strong>
              <br />
              Your genes predispose you to have very unbalanced inflammation
              coordination. It is likely your inflammatory response is
              hypersensitive and significantly overreacts to the size of the
              threat.
            </p>
            <p>
              <strong>Result: Yellow</strong>
              <br />
              Your genes predispose you to have unbalanced inflammation
              coordination. It is likely your inflammatory response is too
              sensitive and inappropriately reacts to the size of the threat.
            </p>
            <p>
              <strong>Result: Green</strong>
              <br />
              Your genes predispose you to have optimal inflammation
              coordination. It is likely your inflammatory response acts
              appropriately to address the size of the threat.
            </p>
          </Col>
        </Row>
      </GraySection>

      <GraySection>
        <h4>Anti-inflammatory Genes</h4>
        <Spacer height={'30px'} />
        <Row>
          <Col md={6}>
            <ReactSpeedometer
              value={getSpeedometer(results.anti_inflammation)}
              height={200}
              width={isMobile ? 250 : 300}
              currentValueText={getValueText(results.anti_inflammation)}
              paddingVertical={20}
              segments={3}
              customSegmentStops={[0, 333, 666, 1000]}
              needleColor={'#707070'}
              needleHeightRatio={0.4}
              segmentColors={['#DEB4B6', '#EED698', '#ABDCD6']}
              customSegmentLabels={[
                {
                  text: 'Poor',
                  position: 'INSIDE',
                  color: '#FFFFFF',
                  fontSize: '16px',
                },
                {
                  text: 'Moderate',
                  position: 'INSIDE',
                  color: '#FFFFFF',
                  fontSize: '16px',
                },
                {
                  text: 'Good',
                  position: 'INSIDE',
                  color: '#FFFFFF',
                  fontSize: '16px',
                },
              ]}
            />
          </Col>
          <Col md={6}>
            <p>
              <strong>Result: Red</strong>
              <br />
              Your genetic ability to push back inflammation is significantly
              impaired. You are predisposed to have difficulty resolving an
              inflammatory state.
            </p>
            <p>
              <strong>Result: Yellow</strong>
              <br />
              Your genetic ability to push back inflammation is partially
              impaired. You are predisposed to have some difficulty resolving an
              inflammatory state.
            </p>
            <p>
              <strong>Result: Green</strong>
              <br />
              Your genetic ability to push back inflammation is optimal. You are
              predisposed to resolve an inflammatory state well.{' '}
            </p>
          </Col>
        </Row>
      </GraySection>

      <Row>
        <Col>
          <Pad>
            <h3>Detoxification System</h3>
            <p>
              Many of the foods we consume must be broken down into smaller
              building blocks for our body to use, for example - complex
              carbohydrates to simple sugars, proteins to amino acids,
              triglycerides into fatty acids.
              <br />
              <br />
              <p className="page-br">
                In addition to the micronutrients and essential building blocks
                that our food provides, our foods can also contain unwanted
                by-products, organic molecules that must be processed and
                eliminated from our body.
              </p>
              <br />
              The processing of these molecules involves multiple reactions in
              the liver to safely modify their structure and prepare them for
              elimination from the body. If this detoxification pathway is not
              supported with appropriate micronutrients and antioxidants, or an
              individual’s genetic profile or lifestyle leads to an imbalance, a
              build-up of unwanted harmful molecules can occur.
              <br />
              <br />
              In short, we want to make sure that you get the good stuff in, and
              the bad stuff out – without any collateral damage.
              <br />
              <br />
              We are now going to see overall, how your body’s genes interact
              with its environment around this detoxification system.
            </p>
          </Pad>
        </Col>
      </Row>

      <GraySection>
        <h4>Detoxification System</h4>
        <Spacer height={'30px'} />
        <Row>
          <Col md={6}>
            <ReactSpeedometer
              value={getSpeedometer(results.detox)}
              height={200}
              width={isMobile ? 250 : 300}
              currentValueText={getValueText(results.detox)}
              paddingVertical={20}
              segments={3}
              customSegmentStops={[0, 333, 666, 1000]}
              needleColor={'#707070'}
              needleHeightRatio={0.4}
              segmentColors={['#DEB4B6', '#EED698', '#ABDCD6']}
              customSegmentLabels={[
                {
                  text: 'Poor',
                  position: 'INSIDE',
                  color: '#FFFFFF',
                  fontSize: '16px',
                },
                {
                  text: 'Moderate',
                  position: 'INSIDE',
                  color: '#FFFFFF',
                  fontSize: '16px',
                },
                {
                  text: 'Good',
                  position: 'INSIDE',
                  color: '#FFFFFF',
                  fontSize: '16px',
                },
              ]}
            />
          </Col>
          <Col md={6}>
            <p>
              <strong>Result: Red</strong>
              <br />
              You are predisposed to have a detoxification system that poorly
              functions. There is significant opportunity to improve how your
              body deals with toxins.
            </p>
            <p>
              <strong>Result: Yellow</strong>
              <br />
              You are predisposed to have a detoxification system that is
              partially functioning. There is an opportunity to improve how your
              body deals with toxins.
            </p>
            <p>
              <strong>Result: Green</strong>
              <br />
              You are predisposed to have a detoxification system that is
              operating optimally. Your body’s ability to deal with toxins is a
              strength.{' '}
            </p>
          </Col>
        </Row>
      </GraySection>

      <Row>
        <Col>
          <Pad>
            <h3>Vitamin D Pathway</h3>
            <p>
              The vitamin D pathway is important for two reasons, it has a
              well-recognised role in developing bone. It also plays a
              signalling role, that turns on 200 other genes that benefit
              wellbeing.
              <br />
              <br />
              If your vitamin D pathway isn’t working to full capacity, it
              cannot use all the vitamin D in your blood.
              <br />
              <br />
              Overpopulating your body with vitamin D will not help on its own,
              unless you also optimise the vitamin D pathway at the same time.
              In addition to this, there are various forms of vitamin D that
              your genes will have a preference for.
            </p>
          </Pad>
        </Col>
      </Row>

      <GraySection className="page-br-img">
        <h4>Vitamin D Pathway</h4>
        <Spacer height={'30px'} />
        <Row>
          <Col md={6}>
            <ReactSpeedometer
              value={getSpeedometer(results.vitamin_d)}
              height={200}
              width={isMobile ? 250 : 300}
              currentValueText={getValueText(results.vitamin_d)}
              paddingVertical={20}
              segments={3}
              customSegmentStops={[0, 333, 666, 1000]}
              needleColor={'#707070'}
              needleHeightRatio={0.4}
              segmentColors={['#DEB4B6', '#EED698', '#ABDCD6']}
              customSegmentLabels={[
                {
                  text: 'Poor',
                  position: 'INSIDE',
                  color: '#FFFFFF',
                  fontSize: '16px',
                },
                {
                  text: 'Moderate',
                  position: 'INSIDE',
                  color: '#FFFFFF',
                  fontSize: '16px',
                },
                {
                  text: 'Good',
                  position: 'INSIDE',
                  color: '#FFFFFF',
                  fontSize: '16px',
                },
              ]}
            />
          </Col>
          <Col md={6}>
            <p>
              <strong>Result: Red</strong>
              <br />
              Your genes indicate your body is only able to use a small portion
              of your available vitamin D, this system needs support.
            </p>
            <p>
              <strong>Result: Yellow</strong>
              <br />
              Your genes indicate your body is able to use a moderate portion of
              your available vitamin D, this system needs some support.
            </p>
            <p>
              <strong>Result: Green</strong>
              <br />
              Your genes indicate your body is able to use your available
              vitamin D optimally, and likely does not require support if you
              are getting enough of this vitamin.{' '}
            </p>
          </Col>
        </Row>
      </GraySection>
    </Wrapper>
  );
};

export default Results;
