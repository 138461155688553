import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { primary } from 'styles/theme';
import { isMobile } from 'react-device-detect';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
    margin-bottom: 30px;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  width: 200px;
  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
  margin-bottom: 30px;
`;

interface NaturalProps {
  results: any;
}
const Natural = ({ results }: NaturalProps) => {
  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/Athleticism.png"
            alt="Athleticism"
            width={'60%'}
          />
        </ImageCol>
        <Col md={8}>
          <Subtitle>Naturally Athletic</Subtitle>
          <Alert>
            <p>You are naturally athletic</p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Spacer height={'10px'} />
          <div>
            <p>
              You have gene SNPs that indicate you are more likely to be
              athletic and may have a slight natural advantage when it comes to
              strength and endurance!
            </p>
            <p>
              Some gene variations have been found more often in athletes. These
              genes are usually related to strength and endurance resulting from
              differences in your muscle fibres (1).
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Natural;
