import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Spacer } from 'components/utils';
import { primary } from 'styles/theme';

const StyledRow = styled(Row)`
  background-color: ${primary};
`;

const LogoWrapper = styled.div`
  padding: 30px 15%;
`;

const Wrapper = styled.div`
  padding: 30px 15%;
  h2,
  h4,
  h5,
  p,
  label {
    color: #ffffff;
  }

  p {
    text-align: justify;
    text-justify: inter-word;
  }
`;

const Logo = styled.img`
  max-height: 50px;
`;

function HealthDisabilityCode() {
  return (
    <StyledRow>
      <Col md={{ span: 8, offset: 2 }}>
        <LogoWrapper>
          <Link to="/">
            <Logo
              src={
                process.env.PUBLIC_URL + '/assets/images/ingeneous_white_r.png'
              }
            />
          </Link>
        </LogoWrapper>
        <Wrapper>
          <h2>HEALTH AND DISABILITY CODE</h2>

          <Spacer height={'100px'} />
        </Wrapper>
      </Col>
    </StyledRow>
  );
}

export default HealthDisabilityCode;
