import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { borderGray } from 'styles/theme';

const OrderRow = styled(Row)`
  border-bottom: 1px solid ${borderGray};
  align-items: center;

  .col {
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
  }
`;

const OrderDetail = ({ order }) => {
  return (
    <OrderRow>
      <Col>{order.order_number}</Col>
      <Col>{order.confirmed_at}</Col>
      <Col />
      {/* {!order.sample_id && <Link to='/register-kit'><Button>Register Kit</Button></Link>} */}
    </OrderRow>
  );
};

export default OrderDetail;
