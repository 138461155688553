import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Alert, Button, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { getAuth } from 'firebase/auth';

import { loginWithEmail } from 'api/auth';
import { Spacer } from 'components/utils';

const Wrapper = styled.div`
  color: #ffffff;

  .btn-secondary {
    border-color: #ffffff;
  }
`;

const FormTitle = styled.h3`
  display: block;
  color: #ffffff;
`;

const FormActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SpanNotif = styled.span`
  display: inline-block;
  color: #ffffff;
  margin: 0 10px;
`;

function LoginLinkForm() {
  const auth = getAuth();
  const { register, handleSubmit, errors, setError, clearErrors, formState } =
    useForm();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    loginWithEmail(data)
      .then((res) => {
        if (res.data?.status === 'ok') {
          setSuccess(true);
        } else {
          setSuccess(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleInputChange = () => {
    if (formState.isSubmitted && formState.touched) {
      clearErrors(['email', 'login']);
    }
  };

  return (
    <Wrapper>
      <FormTitle>Login</FormTitle>
      <Spacer height={'30px'} />
      <p>
        Log into your account to get access to your reports and recommendations.
      </p>
      <Spacer height={'30px'} />

      {!!errors.login && <Alert variant="danger">{errors.login.message}</Alert>}

      <Form className="form" onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            name="email"
            type="email"
            ref={register({ required: true })}
            onChange={handleInputChange}
          />
          {errors.email && (
            <Form.Control.Feedback type="invalid">
              {errors.email.type === 'required' && 'This field is required'}
              {errors.email.type === 'manual' && errors.email.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Spacer height={'10px'} />

        <FormActions>
          <div>
            <Button variant="secondary" type="submit" disabled={loading}>
              {!loading ? (
                'Email Link'
              ) : (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
            {!loading && success && (
              <SpanNotif>Email successfully sent!</SpanNotif>
            )}
          </div>
        </FormActions>
      </Form>
    </Wrapper>
  );
}

export default LoginLinkForm;
