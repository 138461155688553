/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Scroll, { scroller } from 'react-scroll';

import { heroGreen, gray, error, alert, borderGray } from 'styles/theme';
import { getProducts, Product } from 'api/core';

const Wrapper = styled(Row)`
  align-items: center;
  margin: 10px 0;
  padding: 15px;
  border-radius: 5px;

  img {
    max-width: 80%;
  }
  span {
    display: block;
  }

  &:hover {
    background-color: #e5dbd3;
  }

  background-color: ${(props) => (props.active ? '#E5DBD3' : 'transparent')};
`;

const ImageCol = styled(Col)`
  text-align: center;
  padding: 0;
`;

const Span = styled.span<{ color: string; small?: boolean }>`
  color: ${(props) => props.color};
  font-size: ${(props) => (props.small ? '0.75rem' : '1.1rem')};

  cursor: ${(props) => (props.title ? 'pointer' : '')};
`;

const Links = styled.div`
  margin: 5px 0;
  a {
    color: ${gray};
    display: block;
    border-bottom: 1px solid ${borderGray};
    text-decoration: none;
    padding: 5px;
  }
`;

interface ReportTypeProps {
  comingSoon?: boolean;
  loading: boolean;
  report: string;
  status: string;
  shortcode: string;
  activeReport: string;
  image: string;
  order: any;
}

const ReportType = ({
  comingSoon,
  loading,
  report,
  status,
  shortcode,
  activeReport,
  image,
  order,
}: ReportTypeProps) => {
  let history = useHistory();
  const [active, setActive] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const isPurchased = order ? true : false;

  useEffect(() => {
    getProducts().then((res) => {
      let productList = [];

      for (let prod of res.data.products) {
        if (parseInt(prod.variants[0].price) > 0) {
          productList.push(prod);
        }
      }

      setProducts(productList);
    });
  }, []);

  const handleClick = (val: any) => {
    if (comingSoon && !isPurchased) return;
    if (!loading) {
      let product = undefined;
      if (val.indexOf('Essential') >= 0) {
        product = products?.find(
          (e) => e.title.toLowerCase().indexOf('essential') >= 0
        );
      } else if (val.indexOf('Life') >= 0) {
        product = products?.find(
          (e) => e.title.toLowerCase().indexOf('life') >= 0
        );
      }

      if (isPurchased) {
        if (status === 'complete') {
          history.push(`/reports/${shortcode}`);
        } else if (status === 'pending') {
          history.push(`/reports/${shortcode}`);
        }
      } else {
        if (product) {
          history.push(`/order-details?p=${product.handle}`);
        }
        history.push('/order-details');
      }

      var scroll = Scroll.animateScroll;
      scroll.scrollToTop();
    }
  };

  useEffect(() => {
    if (activeReport.toLowerCase() === shortcode) {
      setActive(true);
    }
  }, []);

  const handleScroll = (location: string) => {
    scroller.scrollTo(location, {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: 0,
    });
  };

  return (
    <Wrapper active={active ? 1 : 0}>
      {image && (
        <ImageCol xs={3} md={3}>
          <img src={image} alt="" />
        </ImageCol>
      )}
      <Col xs={9} md={9} style={{ paddingLeft: 5 }}>
        <Span color={gray} title={'true'} onClick={() => handleClick(report)}>
          {report}
        </Span>

        {comingSoon && !isPurchased ? (
          <Span color={heroGreen} small>
            Coming soon!
          </Span>
        ) : (
          <>
            {loading && (
              <Span color={heroGreen} small>
                loading...
              </Span>
            )}

            {!loading &&
              isPurchased &&
              (status !== 'available' && status !== null ? (
                <Span color={status === 'complete' ? heroGreen : alert} small>
                  {status === 'complete' ? 'complete' : 'Pending'}
                </Span>
              ) : (
                <Span color={alert} small>
                  Pending
                </Span>
              ))}
            {!loading && !isPurchased && (
              <Span color={alert} small>
                Not purchased
              </Span>
            )}
          </>
        )}
      </Col>

      <Col xs={12} md={12}>
        {isPurchased && status === 'complete' && shortcode === 'essential' && (
          <Links>
            <Link
              to={`/reports/${shortcode}/#results`}
              onClick={() => handleScroll('results')}
            >
              My Results
            </Link>
            <Link
              to={`/reports/${shortcode}/#action-plan`}
              onClick={() => handleScroll('action-plan')}
            >
              My Action Plan
            </Link>
          </Links>
        )}

        {isPurchased && status === 'complete' && shortcode === 'lifeplus' && (
          <Links>
            <Link
              to={`/reports/${shortcode}/#what-to-drink`}
              onClick={() => handleScroll('what-to-drink')}
            >
              What To Drink
            </Link>
            <Link
              to={`/reports/${shortcode}/#what-to-eat`}
              onClick={() => handleScroll('what-to-eat')}
            >
              What To Eat
            </Link>
            <Link
              to={`/reports/${shortcode}/#how-to-exercise`}
              onClick={() => handleScroll('how-to-exercise')}
            >
              How To Exercise
            </Link>
          </Links>
        )}

        {isPurchased &&
          status === 'complete' &&
          shortcode === 'weight-management' && (
            <Links>
              <Link
                to={`/reports/${shortcode}/#recommendations`}
                onClick={() => handleScroll('recommendations')}
              >
                My Recommendations
              </Link>
              <Link
                to={`/reports/${shortcode}/#predispositions`}
                onClick={() => handleScroll('predispositions')}
              >
                My Predispositions
              </Link>
            </Links>
          )}
      </Col>
    </Wrapper>
  );
};

export default ReportType;
