import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { gray, primary } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import View from '../View';
import Close from '../ViewLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const AlertRef = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
  margin-bottom: ${isMobile ? 0 : '30px'};
`;

const SubtitleGray = styled.h5`
  color: ${gray};
  margin-bottom: 30px;
`;

const WrapperRef = styled.div`
  margin-top: 5px;
`;

const ListGroupUl = styled.ul`
  font-weight: bold;
  padding-left: 20px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const List = styled.li`
  font-weight: 300;

  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
  }
`;

const ListRef = styled.li`
  font-weight: 300;
  padding-left: 20px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    word-break: break-all;
  }
`;

const WrapperTitle = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
`;

interface HotProps {
  results: any;
}
const Hot = ({ results }: HotProps) => {
  const [show, setShow] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/Temperature.png"
            alt="temperature"
            width={'70%'}
          />
        </ImageCol>
        <Col md={8}>
          <WrapperTitle>
            {isMobile ? (
              <>
                <Subtitle>Hot Temperatures</Subtitle>
                <SubtitleGray> will help you lose weight</SubtitleGray>
              </>
            ) : (
              <>
                <Subtitle>Hot Temperatures</Subtitle>
                &nbsp;
                <SubtitleGray> will help you lose weight</SubtitleGray>
              </>
            )}
          </WrapperTitle>
          <Alert>
            <p>Hot Temperatures</p>
            <WrapperRef>
              <ListGroupUl>
                <List>
                  Keep your house at a slightly warmer temperature (25 -29
                  degrees Celsius).
                </List>
                <List>
                  Try using saunas (80 degrees celsius), sessions should be
                  about 20 minutes, the more often the better!
                </List>
                <List>
                  Take hot baths or go to hot pools (15 to 30 min at a time).
                </List>
                <List>Wear more clothing around your house.</List>
              </ListGroupUl>
            </WrapperRef>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <p>
                Your body is fully in favour of hot temperatures making up 30%
                of your optimal weight story. Simulating a hot environment will
                help you burn fat best by decreasing white adipose tissue (WAT).
              </p>
              <p>
                White adipose tissue stores energy in the form of triglycerides
                (fat), brown adipose tissue uses this stored energy to produce
                heat. (1) More white adipose tissue is associated with an
                increased risk of obesity and obesity related disorders. (1) To
                maintain a stable internal temperature your body responds to
                heat by dilating blood vessels and causing sweating, which uses
                energy and burns calories. (2) Your genes respond particularly
                well to increasing temperatures for fat loss. Simulating a hot
                environment with the use of saunas or hot water has been shown
                to decrease BMI (body mass index), weight, and WAT. (3,4,5)
              </p>
              <p>
                While cold temperatures may help some people lose weight, your
                body will burn fat better in these hot temperatures. Don’t try
                to move to Texas just yet, you can stimulate a hot environment
                by following these recommendations!
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
      <Row className="no-print">
        <Col>
          <AlertRef>
            <span>References</span>
            {!showReferences && (
              <View value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <Close value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <WrapperRef>
                <ListGroup>
                  <ListRef>
                    Saely CH, Geiger K, Drexel H. Brown versus white adipose
                    tissue: a mini-review. Gerontology. 2012;58(1):15-23. doi:
                    10.1159/000321319. Epub 2010 Dec 7. PMID: 21135534. &nbsp;
                    <a href="https://pubmed.ncbi.nlm.nih.gov/21135534/">
                      https://pubmed.ncbi.nlm.nih.gov/21135534/
                    </a>
                  </ListRef>
                  <ListRef>
                    Heinonen I, Laukkanen JA. Effects of heat and cold on
                    health, with special reference to Finnish sauna bathing.
                    American Journal of Physiology-Regulatory, Integrative and
                    Comparative Physiology. 2018 May 1.
                  </ListRef>
                  <ListRef>
                    Westerterp-Plantenga MS, Verwegen CR, IJedema MJ, Wijckmans
                    NE, Saris WH. Acute effects of exercise or sauna on appetite
                    in obese and nonobese men. Physiology & behavior. 1997 Dec
                    1;62(6):1345-54.
                  </ListRef>
                  <ListRef>
                    Robert Podstawski, Tomasz Boraczyński, Michał Boraczyński,
                    Dariusz Choszcz, Stefan Mańkowski, Piotr Markowski,
                    "Sauna-Induced Body Mass Loss in Young Sedentary Women and
                    Men", The Scientific World Journal, vol. 2014, Article ID
                    307421, 7 pages, 2014.
                    <a
                      href="https://doi.org/10.1155/2014/307421
"
                    >
                      https://doi.org/10.1155/2014/307421
                    </a>
                  </ListRef>
                  <ListRef>
                    Junichi KATOH, Masarou KURUSU, Jyouji MIYAJI, et al.
                    Physiological Influences on Exercise and Whole
                    Thermotolerance (Sauna) Stress with Obese Patients ..
                    Rigakuryoho Kagaku. 1996, Vol.11, No.2, p.71. &nbsp;
                    <a
                      href="https://www.jstage.jst.go.jp/article/rika1996/11/1/11_1_3/_article/-char/ja/#citedby-wrap
"
                    >
                      https://www.jstage.jst.go.jp/article/rika1996/11/1/11_1_3/_article/-char/ja/#citedby-wrap
                    </a>
                  </ListRef>
                  <ListRef>
                    Biro S, Masuda A, Kihara T, Tei C. Clinical implications of
                    thermal therapy in lifestyle-related diseases. Experimental
                    Biology and Medicine. 2003 Nov;228(10):1245-9.
                  </ListRef>
                  <ListRef>
                    <a href="https://betterhumans.pub/a-guide-to-using-sauna-to-increase-your-health-and-longevity-4c68d7739132">
                      https://betterhumans.pub/a-guide-to-using-sauna-to-increase-your-health-and-longevity-4c68d7739132
                    </a>
                  </ListRef>
                </ListGroup>
              </WrapperRef>
            )}
          </AlertRef>
        </Col>
      </Row>
    </>
  );
};

export default Hot;
