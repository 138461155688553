import React from 'react';
import { Form, Col } from 'react-bootstrap';
import Select from 'react-select';

import { REGIONS } from './regions';
import { SubSectionHeader, Label, Checkbox } from './styles';

const getRegion = (val) => {
  return REGIONS.filter((add) => add.value === val)[0];
};

const OrderAddress = ({
  register,
  defaultValues,
  errors,
  clearErrors,
  setValue,
  withEssentials,
  sameAddress,
  handleSameAddress,
  handleDeliveryRegionSelect,
  handleBillingRegionSelect,
}) => {
  return (
    <>
      {withEssentials && (
        <>
          <SubSectionHeader>
            <Label>Delivery Address</Label>
          </SubSectionHeader>

          <Form.Row>
            <Form.Group as={Col} controlId="line1">
              <Form.Label>Street</Form.Label>
              <Form.Control
                name="delivery.line1"
                type="text"
                ref={register({ required: true })}
                isInvalid={!!errors?.delivery?.line1}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="line2">
              <Form.Label>Suburb</Form.Label>
              <Form.Control
                name="delivery.line2"
                type="text"
                ref={register}
                isInvalid={!!errors?.delivery?.line2}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                name="delivery.city"
                type="text"
                ref={register({ required: true })}
                isInvalid={!!errors?.delivery?.city}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="state">
              <Form.Label>Region</Form.Label>
              <input
                type="hidden"
                name="delivery.state"
                ref={register({ required: true })}
              />
              <Select
                options={REGIONS}
                defaultValue={getRegion(defaultValues?.delivery?.state)}
                onChange={(data) => {
                  setValue('delivery.state', data?.value);
                  if (data?.value) {
                    clearErrors(['delivery.state']);
                  }
                }}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    borderColor:
                      errors?.delivery?.state?.type === 'required'
                        ? 'red'
                        : '#ced4da',
                  }),
                }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="postal_code">
              <Form.Label>Postal Code</Form.Label>
              <Form.Control
                name="delivery.postal_code"
                type="text"
                ref={register({ required: true })}
                isInvalid={!!errors?.delivery?.postal_code}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="country_code">
              <Form.Label>Country</Form.Label>
              <Form.Control
                name="delivery.country"
                type="text"
                value="New Zealand"
                readOnly
                disabled
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Checkbox>
              <Form.Group controlId="sameBillingAddress">
                <Form.Check
                  type="checkbox"
                  label="Same Billing Address"
                  name="same_billing"
                  ref={register}
                  checked={sameAddress}
                  onChange={handleSameAddress}
                />
              </Form.Group>
            </Checkbox>
          </Form.Row>
        </>
      )}

      {(!sameAddress || !withEssentials) && (
        <>
          <SubSectionHeader>
            <Label>Billing Address</Label>
          </SubSectionHeader>

          <Form.Row>
            <Form.Group as={Col} controlId="line1">
              <Form.Label>Street</Form.Label>
              <Form.Control
                name="billing.line1"
                type="text"
                ref={register({ required: true })}
                isInvalid={!!errors?.billing?.line1}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="line2">
              <Form.Label>Suburb</Form.Label>
              <Form.Control
                name="billing.line2"
                type="text"
                ref={register}
                isInvalid={!!errors?.billing?.line2}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                name="billing.city"
                type="text"
                ref={register({ required: true })}
                isInvalid={!!errors?.billing?.city}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="state">
              <Form.Label>Region</Form.Label>
              <input
                type="hidden"
                name="billing.state"
                ref={register({ required: true })}
              />
              <Select
                options={REGIONS}
                defaultValue={getRegion(defaultValues?.billing?.state)}
                onChange={(data) => {
                  setValue('billing.state', data?.value);
                  if (data?.value) {
                    clearErrors(['billing.state']);
                  }
                }}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    borderColor:
                      !!errors?.billing?.state?.type === 'required'
                        ? 'red'
                        : '#ced4da',
                  }),
                }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="postal_code">
              <Form.Label>Postal Code</Form.Label>
              <Form.Control
                name="billing.postal_code"
                type="text"
                ref={register({ required: true })}
                isInvalid={!!errors?.billing?.postal_code}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="country_code">
              <Form.Label>Country</Form.Label>
              <Form.Control
                name="billing.country"
                type="text"
                value="New Zealand"
                readOnly
                disabled
              />
            </Form.Group>
          </Form.Row>
        </>
      )}
    </>
  );
};

OrderAddress.defaultProps = {
  withEssentials: false,
};

export default OrderAddress;
