import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { primary } from 'styles/theme';
import { isMobile } from 'react-device-detect';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
    margin-bottom: 30px;
  }  
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #ffbf00;
  padding: 10px;
  width: ${isMobile ? '100%' : '220px'};
  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
  margin-bottom: ${isMobile ? '10px' : '30px'};
`;

interface NeutralProps {
  results: any;
}
const Neutral = ({ results }: NeutralProps) => {
  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/Athleticism.png"
            alt=""
            width={'60%'}
          />
        </ImageCol>
        <Col md={8}>
          <Subtitle>Athleticism Neutral</Subtitle>
          <Alert>
            <p>You are athleticism neutral</p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Spacer height={'10px'} />
          <div>
            <p>
              Your gene SNPs do not provide an advantage or disadvantage when it
              comes to being athletic.
            </p>
            <p>
              You may not be a natural born athlete, but if you work at it,
              athleticism is easily in reach. Some gene variations have been
              found more often in athletes. These genes are usually related to
              strength and endurance resulting from differences in muscle fibres
              (1).
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Neutral;
