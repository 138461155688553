import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import Icon from 'components/Icon';
import { white, borderGray, gray, primary } from 'styles/theme';

const Wrapper = styled.div`
  background-color: ${white};
  padding: ${isMobile ? '10px' : '30px'};
  border-radius: 5px;
  border: 1px solid ${borderGray};
  color: ${gray};
  position: relative;

  h4 {
    color: ${gray};
  }

  p {
    font-size: 1rem;
    margin: 0;
  }
`;

const FollowButtons = styled.div`
  display: flex;
  a {
    display: flex;
    align-items: center;
    align-content: center;
    margin-left: 15px;
    font-size: 16px;
  }
`;

const JoinUs = () => {
  return useObserver(() => {
    return (
      <div>
        <h3>Join Us</h3>
        <Wrapper>
          <Row>
            <Col md={8}>
              <p>
                Follow us on social media to stay up-to-date with the latest
                news, ideas, tips and tricks.
              </p>
            </Col>
            <Col md={4}>
              <FollowButtons>
                <a
                  href="https://www.facebook.com/Ingeneous1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon
                    brand
                    name="facebook-square"
                    color={primary}
                    size={'30px'}
                  />
                  &nbsp;Follow
                </a>
                <a
                  href="https://www.instagram.com/myingeneous/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon brand name="instagram" color={primary} size={'30px'} />
                  &nbsp;Follow
                </a>
              </FollowButtons>
            </Col>
          </Row>
        </Wrapper>
      </div>
    );
  });
};

export default JoinUs;
