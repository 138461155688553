import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { primary, white, borderGray } from 'styles/theme';

const Wrapper = styled.div`
  position: relative;
  background-color: ${primary};
  color: ${white};
  min-height: 200px;
  border-radius: 5px;
  border: 1px solid ${borderGray};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;

  h4,
  p {
    color: ${white};
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  bottom: ${isMobile ? '-45%' : '-40%'};
  right: -20%;
  overflow: hidden;
  z-index: 0;
`;

const Pad = styled.div`
  padding: ${(props) =>
    props.pad ? props.pad : isMobile ? '20px 10px' : '30px'};
`;

const ImageCol = styled(Col)`
  img {
    max-width: 80%;
  }
`;

const DarkA = styled.a`
  :link{
    color: #197da8;
  }
  :visited{
    color: #209ED4;
  }
`;

const WhatsNext = () => {
  return (
    <Wrapper>
      <Row>
        <Col md={9}>
          <Pad>
            <h4>What's Next?</h4>
            <p>
              Starting your journey to wellness isn’t always easy, but we are
              here to guide you through it. <br />
              <br />
              Your ingeneous practitioner should be in touch with you within 5
              days of receiving this report to schedule a consultation. Please
              email <DarkA href='mailto:hello@ingeneous.co.nz'>hello@ingeneous.co.nz</DarkA> if it has been longer than this and you have not
              heard from your practitioner.
              <br />
              <br />
              You must follow your five Essential Start recommendations for at
              least three weeks to balance your inflammatory response before
              doing anything else.
              <br />
              <br />
              The next step on your journey is a Lifestyle Plus report which
              gives you recommendations about how to eat, drink and exercise in
              a way that will help you maintain your balanced state of
              inflammation.
              <br />
              <br />
              If you have already purchased your LifeStyle Plus it will be
              released to you in three weeks time. If you are interested in
              learning more visit your local ingeneous provider or go to our
              website at myingeneous.com.
              <br />
              Congratulations on taking this first step in your journey to
              wellness!
              <br />
              <br />
              Dr.Lindsay and the Ingeneous team
              <br />
            </p>
          </Pad>
        </Col>
        <ImageCol md={3}>
          <Pad>
            {/* <img src={process.env.PUBLIC_URL + '/assets/images/illustration-1.png'} /> */}
          </Pad>
        </ImageCol>
      </Row>
      <BackgroundImage
        src={process.env.PUBLIC_URL + '/assets/images/Branding_Graphic-091.png'}
      />
    </Wrapper>
  );
};

export default WhatsNext;
