import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { gray, primary } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import View from '../View';
import Close from '../ViewLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const AlertRef = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
  margin-bottom: 30px;
`;

const SubtitleGray = styled.h5`
  color: ${gray};
  margin-top: 10px;
`;

const WrapperRef = styled.div`
  margin-top: 5px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const ListRef = styled.li`
  font-weight: 300;
  padding-left: 20px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    word-break: break-all;
  }
`;

interface LactoProps {
  results: any;
}
const Lacto = ({ results }: LactoProps) => {
  const [show, setShow] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/Supplement.png"
            alt="supplement"
            width={'60%'}
          />
        </ImageCol>
        <Col md={8}>
          <SubtitleGray>
            Your most powerful supplement for weight loss is
          </SubtitleGray>
          <Subtitle>Lactobacillus probiotic</Subtitle>
          <Alert>
            <p>
              Suggested Supplement: Daily Probiotic, LiveBrew (1 drink daily).
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <p>
                A lactobacillus probiotic will be very helpful for you in your
                optimal weight journey. This type of probiotic will help to
                decrease inflammation which can stand in the way of weight loss.
              </p>
              <p>
                Our gut contains trillions of bacteria that are closely
                connected to our health and wellness, so of which can be
                influenced by taking probiotics (1). Different strains of
                Lactobacillus have been shown to help regulate blood glucose,
                improve lipid metabolism and help decrease body weight (2,3). A
                probiotic with Lactobacillus strains will help to decrease your
                inflammation, and support you in reaching your optimal weight
                (4).
              </p>
              <p style={{ fontWeight: 'bold' }}>
                This supplement is best for your genes. If you have any concerns
                this may not suit your current state of health or medications
                check with your doctor.
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
      <Row className="no-print">
        <Col>
          <AlertRef>
            <span>References</span>
            {!showReferences && (
              <View value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <Close value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <WrapperRef>
                <ListGroup>
                  <ListRef>
                    <a href="https://www.livebrew.co.nz/">
                      https://www.livebrew.co.nz/
                    </a>
                  </ListRef>
                  <ListRef>
                    Nagata S, Chiba Y, Wang C, Yamashiro Y. The effects of the
                    Lactobacillus casei strain on obesity in children: a pilot
                    study. Beneficial microbes. 2017 Aug 24;8(4):535-43. &nbsp;
                    <a href="https://www.ingentaconnect.com/content/wagac/bm/2017/00000008/00000004/art00003">
                      https://www.ingentaconnect.com/content/wagac/bm/2017/00000008/00000004/art00003
                    </a>
                  </ListRef>
                  <ListRef>
                    Tanida M, Shen J, Maeda K, Horii Y, Yamano T, Fukushima Y,
                    Nagai K. High-fat diet-induced obesity is attenuated by
                    probiotic strain Lactobacillus paracasei ST11 (NCC2461) in
                    rats. Obesity Research & Clinical Practice. 2008 Sep
                    1;2(3):159-69. &nbsp;
                    <a href="https://www.sciencedirect.com/science/article/abs/pii/S1871403X08000306">
                      https://www.sciencedirect.com/science/article/abs/pii/S1871403X08000306
                    </a>
                  </ListRef>
                  <ListRef>
                    Huang WC, Wei CC, Huang CC, Chen WL, Huang HY. The
                    beneficial effects of Lactobacillus plantarum PS128 on
                    high-intensity, exercise-induced oxidative stress,
                    inflammation, and performance in triathletes. Nutrients.
                    2019 Feb;11(2):353. &nbsp;
                    <a href="https://www.healthline.com/nutrition/l-carnitine">
                      https://www.healthline.com/nutrition/l-carnitine{' '}
                    </a>
                  </ListRef>
                </ListGroup>
              </WrapperRef>
            )}
          </AlertRef>
        </Col>
      </Row>
    </>
  );
};

export default Lacto;
