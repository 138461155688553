import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import CommonProvider from 'stores/common'

import MainWrapper from 'containers/MainWrapper'
import BlankRoute from './BlankRoute'

import EventSignup from './EventSignup'
import Dashboard from './Dashboard'
import EmailConfirmation from './Auth/EmailConfirmation'
import OrderDetails from './Order/index'
import ReportsRouter from './Reports/ReportsRouter'
import AccountRoutes from './Account/routes'
import Orders from './Orders'
import PendingOrders from './PendingOrders'

import ScanKit from './RegisterKit/ScanKit'
import Swab from './RegisterKit/Swab'
import SendKit from './RegisterKit/SendKit'

import Consultation from './Consultation'
import Essentials from './Consultation/Essentials'
import LifePlus from './Consultation/LifePlus'
import Methylation from './Consultation/Methylation'
import WeightManagement from './Consultation/WeightManagement'

import PageNotFound from './StaticPages/404'

const ProtectedRoutes = () => (
  <CommonProvider>
    <MainWrapper>
      <div className="container__wrap">
        <Switch>
          <BlankRoute exact path="/event-signup" component={EventSignup} />
          <Route exact path="/register-kit/" component={ScanKit} />
          <Route exact path="/register-kit/swab/" component={Swab} />
          <Route exact path="/register-kit/send/" component={SendKit} />
          <Route path="/orders/" component={Orders} />
          <Route path="/order-details" component={OrderDetails} />
          <Route path="/order-pending-list" component={PendingOrders}/>
          <Route path="/account/verify" component={EmailConfirmation} />
          <Route path="/reports/" component={ReportsRouter} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/account" component={AccountRoutes} />
          <Route exact path="/consult" component={Consultation} />
          <Route exact path="/consult/essentials/" component={Essentials} />
          <Route exact path="/consult/lifestyle-plus/" component={LifePlus} />
          <Route exact path="/consult/methylation/" component={Methylation} />
          <Route exact path="/consult/weight-management/" component={WeightManagement} />

          <Route exact path="/" component={Dashboard} />
          {/* <Route render={() => <Redirect to="/" />} /> */}
          <Route path="*" component={PageNotFound} />

        </Switch>
      </div>
    </MainWrapper>

  </CommonProvider>
)

export default ProtectedRoutes
