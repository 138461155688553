import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import { primary } from '../styles/theme';

const StyledModal = styled(Modal)`
  background-color: rgba(0,0,0,0.3);

  .modal-content {
    background-color: #F5FFFD;
    color: #474747;
  }

  .close {
    color: ${primary};
  }
`

export const ModalTitle = styled(Modal.Title)`
  width: 100%;
`

export const Title = styled.span`
  display: block;
  margin-bottom: 5px;
  text-align: center;
`

export const StepLabel = styled.span`
  display: block;
  font-size: 14px;
  text-align: center;
`

export const ModalWrapper = styled.div`
`

const CustomModal = ({ children, ...rest }) => {
  return (<StyledModal {...rest}>
    {children}
  </StyledModal>)
}

export default CustomModal;
