/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { AuthContext } from 'stores/auth';
import { sesameLogin } from 'api/auth';

const Wrapper = styled.div`
  padding: ${isMobile ? '10px' : '30px 0'};
`;

const Sesame = () => {
  let history = useHistory();
  const { next, token } = useParams();
  const authStore = useContext(AuthContext);

  useEffect(() => {
    if (token) {
      sesameLogin(token)
        .then((res) => {
          authStore.setTokens(res.data.access, '');
          history.push('/' + next);
        })
        .catch(() => {
          history.push('/');
        });
    }
  }, []);

  return (
    <Container>
      <Wrapper>
        <div className="email-confirmation">
          <h3 className="email-confirmation__title">
            Please wait while we log you in...
          </h3>
        </div>
      </Wrapper>
    </Container>
  );
};

export default Sesame;
