import React from 'react';
import styled from 'styled-components';
import { Container, Navbar } from 'react-bootstrap';

import { footerHeight } from 'styles/theme'

const CustomFooter = styled(Navbar)`
  background-color: transparent;
  color: #666666;
  border-top: 1px solid #BAD1CC;
  padding: 15px 0;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: ${footerHeight};

  .container {
    justify-content: center;
  }
`

const Footer = () => {
  return (<>
    <CustomFooter sticky='bottom'>
      <Container>
        <span>Copyright © 2020 Ingeneous. All rights reserved.</span>
      </Container>
    </CustomFooter>
  </>);
}

export default Footer;
