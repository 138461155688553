import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { AuthContext } from 'stores/auth';
import { Spacer } from 'components/utils';
import { alert, borderGray } from 'styles/theme';

import Recommendations from './Recommendations';

const Wrapper = styled.div`
  background-color: #ffffff;
  min-height: 200px;
  border-radius: 5px;
  border: 1px solid ${borderGray};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

  @media print {
    border: none;
  }
`;

const Pad = styled.div`
  padding: ${(props) =>
    props.pad ? props.pad : isMobile ? '20px 10px' : '30px'};
  padding-bottom: 0;
`;

const Reminder = styled.div`
  padding: 10px 15px;
  background-color: #f2f2f2;
  border: 1px solid ${alert};
  border-radius: 3px;

  strong {
    color: ${alert};
    text-transform: uppercase;
  }
`;

const ImageCol = styled(Col)`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
`;

const ActionPlan = ({ results }) => {
  const authStore = useContext(AuthContext);

  return (
    <Wrapper>
      <Row>
        <Col md={12}>
          <Pad>
            <h2>{authStore.user.first_name}'s Action Plan</h2>
            <p>
              These recommendations have been uniquely determined for you based
              on the most powerful and most prevalent recommendations that
              impact your genetic expression for the entirety of the gene SNPs
              relevant to your essential start profile (42 SNP's).
            </p>

            <Reminder>
              <strong>Please Note</strong>
              <br />
              <p>
                The following results show your overall genetic predisposition
                to inflammation and are representative of an average across all
                of your measured SNPs. These results do not show your current
                state of being.
              </p>
            </Reminder>
          </Pad>
        </Col>
      </Row>

      <Row>
        <Col md={9}>
          <Pad>
            <h3>
              Where To Start: <br />
              Reducing Inflammation
            </h3>
            <p>
              There are many things available that can to some degree improve
              the way that these three systems interact, to avoid creating
              chronic inflammation. What we do at Ingeneous is help you make
              choices. We don’t expect you to be able to change lots of things
              in your life overnight. We want to help you make 5 changes that
              the science tells us are where you may get the most powerful
              changes for you.
              <br />
              <br />
              We are effectively aiming to get you the most gains from what we
              know about your genetic makeup.
              <br />
              <br />
              Using the most current, evidence-based knowledge, we have
              determined the most beneficial recommendations for you to optimise
              your essential systems and reset your inflammatory response. This
              is where you take the lead, with Ingeneous here to support you
              along the way.
            </p>
          </Pad>
        </Col>
        <ImageCol src={'/assets/images/illustration-2.png'} />
      </Row>

      <Row>
        <Col>
          <Pad>
            <h3>Your Top 5 Recommendations</h3>
            <p>
              The following recommendations are based on you being fit and
              healthy, that you do not have a medical condition, are not on a
              prescription medication, are not pregnant or breastfeeding and are
              over the age of 18.
              <br />
              <br />
              If you meet any of the above criteria you are required to have a
              consultation with an Ingeneous practitioner before actioning any
              of the recommendations contained within this report. This
              consultation enables a secondary process of customisation to your
              report, taking these factors into consideration.
              <p className="page-br">
                Please do NOT implement the following recommendations without
                guidance in this instance.
              </p>
              <br className="no-print" />
              <br className="no-print" />
              To book a consultation with an Ingeneous Practitioner, please call
              0800 464 363.
              <br />
              <br />
              If this report is for a child under the age of 18, or an adult
              outside the average BMI (18 - 25) please call our phone support
              line on 0800 464 363 where a practitioner will supply age
              appropriate or weight specific adjustments.
            </p>
            <Recommendations results={results} />
          </Pad>
        </Col>
      </Row>

      <Row className="page-br">
        <Col>
          <Pad>
            <h3>How to get the most out of your inflammatory reset</h3>
            <p>
              To give your body the best opportunity for your inflammation
              system to coordinate, we recommend a 3-week inflammation reset to
              help reduce inflammatory symptoms and increase your energy.
              <br />
              <br />
              Managing inflammation is an ongoing process that can take
              significant time to reset and it is possible that it may never be
              perfectly balanced.
              <br />
              <br />
              Our ultimate goal is that you will keep utilising the
              recommendations from this Essential Start report long term as this
              is where you are most likely to see the get the most benefit.
              <br />
              <br />
              To help your body reset itself over this 3-week period, here are
              some things which you can do to help speed up the process:
              <br />
              <br />
            </p>
            <ol>
              <li>
                <strong>Put your feet up!</strong>
                <br />
                While on your inflammatory reset please cut your current level
                of exercise in half. Exercise can be pro-inflammatory and will
                make it harder for you to reset your inflammatory response.
                <br />
              </li>
              <li>
                <strong>Get hydrated</strong>
                <br />
                Please drink the recommended 8 glasses of water a day when you
                are on your inflammatory reset. Your body is readjusting and
                moving things around and being hydrated is going to make it
                easier.
                <br />
              </li>
              <li>
                <strong>Floss</strong>
                <br />
                Also, don’t forget to floss once a day! The bacteria that live
                in your gums can also add to an over competent inflammatory
                response. So, let’s keep those pearly whites shiny bright and
                floss every day for the next 3 weeks (at ideally ongoing!) for
                maximum inflammatory reset capacity.
                <br />
              </li>
            </ol>
          </Pad>
        </Col>
      </Row>

      <Spacer height={'40px'} />
    </Wrapper>
  );
};

export default ActionPlan;
