import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Element, scroller } from 'react-scroll'
import { isMobile } from 'react-device-detect'

import { Spacer } from 'components/utils'
import Main from './Main'
import Results from './Results'
import ActionPlan from './ActionPlan'
import WhatsNext from './WhatsNext'

const Wrapper = styled.div`
  margin-top: ${isMobile ? '0': '-150px'};
`

const ReportBody = ({ results }) => {

  useEffect(() => {
    if (window.location.hash) {
      scroller.scrollTo(window.location.hash.replace('#', ''), {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset: 0
      })
    }
  }, [results])

  return (<Wrapper>
    <Main />
    <Spacer height={'30px'} />
    {results && <Element name='results' className='results'>
      <Results results={results} />
    </Element>}
    {!results && <p>Loading report...</p>}
    <Spacer height={'30px'} />
    {results && <Element name='action-plan' className='action-plan'>
      <ActionPlan results={results} />
    </Element>}
    {!results && <p>Loading action plan...</p>}
    <Spacer height={'30px'} />
    <WhatsNext />
  </Wrapper>)
}

export default ReportBody
