/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Form, Col, Button, Spinner, Row } from 'react-bootstrap';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { Element, scroller } from 'react-scroll';
import Select from 'react-select';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import {
  createOrder,
  getDiscount,
  getProduct,
  Product,
  checkSampleId as checkSampleIdAPI,
} from 'api/core';
import { eventSignup as eventSignupAPI } from 'api/admin';
import { checkEmail as checkEmailAPI } from 'api/auth';
import { Event } from 'api/models/event.interface';
import { AuthContext } from 'stores/auth';
import { useObserver } from 'mobx-react-lite';
import {
  Wrapper,
  FormSection,
  DarkFormSection,
  CardWrapper,
  SubSectionHeader,
  Label,
  Price,
  Alert,
  StripeWrapper,
  HealthNowWrapper,
  HealthNowBtn,
  OR,
  Checkbox,
  FormActions,
  FormFooter,
  FormFooterContainer,
} from './styles';
import { Spacer } from 'components/utils';
import OrderAddress from '../../routes/Order/components/OrderAddress';
import SelectDatePicker from 'components/form/SelectDatePicker';
import Scanner from '../../routes/EventCart/components/Scanner';
import { primary } from '../../styles/theme';
import CartFormLogin from './CartFormLogin';

const CARD_OPTIONS = {
  style: {
    base: {
      backgroundColor: 'transparent',
      color: '#000000',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      fontWeight: 500,
      lineHeight: '1',
      text_align: 'center',
      ':-webkit-autofill': {
        color: '#000000',
        padding: '10px',
      },
      '::placeholder': {
        color: '#919191',
      },
    },
    invalid: {
      iconColor: '#DE5426',
      color: '#DE5426',
    },
  },
  hidePostalCode: true,
};

const GENDER_OPTIONS = [
  { value: 'f', label: 'Female' },
  { value: 'm', label: 'Male' },
  // { value: 'u', label: 'Unknown' },
];

const CartNames = styled.span`
  color: #ffffff;
`;

const HealthNowSpinner = styled.div`
  color: #ffffff;
  text-align: right;
  margin-right: 1rem;
  margin-top: 1rem;
`;

const LoginHere = styled.p`
  color: ${primary};
  font-size: 0.9rem;
  margin-left: 5px;
  max-width: 300px;

  span {
    font-weight: 600;
  }
`;

interface FormCartProps {
  defaultValues?: any;
  selectedProducts: Product[];
  userProducts: any;
  isLoggedIn: boolean;
  event?: Event;
  events?: Event[];
  eventCode: string;
  askMedication?:boolean;
  loginBox?:boolean;
  eventDiscounts?: {
    product_id: string;
    discount_value: number;
    discount_type: string;
  }[];
  handleEventChange: (pk: number) => void;
}

declare global {
  interface Window {
    url: any;
  }
}

function FormCart({
  defaultValues,
  selectedProducts,
  userProducts,
  isLoggedIn,
  handleEventChange,
  event,
  events,
  eventCode,
  loginBox,
  eventDiscounts,
  askMedication,
}: FormCartProps) {
  let history = useHistory();
  const authStore = useContext(AuthContext);
  const stripe = useStripe();
  const elements = useElements();
  const auth = getAuth();

  const {
    register,
    handleSubmit,
    setValue,
    errors,
    setError,
    clearErrors,
    getValues,
    control,
  } = useForm({
    defaultValues,
  });
  const [loading, setLoading] = useState(false);
  const [sameAddress, setSameAddress] = useState(true);
  // eslint-disable-next-line
  const [medication, setMedication] = useState(false);
  // eslint-disable-next-line
  const [consultation, setConsultation] = useState(false);
  const [cartProducts, setCartProducts] = useState<string[]>([]);

  // eslint-disable-next-line
  const [deliveryPhoneNumber, setDeliveryPhoneNumber] = useState(
    defaultValues?.delivery?.phone_number
  );
  // eslint-disable-next-line
  const [billingPhoneNumber, setBillingPhoneNumber] = useState(
    defaultValues?.billing?.phone_number
  );
  const [discountCode, setDiscountCode] = useState<any | null>();
  const [totalPrice, setTotalPrice] = useState(0);

  const [withEssentials, setWithEssentials] = useState<Product>();
  const [consultationProduct, setConsultationProduct] = useState<Product>();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  // eslint-disable-next-line
  const [gender, setGender] = useState();
  const [loginFormVisible, setLoginFormVisible] = useState(false);

  useEffect(() => {
    getProduct(process.env.REACT_APP_CONSULTATION_PRODUCT_ID ?? '').then(
      (res) => {
        setConsultationProduct(res.data.product);
      }
    );
  }, []);

  useEffect(() => {
    const finalPrice = computeFinalPrice();

    setTotalPrice(finalPrice / 100);

    const essentialsSelected = selectedProducts?.find(
      (e) => e.id.toString() === process.env.REACT_APP_ESSENTIAL_PRODUCT_ID
    );
    setWithEssentials(essentialsSelected);

    if (selectedProducts?.length > 0) {
      const productSort = [
        process.env.REACT_APP_ESSENTIAL_PRODUCT_ID,
        process.env.REACT_APP_LIFE_PLUS_PRODUCT_ID,
        process.env.REACT_APP_CONSULTATION_PRODUCT_ID,
        process.env.REACT_APP_WEIGHT_MANAGEMENT_PRODUCT_ID,
        process.env.REACT_APP_WEIGHT_MANAGEMENT_CONSULTATION_PRODUCT_ID,
      ];
      const cartProductNames = productSort.map((prodId) => {
        const findInCart = selectedProducts?.find(
          (e) => e.id.toString() === prodId
        );
        if (findInCart) {
          return findInCart.title;
        } else {
          return '';
        }
      });
      setCartProducts(cartProductNames);
    }
  }, [selectedProducts, discountCode, consultation, medication]);

  const computeFinalPrice = () => {
    let finalPrice = 0;

    const filteredSelected = selectedProducts?.filter(
      (prod) => !userProducts?.includes(prod.id.toString())
    );
    const prices = filteredSelected?.reduce(function (
      filtered: number[],
      prod: Product
    ) {
      const price = prod?.variants?.[0]?.price;
      if (price) {
        let item_price = parseFloat(price);
        const discountPrice = eventDiscounts?.filter(
          (item) => item.product_id === prod.id.toString()
        )[0];
        if (discountPrice?.discount_type === 'price') {
          item_price = discountPrice.discount_value / 100;
        }
        filtered.push(item_price);
      }
      return filtered;
    },
    []);

    finalPrice +=
      prices?.length > 0
        ? prices?.reduce(
            (prev: any, curr: any) => parseInt(prev) + parseInt(curr)
          ) * 100
        : 0;

    if (discountCode) {
      if (discountCode.percent_off) {
        const discount = (finalPrice * discountCode.percent_off) / 100;
        finalPrice = finalPrice - discount;
      } else if (discountCode.amount_off) {
        finalPrice = finalPrice - discountCode.amount_off;
      }
    }

    if (medication) {
      if (consultation && consultationProduct) {
        finalPrice += parseInt(consultationProduct?.variants[0].price) * 100;
      }
    }

    return finalPrice;
  };

  const processOrder = async (payload: any, user: any) => {
    if (totalPrice === 0 || payload['card_token'] !== undefined) {
      const requestPayload = {
        firebase_uid: user['uid'],
        email: payload['email'],
        first_name: payload['first_name'],
        last_name: payload['last_name'],
        phone_number: payload['phone_number'],
        gender: payload['gender'],
        birthdate: payload['birthdate'],
        sample_id_code: payload['sample_id'],
        event: payload['event'],
        // event: '1',
        product_ids: selectedProducts.map((prod) => prod.id),
        discount_code: payload['discount_code'],
        card_token: payload['card_token'],
        membership: payload['membership'],
        consultation: payload['consultation'],
      };

      eventSignupAPI(requestPayload)
        .then(() => {
          setLoading(false);
          const toastId = 'paymentMade';
          toast('Your payment was successful.', {
            containerId: 'regular',
            toastId: toastId,
          });
          history.push('/register/success/');
        })
        .catch((err) => {
          const error = err.response.data;
          if (error?.type === 'discount_code_error') {
            setError('discount_code', {
              type: 'manual',
              message: err.response?.data?.errors?.discount_code,
            });
          } else if (error?.type === 'order_error') {
            console.log(error);
          } else if (error?.type === 'charge_error') {
            setError('card_token', {
              type: 'manual',
              message: 'There was a problem charging your card.',
            });
          }
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    setLoading(true);

    const stripeResponse =
      totalPrice === 0 || data.healthnow === 'true'
        ? null
        : await processStripe(data);

    if (
      totalPrice === 0 ||
      data.healthnow === 'true' ||
      stripeResponse?.token !== undefined
    ) {
      let cleaned_data = Object.assign({}, data);
      if (withEssentials) {
        cleaned_data['delivery']['country'] = 'NZ';
        cleaned_data['delivery']['address_type'] = 'delivery';
        cleaned_data['delivery']['phone_number'] = cleaned_data['phone_number'];
      }

      if (cleaned_data['same_billing']) {
        cleaned_data['billing'] = Object.assign({}, cleaned_data['delivery']);
        cleaned_data['billing']['address_type'] = 'billing';
      } else {
        cleaned_data['billing']['country'] = 'NZ';
      }

      if (!withEssentials) {
        cleaned_data['delivery'] = cleaned_data['billing'];
        cleaned_data['same_billing'] = true;
      }

      if (cleaned_data['same_billing'] === 'on') {
        cleaned_data['same_billing'] = true;
      } else if (cleaned_data['same_billing'] === 'off') {
        cleaned_data['same_billing'] = false;
      }

      cleaned_data['billing']['phone_number'] = cleaned_data['phone_number'];

      let cardToken = '';
      if (totalPrice === 0) {
        cardToken = 'manual';
      } else if (data.healthnow === 'true') {
        cardToken = 'healthnow';
      } else if (stripeResponse?.token?.id) {
        cardToken = stripeResponse.token.id;
      }

      const payload = {
        product_ids: selectedProducts.map((prod) => prod.id),
        discount_code: cleaned_data['discount_code'],
        card_token: cardToken,
        first_name: cleaned_data['first_name'],
        last_name: cleaned_data['last_name'],
        phone_number: cleaned_data['phone_number'],
        delivery_address: cleaned_data['delivery'],
        billing_address: cleaned_data['billing'],
        same_billing: cleaned_data['same_billing'],
        membership: cleaned_data['membership'],
        consultation: cleaned_data['consultation'],
        event_id: data.event,
      };

      const width = 500;
      const height = 700;
      const left = window.screen.width / 2 - width / 2;
      const top = window.screen.height / 2 - height / 2 - 20;

      let paymentWindow: Window | null;
      if (payload.card_token === 'healthnow') {
        paymentWindow = window.open(
          '',
          'HealthNowPaymentWindow',
          `width=${width}, height=${height}, top=${top}, left=${left}`
        );
      }

      createOrder(payload)
        .then((res) => {
          if (res?.data?.status === 'ok') {
            authStore.fetchUser();
            setLoading(false);
            const toastId = 'paymentMade';
            toast('Your payment was successful.', {
              containerId: 'regular',
              toastId: toastId,
            });
            history.push('/');
          } else if (res?.data?.status === 'pending') {
            if (paymentWindow) {
              paymentWindow.location.href = `${res?.data?.sessionUrl}&nvo=YyejM11DO2d`;
              history.push('/');
            } else {
              window.open(`${res?.data?.sessionUrl}&nvo=YyejM11DO2d`, '_blank');
            }
          }
        })
        .catch((err) => {
          if (err.response?.data?.error) {
            setError('cardToken', {
              type: 'manual',
              message: err.response?.data?.error,
            });
          } else if (err.response?.data?.errors) {
            if (err.response?.data?.errors?.discount_code) {
              setError('discount_code', {
                type: 'manual',
                message: err.response?.data?.errors?.discount_code,
              });
            }
          } else {
            setError('cardToken', {
              type: 'manual',
              message: 'An unknown error has occured. Please check your balance before retrying this transaction',
            });
          }
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const onSubmitGuest = async (data: any) => {
    setLoading(true);

    let payload = Object.assign({}, data);

    const stripeResponse =
      totalPrice === 0 ? null : await processStripe(payload);
    payload['card_token'] =
      totalPrice === 0 ? 'manual' : stripeResponse?.token?.id;

    await createUserWithEmailAndPassword(
      auth,
      payload['email'],
      payload['password']
    )
      .then((userCredential) => {
        const user = userCredential.user;
        processOrder(payload, user);
      })
      .catch((error) => {
        if (error.code === 'auth/email-already-in-use') {
          const toastId = 'email-already-in-use';
          toast('That email address is already in use, please try again.', {
            containerId: 'regular',
            toastId: toastId,
            type: 'error',
            autoClose: 5000,
          });
        }
        setLoading(false);
      });
  };

  const processStripe = async (data: any) => {
    if (!stripe || !elements) {
      return;
    }
    console.log(data)
    const cardElement = elements.getElement(CardElement);
    if (cardElement) {
      const { token, error } = await stripe.createToken(cardElement, data);
      if (error) {
        return { error: error };
      } else {
        return { token: token };
      }
    } else {
      return { error: 'Card element not found' };
    }
  };

  const handleDeliveryPhoneNumber = (data: any) => {
    setDeliveryPhoneNumber(data);
    setValue('delivery.phone_number', data);
  };

  const handleBillingPhoneNumber = (data: any) => {
    setBillingPhoneNumber(data);
    setValue('billing.phone_number', data);
  };

  const handleSameAddress = () => setSameAddress(!sameAddress);

  const handleDiscountCode = async (evt: any) => {
    const code = evt.target.value;
    if (code) {
      await getDiscount(code)
        .then((res) => {
          if (res.data.coupon.valid) {
            setDiscountCode(res.data.coupon);
            clearErrors(['discount_code']);
          } else {
            setDiscountCode(null);
            setError('discount_code', {
              type: 'manual',
              message: 'Invalid discount code',
            });
          }
        })
        .catch(() => {
          setDiscountCode(null);
          setError('discount_code', {
            type: 'manual',
            message: 'Invalid discount code',
          });
        });
    } else {
      setDiscountCode(null);
      clearErrors(['discount_code']);
    }
  };

  const checkDiscountCode = (val: any) => {
    if (val) {
      getDiscount(val)
        .then((res) => {
          if (res.data.coupon.valid) {
            setDiscountCode(res.data.coupon);
            clearErrors(['discount_code']);
            return true;
          } else {
            setDiscountCode(null);
            setError('discount_code', {
              type: 'manual',
              message: 'Invalid discount code',
            });
            return false;
          }
        })
        .catch(() => {
          setDiscountCode(null);
          setError('discount_code', {
            type: 'manual',
            message: 'Invalid discount code',
          });
          return false;
        });
    } else {
      setDiscountCode(null);
      clearErrors(['discount_code']);
      return true;
    }
  };

  // const handleMedication = () => {
  //   const isSelected = selectedProducts?.find(
  //     (e) => e.id.toString() === consultationProduct.id.toString()
  //   );
  //   if (!medication) {
  //     if (!isSelected) {
  //       handleSelectProduct(consultationProduct);
  //     }
  //   } else {
  //     if (isSelected) {
  //       handleSelectProduct(consultationProduct);
  //     }
  //   }

  //   setMedication(!medication);
  // };

  const handleHealthNow = () => {
    if (selectedProducts?.length > 0) {
      setValue('healthnow', 'true');
      handleSubmit(onSubmit)();
    } else {
      scroller.scrollTo('cart-products', {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset: 0,
      });
    }
  };

  // Function for Event

  const handleEventSelected = (data: any) => {
    setValue('event', data.value);
    handleEventChange(data.value);
  };

  const validateEmail = async (value: any) => {
    const res = await checkEmailAPI(value)
      .then((res) => {
        if (res.data.exists) {
          return false;
        } else {
          return true;
        }
      })
      .catch(() => {
        return false;
      });
    return res;
  };

  const checkPassword = (e: any) => {
    if (getValues('password') !== getValues('password_confirm')) {
      setError('password_confirm', {
        type: 'manual',
        message: 'Password does not match.',
      });
      return false;
    } else {
      clearErrors('password_confirm');
      return true;
    }
  };

  const handleGenderSelected = (data: any) => {
    setValue('gender', data.value);
  };

  const handleScan = (value: any) => {
    setValue('sample_id', value);
    setValue('confirm_sample_id', value);
  };

  const checkConfirmSampleId = () => {
    if (getValues('sample_id') !== getValues('confirm_sample_id')) {
      setError('confirm_sample_id', {
        type: 'manual',
        message: 'Sample Kit ID does not match.',
      });
      return false;
    } else {
      clearErrors('confirm_sample_id');
    }
  };

  const checkSampleId = () => {
    const sampleId = getValues('sample_id');
    checkSampleIdAPI(sampleId)
      .then((res) => {
        checkConfirmSampleId();
        if (res.data.exists) {
          setError('sample_id', {
            type: 'manual',
            message: 'Sample ID exists',
          });
          return false;
        } else {
          clearErrors('sample_id');
          return true;
        }
      })
      .catch(() => {
        return false;
      });
  };

  const handleMedication = () => {
    // const isSelected = selectedProducts?.find(
    //   (e: any) => e.id.toString() === consultationProduct?.id.toString()
    // );
    // if (!medication) {
    //   if (!isSelected && consultationProduct) {
    //     handleSelectProduct(consultationProduct);
    //   }
    // } else {
    //   if (isSelected && consultationProduct) {
    //     handleSelectProduct(consultationProduct);
    //   }
    // }

    setMedication(!medication);
  };

  return useObserver(() => {
    let loggedIn = false;
    if (authStore?.loggedIn) {
      isLoggedIn = true;
    } else {
      isLoggedIn = false;
    }
    loggedIn = isLoggedIn;
    return (
      <>
        {loggedIn ? (
          <Element name="order-cart">
            <Form className="form form-light" onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" name="cardToken" ref={register} />
              <Wrapper>
                <FormSection>
                  <SubSectionHeader>
                    <Label>Customer Details</Label>
                  </SubSectionHeader>

                  {events && (
                    <Form.Row>
                      <Col xs={12} md={12}>
                        <Form.Group controlId="event">
                          <Form.Label>Event</Form.Label>
                          <input
                            type="hidden"
                            name="event"
                            ref={register({ required: true })}
                          />
                          <Select
                            options={events?.map((evt) => ({
                              value: evt.pk,
                              label: evt.name,
                            }))}
                            onChange={handleEventSelected}
                          />
                          {errors.event && (
                            <Form.Control.Feedback type="invalid">
                              {errors.event.type === 'required' &&
                                'This field is required'}
                              {errors.event.type === 'manual' &&
                                errors.event.message}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                    </Form.Row>
                  )}

                  <Form.Row>
                    <Form.Group as={Col} controlId="firstName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        name="first_name"
                        type="text"
                        ref={register({ required: true })}
                        isInvalid={!!errors.first_name}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="lastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        name="last_name"
                        type="text"
                        ref={register({ required: true })}
                        isInvalid={!!errors.last_name}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="mobile">
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control
                        name="phone_number"
                        type="text"
                        ref={register({ required: true })}
                        isInvalid={!!errors.phone_number}
                      />
                    </Form.Group>
                  </Form.Row>

                  {medication ? (
                    <Alert>
                      <span>
                        <strong>Additional Add-Ons Required</strong>
                        <br />
                        Please select the Practitioner Consultation -
                        Methylation from the options above.
                      </span>
                    </Alert>
                  ) : (
                    <Spacer height={'30px'} />
                  )}

                  <OrderAddress
                    register={register}
                    defaultValues={defaultValues}
                    errors={errors}
                    clearErrors={clearErrors}
                    setValue={setValue}
                    withEssentials={withEssentials}
                    sameAddress={sameAddress}
                    // billingPhoneNumber={billingPhoneNumber}
                    handleSameAddress={handleSameAddress}
                    handleDeliveryRegionSelect={handleDeliveryPhoneNumber}
                    handleBillingRegionSelect={handleBillingPhoneNumber}
                  />
                </FormSection>
              </Wrapper>

              <Spacer height="20px" />

              <DarkFormSection>
                <Form.Row>
                  <Col>
                    <h3>Check Out</h3>
                    <p>Your Cart</p>
                    {selectedProducts?.length === 0 ? (
                      <CartNames>Your cart is empty.</CartNames>
                    ) : (
                      <CartNames>
                        {cartProducts &&
                          cartProducts.filter((e) => e !== '').join(' + ')}
                      </CartNames>
                    )}
                  </Col>
                </Form.Row>

                <Spacer height="20px" />

                <Form.Row>
                  <Col xs={12} md={3}>
                    <Price>
                      <NumberFormat
                        value={totalPrice}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                      <small>Inc GST</small>
                    </Price>
                    <Form.Group controlId="discount_code">
                      <Form.Label>Discount Code</Form.Label>
                      <Form.Control
                        name="discount_code"
                        type="text"
                        ref={register({
                          validate: async (val) => {
                            const res = await checkDiscountCode(val);
                            return res;
                          },
                        })}
                        isInvalid={!!errors.discount_code}
                        onChange={handleDiscountCode}
                      />
                      {errors.discount_code && (
                        <Form.Control.Feedback type="invalid">
                          {errors.discount_code.type === 'manual' &&
                            errors.discount_code.message}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={9}>
                    <StripeWrapper>
                      <Form.Group controlId="payment">
                        <span className="title">Pay with Card</span>
                        <CardWrapper>
                          <CardElement
                            className="checkout-stripe"
                            options={{
                              iconStyle: 'solid',
                              hidePostalCode: true,
                              disabled: totalPrice <= 0,
                              style: CARD_OPTIONS.style,
                            }}
                          />
                        </CardWrapper>
                        <p className="stripe-help">
                          Secure payments using Stripe
                        </p>
                        {errors.cardToken && (
                          <Form.Control.Feedback type="invalid">
                            {errors.cardToken.type === 'required' &&
                              'This field is required'}
                            {errors.cardToken.type === 'manual' &&
                              errors.cardToken.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      <Button
                        variant="secondary"
                        type="submit"
                        disabled={
                          loading || !stripe || selectedProducts?.length === 0
                        }
                      >
                        {!loading ? (
                          'Submit'
                        ) : (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </Button>
                      <div style={{ clear: 'both' }} />
                    </StripeWrapper>
                    <OR>OR</OR>
                    <HealthNowWrapper>
                      <span className="title">Pay with HealthNow</span>
                      <Row>
                        <Col xs={12} md={7}>
                          <p>
                            Spread your payments across multiple weeks with
                            HealthNow
                          </p>
                        </Col>
                        <Col xs={12} md={5}>
                          <input
                            type="hidden"
                            name="healthnow"
                            value="false"
                            ref={register}
                          />
                          {!loading && (
                            <HealthNowBtn onClick={() => handleHealthNow()}>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  '/assets/images/HealthNow-Button.png'
                                }
                                alt="HealthNow Logo"
                              />
                            </HealthNowBtn>
                          )}

                          {loading && (
                            <HealthNowSpinner>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            </HealthNowSpinner>
                          )}
                        </Col>
                      </Row>
                    </HealthNowWrapper>
                  </Col>
                </Form.Row>
              </DarkFormSection>
            </Form>
          </Element>
        ) : (
          <Form
            className="form form-light"
            onSubmit={handleSubmit(onSubmitGuest)}
          >
            <input type="hidden" name="card_token" ref={register} />
            <Wrapper>
              <FormSection>
              {loginBox && !loggedIn ? <div>
                  <h5>Already an Ingenous customer?</h5>
                  <LoginHere onClick={() => setLoginFormVisible(!loginFormVisible)}>Login here</LoginHere>
                  {loginFormVisible ? <CartFormLogin/> : ''}
                </div> : ''}
              </FormSection>
              <FormSection>
                <SubSectionHeader>
                  <Label>Customer Details</Label>
                </SubSectionHeader>

                <Form.Row>
                  <Col xs={12} md={12}>
                    <Form.Group controlId="event">
                      <Form.Label>Event</Form.Label>
                      <input
                        type="hidden"
                        name="event"
                        ref={register({ required: true })}
                      />
                      <Select
                        options={events?.map((evt) => ({
                          value: evt.pk,
                          label: evt.name,
                        }))}
                        onChange={handleEventSelected}
                      />
                      {errors.event && (
                        <Form.Control.Feedback type="invalid">
                          {errors.event.type === 'required' &&
                            'This field is required'}
                          {errors.event.type === 'manual' &&
                            errors.event.message}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} xs={12} md={6} controlId="firstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      name="first_name"
                      type="text"
                      ref={register({ required: true })}
                      isInvalid={!!errors.first_name}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} md={6} controlId="lastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      name="last_name"
                      type="text"
                      ref={register({ required: true })}
                      isInvalid={!!errors.last_name}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="formEmail">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        name="email"
                        type="text"
                        ref={register({
                          required: true,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'Invalid email address',
                          },
                          validate: {
                            asyncValidate: async (value) => {
                              const result = await validateEmail(value);
                              return result;
                            },
                          },
                        })}
                        isInvalid={!!errors.email}
                      />
                      {errors.email && (
                        <Form.Control.Feedback type="invalid">
                          {errors.email.type === 'required' &&
                            'This field is required'}
                          {errors.email.type === 'manual' &&
                            errors.email.message}
                          {errors.email.type === 'asyncValidate' &&
                            'Email exists'}
                          {errors.email.type !== 'required' &&
                            errors.email.type !== 'manual' &&
                            errors.email.type !== 'asyncValidate' &&
                            'Invalid input'}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="formPhone">
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        name="phone_number"
                        type="text"
                        ref={register({
                          required: true,
                        })}
                        isInvalid={!!errors?.phone_number}
                      />
                      {errors.phone_number && (
                        <Form.Control.Feedback type="invalid">
                          {errors.phone_number.type === 'required' &&
                            'This field is required'}
                          {errors.phone_number.type === 'manual' &&
                            errors.phone_number.message}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} xs={12} md={6} controlId="password">
                    <Form.Label>
                      Password &nbsp;
                      <small onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? 'Hide Password' : 'Show Password'}
                      </small>
                    </Form.Label>
                    <Form.Control
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      ref={register({ required: true, minLength: 8 })}
                      onChange={checkPassword}
                      isInvalid={!!errors?.password}
                    />
                    {errors.password && (
                      <Form.Control.Feedback type="invalid">
                        {errors.password.type === 'required'
                          ? 'This field is required'
                          : errors.password.type === 'minLength'
                          ? 'Password must be at least 8 characters long'
                          : 'Invalid input'}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    xs={12}
                    md={6}
                    controlId="confirmPassword"
                  >
                    <Form.Label>
                      Confirm Password &nbsp;
                      <small
                        onClick={() =>
                          setShowPasswordConfirm(!showPasswordConfirm)
                        }
                      >
                        {showPasswordConfirm
                          ? 'Hide Password'
                          : 'Show Password'}
                      </small>
                    </Form.Label>
                    <Form.Control
                      name="password_confirm"
                      type={showPasswordConfirm ? 'text' : 'password'}
                      ref={register({ required: true, minLength: 8 })}
                      onChange={checkPassword}
                      isInvalid={!!errors?.password_confirm}
                    />
                    {errors.password_confirm && (
                      <Form.Control.Feedback type="invalid">
                        {errors.password_confirm.type === 'required' &&
                          'This field is required'}
                        {errors.password_confirm.type === 'manual' &&
                          errors.password_confirm.message}
                        {errors.password_confirm.type !== 'required' &&
                          errors.password_confirm.type !== 'manual' &&
                          'Invalid input'}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="gender">
                      <Form.Label>Gender</Form.Label>
                      <input
                        type="hidden"
                        name="gender"
                        ref={register({ required: true })}
                      />
                      <Select
                        options={GENDER_OPTIONS}
                        defaultValue={gender}
                        onChange={handleGenderSelected}
                      />
                      {errors.gender && (
                        <Form.Control.Feedback type="invalid">
                          {errors.gender.type === 'required' &&
                            'This field is required'}
                          {errors.gender.type === 'manual' &&
                            errors.gender.message}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="birthdate">
                      <Form.Label>Date Of Birth</Form.Label>
                      <Controller
                        control={control}
                        name="birthdate"
                        rules={{ required: true }}
                        render={(props) => (
                          <SelectDatePicker
                            {...props}
                            seterror={setError}
                            format={'yyyy-MM-dd'}
                            onChange={(e: any) => {
                              props.onChange(e);
                            }}
                          />
                        )}
                      />
                      {errors.birthdate && (
                        <Form.Control.Feedback type="invalid">
                          {errors.birthdate.type === 'required' &&
                            'This field is required'}
                          {errors.birthdate.type === 'manual' &&
                            errors.birthdate.message}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Form.Row>
              </FormSection>

              <FormSection>
                <SubSectionHeader>
                  <Label>Scan Your Kit</Label>
                </SubSectionHeader>

                <Row>
                  <Col md={6}>
                    <p>Hold the square QR code to your device's camera.</p>
                    <Scanner handleScan={handleScan} />
                    <p>Or enter the Sample Kit ID code here</p>
                    <Form.Row>
                      <Form.Group as={Col} controlId="sample_id">
                        <Form.Label>Sample Kit ID</Form.Label>
                        <Form.Control
                          name="sample_id"
                          type="text"
                          ref={register({ required: true })}
                          onChange={checkSampleId}
                          isInvalid={!!errors.sample_id}
                        />
                        {errors.sample_id && (
                          <Form.Control.Feedback type="invalid">
                            {errors.sample_id.type === 'required' &&
                              'This field is required'}
                            {errors.sample_id.type === 'manual' &&
                              errors.sample_id.message}
                            {errors.sample_id.type !== 'required' &&
                              errors.sample_id.type !== 'manual' &&
                              'Invalid input'}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="sample_id">
                        <Form.Label>Confirm Sample Kit ID</Form.Label>
                        <Form.Control
                          name="confirm_sample_id"
                          type="text"
                          ref={register({ required: true })}
                          onChange={checkConfirmSampleId}
                          isInvalid={!!errors.confirm_sample_id}
                        />
                        {errors.confirm_sample_id && (
                          <Form.Control.Feedback type="invalid">
                            {errors.confirm_sample_id.type === 'required' &&
                              'This field is required'}
                            {errors.confirm_sample_id.type === 'manual' &&
                              errors.confirm_sample_id.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Form.Row>
                  </Col>
                </Row>

                {/* <Form.Row>
            <Form.Group as={Col} controlId="phone_number">
              <Form.Label>
                Phone Number
              </Form.Label>
              <Form.Control
                name="phone_number"
                type="text"
                ref={register({ required: true })}
                isInvalid={!!errors.phone_number}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="space" />
          </Form.Row> */}

                <Spacer height={'30px'} />

                {!askMedication ? '' : (
                  <Fragment>
                    <SubSectionHeader>
                      <Label>Please Answer</Label>
                    </SubSectionHeader>

                    <Form.Row>
                      <Checkbox>
                        <Form.Group controlId="medications">
                          <Form.Check
                            type="checkbox"
                            label="Do you have a medical condition or do you take prescription medication?"
                            name="medications"
                            ref={register}
                            checked={medication}
                            onChange={handleMedication}
                          />
                        </Form.Group>
                      </Checkbox>
                    </Form.Row>
                  </Fragment>
                )}

                {medication ? (
                  <Alert>
                    <span>
                      <strong>Additional Add-Ons Required</strong>
                      <br />
                      Please select the Practitioner Consultation - Methylation
                      from the options above.
                    </span>
                  </Alert>
                ) : (
                  <Spacer height={'30px'} />
                )}
              </FormSection>
            </Wrapper>

            <Spacer height="20px" />

            <DarkFormSection>
              <Form.Row>
                <Col>
                  <h3>Check Out</h3>
                  <p>Your Cart</p>
                  {selectedProducts?.length === 0 ? (
                    <CartNames>
                      Your cart is empty, please select at least one product to
                      continue.
                    </CartNames>
                  ) : (
                    <CartNames>
                      {cartProducts &&
                        cartProducts.filter((e) => e !== '').join(' + ')}
                    </CartNames>
                  )}
                </Col>
              </Form.Row>

              <Spacer height="20px" />

              <Form.Row>
                <Col xs={12} md={3}>
                  <Price>
                    <NumberFormat
                      value={totalPrice}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                    <small>Inc GST</small>
                  </Price>
                  <Form.Group controlId="discount_code">
                    <Form.Label>Discount Code</Form.Label>
                    <Form.Control
                      name="discount_code"
                      type="text"
                      ref={register({
                        validate: async (val) => {
                          const res = await checkDiscountCode(val);
                          return res;
                        },
                      })}
                      isInvalid={!!errors.discount_code}
                      onChange={handleDiscountCode}
                    />
                    {errors.discount_code && (
                      <Form.Control.Feedback type="invalid">
                        {errors.discount_code.type === 'manual' &&
                          errors.discount_code.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} md={9}>
                  <StripeWrapper>
                    <Form.Group controlId="payment">
                      <span className="title">Pay with Card</span>
                      <CardWrapper>
                        <CardElement
                          className="checkout-stripe"
                          options={{
                            iconStyle: 'solid',
                            hidePostalCode: true,
                            disabled: totalPrice <= 0,
                            style: CARD_OPTIONS.style,
                          }}
                        />
                      </CardWrapper>
                      <p className="stripe-help">
                        Secure payments using Stripe
                      </p>
                      {errors.cardToken && (
                        <Form.Control.Feedback type="invalid">
                          {errors.cardToken.type === 'required' &&
                            'This field is required'}
                          {errors.cardToken.type === 'manual' &&
                            errors.cardToken.message}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Button
                      variant="secondary"
                      type="submit"
                      disabled={
                        loading || !stripe || selectedProducts?.length === 0
                      }
                    >
                      {!loading ? (
                        'Submit'
                      ) : (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </Button>
                    <div style={{ clear: 'both' }} />
                  </StripeWrapper>
                  {false && (
                    <>
                      <OR>OR</OR>
                      <HealthNowWrapper>
                        <span className="title">Pay with HealthNow</span>
                        <Row>
                          <Col xs={12} md={7}>
                            <p>
                              Spread your payments across multiple weeks with
                              HealthNow
                            </p>
                          </Col>
                          <Col xs={12} md={5}>
                            <input
                              type="hidden"
                              name="healthnow"
                              value="false"
                              ref={register}
                            />
                            {!loading && (
                              <HealthNowBtn onClick={() => handleHealthNow()}>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    '/assets/images/HealthNow-Button.png'
                                  }
                                  alt="HealthNow Logo"
                                />
                              </HealthNowBtn>
                            )}

                            {loading && (
                              <HealthNowSpinner>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              </HealthNowSpinner>
                            )}
                          </Col>
                        </Row>
                      </HealthNowWrapper>
                    </>
                  )}
                </Col>
              </Form.Row>
            </DarkFormSection>
          </Form>
        )}
      </>
    );
  });
}

FormCart.defaultProps = {
  askMedication: true,
  loginBox: false
};


export default FormCart;