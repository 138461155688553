import React, { useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { AuthContext } from 'stores/auth';

import { Spacer } from 'components/utils';
import { Page, PageHeader, PageBody } from 'components/Page';
import NotApproved from './components/NotApproved';

import SearchForm from './components/SearchForm';

const SearchWrapper = styled.div`
  h6 {
    color: #707070;
    padding: 0 15px;
  }
`;

const OffsetCol = styled(Col)`
  margin-top: -110px;
  z-index: 9999;
`;

const OffsetWrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 50px 30px;
  border: 1px solid #dedede;
`;

const Consultation = () => {
  const authStore = useContext(AuthContext);
  const [kitId, setKitId] = useState(localStorage.getItem('consultKitId'));
  const [productIds, setProductIds] = useState(localStorage.getItem('productIds'))
  const [loading, setLoading] = useState(false)

  const handleSearch = (data) => {
    localStorage.setItem('consultKitId', data);
    setKitId(data);
  };

  // if (kitId && productIds) {
  //   let productIdsSplit = productIds.split(',')
  //   if(process.env.REACT_APP_CONSULTATION_PRODUCT_ID in productIdsSplit){
  //     return <Redirect to={`/consult/methylation`} />;
  //   }
    
  // }

  if(!(authStore.isPractitionerOrg() || authStore.isPractitioner())){
    return <Redirect to={'/dashboard'}/>
  }

  if(authStore.isPractitionerOrg()){
    if(!authStore.isApproved()){
      return (
        <>
          <Page>
            <PageHeader>
              {!isMobile && <Spacer height={'30px'} />}
              <Container>
                <Spacer height="120px" />
                <Row>
                  <Col xs={12} md={3}>
                    {/* <h3 style={{ 'color': 'white' }}>{authS?.display_name}</h3> */}
                  </Col>
                </Row>
              </Container>
            </PageHeader>
            <PageBody>
              <NotApproved/>
            </PageBody>
          </Page>
        </>
      )
    }
  }

  return (
    <>
      <Page>
        <PageHeader>
          {!isMobile && <Spacer height={'30px'} />}
          <Container>
            <Spacer height="120px" />
            <Row>
              <Col xs={12} md={3}>
                {/* <h3 style={{ 'color': 'white' }}>{authS?.display_name}</h3> */}
              </Col>
            </Row>
          </Container>
        </PageHeader>
        <PageBody>
          <Container className="practitioner">
            <Row>
              <Col xs={12} md={3}>
                <Spacer height="50px" />
                <SearchWrapper>
                  <h6>Consult Support</h6>
                  <SearchForm handleSearch={handleSearch} handleLoading={setLoading} kitId={kitId}/>
                </SearchWrapper>
              </Col>
              <OffsetCol md={9}>
                <OffsetWrapper>
                  <Row>
                    <Col>
                      <h2>Consult Support</h2>
                      <p>
                        Welcome to your Consult Support area. This is where you
                        can dig into client results.
                      </p>
                      <Spacer height={'40px'} />
                    </Col>
                  </Row>
                </OffsetWrapper>
              </OffsetCol>
            </Row>
            <Spacer height={'30px'} />
          </Container>
        </PageBody>
      </Page>
    </>
  );
};

export default Consultation;