import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import AdminWrapper from '../containers/AdminWrapper';

import { AuthContext } from 'stores/auth';

import Admin from './Admin/index';
import Users from './Admin/Users';
import Events from './Admin/Events';
import CreateEvent from './Admin/Events/Create';
import ReviewEvent from './Admin/Events/Review';
import ViewEvent from './Admin/Events/View';
import Downloads from './Admin/Downloads';
import Trainings from './Admin/Trainings';
import Upload from './Admin/Upload';

const AdminRoutes = () => {
  const authStore = useContext(AuthContext);

  if (authStore.user.scope !== 'admin') {
    return <Redirect to="/" />;
  }
  return (
    <AdminWrapper>
      <Switch>
        <Route exact path="/admin/users" component={Users} />
        <Route exact path="/admin/events" component={Events} />
        <Route exact path="/admin/events/create" component={CreateEvent} />
        <Route
          exact
          path="/admin/events/review/:code"
          component={ReviewEvent}
        />
        <Route exact path="/admin/events/:code" component={ViewEvent} />
        <Route exact path="/admin/downloads" component={Downloads} />
        <Route exact path="/admin/trainings" component={Trainings} />
        <Route exact path="/admin/upload" component={Upload} />
        <Route path="/" component={Admin} />
        <Route render={() => <Redirect to="/admin" />} />
      </Switch>
    </AdminWrapper>
  );
};

export default AdminRoutes;
