import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Element, scroller } from 'react-scroll';

import { Spacer } from 'components/utils';

import COMT from '../../../Reports/LifePlusReport/components/COMT';
import Coffee from '../../../Reports/LifePlusReport/components/Coffee';
import CaffeinatedTea from '../../../Reports/LifePlusReport/components/CaffeinatedTea';
import GreenTea from '../../../Reports/LifePlusReport/components/GreenTea';
import Decaf from '../../../Reports/LifePlusReport/components/Decaf';
import CafeineFree from '../../../Reports/LifePlusReport/components/CafeineFree';

import AlcoholConsumption from '../../../Reports/LifePlusReport/components/AlcoholConsumption';
import CarbohydrateTolerance from '../../../Reports/LifePlusReport/components/CarbohydrateTolerance';
import Tips from '../../../Reports/LifePlusReport/components/Tips';
import FatIntake from '../../../Reports/LifePlusReport/components/FatIntake';
import Inflammation from '../../../Reports/LifePlusReport/components/Inflammation';
import DietType from '../../../Reports/LifePlusReport/components/DietType';
import NutritionChart from '../../../Reports/LifePlusReport/components/NutritionChart';
import Exercise from '../../../Reports/LifePlusReport/components/Exercise';

const BodyCol = styled(Col)``;

const Results = ({ results }) => {
  useEffect(() => {
    if (window.location.hash) {
      scroller.scrollTo(window.location.hash.replace('#', ''), {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset: 0,
      });
    }
  }, [results]);

  console.log(results)

  return (
    <>
      <Row>
        <BodyCol>
          <Spacer height={'30px'} />
          <Element name="what-to-drink" className="drink">
            <h3>What To Drink</h3>
            <Spacer height={'30px'} />
            <Coffee serving={results.coffee} />
            <Spacer height={'30px'} />
            {results.coffee +
              results.caffeinated_tea +
              results.green_tea +
              results.decaf_beverages ===
              0 && (
              <>
                <COMT />
                <Spacer height={'30px'} />
              </>
            )}
            <CaffeinatedTea serving={results.caffeinated_tea} />
            <Spacer height={'30px'} />
            <GreenTea serving={results.green_tea} />
            <Spacer height={'30px'} />
            <Decaf serving={results.decaf_beverages} />
            <Spacer height={'30px'} />
            <CafeineFree />
            <Spacer height={'50px'} />
            <AlcoholConsumption
              alcohol_quantity={results.alcohol_quantity}
              alcohol_warning={results.alcohol_warning}
              alcohol_reduce_inflam={results.alcohol_reduce_inflam}
              alcohol_a2a={results.alcohol_a2a}
              alcohol_e2a={results.alcohol_e2a}
              gender={results.gender}
            />
          </Element>

          <Element name="what-to-eat" className="eat">
            <Spacer height={'50px'} />
            <CarbohydrateTolerance
              aml_count={results.aml_count}
              gender={results.gender}
            />

            <Spacer height={'30px'} />
            <Tips />

            <Spacer height={'30px'} />
            <FatIntake fats={results.fat} />

            <Spacer height={'30px'} />
            <Inflammation />

            <Spacer height={'30px'} />
            <DietType diet_type={results.diet_type} />

            <Spacer height={'30px'} />
            <NutritionChart results={results} />
          </Element>

          <Element name="how-to-exercise" className="exercise">
            <Spacer height={'50px'} />
            <Exercise results={results} />
          </Element>
        </BodyCol>
      </Row>
    </>
  );
};

export default Results;
