import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { gray, primary } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import View from '../View';
import Close from '../ViewLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const AlertRef = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
  margin-bottom: 30px;
`;

const SubtitleGray = styled.h5`
  color: ${gray};
  margin-top: 10px;
`;

const WrapperRef = styled.div`
  margin-top: 5px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const ListRef = styled.li`
  font-weight: 300;
  padding-left: 20px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    word-break: break-all;
  }
`;

interface SerenitenProps {
  results: any;
}
const Sereniten = ({ results }: SerenitenProps) => {
  const [show, setShow] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/Supplement.png"
            alt="supplement"
            width={'60%'}
          />
        </ImageCol>
        <Col md={8}>
          <SubtitleGray>
            Your most powerful supplement for weight loss is
          </SubtitleGray>
          <Subtitle>Sereniten</Subtitle>
          <Alert>
            <p>
              Suggested Supplement: Sereniten Plus, Douglas Labs (1 capsule
              daily).
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <p>
                Taking Sereniten can assist you in your weightloss journey by
                balancing stress and supporting metabolism.
              </p>
              <p>
                The Lactium casein decapeptide, L-theanine, and vitamin D in
                Sereniten Plus help support your hypothalamic-pituitary-adrenal
                (HPA) axis (1). The main function of HPA is to respond to stress
                by stimulating the production of hormones such as epinephrine
                and cortisol (2). When responding to stress all of your body's
                resources are sent to help the current stressor, all other
                activities that can't deal with the stressor are neglected. One
                of these areas is digestion. If you are stressed your digestion
                will suffer. HPA axis repeated activation has been linked to
                obesity, type 2 diabetes, and cardiovascular disease (3).
              </p>
              <p style={{ fontWeight: 'bold' }}>
                This supplement is best for your genes. If you have any concerns
                this may not suit your current state of health or medications
                check with your doctor.
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
      <Row className="no-print">
        <Col>
          <AlertRef>
            <span>References</span>
            {!showReferences && (
              <View value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <Close value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <WrapperRef>
                <ListGroup>
                  <ListRef>
                    <a href="https://www.douglaslabs.com/sereniten-plus-capsule.html">
                      https://www.douglaslabs.com/sereniten-plus-capsule.html{' '}
                    </a>
                  </ListRef>
                  <ListRef>
                    Chen X, Gianferante D, Hanlin L, Fiksdal A, Breines JG,
                    Thoma MV, Rohleder N. HPA-axis and inflammatory reactivity
                    to acute stress is related with basal HPA-axis activity.
                    Psychoneuroendocrinology. 2017 Apr 1;78:168-76.&nbsp;
                    <a href="https://www.sciencedirect.com/science/article/abs/pii/S0306453017300860">
                      https://www.sciencedirect.com/science/article/abs/pii/S0306453017300860
                    </a>
                  </ListRef>
                  <ListRef>
                    Rosmond R. Stress induced disturbances of the HPA axis: a
                    pathway to type 2 diabetes?. Medical Science Monitor. 2003
                    Feb 25;9(2):RA35-9.&nbsp;
                    <a href="https://www.medscimonit.com/abstract/index/idArt/4744/new/1/act/3">
                      https://www.medscimonit.com/abstract/index/idArt/4744/new/1/act/3
                    </a>
                  </ListRef>
                </ListGroup>
              </WrapperRef>
            )}
          </AlertRef>
        </Col>
      </Row>
    </>
  );
};

export default Sereniten;
