import React, { useRef, useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useObserver } from 'mobx-react-lite';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Element } from 'react-scroll';
import { Redirect } from 'react-router-dom';

import { getProducts, getUserProducts, Product } from 'api/core';
import { AuthContext } from 'stores/auth';
import { Spacer } from 'components/utils';
import { Page, PageHeader, PageTitle, PageBody } from 'components/Page';

import Form from '../../components/Cart/Form';
import CartItems from 'components/Cart/CartItems';

const Wrapper = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
  align-items: center;

  .title {
    flex: 1;
    display: ${isMobile ? 'block' : 'flex'};
    align-items: center;
    justify-content: space-between;

    h1 {
      margin: 0;
      margin-right: 20px;
    }
  }
`;

const BannerWrapper = styled.div``;

const Banner = styled.div`
  background-color: #f2f2f2;
  border: 1px solid #e5864b;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 16px;

  p {
    margin-bottom: 0;
  }
`;

function OrderDetails() {
  // Keep track of our component's mounted state
  const componentIsMounted = useRef(true);
  // const queryProduct = query.get('p');

  const authStore = useContext(AuthContext);

  // eslint-disable-next-line
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [userProducts, setUserProducts] = useState<string[]>([]);

  const [productSelected, updateProductSelected] = useState([] as any);

  useEffect(() => {
    getProducts().then((res) => {
      if (!componentIsMounted.current) return;
      setProducts(res.data?.products);
    });

    getUserProducts().then((res) => {
      if (!componentIsMounted.current) return;
      setUserProducts(res.data.purchased_products);
    });

    return () => {
      componentIsMounted.current = false;
    };
  }, []);
  return useObserver(() => {
    const billingAddresses = authStore.addresses
      ? authStore.addresses.filter(
          (item: { address_type: string }) => item.address_type === 'billing'
        )
      : [];
    const billingAddress = billingAddresses[0];
    const shippingAddresses = authStore.addresses
      ? authStore.addresses.filter(
          (item: { address_type: string }) => item.address_type === 'delivery'
        )
      : [];
    const shippingAddress = shippingAddresses[0];

    let defaultValues = {
      delivery: { ...shippingAddress },
      billing: null,
      first_name: null,
      last_name: null,
      phone_number: null,
    };

    if (billingAddress) {
      defaultValues.billing = { ...billingAddress };
    } else if (!billingAddress && shippingAddress) {
      defaultValues.billing = { ...shippingAddress };
    }

    defaultValues.first_name = authStore?.user?.first_name;
    defaultValues.last_name = authStore?.user?.last_name;
    defaultValues.phone_number = authStore?.user?.phone_number;
    if(authStore.isPractitionerOrg()){
      return <Redirect to="/consult"/>;
    }
    return (
      <Page>
        <PageHeader>
          <Spacer height={'30px'} />
          <Container>
            <Wrapper>
              <div className="title">
                <PageTitle>New Order</PageTitle>
                <Link to="/register-kit">
                  <Button variant="outline">Register Kit</Button>
                </Link>
              </div>
            </Wrapper>
          </Container>
        </PageHeader>

        <Spacer height={'30px'} />
        {/* <BannerWrapper>
          <Container>
            <Banner>
              <h2>Ingeneous DNA Test Timings - December and January</h2>
              <Spacer height={'15px'} />
              <p>
                Please note that if you purchase an Ingeneous Essential Start test and report from 01 December 2022 onwards, 
                that your results will be available in February 2023. 
                Tests are prioritised for processing at the Laboratory in the order of when Ingeneous receives your completed 
                DNA test kit. 
              </p>
              <Spacer height={'15px'} />
              <p>
              You can upgrade your purchase at any time to 
              LifeStyle Plus, Practitioner Consultation - Methylation and Weight Management 
              reports and receive these as per our typical timings. 
              Please email <a href="hello@ingeneous.co.nz">hello@ingeneous.co.nz</a> if you have any further queries. 
              </p>
            </Banner>
          </Container>
        </BannerWrapper>
        <Spacer height={'30px'} /> */}

        <PageBody>
          <Container className="order">
            {/* <Spacer height={'40px'} /> */}
            <Element name="cart-products">
              <span>Choose your report</span>

              <Spacer height={'10px'} />

              <Row>
                <Col
                  sm={12}
                  md={12}
                  style={{ padding: isMobile ? '0' : '0 15px' }}
                >
                  <CartItems
                    updateProductSelected={updateProductSelected}
                    eventDiscounts={[]}
                  />
                </Col>
              </Row>

              <Spacer height={'20px'} />
            </Element>

            <Spacer height={'30px'} />

            <Form
              eventCode={''}
              eventDiscounts={[]}
              defaultValues={defaultValues}
              selectedProducts={productSelected}
              userProducts={userProducts}
              isLoggedIn={true}
              handleEventChange={function (pk: number): void {
                throw new Error('Function not implemented.');
              }}
            />

            <Spacer height={'40px'} />

            {/* <StripeFooter>
              <span>
                <strong>Safe and secure checkout</strong>
              </span>
              <img
                src={process.env.PUBLIC_URL + '/assets/images/Stripe.png'}
                alt=""
              />
            </StripeFooter>

            <Spacer height={'40px'} /> */}
          </Container>
        </PageBody>
      </Page>
    );
  });
}

export default OrderDetails;
