import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 0;
  color: #6DA6CA;
`;

interface ReadMoreProps {
  value: boolean;
  setValue: (value: boolean) => void;
}

const ReadMore = ({ value, setValue }: ReadMoreProps) => {
  return (
    <Button onClick={() => setValue(!value)}>
      Read more
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-1 w-1"
        viewBox="0 0 20 20"
        fill="currentColor"
        width={15}
      >
        <path
          fillRule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </Button>
  );
};

export default ReadMore;
