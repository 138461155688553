import React, { useContext } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { Spacer } from 'components/utils';
import { Page, PageHeader, PageBody, PageTitle } from 'components/Page';
import { primary } from 'styles/theme';
import { AuthContext } from 'stores/auth';

const TextWhite = styled.p`
  color: #ffffff;
  font-size: ${isMobile ? '24px' : '40px'};
  margin-bottom: 0;
`;

const TextWrapper = styled.div`
  position: absolute;
  top: 60%;
  left: ${isMobile ? '45%' : '40%'};
  text-align: center;
  transform: ${isMobile ? 'translate(-45%, -60%)' : 'translate(-40%, -60%)'};
`;

const WrapperElement = styled.div`
  display: flex;
  justify-content: center;
`;

const WrapperCalltoAction = styled.div`
  display: block;
  text-align: center;
  a {
    width: ${isMobile ? 'inherit' : '100%'};
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const ColText = styled(Col)`
  order: ${isMobile ? 1 : 0};
  padding: ${isMobile ? 'auto' : '0 100px'};
`;

const ColImage = styled(Col)`
  order: ${isMobile ? 0 : 1};
`;

const GreenText = styled.p`
  color: ${primary};
`;

const Wrapper = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
  align-items: center;
  padding: 15px 0;

  .title {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      width: ${isMobile ? '120px' : 'auto'};
      font-size: ${isMobile ? '12px' : 'auto'};
      background-color: transparent;
      color: rgb(255, 255, 255);
      border: 2px solid rgb(255, 255, 255);
      box-shadow: none;
    }
  }
`;

const Error404 = () => {
  const authStore = useContext(AuthContext);

  return (
    <>
      <Page>
        {authStore?.loggedIn ? (
          ''
        ) : (
          <PageHeader>
            <Container>
              <Wrapper>
                <div className="title">
                  <PageTitle>
                    <img
                      src="/assets/images/ingeneous_white_r.png"
                      alt="ingeneous"
                      width={isMobile ? 100 : 190}
                    />
                  </PageTitle>
                  <a
                    href="/login"
                    className="btn btn-secondary"
                  >
                    SignUp / Login
                  </a>{' '}
                </div>
              </Wrapper>
            </Container>
          </PageHeader>
        )}
        <PageBody>
          <Container>
            <Row>
              <ColText sm={12} md={6}>
                {isMobile ? (
                  <Spacer height={'40px'} />
                ) : (
                  <Spacer height={'90px'} />
                )}
                {isMobile ? (
                  <h4>Uh-Oh, looks like you’ve come to the wrong place.</h4>
                ) : (
                  <h2>Uh-Oh, looks like you’ve come to the wrong place.</h2>
                )}
                <Spacer height={'20px'} />
                <GreenText>
                  We’re sorry, the page you are searching for doesn’t seem to
                  exist. Start by checking your spelling, and if that doesn’t
                  work, our reports page is a great place to start!
                </GreenText>
                <Spacer height={'20px'} />
                <WrapperCalltoAction>
                  {authStore?.loggedIn ? (
                    <a href="/" className="btn btn-primary">
                      Back to My Dashboard
                    </a>
                  ) : (
                    <a
                      href="https://myingeneous.com/gene-reports/"
                      target="_blank"
                      className="btn btn-primary"
                    >
                      Explore Reports
                    </a>
                  )}
                </WrapperCalltoAction>
                {isMobile && <Spacer height={'50px'} />}
              </ColText>
              <ColImage sm={12} md={6}>
                <WrapperElement>
                  <img
                    src="/assets/images/Error.svg"
                    width={isMobile ? 350 : 672}
                  />
                  <TextWrapper>
                    <TextWhite>404</TextWhite>
                    <TextWhite>error lemon</TextWhite>
                  </TextWrapper>
                </WrapperElement>
              </ColImage>
            </Row>
          </Container>
        </PageBody>
      </Page>
    </>
  );
};

export default Error404;
