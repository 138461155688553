import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';

import Icon from 'components/Icon';

export const TR = styled(Row)`
  border-bottom: 1px solid #dedede;
  padding: 15px 0;
  @media print {
    border-top: 1px solid #dedede;
    border-bottom: 0;    
  }
`;

export const Circle = styled.div`
  height: 17px;
  width: 17px;
  border-radius: 100px;
  background-color: ${(props) => props.color};
  margin: 0 auto;
  margin-top: 3px;
`;

export const CollapseBtn = styled(Button)`
  font-size: 20px;
  padding: 5px 10px;
  border: 0;
  box-shadow: none;
`;

export const Collapse = styled.div`
  min-height: 50px;
  margin-bottom: 20px;

  @media print {
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 10px;
    padding-bottom: 25px;
  }
`;

export const DataWrapper = styled.div`
  padding: 20px 0;

  @media print {
    padding: 5px 0;
    & > p {
      margin-bottom: 5px;
    }
  }
`;

export const Score = styled.span`
  display: inline-block;
  padding: 5px 10px;
  margin: 10px 0;
  background-color: ${(props) => props.color};
  border-radius: 5px;
  color: white;
`;

export const getColor = (val) => {
  let color = '#f5f5f5';
  if (val === 2) {
    color = '#d7b6b7'; // Red
  } else if (val === 1) {
    color = '#ead79f'; // Yellow
  } else if (val === 0) {
    color = '#b5dbd6'; // Green
  }

  return color;
};

const TableRow = ({ genes, geneData }) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <>
      <TR>
        <Col md={11}>
          {genes.map((gene) => {
            const value = geneData?.find((e) => 'rs' + gene.rs === e.snp);
            return (
              <Row key={gene.rs}>
                <Col md={2}>{gene.gene}</Col>
                <Col md={1}>
                  <Circle color={getColor(value?.score)} />
                </Col>
                <Col md={2}>RS{gene.rs}</Col>
                <Col md={7}>&nbsp;</Col>
              </Row>
            );
          })}
        </Col>
        <Col md={1}>
          <CollapseBtn
            variant="secondary"
            onClick={() => setCollapsed(!collapsed)}
          >
            <Icon
              name={collapsed ? 'caret-down' : 'caret-up'}
              color="#dedede"
            />
          </CollapseBtn>
        </Col>
      </TR>
      {!collapsed && (
        <Collapse>
          {genes.map((gene) => {
            const value = geneData?.find((e) => 'rs' + gene.rs === e.snp);
            return (
              <DataWrapper key={gene.rs}>
                <h4 style={{ color: '#707070' }}>{gene.gene}</h4>
                {value?.score === 0 && (
                  <Score color={getColor(value?.score)}>
                    If Green result for the RS#{gene.rs}
                  </Score>
                )}
                {value?.score === 1 && (
                  <Score color={getColor(value?.score)}>
                    If Yellow result for the RS#{gene.rs}
                  </Score>
                )}
                {value?.score === 2 && (
                  <Score color={getColor(value?.score)}>
                    If Red result for the RS#{gene.rs}
                  </Score>
                )}
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </DataWrapper>
            );
          })}
        </Collapse>
      )}
    </>
  );
};

export default TableRow;
