import React from 'react';
import { Link } from 'react-router-dom';

const EmailConfirmationCard = () => (
  <div className="email-confirmation">
    <h3 className="email-confirmation__title">Your e-mail address has been successfully verified</h3>
    <p className="email-confirmation__sub">Thank you for choosing the Ingeneous</p>
    <div className="email-confirmation__buttons">
      <Link className="btn btn-primary" to="/dashboard">Go to dashboard</Link>
      <Link className="btn btn-primary" to="/account/profile">Go to profile</Link>
    </div>
  </div>
);


export default EmailConfirmationCard;
