/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';

import { error, primary, gray, white, yellow, alert } from '../../styles/theme';
import { isMobile } from 'react-device-detect';

const ImageWrapper = styled.div`
  position: ${isMobile ? 'static' : 'absolute'};
  right: 0;
  top: 0;
  text-align: center;
  img {
    max-height: 200px;
    max-width: 90%;
  }
`;

const Name = styled.h3``;

const PriceHolder = styled.div`
  span {
    display: inline-block;
  }
`;

const Price = styled.span`
  margin-right: 15px;
  font-size: 1.75rem;
  color: ${gray};
`;

const PriceMobile = styled.span`
  margin-right: 15px;
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: ${gray};
`;

const ComparePrice = styled.span`
  font-size: 1.25rem;
  text-decoration: line-through;
  color: ${error};
`;

const SelectedTag = styled.span`
  background-color: ${primary};
  color: ${white};
  position: absolute;
  top: -15px;
  right: -25px;
  padding: 5px 20px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  text-transform: uppercase;
  font-size: 0.85rem;
`;

const PurchasedTag = styled(SelectedTag)`
  background-color: ${primary};
`;

const PendingTag = styled(SelectedTag)`
  background-color: ${yellow};
  color: ${alert};
`;
const WrapperImage = styled(Col)`
  position: ${isMobile ? 'relative' : 'static'};
`;
const ActionItem = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
  justify-content: flex-start;
  align-items: center;
  margin: ${isMobile ? '0 10px' : '20px 0'};
`;
const ButtonAddtoCart = styled(Button)`
    border-radius: 3px;
    color: #fff;
    display: inline-block;
    margin: ${isMobile ? '20px 0' : '0 55px'};
    text-transform: uppercase;
  }
`;
const RequireText = styled.p`
  color: ${primary};
  font-size: 0.9rem;
  margin-left: 5px;
  max-width: 300px;

  span {
    font-weight: 600;
  }
`;
const NameMobile = styled.h5``;

interface ItemTileProps {
  title: string;
  description: string;
  price: number;
  discount: number;
  imageSrc: string;
  requirement: string;
  status: 'selected' | 'purchased' | 'pending' | null;
  disabled?:boolean;
  reason?:string;
  selectClickHandler:CallableFunction;
  itemId: number;
}

const ItemTile = ({
  title,
  description,
  price,
  discount,
  imageSrc,
  requirement,
  status,
  disabled,
  reason,
  selectClickHandler,
  itemId,
}: ItemTileProps) => {
  // const [isPurchased, setIsPurchased] = useState();
  // const [isPending, setIsPending] = useState();
  // eslint-disable-next-line
  const [isSelected, setIsSelected] = useState(false);

  const handleSelect = () => {
    console.log("in handle select")
    if(status === 'purchased'){
      return
    }
    if(status === 'pending'){
      return
    }
    console.log("about to execute handler")
    selectClickHandler(itemId)
  }

  return (
    <>
      {!isMobile && (
        <Row>
          <Col xs={12}>
            <Name>{title}</Name>
            {status === 'selected' && <SelectedTag>Selected</SelectedTag>}
            {status === 'purchased' && <PurchasedTag>Purchased</PurchasedTag>}
            {status === 'pending' && <PendingTag>Pending</PendingTag>}
          </Col>
          <Col md={9}>
            <p>{description}</p>
            <ActionItem>
              <PriceHolder>
                <Price>${price}</Price>
                {discount ? <ComparePrice>${discount}</ComparePrice> : null}
              </PriceHolder>
              <ButtonAddtoCart 
                variant="primary" 
                disabled={disabled}
                onClick={handleSelect}>
                  Select
              </ButtonAddtoCart>{' '}
              <RequireText>
                {' '}
                <span>Requirements:</span> {requirement}{' '}
              </RequireText>
            </ActionItem>
          </Col>
          <WrapperImage md={3}>
            <ImageWrapper>
              <img src={imageSrc} alt={title} />
            </ImageWrapper>
          </WrapperImage>
          <Col><RequireText>{reason}</RequireText></Col>
        </Row>
      )}

      {/* Mobile Screen */}
      {isMobile && (
        <Row style={{ display: 'relative' }}>
          <Col style={{ padding: 0 }}>
            <Row>
              <Col xs={8}>
                <NameMobile>{title}</NameMobile>
                {isSelected && <SelectedTag>Selected</SelectedTag>}
                <PriceHolder>
                  <PriceMobile>${price}</PriceMobile>
                  {discount ? <ComparePrice>${discount}</ComparePrice> : null}
                </PriceHolder>
              </Col>
              {isMobile && imageSrc && (
                <Col xs={4}>
                  <ImageWrapper>
                    <img src={imageSrc} alt={title} width={80} />
                  </ImageWrapper>
                </Col>
              )}
            </Row>
          </Col>
          <Col md={imageSrc ? 9 : 12}>
            <>
              <p>{description}</p>
              <ButtonAddtoCart 
                variant="primary" 
                disabled={disabled} 
                onClick={handleSelect}>
                    Select
              </ButtonAddtoCart>{' '}
              <RequireText>
                {' '}
                <span>Requirements:</span> {requirement}{' '}
              </RequireText>
            </>
          </Col>
        </Row>
      )}
    </>
  );
};

ItemTile.defaultProps = {
  disabled: false,
};

export default ItemTile;
