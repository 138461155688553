import React from 'react'
import { Row } from 'react-bootstrap'

import { getColor, Serving, Image, BodyCol } from './utils'

const Two = () => {
  return (<>
    <BodyCol xs={12} md={4}>
      <Serving color={getColor(2)}>2 servings a day</Serving>
      <p>Two servings equals 2+ cups of decaffeinated beverages a day.</p>
    </BodyCol>
    <BodyCol xs={12} md={5}>
      <p>&nbsp;</p>
    </BodyCol>
  </>)
}

const One = () => {
  return (<>
    <BodyCol xs={12} md={4}>
      <Serving color={getColor(1)}>1 serving a day</Serving>
      <p>One serving equals 1 cup of decaffeinated beverages a day.</p>
    </BodyCol>
    <BodyCol xs={12} md={5}>
      <p>&nbsp;</p>
    </BodyCol>
  </>)
}

const Zero = () => {
  return (<>
    <BodyCol xs={12} md={4}>
      <Serving color={getColor(0)}>Zero servings a day</Serving>
      <p>&nbsp;</p>
    </BodyCol>
    <BodyCol xs={12} md={5}>
      <p>&nbsp;</p>
    </BodyCol>
  </>)
}

const Decaf = ({ serving }) => {
  return (<Row>
    <BodyCol xs={12}>
      <h4>Decaffeinated Beverages</h4>
      <p>This recommendation includes decaf versions of coffee or black teas (including earl grey and green teas).</p>
    </BodyCol>
    <BodyCol xs={12} md={3}>
      <Image src={process.env.PUBLIC_URL + '/assets/images/lifeplus/ing-caf-drinks.png'} />
    </BodyCol>
    {serving === 2 && <Two />}
    {serving === 1 && <One />}
    {serving === 0 && <Zero />}
  </Row>)
}

export default Decaf
