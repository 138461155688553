/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useObserver } from 'mobx-react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Sticky from 'react-stickynode';
import { isMobile } from 'react-device-detect';
import { Redirect } from 'react-router-dom';

import { AuthContext } from 'stores/auth';
import { getUserProducts } from 'api/core';
import { Spacer } from 'components/utils';
import { Page, PageHeader, PageBody } from 'components/Page';

import ProfileCompletion from './components/ProfileCompletion';
import Reports from './components/Reports';

import FirstOrder from './components/FirstOrder';
import GetStarted from './components/GetStarted';
import JoinUs from './components/JoinUs';
import Support from './components/Support';
import NewReport from './components/NewReport';

const UserRow = styled(Row)`
  h2,
  h3,
  p {
    color: #ffffff;
  }

  p {
    font-size: 0.8rem;
  }
`;

const UserCol = styled(Col)`
  text-align: ${isMobile ? 'left' : 'center'};
`;

const Dashboard = () => {
  const componentIsMounted = useRef(true);
  let history = useHistory();
  const authStore = useContext(AuthContext);
  let displayName = authStore.user?.email;
  if (authStore.user?.first_name) {
    displayName = authStore.user.first_name + ' ' + authStore.user.last_name;
  }
  const [addPad, setAddPad] = useState(false);
  const [userProducts, setUserProducts] = useState();

  const handleStateChange = (status) => {
    setAddPad(status.status === Sticky.STATUS_FIXED);
  };

  useEffect(() => {
    getUserProducts().then((res) => {
      if (!componentIsMounted.current) return;
      setUserProducts(res.data.purchased_products);
      console.log('Purhcased Products', res.data.purchased_products);
    });

    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!authStore.user?.sample_id && userProducts?.length === 0) {
      history.push('/order-details');
    }
  }, [userProducts]);

  return useObserver(() => {
    if(authStore.isPractitionerOrg()){
      return <Redirect to="/consult"/>;
    }
    return (
      <Page>
        <PageHeader>
          {!isMobile && <Spacer height={'30px'} />}
          <Container>
            <UserRow>
              <UserCol xs={12} md={3}>
                <h3>{displayName}</h3>
              </UserCol>
              <Col md={9}>
                <h2>My Wellness Dashboard</h2>
              </Col>
            </UserRow>
          </Container>
        </PageHeader>
        <PageBody>
          <Container className="order">
            <Spacer height={'30px'} />
            <Row>
              <Col xs={12} md={3}>
                {isMobile ? (
                  <Reports
                    active={'dashboard'}
                    pad={addPad}
                    userProducts={userProducts}
                  />
                ) : (
                  <Sticky onStateChange={handleStateChange}>
                    <Reports
                      active={'dashboard'}
                      pad={addPad}
                      userProducts={userProducts}
                    />
                  </Sticky>
                )}
              </Col>
              <Col xs={12} md={9}>
                {userProducts ? (
                  !userProducts?.find(
                    (prodId) =>
                      prodId.toString() ===
                      process.env.REACT_APP_WEIGHT_MANAGEMENT_PRODUCT_ID
                  ) && <NewReport />
                ) : (
                  <NewReport />
                )}
                <Spacer height={'30px'} />

                {userProducts && userProducts?.length > 0 ? (
                  <GetStarted sampleId={authStore.user.sample_id} />
                ) : (
                  <FirstOrder />
                )}

                <Spacer height={'30px'} />

                {/* {userProducts &&
              <FirstReport
                products={products}
                userProducts={userProducts}
              />
            } */}

                {!authStore.user.gender && !authStore.user.birthDate && (
                  <>
                    <ProfileCompletion />
                    <Spacer height={'30px'} />
                  </>
                )}
                <Support />
                <Spacer height={'30px'} />
                <JoinUs />
              </Col>
            </Row>
            <Spacer height={'30px'} />
          </Container>
        </PageBody>
      </Page>
    );
  });
};

export default Dashboard;
