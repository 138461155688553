import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { borderGray } from 'styles/theme';
import { Spacer } from 'components/utils';

import Athleticism from './Athleticism/index';
import ImpulseControl from './ImpulseControl/index';
import BingeEating from './BingeEating/index';
import { Element, scroller } from 'react-scroll';

const Wrapper = styled.div`
  background-color: #ffffff;
  min-height: 200px;
  border-radius: 5px;
  border: 1px solid ${borderGray};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: ${isMobile ? '20px 10px' : '30px'};
  
  @media print {
    border: none;
    margin-top: 50px;
  }
`;

interface PredispositionsProps {
  results: any;
}

const Predispositions = ({ results }: PredispositionsProps) => {
  useEffect(() => {
    if (window.location.hash) {
      scroller.scrollTo(window.location.hash.replace('#', ''), {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset: 0,
      });
    }
  }, [results]);

  return (
    <>
      <Element name="predispositions" className="drink" />
      <Wrapper className="page-br">
        <Row>
          <Col md={8}>
            <h2>Your Predispositions</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Spacer height={'30px'} />
            {results && <Athleticism results={results} />}
            {results && <ImpulseControl results={results} />}
            {results && <BingeEating results={results} />}
          </Col>
        </Row>
      </Wrapper>
    </>
  );
};

export default Predispositions;
