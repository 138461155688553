import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import ReactSpeedometer from 'react-d3-speedometer';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { getEssentials } from 'api/reports';
import { Spacer } from 'components/utils';
import { Page, PageHeader, PageBody } from 'components/Page';

import SearchForm from '../components/SearchForm';
import Recommendations from './components/Recommendations';

const SearchWrapper = styled.div`
  h4 {
    color: #707070;
  }
`;

const OffsetCol = styled(Col)`
  margin-top: -110px;
  z-index: 9999;
`;

const OffsetWrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 50px 30px;
  border: 1px solid #dedede;
`;

const Alert = styled.div`
  color: #e79b43;
  text-align: center;
  padding: 15px;
  text-transform: uppercase;
  background-color: #f1e5d9;
  border-radius: 5px;
`;

const ResultWrapper = styled.div`
  h4 {
    color: #707070;
  }
`;

// Values range from 0 to 1. 0 = good, 1 = bad.
// We display from Bad to Good, so transform via 1 - val
const getSpeedometer = (val) => {
  return (1 - val) * 1000;
};

const getValueText = (val) => {
  let inverse = 1 - val;
  if (inverse < 0.333) {
    return 'You are in the red.';
  } else if (inverse >= 0.333 && inverse < 0.666) {
    return 'You are in the yellow.';
  } else {
    return 'You are in the green.';
  }
};

const Essentials = () => {
  const [results, setResults] = useState();

  const [kitId, setKitId] = useState(localStorage.getItem('consultKitId'));
  const [loading, setLoading] = useState(false)
  const [productIds, setProductIds] = useState(localStorage.getItem('productIds'))
  const [afterSearch, setAfterSearch] = useState(false)

  const handleSearch = (data) => {
    localStorage.setItem('consultKitId', data);
    setKitId(data);
    setAfterSearch(true)
  };

  useEffect(() => {
    if (kitId) {
      getEssentials(kitId).then((res) => {
        if (res.data.status === 'complete') {
          setResults(res.data);
        }
      });
    }
  }, [kitId]);

  if (!kitId) {
    return <Redirect to={'/consult'} />;
  }

  if(!productIds){
    return <Redirect to={'/consult'} />;
  }

  const userHasNotPurchasedReport = () => {
    let productIdsSplit = productIds.split(',')
    return !productIdsSplit.includes(process.env.REACT_APP_ESSENTIAL_PRODUCT_ID.toString())
  }

  if(userHasNotPurchasedReport()){
    return <Redirect to={`/consult`} />;
  }

  if(afterSearch){
    return <Redirect to={`/consult`} />;
  }

  const loadingData = () => {
    return (
      <OffsetCol md={9}>
        <OffsetWrapper>
          <Row>
            <Col>
              <h2>Loading Results. . .</h2>
            </Col>
          </Row>
        </OffsetWrapper>
      </OffsetCol>
    )
  }

  const essentialResult = () => {
    return (
      <OffsetCol md={9}>
        <OffsetWrapper>
          <Row>
            <Col>
              <h2>Essential Start Results</h2>
              <p>
                At Ingeneous we believe that there are three systems
                within your body where every health journey should
                begin, and science agrees with us! These systems include
                inflammation, detoxification, and vitamin D.
                <br />
                <br />
                Inflammation is arguably the most important system in
                the human body. It is your protection system; it
                recognises and responds to threats from invaders like
                infections and heals you from injuries. It is very
                powerful, vigilant and proactive. Some of us have
                hyper-competent inflammatory responses that can be
                likened to a very sensitive accelerator. To prevent
                inflammation from overacting, we can make modifications
                to help our body respond best to the challenges which
                come our way.
              </p>
              <Spacer height={'20px'} />
            </Col>
          </Row>
          {results ? (
            <ResultWrapper>
              <Row>
                <Col xs={12} md={6}>
                  <ReactSpeedometer
                    value={getSpeedometer(results.pro_inflammation)}
                    height={200}
                    width={isMobile ? 250 : 300}
                    currentValueText={getValueText(
                      results.pro_inflammation
                    )}
                    paddingVertical={20}
                    segments={3}
                    customSegmentStops={[0, 333, 666, 1000]}
                    needleColor={'#707070'}
                    needleHeightRatio={0.4}
                    segmentColors={['#DEB4B6', '#EED698', '#ABDCD6']}
                    customSegmentLabels={[
                      {
                        text: 'Poor',
                        position: 'INSIDE',
                        color: '#FFFFFF',
                        fontSize: '16px',
                      },
                      {
                        text: 'Moderate',
                        position: 'INSIDE',
                        color: '#FFFFFF',
                        fontSize: '16px',
                      },
                      {
                        text: 'Good',
                        position: 'INSIDE',
                        color: '#FFFFFF',
                        fontSize: '16px',
                      },
                    ]}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <h4>Pro-inflammatory Genes</h4>
                  <p>
                    <strong>Result: Red</strong>
                    <br />
                    Your inflammation is hyperactive, it overacts to
                    threats even when they are minor.
                  </p>
                  <p>
                    <strong>Result: Yellow</strong>
                    <br />
                    Your inflammation is operating actively. It
                    sometimes overacts to threats when they are minor or
                    moderate.
                  </p>
                  <p>
                    <strong>Result: Green</strong>
                    <br />
                    Your inflammation is operating optimally and
                    responds as it should.
                  </p>
                </Col>
              </Row>

              <Spacer height={'30px'} />

              <Row>
                <Col xs={12} md={6}>
                  <ReactSpeedometer
                    value={getSpeedometer(results.anti_inflammation)}
                    height={200}
                    width={isMobile ? 250 : 300}
                    currentValueText={getValueText(
                      results.anti_inflammation
                    )}
                    paddingVertical={20}
                    segments={3}
                    customSegmentStops={[0, 333, 666, 1000]}
                    needleColor={'#707070'}
                    needleHeightRatio={0.4}
                    segmentColors={['#DEB4B6', '#EED698', '#ABDCD6']}
                    customSegmentLabels={[
                      {
                        text: 'Poor',
                        position: 'INSIDE',
                        color: '#FFFFFF',
                        fontSize: '16px',
                      },
                      {
                        text: 'Moderate',
                        position: 'INSIDE',
                        color: '#FFFFFF',
                        fontSize: '16px',
                      },
                      {
                        text: 'Good',
                        position: 'INSIDE',
                        color: '#FFFFFF',
                        fontSize: '16px',
                      },
                    ]}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <h4>Anti-inflammatory Genes</h4>
                  <p>
                    <strong>Result: Red</strong>
                    <br />
                    Your ability to push back inflammation isn’t working
                    well. Your body will be on the back foot if it is in
                    an inflammatory state.
                  </p>
                  <p>
                    <strong>Result: Yellow</strong>
                    <br />
                    Your ability to push back inflammation is partially
                    working. Your body will be on the back foot if it is
                    in an inflammatory state.
                  </p>
                  <p>
                    <strong>Result: Green</strong>
                    <br />
                    Your ability to push back inflammation is working
                    well.
                  </p>
                </Col>
              </Row>

              <Spacer height={'30px'} />

              <Row>
                <Col xs={12} md={6}>
                  <ReactSpeedometer
                    value={getSpeedometer(results.detox)}
                    height={200}
                    width={isMobile ? 250 : 300}
                    currentValueText={getValueText(results.detox)}
                    paddingVertical={20}
                    segments={3}
                    customSegmentStops={[0, 333, 666, 1000]}
                    needleColor={'#707070'}
                    needleHeightRatio={0.4}
                    segmentColors={['#DEB4B6', '#EED698', '#ABDCD6']}
                    customSegmentLabels={[
                      {
                        text: 'Poor',
                        position: 'INSIDE',
                        color: '#FFFFFF',
                        fontSize: '16px',
                      },
                      {
                        text: 'Moderate',
                        position: 'INSIDE',
                        color: '#FFFFFF',
                        fontSize: '16px',
                      },
                      {
                        text: 'Good',
                        position: 'INSIDE',
                        color: '#FFFFFF',
                        fontSize: '16px',
                      },
                    ]}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <h4>Detoxification System</h4>
                  <p>
                    <strong>Result: Red</strong>
                    <br />
                    Your detoxification system is where you have
                    significant opportunity for improvement. It needs
                    support to move things around efficiently.
                  </p>
                  <p>
                    <strong>Result: Yellow</strong>
                    <br />
                    Your detoxification system is partially performing.
                    It needs a bit of help to move things around
                    efficiently.
                  </p>
                  <p>
                    <strong>Result: Green</strong>
                    <br />
                    Your detoxification system is operating optimally.
                  </p>
                </Col>
              </Row>

              <Spacer height={'30px'} />

              <Row>
                <Col xs={12} md={6}>
                  <ReactSpeedometer
                    value={getSpeedometer(results.vitamin_d)}
                    height={200}
                    width={isMobile ? 250 : 300}
                    currentValueText={getValueText(results.vitamin_d)}
                    paddingVertical={20}
                    segments={3}
                    customSegmentStops={[0, 333, 666, 1000]}
                    needleColor={'#707070'}
                    needleHeightRatio={0.4}
                    segmentColors={['#DEB4B6', '#EED698', '#ABDCD6']}
                    customSegmentLabels={[
                      {
                        text: 'Poor',
                        position: 'INSIDE',
                        color: '#FFFFFF',
                        fontSize: '16px',
                      },
                      {
                        text: 'Moderate',
                        position: 'INSIDE',
                        color: '#FFFFFF',
                        fontSize: '16px',
                      },
                      {
                        text: 'Good',
                        position: 'INSIDE',
                        color: '#FFFFFF',
                        fontSize: '16px',
                      },
                    ]}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <h4>Vitamin D Pathway</h4>
                  <p>
                    <strong>Result: Red</strong>
                    <br />
                    You are only utilising a small portion of your
                    available vitamin D.
                  </p>
                  <p>
                    <strong>Result: Yellow</strong>
                    <br />
                    You are only utilising a moderate portion of your
                    available Vitamin D.
                  </p>
                  <p>
                    <strong>Result: Green</strong>
                    <br />
                    You are utilising your available Vitamin D
                    optimally.
                  </p>
                </Col>
              </Row>

              <Spacer height={'30px'} />

              <Row>
                <Col>
                  <h3>Your Top 5 Recommendations</h3>
                  <Recommendations results={results} />
                </Col>
              </Row>
            </ResultWrapper>
          ) : (
            <Alert>Loading results...</Alert>
          )}
        </OffsetWrapper>
      </OffsetCol>
    )
  }

  return (
    <>
      <Page>
        <PageHeader>
          {!isMobile && <Spacer height={'30px'} />}
          <Container>
            <Spacer height="120px" />
            <Row>
              <Col xs={12} md={3}>
                {/* <h3 style={{ 'color': 'white' }}>{user?.display_name}</h4> */}
              </Col>
            </Row>
          </Container>
        </PageHeader>
        <PageBody>
          <Container className="practitioner">
            <Row>
              <Col xs={12} md={3}>
                <Spacer height="50px" />
                <SearchWrapper>
                  <h4>Consult Support</h4>
                  <SearchForm handleSearch={handleSearch} query={kitId} handleLoading={setLoading}/>
                </SearchWrapper>
              </Col>
              {loading ? loadingData() : essentialResult()}
            </Row>
            <Spacer height={'30px'} />
          </Container>
        </PageBody>
      </Page>
    </>
  );
};

export default Essentials;
