import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Spacer } from 'components/utils';
import ReadMore from '../../ReadMore';
import ReadLess from '../../ReadLess';
import { isMobile } from 'react-device-detect';

import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  position: ${isMobile ? 'static' : 'absolute'};
  right: 0;
  transform: ${isMobile ? 'translateX(0)' : 'translateX(-10%)'};
  margin-top: ${isMobile ? '10px' : '-10px'};
  text-align: ${isMobile ? 'center' : 'right'};
  // display: ${isMobile ? 'flex' : 'inherit'};
  // justify-content: ${isMobile ? 'center' : 'unset'};
  img {
    max-width: 60%;
  }
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const LinkA = styled.a`
  cursor: pointer;
  text-decoration: underline !important;
  color: #6da6ca !important;
`;

interface LowCarbProps {
  results: any;
}
const LowCarb = ({ results }: LowCarbProps) => {
  const [showInflammation, setShowInflammation] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <Col md={12}>
          <h3>Low Carb</h3>
          <ImageCol md={4}>
            <img src="/assets/images/recommendations/Diet.png" alt="Diet" />
          </ImageCol>
          <Col md={8}>
            <Alert>
              <p>
                Cut your amylase score in half to lower your carbohydrate
                intake.
              </p>
            </Alert>
            <Alert>
              <p>Maintain BMR calories.</p>
            </Alert>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!showInflammation && !printing && (
            <ReadMore value={showInflammation} setValue={setShowInflammation} />
          )}
          {(showInflammation || printing) && (
            <div>
              <p>
                Your gene SNPs indicate that your body will benefit the most
                from cutting your carbohydrates in half.
              </p>
              <p>
                To reduce your carbohydrates, cut your amylase score and
                associated carbohydrate allowance in half that was given to you
                in your Lifestyle Plus results.
              </p>
              <p>
                To lose fat you need to use more energy than you take in. A
                calorie (or kJ) is a unit that describes how much energy your
                body gets from the food and drinks you ingest. Your basal
                metabolic rate (BMR) is the energy your body burns to perform
                basic life sustaining functions such as breathing. For you,
                keeping your calories at BMR while reducing your carbohydrates
                will be the most powerful tool to burn fat. Low carbohydrate
                diets have been shown to be effective at reversing obesity and
                preventing related diseases.
              </p>
              <p>
                You can calculate your required BMR calories here:{' '}
                <LinkA
                  href="https://www.calculator.net/calorie-calculator.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.calculator.net/calorie-calculator.html
                </LinkA>
              </p>
              <p>
                While simply cutting calories may be helpful for some people,
                your genes suggest lowering your carbohydrate intake will be a
                more powerful fat burning tool for you. Please only follow this
                diet for 3 weeks, and then go back to your Lifestyle Plus
                recommendations for at least 3 weeks between periods of fat
                loss. Once you have reached your optimal fat level continue to
                monitor your maintenance calories and follow your Lifestyle Plus
                recommendations to ensure you do not overeat in the weeks
                following restriction.
              </p>
            </div>
          )}
          {showInflammation && !printing && (
            <ReadLess value={showInflammation} setValue={setShowInflammation} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default LowCarb;
