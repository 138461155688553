import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import Icon from 'components/Icon';
import {
  TR,
  Collapse,
  CollapseBtn,
  DataWrapper,
  Score,
  getColor,
} from './TableRow';

import CircleDot from './Circle';

const MTHFR = ({ geneData }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [genes, setGenes] = useState();

  useEffect(() => {
    setGenes({
      rs1801131: geneData?.find((e) => 'rs1801131' === e.snp),
      rs1801133: geneData?.find((e) => 'rs1801133' === e.snp),
    });
  }, [geneData]);

  if (!genes) {
    return null;
  }

  return (
    <>
      <TR>
        <Col md={11}>
          <Row>
            <Col md={2}>MTHFR</Col>
            <Col md={1}>
              <CircleDot
                color={getColor(genes?.rs1801131?.score)}
                tooltipColor={genes?.rs1801131?.score}
              />
            </Col>
            <Col md={2}>{genes?.rs1801131?.snp?.toUpperCase()}</Col>
            <Col md={7}>
              800 - 1000mcg 5MTHF + 800 - 1000mcg methylcobalamin daily in the
              am
            </Col>
          </Row>
          <Row>
            <Col md={2}>&nbsp;</Col>
            <Col md={1}>
              <CircleDot
                color={getColor(genes?.rs1801133?.score)}
                tooltipColor={genes?.rs1801133?.score}
              />
            </Col>
            <Col md={2}>{genes?.rs1801133?.snp?.toUpperCase()}</Col>
            <Col md={7}>&nbsp;</Col>
          </Row>
        </Col>
        <Col md={1}>
          <CollapseBtn
            variant="secondary"
            onClick={() => setCollapsed(!collapsed)}
          >
            <Icon
              name={collapsed ? 'caret-down' : 'caret-up'}
              color="#dedede"
            />
          </CollapseBtn>
        </Col>
      </TR>

      <Collapse
        style={{ display: collapsed ? 'none' : 'block' }}
        className="print-only"
      >
        <DataWrapper>
          <h4 style={{ color: '#707070' }}>MTHFR - 5MTHF + methylcobalamin</h4>
          <p>
            <Score color={getColor(2)}>Red result for the RS number</Score>
            {/* <Score color={getColor(2)}>Red result for the RS number</Score> */}
            &nbsp;
            <strong>
              = 800 - 1000mcg 5MTHF + 800 - 1000mcg methylcobalamin daily in the
              am.
            </strong>
          </p>
          <p>
            <strong>Stage 1 of Dosing</strong>
            <ul>
              <li>
                Week 1 - 1 dose of 800 - 1000mcg 5MTHF + 800 - 1000mcg
                methylcobalamin. If the customer gets a positive change – for
                example: heightened mood, more energy, balanced thinking, better
                sleep - stay at this dose for 1 month. If they get no benefit at
                one dose in the first week, increase to a double dose for a
                second week – 1600 - 2000mcg 5MTHF + 1600 - 2000mcg
                methylcobalamin. If still no benefit increase to a triple dose
                for a third week – 2400 - 3000mcg 5MTHF + 2400 - 3000mcg
                methylcobalamin. Do not exceed this amount. If the customer
                feels a bit anxious or emotional and stressed, drop the dose
                down to ½ pill daily or ¼ daily as necessary.
              </li>
            </ul>
          </p>
          <p>
            <strong>Next stage: Steady State Dosage</strong>
            <ul>
              <li>
                After you have found your customer’s useful dose, recommend they
                stay on it for 1 month. Then reassess. 50% of people will
                continue long term on that dose. 50% of people will need to drop
                the dose down 1 notch for long term dosing. If they have a
                negative change for any reason during the month, reduce the
                dosage to 400 - 500mcg 5MTHF + 400 - 500mcg methylcobalamin.
              </li>
            </ul>
          </p>
        </DataWrapper>
      </Collapse>
    </>
  );
};

export default MTHFR;
