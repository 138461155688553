import { AxiosPromise } from 'axios';
import { Product } from './core';
import PrivateAPI from './private';
import PublicAPI from './public';

export const artilectLogin = (token: string, role: string) => {
  return PublicAPI.post('/api/artilect/login/', {
    token: token,
    role: role,
  });
};

export const getSampleId = (sampleId: string) => {
  return PrivateAPI.get('/api/v2/practitioner/sample-ids/?sample_id=' + sampleId);
};

export const getSampleIdV2 = (sampleId: string) => {
  return PrivateAPI.get('/api/v2/practitioner/sample-ids/?sample_id=' + sampleId);
};

export const getPatientInfo = (kitId: string) => {
  return PrivateAPI.post('/api/practitioner/patient/', { kit_id: kitId });
};

// Practioner checkout API

export const getPractionerProducts = (): AxiosPromise<{
  products: Product[];
}> => {
  return PrivateAPI.get('/api/products/practitioner/');
};

export const createPractionerOrder = (payload: {
  billing: any;
  participants: any[];
  price: number;
  product_id: number;
}): AxiosPromise<any> => {
  return PrivateAPI.post('/api/orders/practitioner/create/', payload);
};
