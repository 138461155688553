import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Spacer } from 'components/utils';
import { primary } from 'styles/theme';

const StyledRow = styled(Row)`
  background-color: ${primary};
`;

const LogoWrapper = styled.div`
  padding: 30px 15%;
`;

const Wrapper = styled.div`
  padding: 30px 15%;
  h2,
  h4,
  h5,
  p,
  label,
  ul {
    color: #ffffff;
  }

  p {
    text-align: justify;
    text-justify: inter-word;
  }

  ul {
    list-style: none;

    li {
      margin-bottom: 5px;
    }
  }
`;

const Logo = styled.img`
  max-height: 50px;
`;

function PrivacyPolicy() {
  return (
    <StyledRow>
      <Col md={{ span: 8, offset: 2 }}>
        <LogoWrapper>
          <Link to="/">
            <Logo
              src={
                process.env.PUBLIC_URL + '/assets/images/ingeneous_white_r.png'
              }
            />
          </Link>
        </LogoWrapper>
        <Wrapper>
          <h2>PRIVACY POLICY</h2>

          <Spacer height={'20px'} />
          <h5>Privacy – An Introduction</h5>
          <p>
            The purpose of this Privacy Policy is to let you know when we
            collect Personal Information and what we do with it. This
            introduction provides highlights of the core components of the
            Privacy Policy. You should carefully read the full Privacy Policy
            below, before disclosing any Personal Information, to understand
            your rights and obligations, and Ingeneous’ processes.
            <br />
            <br />
            Privacy is important to us. We seek your agreement for us to use
            your Personal Information. Our Privacy Policy deals with several
            important issues, including:
          </p>
          <ul>
            <li>• What Personal Information Is</li>
            <li>• Why we want to share this Information</li>
            <li>• What Information we plan to Share</li>
            <li>• The People we wish to share it with</li>
            <li>• How we keep your Information safe and secure</li>
          </ul>
          <p>
            We do collect and use Information so that we may carry out our
            Services, including by taking Tests and providing you with Results.
            In general, the information we collect is:
          </p>
          <ul>
            <li>
              • Personal Information, which is information that relates to an
              identifiable person and enables someone else to identify that
              individual.
            </li>
            <li>
              • Genetic Information, which is Personal Information relating to
              your inherited or genetic characteristics.
            </li>
            <li>
              • Health Input Data, which is any details you share with us about
              your health or personal characteristics.
            </li>
            <li>
              • Registration Information, which is any details captured or
              provided when you register for our services or purchase a product.
            </li>
            <li>
              • Web-Behaviour Information, which means information we or our
              Affiliates collect about how you use our Services.
            </li>
          </ul>
          <p>
            The complete definition of these term is listed below in our
            definitions section. Whenever possible we endeavour to work with
            de-identified data, that is, generally data from which all
            personally identifiable information has been removed. This is not
            possible however when we supply you with test results when you use
            the App.
            <br />
            <br />
            You can read more about what Ingeneous does at our website.
            Ingeneous uses this Information for the following purposes:
          </p>
          <ul>
            <li>• To provide you with our Services and Products.</li>
            <li>• To analyse and improve our Services</li>
            <li>• To undertake Ingeneous Research with your consent.</li>
          </ul>
          <p>
            We use state of the art technology to protect your information. We
            do not use, share or disclose Personal Information collected or
            received, other than to our Ingeneous Affiliates and as set out in
            this Privacy Policy. It will only be shared with our Affiliates
            where we are legally required to do so, or where we are required to
            share it with them to provide you with Services or Products. You can
            choose to have your Personal Information deleted, or correct it, any
            time. Just let us know by contacting us at hello@ingeneous.co.nz
            <br />
            <br />
            You can make a complaint about how we handle your Personal
            Information by contacting us at hello@ingeneous.co.nz.
          </p>

          <Spacer height={'20px'} />
          <h5>1. INTRODUCTION</h5>
          <p>
            1.1. Ingeneous is committed to its obligations under the Privacy Act
            2020, the Health Information Privacy Code 2020 and to protecting
            your information, handling it responsibly and securing it with the
            necessary measures and safeguards. Ingeneous understands that
            information relating to your health and wellbeing is private and
            sensitive. All personal information you provide to us is treated as
            confidential.
            <br />
            <br />
            1.2. This Privacy Policy is designed to help you better understand
            how we collect, use, store, process, and transfer your personal
            information when using the Services. Our Privacy Policy applies to
            all new and existing Clients, and to all websites and apps owned and
            operated by Ingeneous Limited (collectively referred “Ingeneous”,
            “we”, “us” and “our”) and Ingeneous Affiliates, and includes your
            use of Our Site and any related Services or Products.
            <br />
            <br />
            1.3. Please carefully review this Privacy Policy and our Terms of
            Service (“Terms”). By using our Services, you acknowledge all of the
            policies and procedures described in these two documents and also by
            reference to our Informed Consent Document. If you do not agree with
            or you are not comfortable with any aspect of this Privacy Policy or
            our Terms, you should immediately discontinue use of our Services.
            <br />
            <br />
            1.4. The key terminology referred to in this Privacy Policy are now
            defined:
          </p>

          <Spacer height={'20px'} />
          <h5>2. KEY DEFINITIONS</h5>
          <p>
            2.1. “Aggregate Information” means information combined with that of
            one or more other Clients, such that no specific individual may be
            reasonably identified.
            <br />
            <br />
            2.2. “Blog” means a website run by either Ingeneous, Ingeneous
            Affiliate or third party which provides information in a
            conversational or informal manner.
            <br />
            <br />
            2.3. “De-identified information” means information from which
            identifying details have been removed and is therefore no longer
            able to identify you as an individual.
            <br />
            <br />
            2.4. “Genetic Information” means Personal Information relating to
            the inherited or acquired genetic characteristics of a natural
            person which gives information unique to that natural person about
            their physiology or their health and which result, in particular,
            from an analysis of a biological sample from the natural person in
            question.
            <br />
            <br />
            2.5. “Health Input Data” means any details shared with us by the
            Client, including but not limited to Personal Information, medical
            conditions, sports-related information, ethnicity, family history,
            and other information that you enter in surveys, forms or features
            while entering Our Site.
            <br />
            <br />
            2.6. “Information” means any data received, requested or obtained by
            Ingeneous from Clients for providing the Services or making Our Site
            available, and includes Personal Information and, Genetic
            Information.
            <br />
            <br />
            2.7. “Ingeneous Affiliate” means any employee, officer, contractor,
            agent, associate, partner, consultant, , subsidiary, , successor
            business, Ingeneous Certified Practitioner, or any other person,
            that Ingeneous contracts or otherwise uses to carry out any part of
            the Services.
            <br />
            <br />
            2.8. “Our Site” means the website that Ingeneous operates and owns,
            located at https://www.Ingeneous.co.nz and includes Ingeneous’
            mobile application (also referred to as our “App”).
            <br />
            <br />
            2.9. “Personal Information” means data about a Client that
            identifies the Client, comprising name, an identification number,
            location data, an online identifier or to one or more factors
            specific to the physical, physiological, genetic, mental, economic,
            cultural or social identity of that individual Client but does not
            include De-identified Information about or involving an individual
            Client.
            <br />
            <br />
            2.10. “Platform” means a digital service that allows two or more
            independent Clients to interact with each other through the
            internet.
            <br />
            <br />
            2.11. “Registration Information” means details captured, or
            provided, when a Client registers for our Services and/or purchasing
            a Product for an account to be set up with personal login
            credentials. Registration Information will be used to provide you
            with your chosen services, and will include, but is not limited to,
            your name, contact details and date of birth.
            <br />
            <br />
            2.12. “Terms” means Ingeneous’ terms of services, as updated from
            time to time.
            <br />
            <br />
            2.13. “Web-Behaviour Information” means information we or our
            Ingeneous Affiliates collect about how you use the Services,
            including through log files, cookies, web beacons, and similar
            technology whether known or developed in the future. Capitalized
            terms that are not defined in this document are defined in our Terms
            and you may refer to that document for those definitions.
          </p>

          <Spacer height={'20px'} />
          <h5>3. INFORMATION WE COLLECT</h5>
          <p>3.1. We collect information you provide to us, including:</p>
          <ul>
            <li>(a) Registration Information.</li>

            <li>
              (b) Health Input Data. Different Services may require different
              Health Input Data. You will be prompted by each Service to provide
              that information. You may choose whether or not to provide all
              Health Input Data requested, but acknowledge that in doing so, we
              may not be able to provide you with full or partial Services or
              results.
            </li>

            <li>(c) Client Content.</li>

            <li>
              (d) Blogs and Platforms. Ingeneous Clients may participate in
              Platforms and Blogs, including by posting or otherwise
              communicating Personal Information, which will be made available
              to others (including other Clients) to read, collect or use. You
              may request that we remove or anonymise your Personal Information
              from our Blog or Platform, whether on Our Site contacting us at
              hello@ingeneous.co.nz. Ingeneous will use its best endeavours to
              remove or anonymise any Personal Information as requested by you
              but in some cases this may be difficult or impossible. You may be
              required to register with an Ingeneous Affiliate or third party
              application to post a comment. To learn how the Ingeneous
              Affiliate or third party application uses your information, please
              review the third party’s privacy statement.
            </li>

            <li>
              (e) Social media features and widgets. Our Services include social
              media features which may collect information about your IP address
              and how you are using Our Site and may set a cookie to enable this
              feature to function properly. We may also allow Ingeneous
              Affiliate or third party social media services to provide us
              information about you, dependent upon your privacy settings with
              the social network. Your interactions with these social media
              features and widgets are governed by the privacy statements of the
              Ingeneous Affiliate or third party companies providing them.
            </li>

            <li>
              (f) Third party services (e.g. social media). If you use a third
              party site, including social media platforms, sites or
              applications, in connection with our Services, whether from our
              Affiliates or otherwise, we may also collect other information
              including the information about persons you communicate with about
              our Services. What information we collect will depend on your
              privacy settings on the Affiliate or third party’s site and may
              include things such as your profile picture, network, gender,
              Client name, Client ID, age range and language. Please review the
              Ingeneous Affiliate or third party’s privacy statement and your
              settings on the third party’s site carefully.
            </li>

            <li>
              (g) Referral information and sharing. When you refer a person to
              Ingeneous, we will collect information about them from you, such
              as their email address, solely for the purposes of contacting them
              about the referral. We will inform them that you have referred
              them. By referring any persons to us or sharing their information,
              you confirm that the person has given you consent for us to
              communicate with him or her through the method/s you have provided
              to us. The person you referred may contact us at
              hello@ingeneous.co.nz to request that we remove this information
              from our database.
            </li>

            <li>
              (h) Gifts. When you provide our Services to a person as a gift
              (referred to in this clause as the “Gift Recipient”), we will
              collect information from them, such as their name, email address
              and postal address, solely for the purposes of contacting them
              about the gift. Once a Gift Recipient registers to use the
              Services, he or she becomes bound by the Terms and all other
              related documentation, including the Privacy Policy and Informed
              Consent Document. No further information about the Gift Recipient
              will be shared with you as the purchaser of the gift.
            </li>

            <li>
              (i) Client service. When you contact client care or correspond
              with us about our Service, we collect information to track and
              respond to your inquiry; investigate any breach of our Terms,
              Privacy Policy or applicable laws or regulations; and analyse and
              improve our Services.
            </li>
          </ul>
          <p>
            3.2. We also collect information related to our Services, including:
          </p>
          <ul>
            <li>
              (a) DNA samples, either from a Sample Collection Kit or a Third
              Party Genetic Profile. If you provide a Sample Collection Kit, an
              Ingeneous Affiliate will extract your DNA for analysis. Your DNA
              sample and DNA are destroyed after our Ingeneous Affiliates
              complete their work, subject to legal and regulatory requirements.
            </li>

            <li>(b) Genetic Information.</li>
          </ul>
          <p>3.3. We collect Web-Behaviour Information:</p>
          <ul>
            <li>
              (a) We and Ingeneous Affiliates use cookies and similar
              technologies (including, but not limited to, web beacons, tags,
              scripts and device identifiers) so that we may recognise how and
              when you are using our Services and improve your experience and
              the experience of others, including by customising the Services,
              monitoring marketing programmes and providing additional security.
              <br />
              <br />
              If you reject cookies, your ability to use some features or areas
              of our site may be limited. We may receive reports based on the
              use of these technologies from Ingeneous Affiliates as
              De-identified Information about individual Clients or as Aggregate
              Information. We and our Affiliates do not use your Personal
              Information Health Input Data for targeted advertising.
            </li>

            <li>
              (b) Google Analytics. Ingeneous uses Google Analytics to perform
              many of the tasks listed above. To opt out of Google Analytics
              Advertising Features please use Google Ad Settings.
            </li>
          </ul>
          <p>
            3.4. Other Types of Information. As Ingeneous enhances and updates
            its Services, we may collect new and different types of information.
            We will update our Privacy Policy and/or obtain your prior consent
            to new processing, as we update and enhance our Services.
          </p>

          <Spacer height={'20px'} />
          <h5>4. HOW WE USE YOUR INFORMATION</h5>
          <p>
            4.1. Ingeneous will use and share your Personal Information with its
            Ingeneous Affiliates only in the ways that are described in this
            Privacy Policy.
          </p>
          <ul>
            <li>
              (a) To provide you with Services and analyse and improve our
              Services.
            </li>

            <li>
              (b) To process, analyse and deliver your genetic testing results.
              We will only process your Personal Information with your prior,
              written and express consent in order to provide you with the
              Services as set out in our Terms. On receipt by our Affiliate,
              your Test will be automatically processed. Saliva samples may be
              retained for a maximum of two years, after which the sample is
              then destroyed.
            </li>

            <li>
              (c) To allow you to share your Personal Information with others,
              including other Clients.
            </li>

            <li>
              (d) To allow you to share your Personal Information for Ingeneous
              Research purposes.
            </li>

            <li>(e) To provide Client support.</li>

            <li>(f) To conduct surveys or polls and obtain testimonials.</li>

            <li>(g) To provide you with marketing communications.</li>
          </ul>

          <Spacer height={'20px'} />
          <h5>
            5. INFORMATION WE SHARE WITH Ingeneous AFFILIATES AND THIRD PARTIES
          </h5>
          <p>
            5.1. We share your Personal Information with Ingeneous Affiliates,
            who act on our behalf, for the following reasons:
          </p>
          <ul>
            <li>(a) Order fulfilment and shipping.</li>

            <li>(b) Processing of Sample Collection Kits.</li>

            <li>(c) Client care support.</li>

            <li>(d) Cloud storage, IT, and Security.</li>

            <li>(e) Marketing and analytics.</li>
          </ul>
          <p>
            5.2. Although our contracts and procedures with each Ingeneous
            Affiliate explicitly protect the confidentiality and security of
            your Personal Information, we cannot guarantee the confidentiality
            and security of your information due to the inherent risks
            associated with storing and transmitting data electronically.
            <br />
            <br />
            5.3. In addition to the above, we permit Ingeneous Affiliates and
            third party advertising networks and providers to collect
            Web-Behaviour Information regarding the use of our Services so that
            we can deliver targeted online advertisements (in this clause “ads”)
            to you. To do this, they gather information about your browser's or
            device's visits and usage patterns on our Services and on other
            websites over time, which helps to better personalize ads to match
            your interests, and to measure the effectiveness of ad campaigns.
            <br />
            <br />
            5.4. We may share Aggregate or De-identified Information with third
            parties. For the avoidance of any doubt, this includes Ingeneous
            Affiliates.
            <br />
            <br />
            5.5. We may share some or all of your Personal Information with
            Ingeneous Affiliates so that we may perform the Services. We may
            provide additional notice and ask for your prior consent if we wish
            to share your Personal Information with the Ingeneous Affiliates in
            a materially different way than discussed in this Privacy Policy.
            <br />
            <br />
            5.6. Under certain circumstances we may be required to disclose your
            Personal Information by law. Ingeneous will provide you with prior
            notification, if it is lawfully able to do so or required by law to
            do so, before this occurs.
            <br />
            <br />
            5.7. In the event that Ingeneous is sold or acquired by another
            company or is restructured, your Personal Information may be among
            the assets transferred. In such a case, your information would
            remain subject to the terms of any pre-existing Privacy Policy.
          </p>

          <Spacer height={'20px'} />
          <h5>6. YOUR CHOICES</h5>
          <p>
            6.1. You have a number of choices about how you and we manage your
            Personal Information. These are set out below:
          </p>
          <ul>
            <li>
              (a) You may access, and download data from, your account at any
              time. If you are unable to access your account for any reason,
              please contact hello@ingeneous.co.nz for assistance. In certain
              circumstances, we may require you to provide additional
              information to us so that we may verify your identity. If you
              choose not to submit the required documentation, or the
              information provided is not sufficient for the purposes sought,
              Ingeneous will not be able to sufficiently verify your identity in
              order to complete your request.
            </li>

            <li>
              (b) You are entitled to access, correct or update any Personal
              Information that Ingeneous holds about you at any time. You are
              able to update your Registration Information on your own within
              your Account Settings. You may update, correct or access any other
              Personal Information we hold about you by contacting
              hello@ingeneous.co.nz
            </li>

            <li>
              (c) You are entitled to access your Genetic Information, including
              the raw data extracted from it, upon written request. Ingeneous
              will not otherwise provide you with that Information. Using the
              Services will assist you to understand the Genetic Information.
              Ingeneous cannot guarantee that the Genetic Information will be
              helpful or informative to you if you choose to use it without the
              Services.
            </li>

            <li>
              (d) You may opt out of receiving product and promotional emails or
              notifications when creating your Ingeneous account. Otherwise, you
              may view or update your email notification preferences by
              contacting hello@ingeneous.co.nz or by clicking the “unsubscribe”
              button at the bottom of promotional email communications.
            </li>

            <li>
              (e) You may decide to share your Personal Information with any
              persons in any way, including through our application programming
              interface (“API”) or through third parties. These third parties
              may use your Personal Information differently than we do under
              this Privacy Policy. We do not endorse or sponsor any API
              applications, and Ingeneous does not affirm the accuracy or
              validity of any interpretations made by third party API
              applications.
            </li>

            <li>
              (f) Ingeneous will have no responsibility or liability for any
              consequences that may result because you have released or shared
              Personal Information with others.
            </li>

            <li>
              g) You may delete your account at any time by contacting
              hello@ingeneous.co.nz
            </li>

            <li>
              (h) As stated in the Informed Consent Document, Genetic
              Information and/or Health Input Data that you have previously
              provided and for which you have given consent to use in Ingeneous
              Research cannot be removed from completed studies that use that
              information. Your data will not be included in studies that start
              more than 30 days after your account is closed.
            </li>

            <li>
              (i) Ingeneous and our laboratories will retain your Genetic
              Information, date of birth, and sex as required for compliance
              with applicable legal obligations for a period of two years.
              Ingeneous will also retain limited information related to your
              account and data deletion request, including but not limited to,
              your email address, account deletion request identifier, and
              record of legal agreements for a period of time as required by
              contractual obligations, and/or as necessary for the
              establishment, exercise or defence of legal claims and for audit
              and compliance purposes.
            </li>
          </ul>

          <Spacer height={'20px'} />
          <h5>7. SECURITY MEASURES </h5>
          <p>
            7.1. Ingeneous employs industry best practice in terms of data
            security, data accuracy and encryption to secure and protect your
            Personal Information.
            <br />
            <br />
            7.2. Registration Information is stripped from Personal Information,
            including Genetic Information and Health Input Data. This data is
            then assigned a randomly generated ID so an individual cannot
            reasonably be identified. Before your Genetic Information is
            processed by an Ingeneous Affiliate laboratory, it is turned into
            De-identified Information.
            <br />
            <br />
            7.3. Ingeneous ensures processing, production, and research
            environments are separated and access is restricted. Data, including
            Registration Information, Genetic Information, and Health Input
            Data, are segmented across logical database systems to further
            prevent re-identifiability.
            <br />
            <br />
            7.4. We limit access to Personal Information to authorised
            personnel, based on job function and role.
            <br />
            <br />
            7.5. Ingeneous uses continuous scanning to maintain the integrity of
            our networks and check for any potential or actual vulnerabilities.
            <br />
            <br />
            7.6. Ingeneous maintains a formal incident management program
            designed to ensure the secure, continuous delivery of its Services.
            Ingeneous has implemented an incident management program using
            industry best practices.
            <br />
            <br />
            7.7. Ingeneous requires its Affiliates to implement and maintain
            accepted industry standard administrative, physical and technical
            safeguards to protect Personal Information.
            <br />
            <br />
            7.8. Protecting your Personal Information is also your
            responsibility and you will need to safeguard your password, and any
            other authentication information you use to access our Services. Do
            not disclose your authentication information to any third party and
            immediately notify Ingeneous of any actual or suspected unauthorised
            use of your password. Ingeneous cannot secure Personal Information
            that you release on your own or that you request us to release.
          </p>

          <Spacer height={'20px'} />
          <h5>8. CHANGES TO THIS PRIVACY STATEMENT</h5>
          <p>
            8.1. Whenever this Privacy Statement is changed in a material way, a
            notice will be posted as part of this Privacy Statement and on our
            website for 30 days. The changes will become effective at the
            expiration of that period. We will also send all Clients an email
            notifying them of the changes and what the changes are.
          </p>

          <Spacer height={'20px'} />
          <h5>9. CONTACT INFORMATION</h5>
          <p>
            9.1. If you have questions about this Privacy Statement, or wish to
            submit a complaint, please email Ingeneous Privacy Administrator at
            hello@ingeneous.co.nz
          </p>

          <Spacer height={'100px'} />
        </Wrapper>
      </Col>
    </StyledRow>
  );
}

export default PrivacyPolicy;
