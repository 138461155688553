import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import Icon from 'components/Icon';
import {
  TR,
  Collapse,
  CollapseBtn,
  DataWrapper,
  Score,
  getColor,
} from './TableRow';

import CircleDot from './Circle';

const CBS = ({ geneData }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [genes, setGenes] = useState();

  useEffect(() => {
    setGenes({
      rs234706: geneData?.find((e) => 'rs234706' === e.snp),
    });
  }, [geneData]);

  if (!genes) {
    return null;
  }

  return (
    <>
      <TR>
        <Col md={11}>
          <Row>
            <Col md={2}>CBS</Col>
            <Col md={1}>
              <CircleDot
                color={getColor(genes?.rs234706?.score)}
                tooltipColor={genes?.rs234706?.score}
              />
            </Col>
            <Col md={2}>{genes?.rs234706?.snp?.toUpperCase()}</Col>
            <Col md={7}>100mg pyroxidine + 40mg Zinc daily in the am</Col>
          </Row>
        </Col>
        <Col md={1}>
          <CollapseBtn
            variant="secondary"
            onClick={() => setCollapsed(!collapsed)}
          >
            <Icon
              name={collapsed ? 'caret-down' : 'caret-up'}
              color="#dedede"
            />
          </CollapseBtn>
        </Col>
      </TR>
      <Collapse
        style={{ display: collapsed ? 'none' : 'block' }}
        className="print-only"
      >
        <DataWrapper>
          <h4 style={{ color: '#707070' }}>CBS - Zinc + B6 pyroxidine</h4>
          <p>
            <Score color={getColor(2)}>Red result for the RS number</Score>
            &nbsp;
            <strong>= 100mg pyroxidine + 40mg Zinc daily in the am</strong>
          </p>
          <p>
            This gene is often called the “hulk” gene because if you go for more
            than 5 hours between meals you can transform instantly into a hangry
            hulk. Don’t worry it’s not your fault - you just should try to not
            skip meals or fast. A quick fix is to have nuts as a snack often to
            curb this effect and the supplementation will also compensate for
            this well.
          </p>
          <p>
            By compensating for your underactive CBS receptor, this will help
            improve your ability to avoid a hangry moment if you have to go too
            long between meals.
          </p>
          <p>
            The high zinc and B6 compensate for your genes' greater need of zinc
            and B6. This will help you have better quality sleep at night. If
            you wake up in the morning and you remember your dreams – you will
            know you have optimal levels of B6. Another simple check is, if when
            you take an oral zinc lozenge it tastes metallic – this is an
            indication of optimal zinc levels, if your levels are too low you
            will not be able to taste the zinc. You can adjust your own
            maintenance dosages accordingly.
          </p>
        </DataWrapper>
      </Collapse>
    </>
  );
};

export default CBS;
