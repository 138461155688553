import React, { CSSProperties, Fragment, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useCSVReader } from 'react-papaparse';
import { primary, tertiary, error } from '../../../styles/theme';
import { uploadAmy } from '../../../api/admin';
import { Alert } from 'react-bootstrap';
import Icon from '../../../components/Icon';

const styles = {
  csvReader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  } as CSSProperties,
  browseFile: {
    width: '20%',
    backgroundColor: `${primary}`,
    color: '#FFFFFF',
    borderColor: `${primary}`,
    borderStyle: 'none',
  } as CSSProperties,
  acceptedFile: {
    border: '1px solid #ccc',
    height: 45,
    lineHeight: 2.5,
    paddingLeft: 10,
    width: '80%',
  } as CSSProperties,
  remove: {
    borderRadius: 0,
    padding: '0 20px',
    backgroundColor: `${tertiary}`,
    borderColor: `${tertiary}`,
  } as CSSProperties,
  progressBarBackgroundColor: {
    backgroundColor: `${primary}`,
  } as CSSProperties,
};

const delay = 2;

interface Dictionary<T> {
  [Key: string]: T;
}

const Upload = () => {
  const { CSVReader } = useCSVReader();

  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorList, setErrorList] = useState(null)

  const fieldErrorProcessor = (fieldErrors: any) => {
    let bulletList:any[] = [];
    Object.keys(fieldErrors).forEach((fieldName: string, fieldNameIndex: number)=>{
      var fieldErrorList = Array.isArray(fieldErrors[fieldName]) ? fieldErrors[fieldName] : [fieldErrors[fieldName]];
      fieldErrorList.forEach((errorString: string, errorIndex: number)=>{
        bulletList.push(
          <li key={fieldNameIndex.toString() + errorIndex.toString()}>
            {fieldName} : {errorString}
          </li>
        )
      })
    })
    return <Fragment>
      <ul>
        {bulletList}
      </ul>
    </Fragment>
  }

  const errorBulletList = (errors: any) => {
    if(errors === null){
      return null
    }
    return fieldErrorProcessor(errors)
  }

  const updateError = (message: string, jsonErrors: any) => {
    setErrorMessage(message)
    setErrorAlert(true)
    setErrorList(jsonErrors)
  }

  const onSubmit = async (data: any) => {
    setErrorAlert(false);
    setSuccessAlert(false);
    await uploadAmy(data)
      .then((res) => {
        console.log(res);
        setErrorAlert(false);
        let timer1 = setTimeout(() => setSuccessAlert(true), delay * 1000);
        return () => {
          clearTimeout(timer1);
        };      })
      .catch((err) => {
        console.log(err);
        console.log(err.response)
        let timer1 = setTimeout(() => updateError(err.response.data['fe_message'], err.response.data['json_error']), delay * 1000);
        return () => {
          clearTimeout(timer1);
        };
      });
  };

  return (
    <Container>
      <CSVReader
        onUploadAccepted={(results: any) => {
          let resultsArr: any = {};
          {
            /* to update after the API work's done */
          }
          resultsArr.results = results.data; // pass it as array
          // resultsArr.results= JSON.stringify(results.data); // pass it with JSON Stringify
          {
            /* to update after the API work's done */
          }
          onSubmit(resultsArr);
        }}
        config={{
          header: true,
          skipEmptyLines: true,
          delimiter: ',',
        }}
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
        }: any) => (
          <>
            {errorAlert && acceptedFile && (
              <Alert variant="danger" style={{display: 'flex', alignItems: 'center'}}>
                <Icon
                  brand={undefined}
                  name="exclamation"
                  color={error}
                  size={'20px'}
                />
                <div>
                  <span style={{paddingLeft: 10}}> Upload Failed{errorList === null ? ", "+errorMessage : ""}</span>
                  <div>
                    {errorBulletList(errorList)}
                  </div>
                </div>
                
              </Alert>
            )}
            {successAlert && acceptedFile && (
              <Alert variant="success" style={{display: 'flex', alignItems: 'center'}}>
                <Icon
                  brand={undefined}
                  name="check"
                  color={primary}
                  size={'20px'}
                />
                <span style={{paddingLeft: 10}}> Upload Successful</span>
              </Alert>
            )}
            <div style={styles.csvReader}>
              <button
                type="button"
                {...getRootProps()}
                style={styles.browseFile}
              >
                Browse file
              </button>
              <div style={styles.acceptedFile}>
                {acceptedFile && acceptedFile.name}
              </div>
              <button {...getRemoveFileProps()} style={styles.remove}>
                Remove
              </button>
            </div>
            <ProgressBar style={styles.progressBarBackgroundColor} />
          </>
        )}
      </CSVReader>
    </Container>
  );
};

export default Upload;
