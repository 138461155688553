import React from 'react';
import styled from 'styled-components';

import ReactTooltip from 'react-tooltip';

export const Circle = styled.div`
  height: 17px;
  width: 17px;
  border-radius: 100px;
  background-color: ${(props) => props.color};
  margin: 0 auto;
  margin-top: 3px;
`;
export const PrintTooltip = styled.div`
  font-size: 11px;
  text-align: center;
`

export const getColor = (val: number) => {
  let color = '#f5f5f5';
  if (val === 2) {
    color = '#d7b6b7'; // Red
  } else if (val === 1) {
    color = '#ead79f'; // Yellow
  } else if (val === 0) {
    color = '#b5dbd6'; // Green
  }

  return color;
};

interface CircleProps {
  tooltipColor: any;
}

const CircleDot = ({ tooltipColor }: CircleProps) => {
  return (
    <>
      <Circle
        color={getColor(tooltipColor)}
        data-tip
        data-for={'color-' + getColor(tooltipColor)}
      />
      <PrintTooltip className='print-only'>
      {tooltipColor == 0 ? 'Green' : tooltipColor == 1 ? 'Yellow' : 'Red'}
      </PrintTooltip>
      <ReactTooltip
        id={'color-' + getColor(tooltipColor)}
        place="top"
        effect="solid"
      >
        {tooltipColor == 0 ? 'Green' : tooltipColor == 1 ? 'Yellow' : 'Red'}
      </ReactTooltip>
    </>
  );
};

export default CircleDot;
