import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { gray, primary } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import View from '../View';
import Close from '../ViewLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const AlertRef = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
  margin-bottom: 30px;
`;

const SubtitleGray = styled.h5`
  color: ${gray};
  margin-top: 10px;
`;

const WrapperRef = styled.div`
  margin-top: 5px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const ListRef = styled.li`
  font-weight: 300;
  padding-left: 20px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    word-break: break-all;
  }
`;

interface CapcicumProps {
  results: any;
}
const Capcicum = ({ results }: CapcicumProps) => {
  const [show, setShow] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/Supplement.png"
            alt="supplement"
            width={'60%'}
          />
        </ImageCol>
        <Col md={8}>
          <SubtitleGray>
            Your most powerful supplement for weight loss is
          </SubtitleGray>
          <Subtitle>Capcicum</Subtitle>
          <Alert>
            <p>
              Suggested Supplement: Cayenne Extra Hot, Nature’s Way (1 capsule
              before each meal)
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <p>
                Taking Cayenne Extra Hot will support feelings of satiety to
                help you in reaching your optimal weight.
              </p>
              <p>
                This supplement contains Cayenne Pepper which is rich in
                capsaicin and can help with weight loss. helping to lower your
                blood pressure, and decrease blood glucose levels after eating,
                helping you to feel full for longer (1,2). This supplement will
                help to ensure you are getting enough capsaicin in your diet
                supporting you on your weight loss journey.
              </p>
              <p style={{ fontWeight: 'bold' }}>
                This supplement is best for your genes. If you have any concerns
                this may not suit your current state of health or medications
                check with your doctor.
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
      <Row className="no-print">
        <Col>
          <AlertRef>
            <span>References</span>
            {!showReferences && (
              <View value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <Close value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <WrapperRef>
                <ListGroup>
                  <ListRef>
                    Tremblay, A., Arguin, H. & Panahi, S. Capsaicinoids: a spicy
                    solution to the management of obesity?. Int J Obes 40,
                    1198–1204 (2016). &nbsp;
                    <a href="https://doi.org/10.1038/ijo.2015.253">
                      https://doi.org/10.1038/ijo.2015.253
                    </a>
                  </ListRef>
                  <ListRef>
                    Whiting S, Derbyshire EJ, Tiwari B. Could capsaicinoids help
                    to support weight management? A systematic review and
                    meta-analysis of energy intake data. Appetite. 2014 Feb
                    1;73:183-8. &nbsp;
                    <a href="https://www.sciencedirect.com/science/article/abs/pii/S0195666313004492">
                      https://www.sciencedirect.com/science/article/abs/pii/S0195666313004492
                    </a>
                  </ListRef>
                </ListGroup>
              </WrapperRef>
            )}
          </AlertRef>
        </Col>
      </Row>
    </>
  );
};

export default Capcicum;
