import React from 'react'
import { Route } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const PublicRoute = ({component: Component, layout: Layout, ...rest}) => {
  return (<Layout>
    <Route
    {...rest}
    render={props => (
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
        <Component {...props} />
      </GoogleReCaptchaProvider>
    )} />
  </Layout>)
}

export default PublicRoute
