import React from 'react'
import { Route } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import BlankWrapper from '../containers/BlankWrapper'

const BlankRoute = ({component: Component, ...rest}) => {
  return (<BlankWrapper>
    <Route
      {...rest}
      render={props => (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
          <Component {...props} />
        </GoogleReCaptchaProvider>
      )} />
  </BlankWrapper>)
}

export default BlankRoute
