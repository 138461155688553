import { Spacer } from 'components/utils';
import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Button, Spinner } from 'react-bootstrap';
import styled from 'styled-components';

import UserRow from './UserRow';

const TableWrapper = styled.div`
  background-color: #ffffff;
  font-family: 'Lato';
`;

const UserTable = ({
  users,
  selectedUsers,
  loading,
  page,
  hasNext,
  hasPrevious,
  setPage,
  handleInputChange,
}) => {

  const [resetPasswordModal, setResetPasswordModal] = useState(false);

  const handleClose = () => setResetPasswordModal(false);
  const handleShow = () => setResetPasswordModal(true);

  return (
    <>
      <TableWrapper>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Customer</th>
              <th>Sample ID</th>
              <th>Actions</th>
              <th>Reset Password</th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((user) => {
                return (
                  <UserRow
                    key={user.id}
                    user={user}
                    handleInputChange={handleInputChange}
                    selectedUsers={selectedUsers}
                    setShow={handleShow}
                  />
                );
              })}
          </tbody>
          
        </Table>
      </TableWrapper>
      <Row>
        <Col>
          <Button
            variant="primary"
            type="button"
            disabled={loading || !hasPrevious}
            onClick={() => setPage(parseInt(page) - 1)}
          >
            {!loading ? (
              
              'Previous'
            ) : (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Col>
        <Col xs="auto">
          <Button
            variant="primary"
            type="button"
            disabled={loading || !hasNext}
            onClick={() => setPage(parseInt(page) + 1)}
          >
            {!loading ? (
              'Next'
            ) : (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Col>
      </Row>
      <Spacer height={'40px'} />
      
    </>
  );
};

export default UserTable;
