import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Spacer } from 'components/utils';
import { gray } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import ReactSpeedometer from 'react-d3-speedometer';
import { isMobile } from 'react-device-detect';

import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  margin-top: ${isMobile ? '0' : '30px'};
  order: ${isMobile ? 'initial' : '2'};
  // position: absolute;
  // top: 0;
  // right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${gray};
  margin-bottom: 30px;
`;

const PrintContent = styled.div`
  @media print {
    margin-bottom: 50px;
  }
`;

interface InflammationMediumProps {
  results: any;
}
const InflammationMedium = ({ results }: InflammationMediumProps) => {
  const [showInflammation, setShowInflammation] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <ImageCol md={4}>
            <ReactSpeedometer
              width={250}
              needleHeightRatio={0.3}
              value={(results.inflammation ?? 0.5) * 1000}
              height={200}
              customSegmentStops={[0, 333, 666, 1000]}
              segmentColors={['#ABDCD6', '#EED698', '#DEB4B6']}
              currentValueText="How are you?"
              customSegmentLabels={[
                {
                  text: 'Small',
                  color: '#fff',
                },
                {
                  text: 'Moderate',
                  color: '#fff',
                },
                {
                  text: 'Large',
                  color: '#fff',
                },
              ]}
              ringWidth={47}
              needleTransitionDuration={3333}
              needleColor={'#707070'}
              textColor={'#fff'}
            />
          </ImageCol>
        </ImageCol>
        <Col md={8}>
          <Subtitle>
            Moderately -{' '}
            <span style={{ color: '#1AA88C' }}>
              Moderately decrease inflammation
            </span>
          </Subtitle>
          <Alert>
            <p>
              Before you begin following your weight management recommendations
              make sure you have recently done a three week inflammatory reset
              with your Essential Start recommendations.
            </p>
          </Alert>
          <Alert>
            <p>
              After three weeks of weight loss follow your lifestyle plus
              recommendations for a minimum of three weeks before trying to lose
              weight.
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!showInflammation &&
            !printing &&(
              <ReadMore
                value={showInflammation}
                setValue={setShowInflammation}
              />
            )}
          {(showInflammation || printing) && (
            <PrintContent>
              <p>
                Based on your gene SNPs you have a moderate predisposition to
                inflammation, with inflammation playing a moderate part of your
                weight optimisation story. This means it is important for you to
                decrease your inflammation to help you reach your optimal
                weight.
              </p>
              <p>
                Inflammation can make it harder for you to lose weight because
                it interferes with your body's response to leptin (a hormone
                that cues hunger signals) (1), and insulin (a hormone that
                controls sugar storage) (2). This causes your body to store more
                fat in adipose tissue, and also in other areas like the liver
                (3).
              </p>
              <p>
                Being overweight can also increase inflammation as adipose
                tissue can trigger inflammatory signals (4,5). At an unhealthy
                BMI, 30% of your proinflammatory genes are held in an
                inflammatory position despite what your DNA predisposition is.
                This means decreasing your inflammation can make it easier to
                reach an optimal weight and reaching this optimal weight also
                has the power to decrease inflammatory signals (6,7)!
              </p>
            </PrintContent>
          )}
          {showInflammation && !printing && (
            <ReadLess value={showInflammation} setValue={setShowInflammation} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default InflammationMedium;
