import React from 'react'

const Sauna = () => {
  return (<div>
    <h5>Use a sauna for 20 minutes a day</h5>
    <p>
      Your body needs particularly strong assistance when it comes to the genes associated with detoxification. Using the sauna for 20 minutes a day can change the gene expression of key genes associated with detoxification and inflammation coordination.
    </p>
    <p>
      Any type of sauna experience will benefit you, if you enjoy a traditional steam sauna these are often available at many gyms and sports clubs. If you prefer a dry heat infrared sauna these have addition benefits and can often be located at specialty spa or wellness facility.
    </p>
    <p>
      During your Inflammation Reset (3-week period) we recommend three sessions a week for 15 -20 minutes a day. Your genes would appreciate you being in a sauna everyday for 15 minutes, although we recognise that this isn’t necessarily achievable. While you are reducing your exercise within the Inflammation Reset, it would be optimal instead to have a sauna instead of working out on some days.
    </p>
  </div>)
}

export default Sauna
