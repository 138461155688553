import React, { useContext, useEffect, useState } from 'react';
import { Page, PageHeader, PageTitle, PageBody } from 'components/Page';
import { Spacer } from 'components/utils';
import { Row, Col, Container } from 'react-bootstrap';
import styled from 'styled-components';
import { gray } from 'styles/theme';
import { isMobile } from 'react-device-detect';

import { useObserver } from 'mobx-react-lite';

import Form from './components/Form';
import { CommonContext } from 'stores/common';
import { useHistory } from 'react-router';
import { createPractionerOrder } from 'api/practitioner';

const Wrapper = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
  align-items: center;
  padding: 15px 0;

  .title {
    flex: 1;
    display: ${isMobile ? 'block' : 'flex'};
    align-items: center;
    justify-content: space-between;

    h1 {
      margin: 0;
      margin-right: 20px;
    }
  }
`;

const Subtitle = styled.p`
  color: ${gray};
  font-size: 26px;
  font-weight: 700;
`;

const Payment = () => {
  const commonStore = useContext(CommonContext);
  let history = useHistory();

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    console.log(commonStore.getPractitionerCheckout());
    if (!commonStore.getPractitionerCheckout().price) {
      history.push('/practitioners/checkout');
      return;
    }

    setTotalPrice(commonStore.getPractitionerCheckout().price);
  }, []);

  const createOrder = async (payload: any) => {
    console.log('Create order:', payload);

    try {
      let res = await createPractionerOrder({
        billing: payload,
        ...commonStore.getPractitionerCheckout(),
      });

      if (res.status === 200 && res.data.status === 'ok') {
        history.push('/practitioners/success');
      } else {
        alert(
          'Something went wrong, please contact support at hello@ingeneous.co.nz'
        );
      }
    } catch (err) {
      console.log(err);
      alert(
        'Something went wrong, please contact support at hello@ingeneous.co.nz'
      );
    }
  };

  return useObserver(() => {
    return (
      <Page>
        <PageHeader>
          <Container>
            <Wrapper>
              <div className="title">
                <PageTitle>
                  <img
                    src="/assets/images/ingeneous_white_r.png"
                    alt="ingeneous"
                    width={190}
                  />
                </PageTitle>
              </div>
            </Wrapper>
          </Container>
        </PageHeader>
        <PageBody style={{ position: 'relative' }}>
          <Container>
            <Row style={{ justifyContent: 'center' }}>
              <Col
                xs={12}
                sm={12}
                style={{ maxWidth: `${isMobile ? '100%' : '50%'}` }}
              >
                <Spacer height={'40px'} />
                <Subtitle>Payment Details</Subtitle>
                <p>
                  Complete your purchase by entering your payment details below.
                </p>
                <Spacer height={'20px'} />
                <Form totalPrice={totalPrice} createOrder={createOrder} />
              </Col>
            </Row>
          </Container>
        </PageBody>
      </Page>
    );
  });
};

export default Payment;
