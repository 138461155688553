import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { primary } from 'styles/theme';
import { isMobile } from 'react-device-detect';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
    margin-bottom: 30px;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  width: ${isMobile ? '100%' : '400px'};
  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
  margin-bottom: 30px;
`;

interface PoorProps {
  results: any;
}
const Poor = ({ results }: PoorProps) => {
  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/ImpulseControl.png"
            alt="impulse"
            width={'60%'}
          />
        </ImageCol>
        <Col md={8}>
          <Subtitle>Poor Impulse Control</Subtitle>
          <Alert>
            <p>You are predisposed to have poor impulse control.</p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Spacer height={'10px'} />
          <div>
            <p>
              Impulse control involves your ability to control your own emotions
              and behaviours. With lower impulse control you may have unplanned
              quick reactions without always considering the consequences.
              Impulse control is related to several different systems in your
              brain including rewards/punishment and attention. Dopamine is
              thought to play a large role in your impulsivity as well as it is
              the main chemical messenger behind reward and motivation.
            </p>
            <p>
              As someone who is naturally inclined to have poor impulse control
              you are likely more spontaneous but may find it difficult to
              control your impulses at times. This may make dieting slightly
              more difficult for you.
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Poor;
