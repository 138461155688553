import React from 'react'

const COMT = () => {
  return (<>
    <h6>No servings of coffee. No servings of caffeinated tea, including green tea. No servings of decaf tea or coffee.</h6>
    <p>
      You have a gene SNP coding for your COMT Receptor that significantly struggles to properly identify estrogen. Normally a COMT receptor can efficiently munch up the oestrogen in the body to keep hormone levels in check and stop an imbalance from being created.
      <br /><br />
      Caffeine, decaf coffee/tea, chocolate and soda molecules look similar to estrogen molecules and sometimes your COMT receptors can’t tell the difference! Imagine that your COMT receptor is really lazy or has poor vision. If you do consume a lot of extra caffeine, decaf coffee/tea, chocolate and soda into your daily diet your COMT Receptor is much less likely to munch up the actual estrogen molecules, allowing estrogen levels to increase.
      <br /><br />
      Imbalanced hormone levels can lead to mood changes and all sorts of homeostasis imbalances in your body system.  For men it can be seen as having a “feminising” effect.
      <br /><br />
      Important. COMT is one of many genes which is linked to brain inflammation which can be improved through nutrigenomic recommendations. It is necessary to speak to an Ingeneous consultant to get further recommendations.
      <br /><br />
      <strong>Caffeine</strong> includes all types of coffee, tea and green tea, plus decaffeinated coffee and tea, all chocolate (except for white chocolate) and all caffeinated sodas (for example Coke and Mountain Dew).
      <br /><br />
      Naturally caffeine free teas make a good alternative, Rooibos actively improves your inflammation coordination.
      <br /><br />
      White chocolate can be an option if you are looking to indulge and treat yourself. Reach for a piece!
    </p>
  </>);
}

export default COMT
