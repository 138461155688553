import React, { useContext } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { useObserver } from 'mobx-react';
import { Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { isBrowser } from 'react-device-detect';

import { AuthContext } from 'stores/auth';
import { Spacer } from 'components/utils';
import { primary } from 'styles/theme';

const StyledRow = styled(Row)`
  background-color: ${primary};
  min-height: 100%;
  width: 100%;
  position: absolute;
`;

const LogoWrapper = styled.div`
  padding: ${isBrowser ? '30px 15%' : '15px'};
`;

const Wrapper = styled.div`
  padding: ${isBrowser ? '30px 15%' : '15px'};
  p,
  label,
  h3 {
    color: #ffffff;
  }
`;

const Logo = styled.img`
  max-height: 50px;
`;

const ImageCol = styled(Col)`
  background: url(${(props) => props.src}) center center no-repeat;
  background-size: cover;
`;

function RegisterSuccess() {
  const authStore = useContext(AuthContext);

  const image =
    process.env.PUBLIC_URL +
    '/assets/images/bg/ING-00' +
    Math.floor(Math.random() * 8 + 1) +
    '.jpg';

  return useObserver(() => {
    if (authStore.isAuthenticated()) {
      return <Redirect to="/" />;
    } else {
      return (
        <StyledRow>
          <Col md={6}>
            <LogoWrapper>
              <Link to="/">
                <Logo
                  src={
                    process.env.PUBLIC_URL +
                    '/assets/images/ingeneous_white_r.png'
                  }
                />
              </Link>
            </LogoWrapper>
            <Wrapper>
              {/* <h3>Please verify your email address to continue.</h3>
            <Spacer height={'30px'} />
            <p>We've sent you an email with a verification link that will take you through to the next step.</p>
            <Spacer height={'100px'} /> */}
              <h3>Registration successful!</h3>
              <Spacer height={'100px'} />
              <Link to="/login">
                <Button variant="secondary">Go to Login</Button>
              </Link>
            </Wrapper>
          </Col>
          <ImageCol md={6} src={image} />
        </StyledRow>
      );
    }
  });
}

export default RegisterSuccess;
