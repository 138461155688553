import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { getProducts, getOrders } from 'api/core';
import { Spacer } from 'components/utils';
import { Page, PageHeader, PageBody } from 'components/Page';
import { white, borderGray } from 'styles/theme';

import ProductDetails from './components/ProductDetails';
import OrderDetail from './components/OrderDetail';

const Products = styled(Row)`
  background-color: ${white};
  border: 1px solid ${borderGray};
  border-radius: 5px;
  padding: ${isMobile ? '0' : '20px'};
`;

const Orders = styled.div`
  background-color: ${white};
  border: 1px solid ${borderGray};
  border-radius: 5px;
  padding: ${isMobile ? '0' : '20px'};
`;

const OrdersView = () => {
  const [products, setProducts] = useState();
  const [orders, setOrders] = useState();

  useEffect(() => {
    getProducts().then((res) => {
      setProducts(res.data.products);
    });

    getOrders().then((res) => {
      setOrders(res.data.orders);
    });
  }, []);

  return (
    <Page>
      <PageHeader>
        <Spacer height={'30px'} />
        <Container>
          <h1 className="white">My Orders</h1>
        </Container>
      </PageHeader>
      <PageBody>
        <Container className="orders">
          <Spacer height={'30px'} />
          <Row>
            <Col md={12}>
              <Products>
                {products &&
                  products.map((product) => {
                    const variant = product ? product.variants[0] : null;

                    if (variant && variant.price > 0) {
                      return (
                        <Col key={product.id} xs={12} md={6}>
                          <ProductDetails product={product} />
                        </Col>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Products>
            </Col>
          </Row>
          <Spacer height={'30px'} />
          <Row>
            <Col md={12}>
              <h2>My Past Orders</h2>
              <Orders>
                {orders &&
                  orders.map((order) => {
                    return <OrderDetail key={order.name} order={order} />;
                  })}
              </Orders>
            </Col>
          </Row>
          <Spacer height={'30px'} />
        </Container>
      </PageBody>
    </Page>
  );
};

export default OrdersView;
