import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Form, Col, Button, Spinner } from 'react-bootstrap';

import QrReader from 'react-qr-reader';

import { checkEmail as checkEmailAPI } from 'api/auth';
import { getProducts, createEventOrder } from 'api/core';
import logo from 'components/images/logo.png';
import FormCard from 'components/cards/FormCard';
import { Spacer } from 'components/utils';

import {
  Header,
  QRWrapper,
  RadioWrapper,
  CheckboxWrapper,
  FormActions,
  FormMessage,
} from './components/styled-components';

const EventSignup = () => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    errors,
    setError,
    clearErrors,
    reset,
  } = useForm({});
  const [saving, setSaving] = useState(false);
  const [products, setProducts] = useState();
  // eslint-disable-next-line no-unused-vars
  const [phoneNumber, setPhoneNumber] = useState();
  const [qrData, setQrData] = useState(null);
  const [message, setMessage] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    getProducts().then((res) => {
      console.log(res.data.products);
      setProducts(res.data.products);
    });
  }, []);

  const onSubmit = async (data) => {
    setSaving(true);
    await createEventOrder(data)
      .then((res) => {
        setMessage({
          type: 'success',
          message: 'Order successful! Reference ' + res.data.order_id,
        });
        setSaving(false);
        reset();
        handlePhoneNumberChange();
      })
      .catch(() => {
        setSaving(false);
        setMessage({
          type: 'error',
          message: 'Order failed!',
        });
      });
  };

  const checkEmail = (e) => {
    const emailPattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
    async function validate(e) {
      await checkEmailAPI(e.target.value)
        .then((res) => {
          if (res.data.exists) {
            setError('email', {
              type: 'manual',
              message: 'Email exists.',
            });
          } else {
            clearErrors('email');
          }
        })
        .catch(() => {
          return false;
        });
    }
    return emailPattern.test(e.target.value) ? validate(e) : false;
  };

  const handlePhoneNumberChange = (phoneNumber) => {
    clearErrors('phone_number');
    setPhoneNumber(phoneNumber);
    setValue('phone_number', phoneNumber);
  };

  const handleScan = (data) => {
    if (data) {
      setQrData(data);
      setValue('test_kit_confirm', data);
      setValue('test_kit', data);
    }
  };

  const handleSampleId = () => {
    const vals = getValues(['test_kit', 'test_kit_confirm']);
    if (vals['test_kit'] !== vals['test_kit_confirm']) {
      setError('test_kit_confirm', {
        type: 'manual',
        message: 'Sample IDs do not match',
      });
    } else {
      clearErrors(['test_kit', 'test_kit_confirm']);
    }
  };

  const handleScanError = () => {
    console.log('scan_error');
  };

  const handleSelected = (evt) => {
    const val = evt.target?.value;
    if (evt.target?.checked) {
      setSelectedProducts((prevState) => [...prevState, val]);
    } else {
      let tempSelected = selectedProducts?.filter((prodId) => prodId !== val);
      setSelectedProducts(tempSelected);
    }
  };

  return (
    <Container>
      <FormCard>
        <Header>
          <h2>Event Signup</h2>
          <img src={logo} alt="" />
        </Header>
        <Spacer height="30px" />
        <Form
          className="form form-dark"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Form.Row>
            <Form.Group as={Col} controlId="first_name">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                name="first_name"
                type="text"
                ref={register({ required: true })}
              />
              {errors.first_name && (
                <Form.Control.Feedback type="invalid">
                  {errors.first_name.type === 'required'
                    ? 'This field is required'
                    : 'Invalid input'}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} controlId="last_name">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                name="last_name"
                type="text"
                ref={register({ required: true })}
              />
              {errors.last_name && (
                <Form.Control.Feedback type="invalid">
                  {errors.last_name.type === 'required'
                    ? 'This field is required'
                    : 'Invalid input'}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                type="email"
                ref={register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
                onChange={checkEmail}
              />
              {errors.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email.type === 'required'
                    ? 'This field is required'
                    : 'Invalid input'}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="phone_number">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                name="phone_number"
                type="text"
                ref={register({ required: true })}
                isInvalid={!!errors.phone_number}
              />
              {/* <PhoneNumber
              name="phone_number"
              ref={register({ required: true })}
              value={phoneNumber}
              handleChange={handlePhoneNumberChange}
            /> */}
              {errors.phone_number && (
                <Form.Control.Feedback type="invalid">
                  {errors.phone_number.type === 'required' &&
                    'This field is required'}
                  {errors.phone_number.type === 'manual' &&
                    errors.phone_number.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="package">
              <Form.Label>Package</Form.Label>
              <RadioWrapper className="mb-3">
                {products &&
                  products.map((product) => {
                    const variant = product.variants[0];
                    let title = product.title;
                    if (product.id !== 6129584013463) {
                      title = product.title + ' $' + variant.price;
                    }

                    if (variant?.compare_at_price) {
                      title += ' (RRP: $' + variant.compare_at_price + ')';
                    }

                    return (
                      <Form.Check
                        key={product.id}
                        name="package"
                        type="checkbox"
                        label={title}
                        value={product.id}
                        ref={register}
                        onChange={handleSelected}
                      />
                    );
                  })}
              </RadioWrapper>
              {errors.package && (
                <Form.Control.Feedback type="invalid">
                  {errors.package.type === 'required' && 'Choose a package'}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="qr">
              <Form.Label>Scan QR Code</Form.Label>
              <QRWrapper valid={qrData !== undefined && qrData !== null}>
                <QrReader
                  className="qr-reader"
                  onError={handleScanError}
                  onScan={handleScan}
                />
              </QRWrapper>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="test_kit">
              <Form.Label>Test Kit</Form.Label>
              <Form.Control
                name="test_kit"
                type="text"
                ref={register({ required: true })}
                onChange={handleSampleId}
              />
              {errors.test_kit && (
                <Form.Control.Feedback type="invalid">
                  {errors.test_kit.type === 'required'
                    ? 'This field is required'
                    : 'Invalid input'}
                  {errors.test_kit.type === 'manual' && errors.test_kit.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} controlId="test_kit_confirm">
              <Form.Label>Confirm Test Kit</Form.Label>
              <Form.Control
                name="test_kit_confirm"
                type="text"
                ref={register({ required: true })}
                onChange={handleSampleId}
              />
              {errors.test_kit_confirm && (
                <Form.Control.Feedback type="invalid">
                  {errors.test_kit_confirm.type === 'required'
                    ? 'This field is required'
                    : 'Invalid input'}
                  {errors.test_kit_confirm.type === 'manual' &&
                    errors.test_kit_confirm.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="email">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                name="notes"
                ref={register}
              />
              {errors.notes && (
                <Form.Control.Feedback type="invalid">
                  {errors.notes.type === 'required'
                    ? 'This field is required'
                    : 'Invalid input'}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="terms">
              <CheckboxWrapper>
                <Form.Check
                  name="terms"
                  type="checkbox"
                  label="I agree to the Terms and Conditions"
                  ref={register({ required: true })}
                />
              </CheckboxWrapper>
              {errors.terms && (
                <Form.Control.Feedback type="invalid">
                  {errors.terms.type === 'required' &&
                    'This field is required.'}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="consent">
              <CheckboxWrapper>
                <Form.Check
                  name="consent"
                  type="checkbox"
                  label="I agree to the Consent Form"
                  ref={register({ required: true })}
                />
              </CheckboxWrapper>
              {errors.consent && (
                <Form.Control.Feedback type="invalid">
                  {errors.consent.type === 'required' &&
                    'This field is required.'}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="privacy_policy">
              <CheckboxWrapper>
                <Form.Check
                  name="privacy_policy"
                  type="checkbox"
                  label="I agree to the Privacy Policy"
                  ref={register({ required: true })}
                />
              </CheckboxWrapper>
              {errors.privacy_policy && (
                <Form.Control.Feedback type="invalid">
                  {errors.privacy_policy.type === 'required' &&
                    'This field is required.'}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="health_disability_code">
              <CheckboxWrapper>
                <Form.Check
                  name="health_disability_code"
                  type="checkbox"
                  label="I agree to the Health and Disability Code"
                  ref={register({ required: true })}
                />
              </CheckboxWrapper>
              {errors.health_disability_code && (
                <Form.Control.Feedback type="invalid">
                  {errors.health_disability_code.type === 'required' &&
                    'This field is required.'}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form.Row>

          <FormActions>
            <Button
              variant="light"
              type="submit"
              disabled={saving || !!errors.email}
            >
              {!saving ? (
                'Save'
              ) : (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
            {message && <FormMessage>{message.message}</FormMessage>}
          </FormActions>
        </Form>
      </FormCard>
    </Container>
  );
};

export default EventSignup;
