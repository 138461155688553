import React from 'react'

const SmokedFood = () => {
  return (<div>
    <h5>Burnt, smoked, or chargrilled foods aren’t the best for your body</h5>
    <p>
      The carcinogens found in burnt, smoked, or chargrilled foods affect you more profoundly than someone with different genetics.
    </p>
    <p>
      In an ideal world you would do your best to avoid smoked and BBQ food.
    </p>
    <p>
      We understand that BBQ food is often more about the lifestyle than the nutrition, so here are some ways that you can minimize the effects:
    </p>
    <ol>
      <li>Don’t over grill your food. Minimise the carcinogen production by flipping meat more often, every 30 seconds, and try not to cook past medium rare</li>
      <li>Fish, shellfish, and seafood in general produce less carcinogens in response to smoking/chargrilling than other meats</li>
      <li>Precook foods in the oven before putting on the BBQ, this reduces the time that your food will need to spend on the BBQ. We sympathise that this does reduce some of the fun of the experience.</li>
    </ol>
    <p>
      If you are not keen on using any of these carcinogen minimising strategies, then we recommend trying to save the BBQ for a special occasion rather than using daily.
    </p>
  </div>)
}

export default SmokedFood
