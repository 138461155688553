import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Delivery from './Delivery';
import Billing from './Billing';
import { Spacer } from 'components/utils'

const AccountRoutes = () => (<div>
  <Spacer height={'60px'} />
  <Switch>
    <Route path="/account/delivery/edit" component={Delivery} />
    <Route path="/account/billing/edit" component={Billing} />
  </Switch>
</div>);

export default AccountRoutes;
