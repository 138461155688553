import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { primary } from 'styles/theme';
import { isMobile } from 'react-device-detect';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  width: ${isMobile ? '100%' : '400px'};
  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
  margin-bottom: 30px;
`;

interface ModerateProps {
  results: any;
}
const Moderate = ({ results }: ModerateProps) => {
  return (
    <>
      <Row style={{ position: 'relative' }}>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/BingeEating.png"
            alt=""
            width={'60%'}
          />
        </ImageCol>
        <Col md={8}>
          <Subtitle>Risk of Binge Eating: Moderate</Subtitle>
          <Alert>
            <p>Your risk of binge eating is moderate.</p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Spacer height={'10px'} />
          <div>
            <p>
              Your genes predict you could occasionally struggle with urges to
              binge eat. Some gene variations have been linked to difficulty
              with suppression of appetite leading to binge eating.
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Moderate;
