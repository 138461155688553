import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { primary } from 'styles/theme';
import { isMobile } from 'react-device-detect';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  margin-bottom: ${isMobile ? '20px' : '0'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  width: ${isMobile ? '100%' : '400px'};
  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
  margin-bottom: 30px;
`;

interface HighProps {
  results: any;
}
const High = ({ results }: HighProps) => {
  return (
    <>
      <Row style={{ position: 'relative' }}>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/BingeEating.png"
            alt=""
            width={'60%'}
          />
        </ImageCol>
        <Col md={8}>
          <Subtitle>Risk of Binge Eating: High</Subtitle>
          <Alert>
            <p>Your risk of binge eating is high.</p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Spacer height={'10px'} />
          <div>
            <p>
              Your genes predict you are likely to binge eat. Some gene
              variations have been linked to difficulty with suppression of
              appetite leading to binge eating. Unfortunately your SNPs indicate
              a genetic susceptibility to bingeing. This may be something you
              have to work harder at than most to avoid.
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default High;
