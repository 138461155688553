import React from 'react'
import { Row } from 'react-bootstrap'

import { getColor, Serving, Image, BodyCol } from './utils'

const Two = () => {
  return (<>
    <BodyCol xs={12} md={4}>
      <Serving color={getColor(2)}>2+ servings a day</Serving>
      <p>Two servings equals 2+ cups of caffeinated tea a day.</p>
    </BodyCol>
    <BodyCol xs={12} md={5}>
      <p>&nbsp;</p>
    </BodyCol>
  </>)
}

const One = () => {
  return (<>
    <BodyCol xs={12} md={4}>
      <Serving color={getColor(1)}>1 serving a day</Serving>
      <p>One serving equals 1 cup of caffeinated tea a day.</p>
    </BodyCol>
    <BodyCol xs={12} md={5}>
      <p>&nbsp;</p>
    </BodyCol>
  </>)
}

const Zero = () => {
  return (<>
    <BodyCol xs={12} md={4}>
      <Serving color={getColor(0)}>Zero servings of caffeinated tea</Serving>
      <p>We are sorry, your genotype does not like caffeinated tea</p>
    </BodyCol>
    <BodyCol xs={12} md={5}>
      <p>Life hack. If you were recommended Earl Grey tea in the Essential Start programme, it would be preferrable that you swap to taking the Bergamet supplement instead as your genotype does not like caffeinated teas.</p>
    </BodyCol>
  </>)
}

const CaffeinatedTea = ({ serving }) => {
  return (<Row>
    <BodyCol xs={12}>
      <h4>Caffeinated Tea</h4>
      <p>Your genes have told us that you can drink the following quantities of black tea, including English Breakfast Tea, Ceylon, Darjeeling, Earl Grey:</p>
    </BodyCol>
    <BodyCol xs={12} md={3}>
      <Image src={process.env.PUBLIC_URL + '/assets/images/lifeplus/ing-tea.png'} />
    </BodyCol>
    {serving === 2 && <Two />}
    {serving === 1 && <One />}
    {serving === 0 && <Zero />}
  </Row>)
}

export default CaffeinatedTea
