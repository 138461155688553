import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

import { Spacer } from 'components/utils';
import EventForm from './components/EventForm';

const HeaderRow = styled.div`
  display: flex;
  align-items: center;

  h3 {
    margin: 0;
  }

  .btn {
    font-size: 0.9rem;
    padding: 10px 30px;
    margin-left: 20px;
  }
`;

const FormWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0;
  border: 1px solid #bbb;
  padding: 30px;
`;

const CreateEvent = () => {
  return (
    <Container>
      <FormWrapper>
        <HeaderRow>
          <h3>Create Event</h3>
        </HeaderRow>
        <Spacer height={'20px'} />
        <EventForm />
      </FormWrapper>
      <Spacer height={'20px'} />
    </Container>
  );
};

export default CreateEvent;
