import React, { useContext, useState } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { AuthContext } from 'stores/auth';
import { Spacer } from 'components/utils';
import { borderGray } from 'styles/theme';
import useDetectPrint from 'react-detect-print';

const Wrapper = styled.div`
  background-color: #ffffff;
  min-height: 200px;
  border-radius: 5px;
  border: 1px solid ${borderGray};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: ${isMobile ? '10px' : '30px'};

  @media print {
    border: none;
    margin-top: 120px;
  }
`;

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
`;

const StyledTabs = styled(Tabs)`
  border-color: ${borderGray};

  .nav-item {
    text-transform: uppercase;
    font-size: 0.8rem;
    background-color: #f2f2f2;
    margin-right: 10px;
    border: 1px solid ${borderGray};

    &.active {
      background-color: #ffffff;
    }
  }
`;

const TabWrapper = styled.div`
  .tab-content {
    padding: 20px;
    overflow: scroll;
  }
  .print-tab {
    @media print {
      max-height: auto;
    }
  }
  .no-print-tab {
    max-height: 400px;
  }
`;

const Main = () => {
  const authStore = useContext(AuthContext);
  const [key, setKey] = useState('genes');
  const printing = useDetectPrint();

  return (
    <Wrapper>
      <Row>
        <Col md={8}>
          <h2>Essentials Report</h2>
          <p>
            Kia ora {authStore.user.first_name},
            <br />
            <br />
            Your results are in and we can’t wait to share them with you! Below
            is an overview of how well your three hero systems predisposition
            your inflammatory response to function. To support your unique gene
            SNPs and coordinate your inflammatory response we provide five
            recommendations which will be the most powerful for you! Think of
            this as your personalised roadmap to wellness, these are the five
            things you should focus your energy on doing for your health as they
            will be the most impactful for you.
          </p>
        </Col>
        <ImageCol md={4}>
          <img
            src={process.env.PUBLIC_URL + '/assets/images/illustration-1.png'}
            alt="Essentials illustration"
          />
        </ImageCol>
      </Row>
      <Spacer height={'30px'} />
      <Row>
        <Col>
          <h3>Understanding Your Report</h3>
          <TabWrapper>
            <StyledTabs
              id="report-tab"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab
                eventKey="genes"
                title="Genes"
                className={`${printing ? 'print-tab' : 'no-print-tab'}`}
              >
                <h5>About Ingeneous</h5>
                <p>
                  Our body has been interacting with nature our entire existence
                  as a species. At the beginning of the relationship between our
                  genes and our environment, plants were allowed to grow in a
                  way that they came to fruition packed full of life-giving
                  nutrients and the soil was full of essential minerals. Our
                  bodies and our DNA evolved in a symbiotic relationship with
                  these elements. We evolved to depend on these ingredients to
                  operate as we were designed.
                </p>

                <p>
                  However, the world around us has evolved so much more rapidly
                  than our DNA. We are struggling to keep up. Our ancient DNA
                  has lost its best friend, those special elements only captured
                  in food when they are in key times of the life cycle. At
                  Ingeneous we believe that nutritional elements have a profound
                  beneficial ability to communicate and lend a hand to our DNA,
                  continuing to follow an age-old symbiotic relationship.
                </p>

                <p>
                  Through recent breakthroughs in science and a growing
                  appreciation and understanding of how our genetics truly
                  interact with our environment, these lost treasures are being
                  re-discovered. Science is helping us get back to our roots,
                  literally! We think the merging, or re-emerging of nature and
                  nurture working hand in hand to create the best you possible
                  is amazing, in fact, we think it’s genius.
                </p>

                <p>
                  Ingeneous is all about the journey. We understand fully that
                  to achieve optimal wellness it takes more than just one thing.
                  It takes a holistic approach. What we do for you is create a
                  roadmap for your health journey, including which actions are
                  most beneficial for you.
                </p>
              </Tab>
              {/* <Tab eventKey="data" title="Data">

            </Tab> */}
            </StyledTabs>
          </TabWrapper>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Main;
