import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Nav } from 'react-bootstrap';
import styled from 'styled-components';

import { primary } from 'styles/theme';

const Wrapper = styled.div`
  background-color: #dedede;

  .active {
    font-weight: 600;
    border-bottom: 3px solid ${primary};
  }

  .nav-item {
    padding: 0;
  }

  .nav-link {
    padding: 0.75rem 1rem;
  }
`;

const Navigation = () => {
  let location = useLocation();
  return (
    <Wrapper>
      <Container>
        <Nav activeKey={location.pathname}>
          <Nav.Item>
            <Nav.Link eventKey="/admin/users" href="/admin/users">
              Users
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="/admin/events" href="/admin/events">
              Events
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="/admin/trainings" href="/admin/trainings">
              Trainings
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="/admin/upload" href="/admin/upload">
              Upload
            </Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link eventKey="/admin/downloads" href="/admin/downloads">
              Downloads
            </Nav.Link>
          </Nav.Item> */}
        </Nav>
      </Container>
    </Wrapper>
  );
};

export default Navigation;
