import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import Icon from 'components/Icon';
import {
  TR,
  Collapse,
  CollapseBtn,
  DataWrapper,
  Score,
  getColor,
} from './TableRow';

import CircleDot from './Circle';

const PEMTBHMT = ({ geneData }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [genes, setGenes] = useState();

  useEffect(() => {
    setGenes({
      rs7946: geneData?.find((e) => 'rs7946' === e.snp),
      rs3733890: geneData?.find((e) => 'rs3733890' === e.snp),
    });
  }, [geneData]);

  if (!genes) {
    return null;
  }

  return (
    <>
      <TR>
        <Col md={11}>
          <Row>
            <Col md={2}>PEMT</Col>
            <Col md={1}>
              <CircleDot
                color={getColor(genes?.rs7946?.score)}
                tooltipColor={genes?.rs7946?.score}
              />
            </Col>
            <Col md={2}>{genes?.rs7946?.snp?.toUpperCase()}</Col>
            <Col md={7}>1500mg of Phosphatidylcholine daily in the am</Col>
          </Row>
          <Row>
            <Col md={2}>BHMT</Col>
            <Col md={1}>
              <CircleDot
                color={getColor(genes?.rs3733890?.score)}
                tooltipColor={genes?.rs3733890?.score}
              />
            </Col>
            <Col md={2}>{genes?.rs3733890?.snp?.toUpperCase()}</Col>
            <Col md={7}>&nbsp;</Col>
          </Row>
        </Col>
        <Col md={1}>
          <CollapseBtn
            variant="secondary"
            onClick={() => setCollapsed(!collapsed)}
          >
            <Icon
              name={collapsed ? 'caret-down' : 'caret-up'}
              color="#dedede"
            />
          </CollapseBtn>
        </Col>
      </TR>

      <Collapse
        style={{ display: collapsed ? 'none' : 'block' }}
        className="print-only"
      >
        <DataWrapper>
          <h4 style={{ color: '#707070' }}>PEMT & BHMT - Choline</h4>
          <p>
            <Score color={getColor(2)}>Red result for 1 or 2 RS numbers</Score>
            &nbsp;
            <strong>= 1500mg of Phosphatidylcholine daily in the am</strong>
          </p>
          <p>
            By compensating for your underactive PEMT/BHMT receptor they are
            going to help improve your foundation bridge to your inflammation
            systems. The customer should see a big boost in energy levels, and
            this should help them cope with stress
          </p>
        </DataWrapper>
      </Collapse>
    </>
  );
};

export default PEMTBHMT;
