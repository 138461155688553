import React from 'react';

const SesameParsley = () => {
  return (
    <div>
      <h5>Sesame and parsley</h5>
      <p>
        Please note that your genetic SNP testing with Ingeneous is NOT able to
        tell you if you have an allergy or intolerance to parsley or sesame. If
        you know that you have an allergy or intolerance to these foods, please
        avoid this recommendation. On the bright side, as your inflammation
        coordination improves as you follow your Essential Start programme you
        may find that existing sensitivities may no longer trigger symptoms in
        the future.
      </p>
      <p>
        Your genes may respond particularly well to these foods. Both sesame and
        parsley are particularly powerful for boosting your body’s ability to
        make its own antioxidants, which are stronger than any antioxidants that
        can come from external sources.
      </p>
      <p>
        Serving recommendation: Consume 2 tablespoons of combined sesame oil and
        parsley for 2 months for a beneficial change to your gene expression.
      </p>

      <p className="page-br">Ingeneous tips:</p>
      <ul>
        <li>
          You can make a delicious pesto by combining parsley with sesame oil
          and olive oil, pine nuts and lemon juice.
        </li>
        <li>
          You can put lots of parsley in your spaghetti bolognaise sauce or have
          parsley as your choice of “supergreen” shot added to a daily smoothie.
        </li>
        <li>
          Sesame oil is a great addition drizzled over vegetables and salads.
        </li>
      </ul>
    </div>
  );
};

export default SesameParsley;
