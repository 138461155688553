import React, { useState, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import styled from 'styled-components';
import QrReader from 'react-qr-reader';
import { isMobile } from 'react-device-detect';

import { assignSampleId } from 'api/core';
import { Spacer } from 'components/utils';
import { Page, PageHeader, PageTitle, PageBody } from 'components/Page';
import { AuthContext } from 'stores/auth';

const Wrapper = styled.div`
  padding: ${isMobile ? '10px 0' : '50px 20%'};
`;

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
`;

const StepCol = styled(Col)`
  h4 {
    font-weight: 600;
    color: #646464;
  }

  p {
    font-size: 1rem;
  }
`;

const QRWrapper = styled.div`
  margin-bottom: 15px;
`;

const ScanKit = () => {
  let history = useHistory();
  const authStore = useContext(AuthContext);
  const { register, handleSubmit, setValue, getValues, errors, setError } =
    useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    await assignSampleId(data)
      .then(() => {
        authStore.fetchUser();
        history.push('/register-kit/swab');
      })
      .catch(() => {
        setError('sampleId', {
          type: 'manual',
          message: 'Invalid Sample ID',
        });
      });
    setLoading(false);
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleScan = (data) => {
    if (data) {
      if (getValues('sampleId')) {
        setValue('confirmSampleId', data);
      } else {
        setValue('sampleId', data);
      }
    }
  };

  if (authStore.user.sample_id) {
    return (
      <Page>
        <PageHeader>
          {!isMobile && <Spacer height={'30px'} />}
          <Container>
            <PageTitle small>Register Kit</PageTitle>
          </Container>
        </PageHeader>
        <PageBody>
          <Container>
            <Wrapper>
              {!isMobile && <Spacer height={'30px'} />}
              <p>
                We are processing your reports. Please go to the{' '}
                <Link to="/dashboard">Dashboard</Link> to check the status of
                your report.
              </p>
            </Wrapper>
          </Container>
        </PageBody>
      </Page>
    );
  } else {
    return (
      <Page>
        <PageHeader>
          {!isMobile && <Spacer height={'30px'} />}
          <Container>
            <PageTitle small>Register Kit</PageTitle>
          </Container>
        </PageHeader>
        <PageBody>
          <Container>
            <Wrapper>
              <Row>
                {!isMobile && (
                  <ImageCol md={3}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/images/illustration-1.png'
                      }
                      alt=""
                    />
                  </ImageCol>
                )}
                <StepCol md={isMobile ? 12 : 9}>
                  <Form
                    className="form form-light"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    {!isMobile && <Spacer height={'30px'} />}
                    <h3>Your journey starts here</h3>
                    <Spacer height={isMobile ? '10px' : '20px'} />
                    <h4>Step 1 - Scan Your Kit</h4>
                    <p>Hold the square QR code to your computer's camera.</p>
                    <QRWrapper>
                      <QrReader
                        delay={1000}
                        onError={handleError}
                        onScan={handleScan}
                      />
                    </QRWrapper>
                    <p>Or enter the Sample Kit ID code here</p>
                    <Form.Row>
                      <Form.Group as={Col} controlId="sampleId">
                        <Form.Label>Sample Kit ID</Form.Label>
                        <Form.Control
                          name="sampleId"
                          type="text"
                          ref={register({ required: true })}
                          isInvalid={!!errors.sampleId}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="confirmSampleId">
                        <Form.Label>Confirm Sample Kit ID</Form.Label>
                        <Form.Control
                          name="confirmSampleId"
                          type="text"
                          ref={register({ required: true })}
                          isInvalid={!!errors.confirmSampleId}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Button variant="primary" type="submit" disabled={loading}>
                      {loading ? 'Saving...' : 'Submit'}
                    </Button>
                  </Form>
                </StepCol>
              </Row>
            </Wrapper>
          </Container>
        </PageBody>
      </Page>
    );
  }
};

export default ScanKit;
