import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Wrapper = styled.span`
  font-size: ${(props) => props.size || 'inherit'};
`

function Icon ({ name, size, regular, brand, spaceAfter, spaceBefore, dark, klassName, color }) {
  const _id = _.uniqueId('icon__')
  let iconClassName = ['icon', 'ion-icon']
  let prefix = null

  if (klassName) iconClassName.push(klassName)
  if (spaceAfter) iconClassName.push('space-after')
  if (spaceBefore) iconClassName.push('space-before')
  if (dark) iconClassName.push('dark')

  if (regular) {
    prefix = 'far'
  }

  if (brand) {
    prefix = 'fab'
  }

  const icon = prefix ? [prefix, name] : name

  return (<Wrapper size={size}>
    <FontAwesomeIcon
      className={iconClassName.join(' ')}
      id={_id}
      icon={icon}
      color={color || 'white'}
    />
  </Wrapper>)
}

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  src: PropTypes.string,
  klassName: PropTypes.string,
  regular: PropTypes.bool,
  spaceAfter: PropTypes.bool,
  spaceBefore: PropTypes.bool,
  dark: PropTypes.bool,
  tooltip: PropTypes.string
}

export default Icon;
