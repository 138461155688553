import React, { useState } from 'react';
import styled from 'styled-components';
import { gray } from 'styles/theme';

import ReadMore from '../View';
import ReadLess from '../ViewLess';

import Naturally from './Naturally';
import Neutral from './Neutral';
import NotAthletic from './NotAthletic';

interface AthleticProps {
  results: any;
}
const Alert = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const WrapperRef = styled.div`
  margin-top: 20px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const List = styled.li`
  padding-left: 15px;
  font-weight: 300;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    font-size: 14px;
    word-break: break-all;
  }
`;

const SubtitleGray = styled.h3`
  color: ${gray};
  margin-top: 10px;
`;
const WrapperResult = styled.div`
  position: relative;
`;
const Athletic = ({ results }: AthleticProps) => {
  const [showReferences, setShowReferences] = useState(false);

  let athleticismType;
  if (results.athleticism === 'naturally gifted athlete') {
    athleticismType = <Naturally results={results.athleticism} />;
  } else if (results.athleticism === 'moderately athletic') {
    athleticismType = <Neutral results={results.athleticism} />;
  } else {
    athleticismType = <NotAthletic results={results.athleticism} />;
  }

  return (
    <WrapperResult>
      <SubtitleGray>Athleticism</SubtitleGray>
      {athleticismType}

      <Alert className="no-print">
        <span>References</span>
        {!showReferences && (
          <ReadMore value={showReferences} setValue={setShowReferences} />
        )}
        {showReferences && (
          <ReadLess value={showReferences} setValue={setShowReferences} />
        )}
        {showReferences && (
          <WrapperRef>
            <ListGroup>
              <List>
                <a
                  href="https://medlineplus.gov/genetics/understanding/traits/athleticperformance/#:~:text=The%20best%2D
                  studied%20genes%20associated,linked%20to%20strength%20and%20endurance.&text=Some%20
                  people%20have%20this%20variant,is%20referred%20to%20as%20577XX."
                >
                  https://medlineplus.gov/genetics/understanding/traits/athleticperformance/#:~:text=The%20best%2D
                  studied%20genes%20associated,linked%20to%20strength%20and%20endurance.&text=Some%20
                  people%20have%20this%20variant,is%20referred%20to%20as%20577XX.
                </a>
              </List>
            </ListGroup>
          </WrapperRef>
        )}
      </Alert>
      <hr className="no-print" />
    </WrapperResult>
  );
};

export default Athletic;
