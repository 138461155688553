import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { gray, primary } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import View from '../View';
import Close from '../ViewLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const AlertRef = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
  margin-bottom: 30px;
`;

const SubtitleGray = styled.h5`
  color: ${gray};
  margin-top: 10px;
`;

const WrapperRef = styled.div`
  margin-top: 5px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const ListRef = styled.li`
  font-weight: 300;
  padding-left: 20px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    word-break: break-all;
  }
`;

interface CytokineProps {
  results: any;
}
const Cytokine = ({ results }: CytokineProps) => {
  const [show, setShow] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/Supplement.png"
            alt="supplement"
            width={'60%'}
          />
        </ImageCol>
        <Col md={8}>
          <SubtitleGray>
            Your most powerful supplement for weight loss is
          </SubtitleGray>
          <Subtitle>Cytokine Suppress</Subtitle>
          <Alert>
            <p>
              Suggested Supplement: Cytokine Suppress with EGCG, Life Extension
              (1 capsule daily)
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <p>
                Taking Cytokine Suppress with EGCG will help decrease your
                inflammation which plays a significant role in your particular
                weight loss story.
              </p>
              <p>
                Cytokines are molecules that act as chemical messengers during
                an inflammatory response. Decreasing the level of cytokines can
                help ensure your body is not overreacting and responding to
                small threats with a large inflammatory response which can
                result in chronic inflammation (1). The primary active
                ingredients are flavonoids from mung beans and
                epigallocatechin-3-gallate (EGCG) from green tea which help to
                activate your endogenous antioxidants (2). Endogenous
                antioxidants are produced by your body and much more powerful
                than antioxidants from food. These antioxidants help to balance
                your inflammatory response. You are someone who needs extra
                support with decreasing your inflammation because of your gene
                SNPs. Decreasing this inflammation should assist you in your
                weight loss journey. Mung beans have also been shown to decrease
                blood sugar spike and increase satiety(3,4)!
              </p>
              <p style={{ fontWeight: 'bold' }}>
                This supplement is best for your genes. If you have any concerns
                this may not suit your current state of health or medications
                check with your doctor.
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
      <Row className="no-print">
        <Col>
          <AlertRef>
            <span>References</span>
            {!showReferences && (
              <View value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <Close value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <WrapperRef>
                <ListGroup>
                  <ListRef>
                    Cerami A. Inflammatory cytokines. Clinical immunology and
                    immunopathology. 1992 Jan 1;62(1):S3-10. &nbsp;
                    <a href="https://www.sciencedirect.com/science/article/abs/pii/009012299290035M">
                      https://www.sciencedirect.com/science/article/abs/pii/009012299290035M
                    </a>
                  </ListRef>
                  <ListRef>
                    <a
                      href="https://nz.iherb.com/pr/life-extension-cytokine-suppress-with-egcg-30-vegetarian-capsules/55653?gclid=CjwKCAiA1aiMBhAU
                      EiwACw25Mb_eAKUT5GaFl8rChLT2IqROQlRKIevYTW11OanCrcpKt4dhbvdhRRoCi6oQAvD_BwE&gclsrc=aw.ds"
                      style={{ fontSize: 14 }}
                    >
                      https://nz.iherb.com/pr/life-extension-cytokine-suppress-with-egcg-30-vegetarian-capsules/55653?gclid=CjwKCAiA1aiMBhAU
                      EiwACw25Mb_eAKUT5GaFl8rChLT2IqROQlRKIevYTW11OanCrcpKt4dhbvdhRRoCi6oQAvD_BwE&gclsrc=aw.ds
                    </a>
                  </ListRef>
                  <ListRef>
                    Hou D, Zhao Q, Yousaf L, Xue Y, Shen Q. Whole mung bean
                    (Vigna radiata L.) supplementation prevents high-fat
                    diet-induced obesity and disorders in a lipid profile and
                    modulates gut microbiota in mice. European journal of
                    nutrition. 2020 Feb 11:1-8.&nbsp;
                  </ListRef>
                  <ListRef>
                    <a href="https://www.lifeextension.com/magazine/2014/1/the-mighty-mung-bean">
                      https://www.lifeextension.com/magazine/2014/1/the-mighty-mung-bean
                    </a>
                  </ListRef>
                </ListGroup>
              </WrapperRef>
            )}
          </AlertRef>
        </Col>
      </Row>
    </>
  );
};

export default Cytokine;
