/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { addHours, formatISO } from 'date-fns';
import styled from 'styled-components';

import { AuthContext } from 'stores/auth';
import { saveEvent } from 'api/admin';
import Discounts from './Discounts';
import EventAddress from './EventAddress';

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    /* height: calc(1.5em + 0.75rem + 2px); */
  }

  .react-datepicker__input-container {
    /* height: 100%; */
  }
`;

const CustomDatePicker = styled(DatePicker)`
  height: calc(1.5em + 0.75rem + 2px);
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  color: #495057;
`;

const EventForm = () => {
  // eslint-disable-next-line
  const authStore = useContext(AuthContext);
  let history = useHistory();
  const formProps = useForm();
  const {
    register,
    handleSubmit,
    setValue,
    // eslint-disable-next-line
    getValues,
    errors,
    // eslint-disable-next-line
    setError,
    // eslint-disable-next-line
    clearErrors,
  } = formProps;
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    console.log(data);
    await saveEvent(data)
      .then((res) => {
        history.push('/admin/events/review/' + res.data?.event?.pk);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addHours(new Date(), 2));

  const filterPassedTime = (time) => {
    const selectedDate = new Date(time);

    return startDate.getTime() < selectedDate.getTime();
  };

  const handleDateRange = (start, end) => {
    setStartDate(start);
    setEndDate(end);

    if (start < end) {
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(start);
      setEndDate(addHours(start, 2));
    }
  };

  useEffect(() => {
    setValue('start_date', formatISO(startDate));
    setValue('end_date', formatISO(endDate));
  }, [startDate, endDate]);

  return (
    <Form className="form form-light" onSubmit={handleSubmit(onSubmit)}>
      <Form.Row>
        <Form.Group as={Col} controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            name="name"
            type="text"
            ref={register({ required: true })}
            isInvalid={!!errors.name}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="startDate">
          <Form.Label>Start Date & Time</Form.Label>
          <Form.Control
            name="start_date"
            type="hidden"
            ref={register({ required: true })}
            isInvalid={!!errors.start_date}
          />
          <DatePickerWrapper>
            <CustomDatePicker
              selected={startDate}
              onChange={(inputDate) => handleDateRange(inputDate, endDate)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              timeInputLabel="Time:"
              showTimeInput
              filterTime={filterPassedTime}
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          </DatePickerWrapper>
          {errors.short_url && (
            <Form.Control.Feedback type="invalid">
              {errors.short_url.type === 'required' && 'This field is required'}
              {errors.short_url.type === 'manual' && errors.short_url.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="startDate">
          <Form.Label>Start Date & Time</Form.Label>
          <Form.Control
            name="start_date"
            type="hidden"
            ref={register({ required: true })}
            isInvalid={!!errors.start_date}
          />
          <DatePickerWrapper>
            <CustomDatePicker
              selected={startDate}
              onChange={(inputDate) => handleDateRange(inputDate, endDate)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              timeInputLabel="Time:"
              showTimeInput
              filterTime={filterPassedTime}
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          </DatePickerWrapper>
        </Form.Group>
        <Form.Group as={Col} controlId="endDate">
          <Form.Label>End Date & Time</Form.Label>
          <Form.Control
            name="end_date"
            type="hidden"
            ref={register({ required: true })}
            isInvalid={!!errors.end_date}
          />
          <DatePickerWrapper>
            <CustomDatePicker
              selected={endDate}
              onChange={(inputDate) => handleDateRange(startDate, inputDate)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              timeInputLabel="Time:"
              showTimeInput
              filterTime={filterPassedTime}
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          </DatePickerWrapper>
        </Form.Group>
      </Form.Row>

      <EventAddress formProps={formProps} />
      <Discounts formProps={formProps} />

      <Row>
        <Col>
          <Button variant="primary" type="submit" disabled={loading}>
            {!loading ? (
              'Save'
            ) : (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default EventForm;
