import React, { useState } from "react";
import styled from 'styled-components';
import { isBrowser } from 'react-device-detect';
import { isMobile } from 'react-device-detect';
import { Spacer } from 'components/utils';
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";


const Wrapper = styled.div`
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  padding-bottom: ${isBrowser ? '200px' : '50px'};
  background-color: #19a88b;
  height:100%;
  position:absolute;
  width:100%
`;

const LogoWrapper = styled.div`
  padding: ${isBrowser ? '30px 15%' : '15px'};
`;

const Logo = styled.img`
  max-height: 50px;
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  // display: none;

  img {
    z-index: 0;
  }

  @media only screen and (min-width: 768px) {
    display: block;
  }
`;

const ImageWrapper2 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  // display: none;

  img {
    z-index: 0;
  }

  @media only screen and (min-width: 768px) {
    display: block;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  color:white;
  p,
  label {
    color: #ffffff !important;
  }
`;

const Title = styled.h3`
  color:white;
`

function SuccessPracRegister(){
    return (
        <Wrapper>
            <LogoWrapper>
            {isMobile ? (
                <Logo
                src={
                    process.env.PUBLIC_URL + '/assets/images/ingeneous_white.png'
                }
                alt="logo"
                width={150}
                />
            ) : (
                <Logo
                src={
                    process.env.PUBLIC_URL + '/assets/images/ingeneous_white.png'
                }
                alt="logo"
                />
            )}
            </LogoWrapper>
            {isMobile && <Spacer height={'40px'} />}
            {isMobile ? (
            <ImageWrapper>
                <img
                src={
                    process.env.PUBLIC_URL +
                    '/assets/images/landing/landing-branding-2-mobile.png'
                }
                alt="branding-2"
                width={150}
                />
            </ImageWrapper>
            ) : (
            <ImageWrapper>
                <img
                src={
                    process.env.PUBLIC_URL + '/assets/images/landing-branding-2.png'
                }
                alt="branding-2"
                />
            </ImageWrapper>
            )}
            {isMobile ? (
            <ImageWrapper2>
                <img
                src={
                    process.env.PUBLIC_URL +
                    '/assets/images/landing/landing-branding-1-mobile.png'
                }
                alt="landing-1"
                width={130}
                />
            </ImageWrapper2>
            ) : (
            <ImageWrapper2>
                <img
                src={
                    process.env.PUBLIC_URL + '/assets/images/landing-branding-1.png'
                }
                alt="landing-1"
                />
            </ImageWrapper2>
            )}
            <ContentWrapper>
              <Container>
                <Row>
                  <Title>Registration Succesful</Title>
                </Row>
                <Row>
                  <p>Your application will need to be reviewed. It might take a while</p>
                </Row>
                <Row>
                  <Link to="/consult/login">
                    <Button variant="secondary">Go to Login</Button>
                  </Link>
                </Row>
              </Container>
            </ContentWrapper>
        </Wrapper>
    )
}

export default SuccessPracRegister