import React from 'react';

import HotCold from './HotAndCold';
import Hot from './Hot';
import Cold from './Cold';
import styled from 'styled-components';

interface TemperatureProps {
  results: any;
}
const WrapperResult = styled.div`
  position: relative;
`;
const Temperature = ({ results }: TemperatureProps) => {
  let temperatureType;
  if (results.temperature === 'cold') {
    temperatureType = <Cold results={results.temperature} />;
  } else if (results.temperature === 'hot') {
    temperatureType = <Hot results={results.temperature} />;
  } else {
    temperatureType = <HotCold results={results.temperature} />;
  }

  return (
    <>
      <WrapperResult className="temperature">
        <h3>Temperature</h3>
        {temperatureType}

        <hr className="no-print" />
      </WrapperResult>
    </>
  );
};

export default Temperature;
