import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { Spacer } from 'components/utils';
import { getColor, Serving, BodyCol } from './utils';

const ImageCol = styled(Col)`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center center;
`;

const AlcoholConsumption = ({
  gender,
  alcohol_quantity,
  alcohol_warning,
  alcohol_reduce_inflam,
  alcohol_a2a,
  alcohol_e2a,
}) => {
  return (
    <>
      <Row>
        <BodyCol xs={12}>
          <h3>Alcohol Consumption</h3>
          <p>
            The following recommendations outline how you can have a healthy
            relationship with alcohol to avoid flaring up inflammation.
          </p>
          <p>
            Your genes tell us that you can tolerate the following amounts of
            alcohol:
          </p>
          <Spacer height={'10px'} />
          <p>
            <strong>
              One standard drink contains 10 grams of pure alcohol. This is
              equivalent to a 330ml can of beer at 4% alcohol, a 100ml glass of
              wine at 12.5% acohol or 30ml of straight spirits at 42% alcohol.
            </strong>
          </p>
          <Spacer height={'10px'} />
        </BodyCol>
      </Row>
      <Row className='page-br'>
        <BodyCol xs={12} md={8}>
          {gender === 'male' && alcohol_quantity === 0 && (
            <>
              <Serving color={getColor(alcohol_quantity)}>
                Alcohol tolerance per day: Zero Alcohol
              </Serving>
              <p>
                You have a gene SNP coding for a receptor that significantly
                struggles to process alcohol, creating an inflammatory imbalance
                and free radicals in the wake of consumption - thus alcohol
                ought to be avoided. Alcohol can reduce cellular function and
                impair your cells ability to detoxify causing additional
                inflammation. This can in turn affect your mood, mental health
                and energy levels which again is first sign for identifying
                cells that are not healthy.
              </p>
            </>
          )}

          {gender === 'male' && alcohol_quantity === 1 && (
            <>
              <Serving color={getColor(alcohol_quantity)}>
                Alcohol tolerance per day: 1 standard serving of alcohol per day
              </Serving>
              <p>
                You have some ability to metabolise (munch up) alcohol when it
                enters your system, but it does exert a negative impact during
                that short time that it is active, so alcohol consumption should
                be minimised.
              </p>
            </>
          )}

          {gender === 'male' && alcohol_quantity === 2 && (
            <>
              <Serving color={getColor(alcohol_quantity)}>
                Alcohol tolerance per day: 2 standard servings of alcohol per
                day
              </Serving>
              <p>
                You have sufficient ability to metabolise (munch up) alcohol
                quickly when it enters your system, but it does exert a negative
                impact during that short time that it is active, so alcohol
                consumption should be minimised. Please try to not exceed the
                weekly maximum recommendation of 14 standard servings for males.
              </p>
            </>
          )}

          {gender === 'female' && alcohol_quantity === 0 && (
            <>
              <Serving color={getColor(alcohol_quantity)}>
                Alcohol tolerance per day: Zero Alcohol
              </Serving>
              <p>
                You have a gene SNP coding for a receptor that significantly
                struggles to process alcohol, creating an inflammatory imbalance
                and free radicals in the wake of consumption - thus alcohol
                ought to be avoided. Alcohol can reduce cellular function and
                impair your cells ability to detoxify causing additional
                inflammation. This can in turn affect your mood, mental health
                and energy levels which again is first sign for identifying
                cells that are not healthy.
              </p>
            </>
          )}

          {gender === 'female' && alcohol_quantity === 1 && (
            <>
              <Serving color={getColor(alcohol_quantity)}>
                Alcohol tolerance per day: 1 standard serving of alcohol per day
              </Serving>
              <p>
                You have some ability to metabolise (munch up) alcohol when it
                enters your system, but it does exert a negative impact during
                that short time that it is active, so alcohol consumption should
                be minimised. Please try to not exceed the weekly maximum
                recommendation of 7 standard servings for females.
              </p>
            </>
          )}

          {gender === 'female' && alcohol_quantity === 2 && (
            <>
              <Serving color={getColor(alcohol_quantity)}>
                Alcohol tolerance per day: 2 standard servings of alcohol per
                day
              </Serving>
              <p>
                You have sufficient ability to metabolise (munch up) alcohol
                quickly when it enters your system, but it does exert a negative
                impact during that short time that it is active, so alcohol
                consumption should be minimised. Please try to not exceed the
                weekly maximum recommendation of 7 standard servings for
                females.
              </p>
            </>
          )}

          {alcohol_warning && (
            <>
              <Spacer height={'10px'} />
              <p>
                Consumption of more than 4 standard drinks in any single sitting
                will create an inflammatory cascade that throws inflammation
                into imbalance for an average of 4 days afterwards, making your
                body pay a heavy price.
              </p>
            </>
          )}

          {alcohol_reduce_inflam && (
            <>
              <Spacer height={'10px'} />
              <Serving color={getColor(2)}>
                Your best alcoholic option is: <strong>Red Wine</strong>
              </Serving>
              <p>
                One small 100ml glass per day can potentially have
                anti-inflammatory properties and thus may lend to improving
                inflammation coordination.
              </p>
            </>
          )}

          <Spacer height={'10px'} />

          {alcohol_e2a === 'slow' && (
            <>
              <Serving color={getColor(0)}>
                Your body processes alcohol slowly which means your level of
                inebriation is prolonged.
              </Serving>
            </>
          )}

          {alcohol_e2a === 'moderate' && (
            <>
              <Serving color={getColor(1)}>
                Your body processes alcohol at a modest speed, which means your
                level of inebriation is slightly prolonged.
              </Serving>
            </>
          )}

          {alcohol_e2a === 'fast' && (
            <>
              <Serving color={getColor(2)}>
                Your body processes alcohol quickly which means your level of
                inebriation is shortened.
              </Serving>
            </>
          )}

          <Spacer height={'10px'} />

          {alcohol_a2a === 'slow' && (
            <>
              <Serving color={getColor(0)}>
                Your body experiences a high degree of toxic accumulation –
                resulting in a more significant hangover than others.
              </Serving>
            </>
          )}

          {alcohol_a2a === 'moderate' && (
            <>
              <Serving color={getColor(1)}>
                Your body experiences a modest degree of toxic accumulation –
                resulting in a slightly more significant hangover than others.
              </Serving>
            </>
          )}

          {alcohol_a2a === 'fast' && (
            <>
              <Serving color={getColor(2)}>
                Your body experiences a lower degree of toxic accumulation –
                resulting in a less significant hangover than others.
              </Serving>
            </>
          )}
        </BodyCol>
        {!isMobile && (
          <ImageCol
            md={4}
            src={process.env.PUBLIC_URL + '/assets/images/lifeplus/ing-alc.png'}
          />
        )}
      </Row>
    </>
  );
};

export default AlcoholConsumption;
