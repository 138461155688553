/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';

import { Spacer } from 'components/utils';
import { alert, heroGreen } from 'styles/theme';
import { getColor, Serving, BodyCol } from './utils';

const Alert = styled.div`
  background-color: #f2f2f2;
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px solid ${alert};

  span {
    color: ${alert};
    text-transform: uppercase;
    font-size: 0.8rem;
  }
`;

const ExerciseChart = styled.div`
  display: flex;
  align-items: top;
`;

const ImageCol = styled.div`
  padding-top: 30px;
  max-width: 50px;
  text-align: center;
  span {
    display: inline-block;
    font-size: 0.8rem;
  }
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
`;

const RangeWrapper = styled.div`
  flex: 1;
  text-align: center;
`;

const RangeTitle = styled.div`
  font-size: 1.1rem;
  color: ${heroGreen};
`;

const RangeSplit = styled.div`
  font-size: 1.1rem;
  color: ${heroGreen};
`;

const BarWrapper = styled.div``;
const Bar = styled.div`
  background-color: ${(props) => props.color};
  width: ${(props) => props.size}%;
  height: 20px;
  display: inline-block;
  border-radius: 5px;
`;

const BarLabel = styled.span`
  color: ${(props) => props.color};
`;

const Exercise = ({ results }) => {
  // const [aerobic, setAerobic] = useState([0]);
  // const [endurance, setEndurance] = useState([0]);

  // useEffect(() => {
  //   setAerobic([(1 - results.resistance_aerobic) * 100]);
  //   setEndurance([(1 - results.burst_endurance) * 100]);
  // }, []);

  return (
    <>
      <Row>
        <BodyCol xs={12}>
          <h3>How To Exercise</h3>
          <Alert>
            <span>Please Note</span>
            <br />
            <p>
              We recommend consulting with your health practitioner before
              implementing the following recommendations if you have a medical
              condition or are taking any prescribed medications.
            </p>
            <p>
              If you aren’t currently exercising regularly, please take the time
              you need to build up to the following parameters. During this time
              you want to be able to maintain a conversation at all times when
              exercising, please use your body as a guide.
            </p>
          </Alert>
          <Spacer height={'10px'} />

          <strong>Exercise in a way that maintains your inflammation</strong>
          <p>
            To maintain balanced inflammation this Lifestyle Plus analysis of
            your genotype is an opportunity to understand your relationship with
            exercise.
            <br />
            <br />
            Exercising regularly is an important part of being healthy and has
            wide ranging physical, emotional and social health benefits.
            <br />
            <br />
            Not exercising at all can contribute to inflammation building up
            over time, however, over exercising can also be equally problematic
            and can result in acute releases of inflammation via cytokine cells
            that live in stomach fat. When they are released and you are
            actively inflamed, these exercise released inflammatory cells can
            act like lighter fluid on a flame, they can make the problem worse.
            Our opportunity is to find your perfect balance of exercise both in
            frequency and nature as this is a very powerful way to maintain your
            anti-inflammatory gains.
            <br />
            <br />
            To maintain the gains you’ve made in your inflammatory reset let’s
            now learn about your exercise boundaries and discover your
            definition of healthy exercise.
          </p>
          <Spacer height={'40px'} />
        </BodyCol>
      </Row>

      <Row className="page-br">
        <BodyCol xs={12}>
          <h4>Type of Exercise</h4>
        </BodyCol>
        <BodyCol xs={12} md={6}>
          <strong>Resistance or Aerobic?</strong>
          <p>
            There are two main types of exercise: Resistance exercise (builds
            strength and muscle) and Cardiovascular exercise (any exercise that
            raises your heart rate for the workout). Each has an important
            function but your body may favour one over the other.
          </p>
          <Serving color={getColor(2)}>
            You should aim for {Math.round(results.resistance_aerobic * 100)}%
            of resistance exercise and{' '}
            {Math.round((1 - results.resistance_aerobic) * 100)}% of
            cardiovascular exercise.
          </Serving>
        </BodyCol>
        <BodyCol xs={12} md={6}>
          <ExerciseChart>
            <ImageCol>
              <Image
                src={
                  process.env.PUBLIC_URL +
                  '/assets/images/lifeplus/ing-resistance.png'
                }
              />
              <BarLabel color={'#4071a5'}>Resistance</BarLabel>
            </ImageCol>
            <RangeWrapper>
              <RangeTitle>Your Split</RangeTitle>
              <RangeSplit>
                {Math.round(results.resistance_aerobic * 100)} /{' '}
                {Math.round((1 - results.resistance_aerobic) * 100)}
              </RangeSplit>
              <BarWrapper>
                <Bar
                  size={Math.round(results.resistance_aerobic * 100)}
                  color={'#4071a5'}
                />
                <Bar
                  size={Math.round((1 - results.resistance_aerobic) * 100)}
                  color={'#662aa3'}
                />
              </BarWrapper>
            </RangeWrapper>
            <ImageCol>
              <Image
                src={
                  process.env.PUBLIC_URL +
                  '/assets/images/lifeplus/ing-aerobic.png'
                }
              />
              <BarLabel color={'#662aa3'}>Cardio</BarLabel>
            </ImageCol>
          </ExerciseChart>
        </BodyCol>
      </Row>

      <Spacer height={'50px'} />

      <Row>
        <BodyCol xs={12}>
          <h4>Style of Exercise</h4>
        </BodyCol>
        <BodyCol xs={12} md={6}>
          <strong>Burst or Endurance?</strong>
          <p>
            The way you get your heart pumping and the way you build muscle may
            both benefit by a certain style of training.
            <br />
            <br />
            If you suit a burst style training you benefit from exerting a
            variety of intensity with periods of rest over intervals. Your
            cardio is better suited to sprinting or HIIT training for example
            and your weight sessions could include heavier weights for less
            repetitions.
            <br />
            <br />
            If you suit endurance style training a slow build to a steady
            workout is the name of the game. Your cardio is likely better suited
            to long distance running and your weight sessions could include
            lifting less weight but increasing the number of repetitions.
          </p>
          <Serving color={getColor(2)}>
            You should aim for {Math.round(results.burst_endurance * 100)}% of
            burst exercise and {Math.round((1 - results.burst_endurance) * 100)}
            % of endurance exercise.
          </Serving>
        </BodyCol>
        <BodyCol xs={12} md={6}>
          <ExerciseChart>
            <ImageCol>
              <Image
                src={
                  process.env.PUBLIC_URL +
                  '/assets/images/lifeplus/ing-burst.png'
                }
              />
              <BarLabel color={'#4071a5'}>Burst</BarLabel>
            </ImageCol>
            <RangeWrapper>
              <RangeTitle>Your Split</RangeTitle>
              <RangeSplit>
                {Math.round(results.burst_endurance * 100)} /{' '}
                {Math.round((1 - results.burst_endurance) * 100)}
              </RangeSplit>
              <BarWrapper>
                <Bar
                  size={Math.round(results.burst_endurance * 100)}
                  color={'#4071a5'}
                />
                <Bar
                  size={Math.round((1 - results.burst_endurance) * 100)}
                  color={'#662aa3'}
                />
              </BarWrapper>
            </RangeWrapper>
            <ImageCol>
              <Image
                src={
                  process.env.PUBLIC_URL +
                  '/assets/images/lifeplus/ing-enduro.png'
                }
              />
              <BarLabel color={'#662aa3'}>Endurance</BarLabel>
            </ImageCol>
          </ExerciseChart>
        </BodyCol>
      </Row>

      <Spacer height={'50px'} />

      <Row>
        <BodyCol xs={12}>
          <h4>Your Cardio Workout</h4>
        </BodyCol>
        <BodyCol xs={12} md={12}>
          <p>
            This focuses on turning up the dial of intensity either extending
            the limits for how hard you push yourself during a burst or what
            level you should aim to maintain for your steady section of your
            cardio workouts.
          </p>

          {results.aerobic_exercise === 'mild' && (
            <>
              <Serving color={getColor(2)}>
                For burst training you can use walking as a baseline and a jog
                as your burst. For endurance training aim to keep your core
                walk/jog at a mild intensity 40-50% effort. If you are a fitness
                buff you can use 40-50% Vo2 max as a more specific guide. This
                workout should last for {results.aerobic_exercise_mins} mins.
              </Serving>
            </>
          )}

          {results.aerobic_exercise === 'moderate' && (
            <>
              <Serving color={getColor(2)}>
                For burst training you can use light jogging as a baseline and a
                moderate sprint as your burst. For endurance training aim to
                keep your core jog at a moderate intensity of 60% effort. If you
                are a fitness buff you can use 60% Vo2 max as a more specific
                guide. This workout should last for{' '}
                {results.aerobic_exercise_mins} mins.
              </Serving>
            </>
          )}

          {results.aerobic_exercise === 'high' && (
            <>
              <Serving color={getColor(2)}>
                For burst training you can use jogging as a baseline and an
                intense sprint as your burst. For endurance training aim to keep
                your core run a moderately intense level, around 70% effort. If
                you are a fitness buff you can use 70% Vo2 max as a more
                specific guide. This workout should last for{' '}
                {results.aerobic_exercise_mins} mins.
              </Serving>
            </>
          )}
        </BodyCol>
      </Row>

      <Spacer height={'50px'} />

      {/* <Row>
      <BodyCol xs={12}>
        <h4>Recovery days x per week</h4>
      </BodyCol>
      <BodyCol xs={12} md={12}>
        <p>
          You are someone who would benefit from a rest day after a moderate or intense exercise session. There is clinical evidence base that suggests that exercising less can result in greater strength or endurance. In essence, you may get more from doing less.
          <br /><br />
          If for you exercise is a habit and a pattern you want to continue to maintain (we get it!) then on your ‘rest days’, an option is to go for a nice walk, slow jog or cycle.
          <br /><br />
          Of note, if you are an athlete or training for specific events, we recommend talking to one of our consultants to understand how you can protect yourself while continuing to exercise at more constant levels than your genetics has told us with an objective of protecting yourself long term.
          <br /><br />
          Life Hack. If you would like to be active on what we’re suggesting should be a recovery day – an option for you is to go for a nice walk, slow jog or recreational cycle. In essence, this should be a light session, with a goal of keeping your heart rate low.
        </p>
      </BodyCol>
    </Row>

    <Spacer height={'50px'} />*/}

      <Row className="page-br">
        <BodyCol xs={12}>
          <h4>Recovery</h4>
        </BodyCol>
        <BodyCol xs={12} md={12}>
          {results.recovery === 'high' && (
            <>
              <Serving color={getColor(0)}>
                Your risk of inflammatory based injury is high.
              </Serving>
              <br />
              <p>
                Be aware of pushing yourself too hard too often or pushing
                yourself infrequently too hard. Your genetics indicate that
                excessive exercise behaviours will challenge your inflammatory
                system - causing oxidative stress and cellular damage, putting
                you at increased risk of inflammatory injury.
                <br />
                <br />
                You are someone who would benefit from a rest day after a
                moderate or intense exercise session. There is clinical evidence
                that suggests that exercising less can result in greater
                strength or endurance. In essence, you may get more from doing
                less.
                <br />
                <br />
                Engaging in exercise levels that exceed these recommendations is
                unhealthy for your body as it will fire up inflammation and put
                your body at risk of injury. We understand that this may be a
                significant change in mindset for you, especially if you love
                exercising. But the old adage says, too much of a good thing can
                be less optimum. If you are exceeding these amounts, we
                recommend you reduce where possible to fall within the healthy
                limits recommended for you.
                <br />
                <br />
                We need to protect our bodies from daily wear and tear and
                fatigue. You may be outside your body’s comfort level if
                exercising at intense and for extended durations – so managing
                your inflammation is important.
                <br />
                <br />
                If you are committed to participating in sport or don’t wish to
                cut back, an option is to speak with an Ingeneous practitioner
                to look at how you can protect yourself by possibly adjusting
                your supplement recommendations from the Essential Start report,
                and how you can work to protect your body during times of high
                exercise.
              </p>
            </>
          )}

          {results.recovery === 'medium' && (
            <>
              <Serving color={getColor(1)}>
                Your risk of inflammatory based injury is medium.
              </Serving>
              <br />
              <p>
                Caution and balance is needed. Your genetics indicate your
                inflammatory system could be challenged without a well-paced
                weekly regime incorporating recovery days.
                <br />
                <br />
                Engaging in exercise levels that exceed these recommendations is
                less optimal for your body as it may increase inflammation and
                put your body at risk of injury. If you are exceeding these
                amounts, we recommend you reduce where possible to fall within
                the healthy limits recommended for you.
                <br />
                <br />
                We need to protect our bodies from daily wear and tear and
                fatigue. You may be outside your body’s comfort level if
                exercising at intense and for extended durations – so managing
                your inflammation is important.
                <br />
                <br />
                If you are committed to participating in sport or don’t wish to
                cut back, an option is to speak with an Ingeneous practitioner
                to look at how you can protect yourself by possibly adjusting
                your supplement recommendations from the Essential Start report,
                and how you can work to protect your body during times of high
                exercise.
              </p>
            </>
          )}

          {results.recovery === 'low' && (
            <>
              <Serving color={getColor(2)}>
                Your risk of inflammatory based injury is low.
              </Serving>
              <br />
              <p>
                Go ahead, continue to exercise at your current levels – your
                genes don’t restrict you.
              </p>
            </>
          )}
        </BodyCol>
      </Row>
    </>
  );
};

export default Exercise;
