import React from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import 'mobx-react-lite/batchingForReactDom';

import App from './App';
import * as serviceWorker from './serviceWorker';

import AuthProvider from 'stores/auth';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

library.add(fas, far, fab);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(
  <AuthProvider>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </AuthProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
