import React, { useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { AuthContext } from 'stores/auth'
import { Spacer } from 'components/utils'

import Form from './components/Form'

function Delivery () {
  const authStore = useContext(AuthContext)
  const addresses = authStore.user.addresses ? authStore.user.addresses.filter(item => item.address_type === 'delivery') : []
  const address = addresses[0]

  let defaultValues = {...address}
  defaultValues['phone_number'] = authStore.user.phone_number

  return (
    <Container className="auth">
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <Form defaultValues={defaultValues} addressType='delivery' />
        </Col>
      </Row>
      <Spacer height={'30px'} />
    </Container>
  )
}

export default Delivery
