import React from 'react';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';

import { Spacer } from 'components/utils';
import { BodyCol } from './utils';

const Image = styled.img`
  max-width: 100%;
`;

const Tips = () => {
  return (
    <>
      <Row>
        <BodyCol xs={12} md={2}>
          <Image
            src={
              process.env.PUBLIC_URL +
              '/assets/images/lifeplus/ing-lifehack.png'
            }
          />
        </BodyCol>
        <BodyCol xs={12} md={10}>
          <strong>
            Tips to increase your amylase to better tolerate starchy
            carbohydrates
          </strong>

          <p>
            If you would like to go further and improve your starch processing
            so that you can eat more carbohydrates than your genes were
            programmed to, please follow these recommendations:
          </p>
        </BodyCol>
        <Spacer height={'20px'} />
        <BodyCol xs={12}>
          <ul>
            <li>Avoid starches for breakfast and lunch.</li>
            <li>Exercise doing burst training between 3-5pm.</li>
            <li>Eat dinner within two hours of exercising.</li>
            <li>Drink lemon or lime water and eat a salad before dinner.</li>
            <li>Eat protein and fat first and starchy foods last.</li>
            <li>
              Eat slowly and chew often. Ideally the starchy food would have
              cooled down for at least 15mins.
            </li>
            <li>
              Avoid drinking alcohol and tea (caffeine & herbal) at the same
              time that you eat starchy foods.
            </li>
          </ul>
        </BodyCol>
      </Row>
    </>
  );
};

export default Tips;
