import React from 'react';
import styled from 'styled-components';

import Header from './components/Header';
import Footer from './components/Footer';

import { footerHeight } from 'styles/theme';

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
`;

const Container = styled.div`
  padding-bottom: ${footerHeight}; /* Footer height */
  position: relative;
`;

function ArtilectWrapper({ children }) {
  return (
    <Wrapper>
      <Container>
        <Header />
        {children}
      </Container>
      <Footer />
    </Wrapper>
  );
}

export default ArtilectWrapper;
