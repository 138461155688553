import React from 'react';
import { useLocalStore } from 'mobx-react-lite';
import { toJS } from 'mobx';

export const CommonContext = React.createContext();

const CommonStore = ({ children }) => {
  const store = useLocalStore(() => ({
    ready: false,
    sampleId: null,
    reportAvailable: true,
    practitionerCheckout: {},

    setSampleId: (sampleId) => {
      store.sampleId = sampleId;
    },

    setReportAvailable: (val) => {
      store.reportAvailable = val;
    },

    setPractitionerCheckout: (val) => {
      store.practitionerCheckout = val;
    },

    getPractitionerCheckout: () => {
      const participants = toJS(store.practitionerCheckout.participants);
      return {
        ...store.practitionerCheckout,
        participants: participants,
      };
    },
  }));

  return (
    <CommonContext.Provider value={store}>{children}</CommonContext.Provider>
  );
};

export default CommonStore;
