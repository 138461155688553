import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { Spacer } from 'components/utils';
import { borderGray, primary, white } from 'styles/theme';

const Wrapper = styled.div`
  background-color: ${primary};
  padding: ${isMobile ? '10px 0 0 10px' : '30px 3px 30px 30px'};  
  border-radius: 5px;
  border: 1px solid ${borderGray};
  color: ${white};
  position: relative;

  h4 {
    color: ${white};
    margin: ${isMobile ? '20px 0 40px' : '0 0 8px'};
  }

  p {
    font-size: 1rem;
    margin: 0;
    color: ${white};
  }
`;

const WrapperImg = styled(Col)`
  display: ${isMobile ? 'block' : 'flex'};
  padding: ${isMobile ? '0' : '0 15px'};
  align-items: ${isMobile ? 'unset' : 'flex-start'};

  div {
    display: ${isMobile ? 'flex' : 'block'};
    justify-content: ${isMobile ? 'flex-end' : 'unset'};
    align-items: ${isMobile ? 'flex-start' : 'unset'};
    margin-top: ${isMobile ? '20px' : 'inital'};

    img:first-child {
      width: ${isMobile ? '35px' : 'initial'};
    }
    img:nth-last-child(1) {
      width: ${isMobile ? '145px' : 'initial'};
    }
  }
`

const Photo = styled.img`
  position: ${isMobile ? 'static' : 'absolute'};
  right: ${isMobile ? 'initial' : '6px'};
  bottom: ${isMobile ? 'initial' : '-15px'};
  transform: ${isMobile ? 'unset' : 'translate(-6px, 15px)'};
  height: ${isMobile ? 'auto' : '233px'};
`;

const Vector = styled.img`
  position: ${isMobile ? 'static' : 'absolute'};
  left: ${isMobile ? '0' : '20%'};
  transform: ${isMobile ? 'unset' : 'translateX(-20%)'};
`;

const NewReport = () => {
  return (
    <div>
      <h3>New Report</h3>
      {/* <Wrapper>
      <Row>
        <Col md={8}>
          <h4>Find a Local Practitioner</h4>
          <p>
            Need help or guidance with your results and recommendations?<br />
            Reach out to one of our trained and certified practitioners for advanced assistance.
          </p>
          <Spacer height={'20px'} />
          <Link to='/dashboard'><Button>Find</Button></Link>
        </Col>
        <Col md={4}>
          <Image src={process.env.PUBLIC_URL + '/assets/images/Practitioner-icon-92.png'} />
        </Col>
      </Row>
    </Wrapper>

    <Spacer height={'20px'} /> */}

      <Wrapper>
        <Row>
          <Col md={8}>
            <h4>Weight Management Report Now Available</h4>
            <p>
              Not everyone burns fat the same way. Get personalised
              recommendations and insights based on your genetics. Work with
              your body to burn body fat and reach your weight goal with tips
              that are unique for you!
            </p>
            <Spacer height={'20px'} />
            <a href="/order-details">
              <Button variant="secondary" className="shadow-none">
                Unlock Now&nbsp;
              </Button>
            </a>
            <a
              href="https://myingeneous.com/weight-management/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="primary"
                className="shadow-none"
                style={{ textTransform: 'capitalize', marginLeft: 10 }}
              >
                Learn More&nbsp;
              </Button>
            </a>
          </Col>
          <WrapperImg md={4}>
            <div>
              <Vector
                src={process.env.PUBLIC_URL + '/assets/images/Vector.svg'}
              />
              <Photo
                src={process.env.PUBLIC_URL + '/assets/images/NewReport.svg'}
              />
            </div>
          </WrapperImg>
        </Row>
      </Wrapper>
    </div>
  );
};

export default NewReport;
