import React from 'react'

const FruitsVegetables = () => {
  return (<div>
    <h5>33 servings of fruit and vegetables a week (or just under 5 per day)</h5>
    <p>
      Please note that your genetic SNP testing with Ingeneous is NOT able to tell you if you have a bacterial imbalance in your gut bacteria or oral biofilm that is sensitive to raw fruit and vegetables. Any positive improvement that you make to your detoxification system and bacterial environment can result in bad bacterial die off, with symptoms such as diarrhoea, constipation, fever or headache. This is a normal and healthy cleansing of the endotoxins from the dead (bad) bacterial bodies and should pass after a few days. If these symptoms persist longer than 7 days, we recommend you call your primary health provider. If you have an Ingeneous membership you can also contact our practitioner line. The Ingeneous practitioner may recommend an efficient bacterial rebalance that involves one product and lasts about 14 days.
    </p>
    <p>
      This is a particularly powerful recommendation for you, to boost your body’s ability to make its own antioxidants, which are exponentially more powerful than any antioxidants that come from outside your body.
    </p>

    <p>Serving sizes:</p>
    <ul>
      <li><strong>A serving of vegetables is 1 cup</strong> of either raw or cooked, broccoli, cauliflower, banana etc.</li>
      <li>For leafy greens - kale, spinach etc, a serve is <strong>2 cups raw</strong> measured before cooking.</li>
    </ul>

    <p>Handy guidance:</p>
    <ul>
      <li><strong>If you eat 3 meals a day</strong> you need to average around <strong>1½ handfuls</strong> of vegetables or fruit per meal.</li>
      <li><strong>If you eat 2 meals a day</strong> (or simply don’t enjoy fruit and vegetables with your breakfast!) you need to average around <strong>2 1/3 handfuls</strong> of vegetables and fruit per meal.</li>
    </ul>

    <p>Options to help hit this recommendation:</p>
    <ul>
      <li>Have a mixed salad with a portion of protein for one meal</li>
      <li>Make a green smoothie including vegetables and only ½ piece of fruit for sweetness e.g. cucumber, zucchini, carrot, baby spinach or cooked kale, avocado, ½ green apple, lemon juice, chia seeds, soaked cashews or almonds for protein, a few drops of vanilla to lift the flavour.</li>
      <li>Cook and freeze kale into ice cubes and simply add to smoothies. Raw kale is too hard on the digestive system.</li>
    </ul>

    <p>And lastly – as eating large quantities of fruit alone can potentially push up blood sugars, try and limit to 1-2 servings of fruit per day.</p>
  </div>)
}

export default FruitsVegetables
