import React from 'react'

const Calcium = () => {
  return (<div>
    <h5>Calcium daily - 900mg a day (3 servings)</h5>
    <p>
      Please note that your Gene SNP testing with Ingeneous is NOT able to tell you if you have lactose intolerance or a dairy allergy. If you know that you have either, please choose non-dairy alternatives from the list provided. On the bright side, as you follow your Essential Start recommendations and your inflammation coordination improves, you may find that existing food sensitivities like lactose or dairy may trigger less symptoms in the future!
    </p>

    <p>
      As a woman with your particular genetics’ calcium is extremely important for your vitamin D receptor function, along with benefiting your pro-inflammatory and detoxification systems.
    </p>

    <p>
      These calcium rich options have been listed in order of quality sources, taking into account the body’s ability to utilise this key nutrient from your food.
    </p>
    <ul>
      <li>Tofu, firm ½ cup = 682.7mg</li>
      <li>Sardines, 1 can (106 grams) = 348mg</li>
      <li>Spinach, cooked 1 cup = 306mg</li>
      <li>Collard greens, chopped, cooked 1 cup = 267.90mg</li>
      <li>Parmesan cheese, grated ¼ cup= 266mg</li>
      <li>Cheddar cheese, ¼ cup = 204.4mg</li>
      <li>Bok Choy, cooked 1 cup = 158.1mg</li>
      <li>Yoghurt or cow’s milk, ¼ cup = 74.1mg</li>
    </ul>

    <p>
      <strong>Advanced Ingeneous Tip:</strong><br />
      * Calcium is a game of co-factors. To take it up a level and give your body the most powerful ability to activate positive gene expression – the ultimate combination is to have your servings of Calcium along with Vitamin D plus Vitamin K2 rich foods as these foods consumed together help your body to absorb the key nutrients.
    </p>

    <p>Foods high in Vitamin D include:</p>
    <ul>
      <li>Salmon, fresh wild average palm of hand size = 511.4IU</li>
      <li>Sardines, 1 can (106 grams) = 175.1IU</li>
      <li>Milk (cow) ½ cup = 62.2IU</li>
      <li>Tuna, ½ cup = 61.7IU</li>
      <li>Eggs, 1 medium = 43.50IU</li>
    </ul>

    <p>Foods high in Vitamin K include:</p>
    <ul>
      <li>Kale, raw 1 cup = 1062mcg</li>
      <li>Spinach, 1 cup = 888mcg</li>
      <li>Collard greens, chopped and cooked 1 cup = 772mcg</li>
      <li>Parsley, ½ cup = 498mcg</li>
      <li>Broccoli, 1 cup = 220mcg</li>
    </ul>

  </div>)
}

export default Calcium
