import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { AuthContext } from 'stores/auth';
import { Spacer } from 'components/utils';

import BillingForm from './components/BillingForm';

function Delivery () {
  const authStore = useContext(AuthContext);
  const addresses = authStore.user.addresses ? authStore.user.addresses.filter(item => item.address_type === 'billing') : []
  const address = addresses[0];

  let defaultValues = {...address};

  return (
    <Container className="auth">
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <BillingForm defaultValues={defaultValues} addressType='billing' />
        </Col>
      </Row>
      <Spacer height={'30px'} />
    </Container>
  )
};

export default Delivery;
