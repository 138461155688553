import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { gray, primary } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import View from '../View';
import Close from '../ViewLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const AlertRef = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
  margin-bottom: ${isMobile ? 0 : '30px'};
`;

const SubtitleGray = styled.h5`
  color: ${gray};
  margin-bottom: 30px;
`;

const WrapperRef = styled.div`
  margin-top: 5px;
`;

const ListGroupUl = styled.ul`
  font-weight: bold;
  padding-left: 20px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const List = styled.li`
  font-weight: 300;

  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
  }
`;

const ListRef = styled.li`
  font-weight: 300;
  padding-left: 20px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    word-break: break-all;
  }
`;

const WrapperTitle = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
`;

interface ColdProps {
  results: any;
}
const Cold = ({ results }: ColdProps) => {
  const [show, setShow] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/Temperature.png"
            alt="temperature"
            width={'70%'}
          />
        </ImageCol>
        <Col md={8}>
          <WrapperTitle>
            {isMobile ? (
              <>
                <Subtitle>Cold Temperatures</Subtitle>
                <SubtitleGray> will help you lose weight</SubtitleGray>
              </>
            ) : (
              <>
                <Subtitle>Cold Temperatures</Subtitle>
                &nbsp;
                <SubtitleGray> will help you lose weight</SubtitleGray>
              </>
            )}
          </WrapperTitle>
          <Alert>
            <p>Cold Temperatures</p>
            <WrapperRef>
              <ListGroupUl>
                <List>
                  Keep your house at a slightly cooler temperature (16-19°C)
                </List>
                <List>
                  Take a cold shower or use a plunge pool (try to work your way
                  up to 10 mins)
                </List>
                <List>Swim in the sea or a cool pool (10-20 mins) </List>
                <List>
                  Try Whole Body Cryotherapy (currently available in
                  Christchurch and Auckland)
                </List>
              </ListGroupUl>
            </WrapperRef>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <p>
                Your body is fully in favour of cold temperatures making up 30%
                of your optimal weight story. Simulating a cold environment will
                help you burn fat best by increasing thermogenesis (fat burning
                to produce energy) in your brown adipose tissue (BAT).
              </p>
              <p>
                White adipose tissue stores energy in the form of triglycerides
                (fat), brown adipose tissue is specialised in using this stored
                energy to produce heat. These are your two main types of fatty
                tissue, but a higher BAT ratio is actually helpful and
                associated with lower BMI and healthier weight. (1,2) Exposing
                yourself to cold temperatures activates your BAT, increasing
                your basal metabolic rate (BMR) which means you are able to burn
                more calories. (4) Shivering can cause you to burn about 5 times
                more calories while resting! (4) By decreasing the temperature
                of your home to a mild cold (17-19°C) you will be able to
                activate the calorie burning powers of your brown adipose tissue
                without inducing shivering. (5)
              </p>
              <p>
                While hot temperatures may help some people lose weight, your
                body will burn fat better in these cold temperatures. With cold
                exposure it is always good to start off with short cool
                exposures and slowly extend your exposure time and cold
                intensity as you adjust.Don’t go moving to Antarctica! There are
                many ways to simulate a cold environment to help you lose weight
                which we have recommended for you to try.
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
      <Row className="no-print">
        <Col>
          <AlertRef>
            <span>References</span>
            {!showReferences && (
              <View value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <Close value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <WrapperRef>
                <ListGroup>
                  <ListRef>
                    Saely CH, Geiger K, Drexel H. Brown versus white adipose
                    tissue: a mini-review. Gerontology. 2012;58(1):15-23. doi:
                    10.1159/000321319. Epub 2010 Dec 7. PMID: 21135534. &nbsp;
                    <a href="https://pubmed.ncbi.nlm.nih.gov/21135534">
                      https://pubmed.ncbi.nlm.nih.gov/21135534/
                    </a>
                  </ListRef>
                  <ListRef>
                    <a href="https://www.news-medical.net/life-sciences/The-Differences-Between-White-and-Brown-Adipose-Tissue.aspx">
                      https://www.news-medical.net/life-sciences/The-Differences-Between-White-and-Brown-Adipose-Tissue.aspx
                    </a>
                  </ListRef>
                  <ListRef>
                    Cannon B, Nedergaard JA. Brown adipose tissue: function and
                    physiological significance. Physiological reviews. 2004
                    Jan;84(1):277-359. &nbsp;
                    <a href="https://journals.physiology.org/doi/full/10.1152/physrev.00015.2003">
                      https://journals.physiology.org/doi/full/10.1152/physrev.00015.2003
                    </a>
                  </ListRef>
                  <ListRef>
                    van Marken Lichtenbelt W, Kingma B, Van Der Lans A, Schellen
                    L. Cold exposure–an approach to increasing energy
                    expenditure in humans. Trends in Endocrinology & Metabolism.
                    2014 Apr 1;25(4):165-7. &nbsp;
                    <a href="https://www.ics.uci.edu/~wayne/tmp/Lichtenbelt-CELL-2014-Cold-exposure-increasing-energy-expenditure.pdf">
                      https://www.ics.uci.edu/~wayne/tmp/Lichtenbelt-CELL-2014-Cold-exposure-increasing-energy-expenditure.pdf
                    </a>
                  </ListRef>
                  <ListRef>
                    Ouellet V, Labbé SM, Blondin DP, Phoenix S, Guérin B, Haman
                    F, Turcotte EE, Richard D, Carpentier AC. Brown adipose
                    tissue oxidative metabolism contributes to energy
                    expenditure during acute cold exposure in humans. The
                    Journal of clinical investigation. 2012 Feb 1;122(2):545-52.
                    &nbsp;
                    <a href="https://www.jci.org/articles/view/60433">
                      https://www.jci.org/articles/view/60433
                    </a>
                  </ListRef>
                  <ListRef>
                    Velickovic K, Leija HA, Bloor I, Law J, Sacks H, Symonds M,
                    Sottile V. Low temperature exposure induces browning of bone
                    marrow stem cell derived adipocytes in vitro. Scientific
                    reports. 2018 Mar 21;8(1):1-2. &nbsp;
                    <a href="https://www.nature.com/articles/s41598-018-23267-9">
                      https://www.nature.com/articles/s41598-018-23267-9
                    </a>
                  </ListRef>
                  <ListRef>
                    <a href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0068933">
                      https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0068933{' '}
                    </a>
                  </ListRef>
                </ListGroup>
              </WrapperRef>
            )}
          </AlertRef>
        </Col>
      </Row>
    </>
  );
};

export default Cold;
