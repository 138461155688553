import React from "react";

import { Container, Row } from "react-bootstrap";
import styled from "styled-components";

const ContentWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  color:white;
  p,
  label {
    color: #ffffff !important;
  }
  h1 {
    text-align: center;
  }
`;

function NotApproved(){
    return(
        <ContentWrapper>
            <Container>
                <Row>
                    <h1>Your account is not yet approved by our admin. Please try again later</h1>
                </Row>
            </Container>
        </ContentWrapper>
        
    )
}

export default NotApproved