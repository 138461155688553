import React from 'react';
import styled from 'styled-components';
import { primary } from 'styles/theme';

export const Page = styled.div``;

export const PageHeader = styled.div`
  background-color: ${primary};
  color: #ffffff;
  padding: 10px;

  @media print {
    display: none;
    -webkit-print-color-adjust: exact;
  }
`;

export const PageTitleStyle = styled.span`
  display: inline-block;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #ffffff;
  }
`;

export const PageBody = styled.div`
  height: 100%;
`;

interface PageTitleProps {
  children: React.ReactNode;
  small?: boolean;
}

export const PageTitle = ({ children, small }: PageTitleProps) => {
  return (
    <PageTitleStyle>
      {!small ? <h1>{children}</h1> : <h2>{children}</h2>}
    </PageTitleStyle>
  );
};
