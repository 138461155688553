import React from 'react';
import { Container } from 'react-bootstrap';

import { Spacer } from 'components/utils';

// import Users from './Users/index';

const Admin = () => {
  return (
    <>
      <Container>
        <Spacer height={'20px'} />
        {/* <Users /> */}
        {/* <Spacer height={'20px'} />
      <Orders /> */}
        Select Users, Events or Downloads above to get started.
      </Container>
    </>
  );
};

export default Admin;
