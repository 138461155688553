import React from 'react';
import { useLocalStore } from 'mobx-react';
import { countries } from 'countries-list';

import {
  refresh,
  userDetail,
  userUpdate,
  userChangePhoto,
  changePassword as changePasswordApi,
} from '../api/auth';

export const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const store = useLocalStore(() => ({
    loading: false,
    loggedIn: false,
    user: null,
    userType: 'user',
    reports: {
      essential: 'available',
      life_plus: 'available',
      membership: false,
      practitioner: false,
      weight_management: 'available'
    },
    orders: null,
    addresses: null,
    prac_org_approved: false,

    isLoggedIn: () => {
      return store.loggedIn
    },

    isApproved: () => {
      return store.prac_org_approved
    },

    isPractitioner: () => {
      return store.userType == 'practitioner';
    },

    isPractitionerOrg: () => {
      return store.userType == 'prac_org';
    },

    hasToken: () => {
      return localStorage.getItem('accessToken');
    },

    isAuthenticated: () => {
      return store.loggedIn;
    },

    isAdmin: () => {
      return store.user.scope === 'admin';
    },

    setLoading: (loading) => {
      store.loading = loading;
    },

    setLoggedIn: (loggedIn) => {
      store.loggedIn = loggedIn;
    },

    setUser: (user) => {
      store.user = user;
    },

    login: (userData) => {
      localStorage.setItem('accessToken', userData.accessToken);
      return store.fetchUser();

      // return loginApi({
      //   'email': payload.email,
      //   'password': payload.password
      // }).then((res) => {

      //   store.fetchUser()
      //   // store.loggedIn = true
      //   return
      // }).catch((err) => {
      //   store.reset()
      //   if (err.response) {
      //     throw new Error(err.response)
      //   }
      // })
    },

    setTokens: (access, refresh) => {
      localStorage.setItem('accessToken', access);
      localStorage.setItem('refreshToken', refresh);
      store.loggedIn = true;
      return store.fetchUser();
    },

    reset: () => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');

      store.user = null;
      store.loggedIn = false;
      store.loading = false;
    },

    logout: () => store.reset(),

    fetchUser: () => {
      store.loading = true;
      return userDetail()
        .then((res) => {
          store.user = res.data.user;
          store.userType = res.data.user?.scope || 'user';
          store.reports = res.data.reports;
          store.orders = res.data?.orders;
          store.addresses = res.data?.addresses;
          store.loggedIn = true;
          store.loading = false;
          store.prac_org_approved = res.data?.user?.prac_org_approved;
          return res.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            refresh()
              .then((res) => {
                localStorage.removeItem('accessToken');
                localStorage.setItem('accessToken', res.data.access);
                store.user = res.data.user;
                store.loggedIn = true;
                store.loading = false;
                return;
              })
              .catch(() => {
                store.reset();
              });
          } else {
            store.reset();
            throw new Error(err);
          }
        });
    },

    updateUser: (data) => {
      return userUpdate(data)
        .then((res) => {
          store.user = res.data;
          return res.data;
        })
        .catch((err) => {
          throw new Error(err);
        });
    },

    changePhoto: (data) => {
      return userChangePhoto(data)
        .then((res) => {
          store.user = res.data;
          return res.data;
        })
        .catch((err) => {
          throw new Error(err);
        });
    },

    changePassword: (data) => {
      return changePasswordApi(data)
        .then(() => {
          return true;
        })
        .catch((err) => {
          throw new Error(err);
        });
    },

    getCountry: (format) => {
      if (store.user === null) return null;

      let result = null;
      for (const [key, val] of Object.entries(countries)) {
        if (store.user.country === key || store.user.country === val.name) {
          if (format === 'ISO') {
            return key;
          } else {
            return val.name;
          }
        }
      }
      return result;
    },

    loginArtilect: (userData) => {
      store.reset();
      store.loading = true;
      localStorage.setItem('accessToken', userData.accessToken);
      return store.fetchUser();
    },

    loginCustomer: (userData) => {
      store.reset();
      store.loading = true;
      localStorage.setItem('accessToken', userData.accessToken);
      store.loggedIn = true;
      return store.fetchUser();
    },
  }));

  return <AuthContext.Provider value={store}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
