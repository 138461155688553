/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import QRCode from 'react-qr-code';

import { getEvent } from 'api/admin';
import { Spacer } from 'components/utils';

const Wrapper = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0;
  border: 1px solid #bbb;
  padding: 30px;
  margin: 20px 0;
  text-align: center;
`;

const ViewEvent = () => {
  let { code } = useParams();
  const [event, setEvent] = useState();

  useEffect(() => {
    getEvent(code)
      .then((res) => {
        setEvent(res.data?.event);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  return (
    <Container>
      <Wrapper>
        {event && (
          <>
            <h3>{event.name}</h3>
            <p>
              <strong>{event.start_date}</strong>&nbsp;to&nbsp;
              <strong>{event.end_date}</strong>
            </p>
            <QRCode
              value={process.env.REACT_APP_EVENT_HOST}
              // value={process.env.REACT_APP_EVENT_HOST + event?.token}
              title={event.name}
              size={512}
            />
            <Spacer height={'30px'} />
            <Link
              to={process.env.REACT_APP_EVENT_HOST + event?.token}
              target="_blank"
            >
              Visit Event Page
            </Link>
          </>
        )}
      </Wrapper>
    </Container>
  );
};

export default ViewEvent;
