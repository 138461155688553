import React from 'react';
import { Page, PageHeader, PageTitle, PageBody } from 'components/Page';
import { Spacer } from 'components/utils';
import { Row, Col, Container, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { gray, primary } from 'styles/theme';
import { isMobile } from 'react-device-detect';

import { device } from 'components/breakpoints';

const Wrapper = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
  align-items: center;
  padding: 15px 0;

  .title {
    flex: 1;
    display: ${isMobile ? 'block' : 'flex'};
    align-items: center;
    justify-content: space-between;

    h1 {
      margin: 0;
      margin-right: 20px;
    }
  }
`;

const BgBiggerScreen = styled(Row)`
  & > div[class^='col'] {
    display: block;
    ${device.xl`
      display: none;
    `}
  }
  margin-right: 0;
`;

const FormLayoutPosition = styled.div`
  & > .container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  & > .container > .row > div[class^='col'] {
    padding-top: 6.875rem;
  }

  & > .container > .row > div[class^='col'] > div > h2,
  & > .container > .row > div[class^='col'] > div > p {
    display: block;
    width: 50%;
  }

  ${device.xl`
    & > .container {
      position: static;
      max-width: 100%;
      padding: 0;
    }
    .container > .row {
        display: block;
    }
    .container > .row > div[class^='col'] {
      background: #fff;
      padding-right: 40px;
      padding-top: 40px;
      width: 100%;
      max-width: 100%;
    }

    & > .container > .row > div[class^='col'] > div > h2,
    & > .container > .row > div[class^='col'] > div > p {
      width: 100%;
    }
  `}
`;

const DescriptionArea = styled.p`
  color: ${gray};
  font-family: 'Cabin';
  font-weight: 400;
  font-size: 1rem;
`;

const OuterWrapper = styled.div`
  ${device.xl`
        padding: 0 5rem 1rem;
    `}

  ${device.sm`
        padding: 0 0.5rem 1rem;
    `}
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;

  a {
    color: ${primary};
  }
`;

const ImageWrapper = styled.div`
  background-color: #f4f0ee;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  position: relative;
  margin-right: 13px;
`;

const ImageLink = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const HeroImage = styled.img`
  position: absolute;
  right: 0;
  margin-right: -17rem;
  margin-top: -7rem;

  ${device.xl`
    position: static;
    display: block;
    width: 50%;
    margin: 5px auto 30px;
  `}
  ${device.md`
    width: 70%;
  `}
  ${device.sm`
    width: 100%;
  `}
`;
const ContentWrapper = styled.div`
  width: 70%;

  ${device.xl`
    margin-top: 1rem;
    width: 100%;
  `}
`;

const Success = () => {
  return (
    <Page>
      <PageHeader>
        <Container>
          <Wrapper>
            <div className="title">
              <PageTitle>
                <img
                  src="/assets/images/ingeneous_white_r.png"
                  alt="ingeneous"
                  width={190}
                />
              </PageTitle>
            </div>
          </Wrapper>
        </Container>
      </PageHeader>
      <PageBody style={{ position: 'relative' }}>
        <BgBiggerScreen>
          <Col
            xs={12}
            sm={8}
            style={{ background: '#fff', minHeight: '100vh' }}
          ></Col>
          <Col xs={12} sm={4} style={{ minHeight: '100vh' }}></Col>
        </BgBiggerScreen>
        <FormLayoutPosition>
          <Container>
            <Row style={{ marginRight: 0 }}>
              <Col xs={12} sm={9}>
                <OuterWrapper>
                  <h2>Your purchase has been successful.</h2>
                  <Spacer height={'10px'} />
                  <DescriptionArea>
                    Congratulations on taking the first step to become an
                    Ingeneous Certified Practitioner.
                  </DescriptionArea>
                  <Spacer height={'30px'} />
                  <HeroImage
                    src="/assets/images/pract-success-main.svg"
                    alt="success"
                  />
                  <ContentWrapper>
                    <div
                      style={{
                        width: 82,
                        border: '2px solid #2AA78D',
                      }}
                    ></div>
                    <Spacer height={'50px'} />
                    <h3>Still browsing?</h3>
                    <Spacer height={'30px'} />
                    <Row>
                      <Col>
                        <TextWrapper>
                          <ImageWrapper>
                            <ImageLink
                              src="/assets/images/pract-success-1.svg"
                              alt="libby"
                            />
                          </ImageWrapper>
                          <a
                            href="https://myingeneous.com/dr-libby-lindsay/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Get to know Libby?
                          </a>
                        </TextWrapper>
                        <Spacer height={'30px'} />
                        <TextWrapper>
                          <ImageWrapper>
                            <ImageLink
                              src="/assets/images/pract-success-2.svg"
                              alt="event"
                            />
                          </ImageWrapper>
                          <a
                            href="https://myingeneous.com/events/list/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Find our upcoming events
                          </a>
                        </TextWrapper>
                      </Col>
                      <Col>
                        {isMobile && <Spacer height={'30px'} />}
                        <TextWrapper>
                          <ImageWrapper>
                            <ImageLink
                              src="/assets/images/pract-success-3.svg"
                              alt="team"
                            />
                          </ImageWrapper>
                          <a
                            href="https://myingeneous.com/about-ingeneous/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Learn more about our team
                          </a>
                        </TextWrapper>
                        <Spacer height={'45px'} />
                        <div>
                          <Button
                            variant="primary"
                            style={{ width: `${isMobile ? 'auto' : '288px'}` }}
                            href="https://myingeneous.com"
                            target="_blank"
                          >
                            GO HOMEPAGE
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    {!isMobile ? (
                      <Spacer height={'70px'} />
                    ) : (
                      <Spacer height={'30px'} />
                    )}
                    <p style={{ fontSize: `${isMobile ? 14 : 20}` }}>
                      If you have any questions please email
                      hello@ingeneous.co.nz
                    </p>
                  </ContentWrapper>
                </OuterWrapper>
              </Col>
            </Row>
          </Container>
        </FormLayoutPosition>
      </PageBody>
    </Page>
  );
};

export default Success;
