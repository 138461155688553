import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Spacer } from 'components/utils';
import { primary } from 'styles/theme';

const StyledRow = styled(Row)`
  background-color: ${primary};
`;

const LogoWrapper = styled.div`
  padding: 30px 15%;
`;

const Wrapper = styled.div`
  padding: 30px 15%;
  h2,
  h4,
  h5,
  p,
  label,
  ul {
    color: #ffffff;
  }

  p {
    text-align: justify;
    text-justify: inter-word;
  }

  ul {
    list-style: none;

    li {
      margin-bottom: 5px;
    }
  }
`;

const Logo = styled.img`
  max-height: 50px;
`;

function TermsConditions() {
  return (
    <StyledRow>
      <Col md={{ span: 8, offset: 2 }}>
        <LogoWrapper>
          <Link to="/">
            <Logo
              src={
                process.env.PUBLIC_URL + '/assets/images/ingeneous_white_r.png'
              }
            />
          </Link>
        </LogoWrapper>
        <Wrapper>
          <h2>Terms and Conditions</h2>
          <Spacer height={'20px'} />
          <h4>BACKGROUND</h4>
          <Spacer height={'20px'} />
          <p>
            Ingeneous provides a personalised genetic platform, helping to
            empower people to activate and improve their health.
            <br />
            <br />
            In a health landscape that has become even more uncertain and filled
            with products offering to improve our health or prevent an illness,
            one of the best ways of really knowing our health potential lies
            with our genes. Ingeneous uses panels of specialized sequence
            testing to provide insights to its clients to help them understand
            where improvements in their health can be made.
            <br />
            <br />
            Ingeneous uses a set open array gene test panel of 128 genes, a
            specialised IT system and artificial intelligence that analyses a
            client’s unique genes to enable them to obtain personalised health
            guidance and recommendations, should they wish to do so. This
            specialised genetic testing can be used in adopting a holistic
            approach to health, to show which genes might potentially be
            optimised for greater wellbeing. Our personalised genetic reports
            will help guide our clients to make decisions, with appropriate
            medical and genetic advice how a mix of lifestyle change, dietary
            adjustments, exercise and supplementation may provide better
            outcomes specific to their own particular circumstances.
            <br />
            <br />
            Ingeneous’ mission is to help empower humanity to achieve wellness
            by harnessing genetics. A wrap-around system provides quality
            actionable information. An in-app feature allows certified
            practitioners to be consulted and progress tracking helps ensure
            clients can access the wellness and wellbeing services they need.
            <br />
            <br />
            Ingeneous offers as part of its services affordable testing,
            individualised digital genetic reports with relevant action points
            and explanations, access for an additional fee to specialized
            service consultant support, the provision of progress tracking and
            performance support alerts and easy to use links to supplement
            purchases.
            <br />
            <br />
            The Terms of Service set out below record the legal contract between
            you and Ingeneous and affect your rights and obligations, so it is
            important to read these terms carefully and if necessary, get legal
            advice before going any further.
          </p>

          <Spacer height={'20px'} />
          <h5>1. INTRODUCTION</h5>
          <p>
            1.1. Ingeneous Limited (“Ingeneous”) is registered at PKF Bredin
            McCormack Rewcastle Ltd, Level 7, 248 Cumberland Street, Dunedin.
            Its New Zealand Company Registration Number is 8048437.
            <br />
            <br />
            1.2. Ingeneous provides personalised genetic information to its
            Clients through the use of DNA samples. To use any Ingeneous Service
            or Product, you must agree to these Terms of Service (“Terms”). This
            is a legal contract that affects your rights and obligations. Any
            use of the Service signifies your acceptance of these Terms of
            Service.
          </p>

          <Spacer height={'20px'} />
          <h5>2. KEY TERMS AND DEFINITIONS</h5>
          <p>
            2.1. “Algorithm” means a formula created and owned by Ingeneous to
            provide the Services.
            <br />
            <br />
            2.2. “Client” means you, the person making use of Our Site and/or
            the Services and/or the Products and who have fully completed the
            Informed Consent Document, agreed to comply with the Privacy Policy
            and completed all requirements set out in these Terms, thereby
            entering into a contractual arrangement with Ingeneous pursuant to
            these Terms.
            <br />
            <br />
            2.3. “Client Content” means all other information provided by
            Clients, including Personal Information, but not including Genetic
            Information and Health Input Data. This information is distributed
            publicly or privately, to or through Ingeneous or Ingeneous
            Affiliates.
            <br />
            <br />
            2.4. “Confirmation Email” means an email sent by Ingeneous to a
            Client, upon acceptance by Ingeneous of the Client’s order.
            <br />
            <br />
            2.5. “De-identified Information” means information from which all
            identifying details have been removed, and is therefore no longer
            able to identify you as an individual.
            <br />
            <br />
            2.6. “Fee” means an amount payable for a Service, as set out in our
            Fees List.
            <br />
            <br />
            2.7. “Fees Lists” means a list of Fees payable for each Service
            offered by Ingeneous and accessible at hello@ingeneous.co.nz.
            <br />
            <br />
            2.8. “Information” means any data received, requested or obtained by
            Ingeneous from Clients for the purposes of providing the Services or
            making Our Site available, and includes Personal Information and,
            Genetic Information.
            <br />
            <br />
            2.9. “Informed Consent Document” means a specific document dedicated
            to obtaining the express, written and voluntary consent from a
            Client to process their Information.
            <br />
            <br />
            2.10. “Ingeneous Affiliate” means any employee, officer, contractor,
            agent, associate, partner, consultant, subsidiary, successor
            business, Ingeneous Certified Practitioner, or any other person,
            that Ingeneous contracts or otherwise uses to carry out any part of
            the Services.
            <br />
            <br />
            2.11. “Ingeneous Certified Practitioner” means a person who is
            certified by Ingeneous to provide support, health advice or medical
            advice (as is appropriate) to Clients and includes:
            <br />
            <br />
            (a) “Ingeneous Health Practitioner” means a person who meets the
            definition of a health practitioner in s 5(1) of the Health
            Practitioners Competence Assurance Act 2003 (including any
            amendments to that definition) who is contracted by Ingeneous to
            provide separate independent health advice in their registered
            profession to Clients in relation to the Ingeneous Services and
            Products.
            <br />
            <br />
            (b) “Ingeneous Medical Practitioner” means a person who meets the
            definition of a medical practitioner in s 2(1) of the Health Act
            1956 (including any amendments to that definition) who is contracted
            by Ingeneous to provide separate medical advice in their registered
            field to Clients in relation to the Ingeneous Services and Products.
            <br />
            <br />
            (c) “Ingeneous Wellbeing Advisor” means an ancillary care provider
            who is highly skilled in their specific field (for example, but not
            limited to, nutrition, nutrigenetics and complementary care) and who
            is contracted to Ingeneous to provide a range of holistic services
            to assist Clients to improve their health and overall wellbeing,
            including by directing Clients to appropriate Ingeneous Health
            Practitioners/Ingeneous Medical Practitioners or other Medical
            Practitioners.
            <br />
            <br />
            2.12. “Ingeneous Membership” means access (for a Fee) to exclusive
            Services and Products that may be chosen, updated, or removed by
            Ingeneous from time to time at its sole discretion.
            <br />
            <br />
            2.13. “Ingeneous Research” means scientific research and data
            collection and analysis that Ingeneous undertakes to improve the
            Services and its scientific processes and know-how.
            <br />
            <br />
            2.14. “Genetic Information” means Personal Information relating to
            the inherited or acquired genetic characteristics of a natural
            person which gives information unique to that natural person about
            their physiology or their health and which result, in particular,
            from an analysis of a biological sample from the natural person in
            question.
            <br />
            <br />
            2.15. “Health Input Data” means any information shared with us by
            the Client, including but not limited to Personal Information,
            medical conditions, sports-related information, ethnicity, family
            history, and other information that you enter in surveys, forms or
            features while using or entering Our Site.
            <br />
            <br />
            2.16. “License Agreement” means the agreement between the Client and
            Ingeneous, and any Ingeneous Affiliates, to use the Software.
            <br />
            <br />
            2.17. “Medical Practitioner” means a person who meets the definition
            of a medical practitioner in s 2(1) of the Health Act 1956
            (including any amendments to that definition).
            <br />
            <br />
            2.18. “Our Site” means the website that Ingeneous operates and owns,
            located at https://www.Ingeneous.co.nz includes Ingeneous’ mobile
            application (also referred to as our “App”:
            <br />
            <br />
            2.19. “Personal Information” means data about a Client that
            identifies the Client, comprising name, an identification number,
            location data, an online identifier or to one or more factors
            specific to the physical, physiological, genetic, mental, economic,
            cultural or social identity of that individual Client, but does not
            include De-Identified Information about or involving an individual
            Client.
            <br />
            <br />
            2.20. “Privacy Policy” means our policy on how we collect, use,
            store, process and transfer your Personal Information, which can be
            found at www.Ingeneous.co.nz/privacy.asp, as updated from time to
            time.
            <br />
            <br />
            2.21. “Product” means any item or substance manufactured by or for
            Ingeneous for sale, as amended from time to time. Products include
            Services supplied in conjunction with that product and includes:
          </p>
          <ul>
            <li>
              (a) “Add-ons” which means additional reports that may be added,
              for a Fee, to the Services and Products, which are currently the
              Essential Start, Essential Baby or Weight Loss/Life Plus Services.
            </li>

            <li>
              (b) “Essential Start” which means Ingeneous’ standard Service, the
              contents of which may be amended from time to time by Ingeneous.
            </li>

            <li>
              (c) “Guided Essentials” which means Ingeneous’ Service for
              high-need Clients or Clients with pre-existing medical conditions,
              or Clients who are pregnant, or intending to become pregnant, or
              breast-feeding.
            </li>

            <li>
              (d) “Ingeneous Phone Line” which means a phone line by which
              Clients who have purchased Ingeneous Membership may call an
              Ingeneous Wellbeing Advisor or Ingeneous Health Practitioner for
              support or assistance.
            </li>

            <li>
              (e) “Weight Loss/Life Plus” which means Ingeneous’ nutrition and
              wellbeing focussed Service, the contents of which may be amended
              from time to time by Ingeneous.
            </li>
          </ul>
          <p>
            2.22. “Registration Information” means details captured or provided
            when a Client registers for our services and/or purchases a product
            in order for an account to be set up with personal login
            credentials. Registration Information will be used to provide you
            with your chosen services, and will include, but is not limited to,
            your name, contact details and date of birth.
            <br />
            <br />
            2.23. “Result” means the outcome of your Test(s) as communicated to
            the individual Client but does not include any underlying data,
            information, software, know-how or insights utilised by Ingeneous or
            Ingeneous Affiliates in conducting the Test or providing the Result
            as set out in clause 13.5 of these Terms.
            <br />
            <br />
            2.24. “Sample Collection Kit” means a kit created by Ingeneous and
            sent to you, upon your request and payment of any applicable Fees,
            containing a cheek swab for the collection of your DNA, and any
            other contents as Ingeneous may choose to include.
            <br />
            <br />
            2.25. “Service” means Ingeneous’ Products, App, software, services,
            website (including but not limited to graphics, text, images and
            other information displayed on Our Site), Tests, reporting
            (including updates provided by Ingeneous from time to time),
            Ingeneous Membership and Ingeneous Phone Line and follow up
            consultations with one or more Ingeneous Certified Practitioners,
            regardless of whether the Client is in connection with an account or
            not.
            <br />
            <br />
            2.26. “Software” means any necessary software – including software
            from Ingeneous Affiliates or third parties – used in connection with
            the Services, Our Site or the App.
            <br />
            <br />
            2.27. “SNPs” means a single nucleotide polymorphism (pronounced
            “snips”) which are the most common type of genetic variation among
            people. SNPs are low penetrants that are not associated with a
            disease but can be influenced by a number of factors, including your
            environment. Each SNP represents a difference in a single DNA
            building block, called a nucleotide.
            <br />
            <br />
            2.28. “Test” means any testing that Ingeneous carries out,
            including:
          </p>
          <ul>
            <li>
              (a) “Amylase copy” which means a test or tests carried out by
              Ingeneous or an Ingeneous Affiliate to determine the concentrate
              of the enzyme Amylase in your mouth.
            </li>

            <li>
              (b) “DNA testing” which means a test or tests carried out by
              Ingeneous or an Ingeneous Affiliate to determine your Genetic
              Information.
            </li>
          </ul>
          <p>
            2.29. “Third Party Genetic Profile” means a genetic profile and/or
            results you have received from a third party company (who may or may
            not be an Ingeneous Affiliate) to whom you have provided a sample
            for genetic testing and analysis.
            <br />
            <br />
            2.30. “Underage Client” means a person under the age of 16 years
            old.
            <br />
            <br />
            2.31. “you” and “yours” means you as the Client of Our Site and/or
            Services to whom these Terms are applicable to, including for
            purposes of the Privacy Policy. Terms defined in the singular or
            plural may have the same meaning throughout the terms whether they
            are used in the singular or plural.
          </p>

          <Spacer height={'20px'} />
          <h5>3. ACCEPTANCE OF TERMS</h5>
          <p>
            3.1. Your use of Ingeneous’ Services and Products is subject to the
            terms defined in these Terms. These Terms apply to any use of
            Ingeneous’ Services and/or Products, including:
          </p>
          <ul>
            <li>
              (a) Submitting a saliva sample for DNA extraction and analysis;
            </li>

            <li>
              (b) Providing a copy of your Third Party Genetic Profile for our
              access so that Ingeneous may interpret the analysed data and
              information on Our Site;
            </li>

            <li>
              (c) Registering and using a free Ingeneous account without
              providing a saliva sample or Genetic Information; and/or
            </li>

            <li>
              (d) Seeking or receiving a consultation with one or more Ingeneous
              Certified Practitioners.
            </li>
          </ul>
          <p>
            3.2. Your use of the Services is also subject to any applicable
            terms, policies or rules that we may post or provide links to. This
            currently includes, but is not limited to, the Ingeneous Privacy
            Policy, Informed Consent Documents, and Fees List. All such
            applicable terms, policies and rules are considered part of these
            Terms and may be updated, added to, or deleted, from time to time
            without notice to you. All updated/amended terms, policies and rules
            will however be accessible on Our Site.
            <br />
            <br />
            3.3. To use Ingeneous’ Services, you must first agree to the Terms.
            You cannot use the Services if you do not accept the Terms. The
            Terms can be accepted by:
          </p>
          <ul>
            <li>
              (a) Clicking to accept or agree to the Terms (where this option is
              available);
            </li>

            <li>(b) acknowledgment of the Informed Consent Document;</li>

            <li>(c) purchasing a Service; and/or</li>

            <li>
              (d) actual use of the Services. In this case, you accept and agree
              that Ingeneous will treat your continued use of the Services as
              implied acceptance of the Terms from that point onward. In
              addition, when using particular Services, you shall be subject to
              any guidelines or rules applicable to such Services that may be
              posted on Our Site from time to time. All such guidelines or rules
              are hereby incorporated by reference into the Terms. Ingeneous may
              offer other Services from time to time that are governed by
              different terms of services. The terms for such Services are
              subject to revision or change, like the rest of the Terms, without
              prior notice to you.
            </li>
          </ul>
          <p>
            3.4. Currently the Services cannot be purchased through Our Site.
          </p>

          <Spacer height={'20px'} />
          <h5>4. PREREQUISITES</h5>
          <p>
            4.1. Clients under the age of 16 years may not use the Services
            unless Ingeneous receives the written consent of the parent or
            guardian of the prospective Underage Client. By using the Services,
            you represent and warrant that you are at least 16 years of age and
            have the right, authority and capacity, including on behalf of
            another person, to enter into these Terms and to abide by the terms
            and conditions of these Terms.
            <br />
            <br />
            4.2. You may not use the Services for or on behalf of another person
            or organisation, unless that person is an Underage Client and you
            are their parent or guardian.
            <br />
            <br />
            4.3. Clients who are pregnant, breastfeeding or intending to become
            pregnant or breastfeed may only purchase and use the Guided
            Essentials in conjunction with an Ingeneous Medical Practitioner or
            a Medical Practitioner chosen by the Client.
            <br />
            <br />
            4.4. Clients with any pre-existing medical condition(s) are required
            to use the Guided Essentials in conjunction with an Ingeneous
            Medical Practitioner, or a Medical Practitioner chosen by the
            Client.
            <br />
            <br />
            4.5. You may only use the Services if you are a New Zealand resident
            or citizen, located and living in New Zealand. Clients resident or
            living in countries outside New Zealand will not be permitted to use
            the Services.
          </p>

          <Spacer height={'20px'} />
          <h5>5. THE SERVICES</h5>
          <p>
            5.1. The Services include access to Our Site and its personal
            genetic services, which include the collection, extraction, and
            analysis of your Genetic Information, based on a saliva sample
            provided by:
          </p>
          <ul>
            <li>
              (a) Requesting, completing and returning a Sample Collection Kit
              from Ingeneous. Completed Sample Collection Kits must be returned
              to Ingeneous within six months after you request the Sample
              Collection Kit. Sample Collection Kits returned after that date
              will not be processed.
            </li>

            <li>
              (b) The electronic transmission of your Genetic Information from a
              Third Party Genetic Profile as you direct.
            </li>
          </ul>
          <p>
            5.2. Once you have provided a Sample Collection Kit to Ingeneous or
            our laboratories we will use gene sequencing to read your DNA. This
            will be utilised for your Results, which we will provide to you.
            <br />
            <br />
            5.3. If you select the option listed at cl 5.1(b), you acknowledge
            and agree that a Third Party Genetic Profile may not provide all
            information required by Ingeneous to provide any or all of the
            Services. Ingeneous does not guarantee that a Third Party Genetic
            Profile will provide the same results, accuracy or assistance that
            you will receive if you choose the option selected at cl 5.1(a).
            Ingeneous reserves the right, at its sole discretion, not to accept
            a Third Party Genetic Profile and may require you to use, at your
            own cost, a Sample Collection Kit in order to utilise the Services.
            Under no circumstances will Ingeneous be associated with or
            responsible for the contents, results, accuracy or validity of a
            Third Party Genetic Profile.
            <br />
            <br />
            5.4. Unless expressly stated, each new feature that is added to the
            current Services, including new Products, will be subject to the
            Terms. You accept and agree that the Services are provided “as-is”
            and are based solely on current genetic research and technology used
            by Ingeneous when using the Services.
            <br />
            <br />
            5.5. You accept that circumstances may change and agree that the
            Services might change from time to time without any prior notice to
            you. You accept and agree that Ingeneous might stop (permanently or
            temporarily) providing some Services (or features within the
            Services) to its Clients without prior notice.
            <br />
            <br />
            5.6. You can stop using the Services at any time. You do not need to
            inform Ingeneous when you stop using the Services, unless you
            request to close your account. Ingeneous does not have any
            responsibility for the use of the Services outside the Terms or any
            other terms that apply.
            <br />
            <br />
            5.7. In order to access the Services, you need to ensure that you
            have the right devices, software and apps and adequate Internet
            access, for which Ingeneous is not responsible. Ingeneous may
            provide advice or guidance as to any technical requirements to
            access the Services at any time, at its sole discretion. You agree
            that you will be responsible to upgrade and/or configure your
            devices, software, apps and Internet service to meet these
            requirements, in order to access the Services
            <br />
            <br />
            5.8. The Products have differing functionality based on each
            Client’s subscription level. Each Service typically requires a
            different Fee, as set out in the Fees List. This must be paid before
            a Client may access that Service. Ingeneous reserves the right, at
            its sole discretion, to change the Services and Fees from time to
            time without prior notice to you.
            <br />
            <br />
            5.9. The Ingeneous Phone Line is only available for those Clients
            who have purchased Ingeneous Membership.
            <br />
            <br />
            5.10. Ingeneous uses Shopify to process online payments. The Shopify
            Payments card processing service supports most New Zealand- issued
            cards including credit, debit, pre-paid, or gift cards. Shopify will
            only process card transactions that have been authorised by the
            applicable Payment Network or card issuer. It is your sole
            responsibility to verify the identity of persons who utilise your
            payment method and of the eligibility of a presented payment card
            used to purchase Ingeneous’ Services. You, and not Shopify nor
            Ingeneous, are liable if your transactions are authorised and
            completed and then later be reversed or charged back for any reason.
            Shopify may add or remove one or more types of cards as a supported
            payment card any time without prior notice to you.
            <br />
            <br />
            5.11. It is your responsibility to provide us with accurate and
            valid credit card details or other payment information. You will
            update your credit card or other payment information where it is
            incomplete or becomes invalid or outdated. If any charge is rejected
            by our bank or payment providers, you will remain liable to pay the
            Fees.
            <br />
            <br />
            5.12. We retain the right to charge interest on any overdue balance
            at the rate of the higher of 1.5% per month, or the maximum amount
            permitted by law. You will also be responsible for our reasonable
            costs of collection, including legal and debt collection fees.
            <br />
            <br />
            5.13. Your order is not accepted by Ingeneous until you receive a
            Confirmation Email from us. You may cancel your order at any time
            before you receive a Confirmation Email from us. We reserve the
            right to accept, refuse or cancel any orders placed through the
            Service, without liability or justification. We will however refund
            you should we cancel your order after your payment method has been
            charged. You must request a refund in writing.
            <br />
            <br />
            5.14. Your estimated delivery date for your results will be as set
            out in the Confirmation Email. We cannot guarantee that the results
            will make it in the timeframe estimated. Your order will be complete
            when we make your results available to you.
            <br />
            <br />
            5.15. A refund for any product or Service will no longer be
            available to you once you receive a Confirmation Email. However,
            Ingeneous will meet its obligations under the Consumer Guarantees
            Act 1993 and the Fair Trading Act 1986.
            <br />
            <br />
            5.16. Ingeneous will comply with the Health and Disability
            Commissioner (Code of Health and Disability Services Consumers’
            Rights) Regulations 1996.
          </p>

          <Spacer height={'20px'} />
          <h5>6. RISKS AND CONSIDERATIONS</h5>
          <p>
            6.1. While Ingeneous endeavours to adhere to best practice, genetics
            research is still in its relative infancy and scientific knowledge
            and know-how continues to rapidly evolve. As a result, Genetic
            Information is subject to significant limitations. You acknowledge
            and accept that you rely on information provided by Ingeneous and
            Ingeneous Affiliates at your own risk.
            <br />
            <br />
            6.2. Ingeneous receives, based on its Algorithm, only a small
            selection of genetic variants specifically associated with wellness
            traits. As our Services interpret Genetic Information based on
            published scientific studies, some of the interpretations that we
            provide may not be applicable to all Clients. In addition, future
            genetic research may change how DNA is interpreted by Ingeneous.
            <br />
            <br />
            6.3. Genetic Information is only one aspect of an individual’s
            entire health and fitness outlook and should not be assumed to be
            conclusive.
            <br />
            <br />
            6.4. Ingeneous does not guarantee the effectiveness of any specific
            course of action, procedure, opinion, or other information that may
            be mentioned on Our Site or provided by an Ingeneous Certified
            Practitioner. Any guidance or recommendations we provide to you is
            intended for information and educational purposes only. No medical
            advice is provided by Ingeneous, unless provided by an Ingeneous
            Medical Practitioner. Genetic Information reported has not been
            clinically validated.
            <br />
            <br />
            6.5. Health, fitness, nutrition or wellness behaviours should not be
            altered solely based on the information provided by Ingeneous. You
            should discuss your results with an appropriate Ingeneous Certified
            Practitioner, or your own Medical Practitioner before taking any
            steps or altering any behaviours. Factors, such as other (possibly
            unknown) genetic markers, environmental factors and lifestyle
            choices, also affect wellness traits.
            <br />
            <br />
            6.6. In some cases, Genetic Information may provide knowledge and
            information that may be unexpected or cause strong emotions or
            anxiety. You should consider the impact the Information may have on
            you, and seek counselling services if necessary, before using the
            Services.
            <br />
            <br />
            6.7. During the course of testing, we collect information on a
            selected range of SNPs. Each SNP has a particular purpose and each
            Service may focus on particular group or selection of SNPs. Testing
            each SNP may result in additional genetic findings not related to
            the primary purpose of sequencing. We will not report information to
            you that is not related to the original purpose of our testing.
            Ingeneous reserves the right, at its sole discretion, to change,
            amend, remove, modify, or add to the list of SNPs it tests for each
            Service without any notice to you. You can read more about this in
            the introduction to these Terms.
            <br />
            <br />
            6.8. Sharing Genetic Information with companies, family, friends or
            employers should be done with caution as it may be used against your
            best interests. Before doing so you should carefully read our
            Privacy Policy and any information on Our Site. You take full
            responsibility for all possible consequences resulting from your
            sharing with others access to your Genetic Information and your
            Health Input Data.
            <br />
            <br />
            6.9. Genetic Information shared with a Medical Practitioner,
            including an Ingeneous Medical Practitioner, may become part of your
            medical records and can potentially be accessed by third party
            health care providers or insurance companies.
            <br />
            <br />
            6.10. The technology used by Ingeneous, which is also used by the
            research community, has not been used for clinical testing. To
            accelerate the understanding and the applications of genetic
            knowledge for clinical use, we may use Genetic Information of our
            Clients for Ingeneous Research. By accepting these Terms, you agree
            that your Information may be used in Ingeneous Research.
          </p>

          <Spacer height={'20px'} />
          <h5>7. CLIENT REPRESENTATIONS</h5>
          <p>
            7.1. By accessing the Services, you agree to, acknowledge, and
            represent as follows:
          </p>
          <ul>
            <li>
              (a) You understand that the information you obtain from Ingeneous
              is not designed to diagnose, prevent, or treat any conditions or
              to discover the state of your health.
            </li>

            <li>
              (b) You understand that the Services are only for research,
              informational, and educational purposes and that while information
              provided by the Services might suggest some propensity towards a
              particular illness or health condition, or point to a potential
              diagnosis or treatment, it should always be confirmed and assessed
              by clinical and medical information and testing, including by
              consultation with a Medical Practitioner.
            </li>

            <li>
              (c) You understand that Ingeneous strongly encourages you to seek
              the advice of an Ingeneous Medical Practitioner or your own
              Medical Practitioner.
            </li>

            <li>(d) You confirm that you are 16 years of age or older.</li>

            <li>
              (e) You guarantee that the sample you provide is coming from your
              saliva, or if you are providing a sample of an Underage Client in
              your capacity as a parent or guardian, you guarantee that the
              sample provided comes from and belongs to the Underage Client and
              you have legal authority to act on the Underage Client’s behalf.
            </li>

            <li>
              (f) You acknowledge that any saliva sample that you provide, and
              its resulting information can be transferred and/or processed
              outside New Zealand.
            </li>

            <li>
              (g) You guarantee that you do not, directly or indirectly,
              represent an insurance company or an employer and are not trying
              to obtain information about an insured person or an employee.
            </li>

            <li>
              (h) You understand that Ingeneous reserves the right to remove or
              instruct removal without notice any public posts or communications
              that are deemed in violation of the Terms.
            </li>

            <li>
              (i) You acknowledge that your Personal Information will be kept in
              Ingeneous databases and will be processed in accordance with the
              Ingeneous Privacy Policy.
            </li>

            <li>
              (j) You acknowledge that by accessing the Services in any way,
              including by agreeing to these Terms, you do not have any
              proprietary or other rights in any data, research, know-how or
              products that might be analysed or developed by Ingeneous or its
              Affiliates. You can read more about our Affiliates in the
              introduction to these Terms.
            </li>

            <li>
              (k) You agree that you have the authority under New Zealand law to
              make these representations. In case you break any of these
              representations or breach these Terms in any manner, Ingeneous has
              the right to terminate your account and refuse the future use of
              the Services. You will indemnify and hold harmless Ingeneous and
              its Affiliates against any liability or damages that may arise in
              case these Terms are broken.
            </li>
          </ul>

          <Spacer height={'20px'} />
          <h5>8. ACCOUNT MAINTENANCE, PASSWORDS AND SECURITY OBLIGATIONS</h5>
          <p>8.1. You agree to:</p>
          <ul>
            <li>
              (a) provide accurate and current Registration Information about
              yourself, or the Underage Client whom you represent, as requested
              by the Services; and
            </li>

            <li>
              (b) maintain and update the Registration Information you provide
              to keep it accurate and current.
            </li>
          </ul>
          <p>
            If you provide information that is inaccurate, incomplete or not
            current, or if Ingeneous suspects that your Registration Information
            is inaccurate, incomplete and not current, Ingeneous has the right
            to terminate or suspend your account and refuse any current or
            future use of the Service.
            <br />
            <br />
            8.2. It is your responsibility to maintain the confidentiality of
            your login credentials for your account. You are also responsible
            for any activities that occur using your account. If you allow
            others to access Our Site through your account, you will indemnify
            Ingeneous and its Affiliates against any liability or costs arising
            from claims by other people based on the access and use of your
            account.
            <br />
            <br />
            8.3. Through your account profile, you can access Personal
            Information, set your browser settings to determine how we track
            your web behaviour, opt out of direct marketing and Ingeneous
            Research and choose when to share information on public forums,
            discussion boards and social media.
            <br />
            <br />
            8.4. You agree to:
          </p>
          <ul>
            <li>
              (a) immediately notify Ingeneous of any actual or suspected use of
              your password or account that has not been authorized; and
            </li>

            <li>(b) exit from your account after each session.</li>
          </ul>
          <p>
            Ingeneous expressly disclaims all liability for any damage arising
            from not following this section.
          </p>

          <Spacer height={'20px'} />
          <h5>9. Ingeneous PRIVACY STATEMENT AND DISCLOSURE OF INFORMATION</h5>
          <p>
            9.1. Ingeneous is committed to protecting Client privacy and abides
            by the principles of the Privacy Act 2020. Ingeneous operates a
            strict confidentiality policy in relation to all Results and any
            Personal Information. Our Privacy Policy applies to all new and
            existing Clients.
            <br />
            <br />
            9.2. To use any Service, you must agree to the Privacy Policy. You
            may not use the Services if you do not accept the Privacy Policy as
            it forms part of the Terms. You will be required to acknowledge and
            accept the Privacy Policy when signing up to a Ingeneous Product and
            by continued access of the Ingeneous Services.
            <br />
            <br />
            9.3. The Privacy Policy will be updated from time to time. Material
            changes will be notified by Our Site or by email to existing Clients
            if appropriate. You may request to discontinue use of the Services
            if you do not accept the changes. Your continued access to or use of
            Our Site and/or Services after the effective date will mean that you
            agree to any updates or changes.
            <br />
            <br />
            9.4. Previous versions of the Privacy Policy are available on
            request.
            <br />
            <br />
            9.5. Ingeneous personnel, including any Ingeneous Affiliates, can
            and may monitor the use of its systems and can keep copies of such
            monitoring.
            <br />
            <br />
            9.6. You may refer to the Privacy Policy to learn more about how
            Ingeneous protects its data as it relates to all your Personal
            Information.
          </p>

          <Spacer height={'20px'} />
          <h5>10. LIMITED LICENSE</h5>
          <p>
            10.1. You acknowledge that all the Client Content, whether public or
            private, is the responsibility of the author or poster of the
            particular Client Content. Ingeneous is not responsible for any
            Client Content.
            <br />
            <br />
            10.2. You acknowledge that the content from the Services available
            to you is protected by copyright and intellectual property rights
            owned by Ingeneous. You may not share any of the content unless you
            receive the express prior written permission of Ingeneous. You may
            not use the Services in any way, including but not limited to by
            modifying, leasing, selling, distributing or creating works based on
            the Services content, or any part thereof, unless you have received
            and signed an express written contract or agreement with Ingeneous
            to do so.
          </p>

          <Spacer height={'20px'} />
          <h5>11. CLIENT CONDUCT – UNLAWFUL AND PROHIBITED USE</h5>
          <p>
            11.1. You warrant and guarantee that you will not use the Services
            for any unlawful or prohibited purposes, including those listed in
            cl 11.2 below. You are prohibited from using the Services, or any
            part of them, in any way that is not expressly permitted by these
            Terms, including by attempting to or actually damaging or impairing
            the Services, or interfering with another Client or person’s use of
            the Services. You may not try to or obtain any information through
            any ways that are not made available explicitly through the Service.
            <br />
            <br />
            11.2. In addition to cl 11.1 above, you specifically agree to not
            use the Services to:
          </p>
          <ul>
            <li>
              (a) upload, post or share any obscene, offensive material, or
              material that is discriminatory under the Human Rights Act 1993;
            </li>

            <li>
              (b) impersonate, or misrepresent your affiliation with, another
              person, including an Ingeneous Affiliate;
            </li>

            <li>
              (c) disguise, in any way, the origin of any content that is shared
              through the Service;
            </li>

            <li>(d) stalk or harass another person;</li>

            <li>
              (e) upload, post or share any content that you cannot lawfully
              transmit;
            </li>

            <li>
              (f) use information that you receive through the Services to try
              to identify or contact other clients or customers (except when
              using features designed by Our Site or the App for that purpose
              explicitly);
            </li>

            <li>
              (g) download any files or disseminate any information that is
              shared by other Clients that, to your knowledge, you may not
              legally download or distribute;
            </li>

            <li>
              (h) infringe any patent, trade mark, copyright or other
              proprietary rights of Ingeneous and other parties;
            </li>

            <li>(i) harm minors;</li>

            <li>
              (j) advertise, buy or sell any goods or services for business
              purposes, unless you receive prior written consent from Ingeneous
              to do so;
            </li>

            <li>
              (k) upload, post or share any unauthorised advertising, such as
              junk mail or spam, or any content that may breach the Unsolicited
              Electronic Messages Act 2007, unless you receive the express prior
              written consent of Ingeneous to do so;
            </li>

            <li>
              (l) upload, post or share any information that contains viruses or
              any other codes, files or programs used to destroy or limit the
              functionality of a computer or hardware equipment;
            </li>

            <li>
              (m) use software, devices or services to interact with the
              Services or Ingeneous’ servers, including by overriding, or
              attempting to override, security components in the Services;
            </li>

            <li>
              (n) engage in framing or simulating the function of Our Site;
            </li>

            <li>
              (o) interfere or disrupt the Services, networks, or Clients that
              are connected to the Services, or disobey any requirements,
              policies, or regulations of networks that are connected to the
              Service;
            </li>

            <li>
              (p) violate the Terms or any other applicable guidelines that
              apply to the area of Service or have been communicated to you by
              anyone from Ingeneous; or
            </li>

            <li>(q) violate any law, or any regulations within the law.</li>
          </ul>
          <p>
            11.3. You acknowledge and accept that you (and not Ingeneous nor any
            Ingeneous Affiliate) are responsible for any violation of your
            obligations under the Terms and for the consequences of any
            violations, including any damage suffered by Ingeneous. Where
            Ingeneous reasonably suspects or knows that you have violated the
            Terms, Ingeneous may, at its sole discretion, suspend or terminate
            your account and refuse any future Service use. You will indemnify
            Ingeneous and the Ingeneous Affiliates against any costs or damages
            that might arise from the violations.
          </p>

          <Spacer height={'20px'} />
          <h5>12. INFORMATION POSTED THROUGH THE SERVICE</h5>
          <p>
            12.1. Ingeneous does not control the Client Content, nor does it
            guarantee the accuracy or quality of the Client Content or any other
            outside content not specifically provided by Ingeneous. You
            acknowledge that using the Services may expose you to offensive and
            indecent content and that, under no circumstance, is Ingeneous
            responsible for any outside content.
            <br />
            <br />
            12.2. You understand that Ingeneous may, in its sole discretion,
            review, modify, pre-screen or refuse any content that is available
            through the Services. Ingeneous and its designees have the right to
            remove any content that is violating the Terms at Ingeneous’ sole
            discretion. You agree to evaluate and understand all the risks
            associated with content use, including accuracy or usefulness of the
            content.
          </p>

          <Spacer height={'20px'} />
          <h5>13. YOUR PROPERTY RIGHTS OVER MATERIAL PROVIDED TO Ingeneous</h5>
          <p>
            13.1. Ingeneous does not have any ownership of any Client Content
            unless otherwise stated. Any copyright over the Client Content is
            owned by you, or other third parties (who may or may not be an
            Ingeneous Affiliate) as are other rights already possessed by you
            over Client Content. Regardless, you give Ingeneous an irrevocable
            royalty free license to use any Client Content in any way that
            Ingeneous chooses. You understand and accept that this license gives
            Ingeneous the right to make the Client Content available to
            Ingeneous Affiliates, and use the Client Content in connection with
            their services.
            <br />
            <br />
            13.2. You acknowledge that while performing required technical steps
            to provide Services to Clients, Ingeneous may:
          </p>
          <ul>
            <li>
              (a) distribute your Client Content over several public networks
              and media; and
            </li>

            <li>
              (b) make changes to your content to conform and adapt to the
              requirements of networks, services or media. You understand and
              accept that this license permits Ingeneous to take these actions.
              You give this right and power to Ingeneous to grant the license
              stated here.
            </li>
          </ul>
          <p>
            13.3. We cannot, and will not, disclose individual Genetic and/or
            Health Input Data to any Ingeneous Affiliate who is not directly
            employed by Ingeneous without consent unless required by law (as
            described in our Privacy Policy and in line with the Privacy Act
            2020). However, you acknowledge that if you share any such
            Information publicly, you may cease to have control over the further
            dissemination of the Information. Ingeneous is not responsible for
            any consequences of you sharing your Information.
            <br />
            <br />
            13.4. Once you submit your Sample Collection Kit and Ingeneous has
            analysed it, the sample is irreversibly processed and it cannot be
            returned to you. Any Genetic Information taken from your sample
            remains your property but is subject to the rights that are
            expressed in these Terms. You acknowledge that you should not expect
            any type of financial benefit from Ingeneous from having your
            Genetic Information or Health Input Data processed or shared with
            applicable Ingeneous Affiliates as detailed in the Privacy Policy
            and Informed Consent Document.
            <br />
            <br />
            13.5. You acknowledge, agree and accept that Ingeneous will not
            provide you with the raw data extracted from your Genetic
            Information but will rather provide you with the Result in a format
            chosen at Ingeneous’ sole discretion. However, if you would like to
            see your raw data you may request a copy of it from Ingeneous in
            writing. The raw data may not be comprehensible to the Client and
            Ingeneous accepts no liability from such raw data provided to the
            Client.
            <br />
            <br />
            13.6. You acknowledge that by accessing or using the Services, you
            have no rights whatsoever in any products that might be developed by
            Ingeneous or any Ingeneous Affiliates. You acknowledge that you will
            not receive any compensation for research or commercial products
            that result from your Genetic Information or Health Input Data.
          </p>

          <Spacer height={'20px'} />
          <h5>14. INDEMNITY</h5>
          <p>
            14.1. You agree to defend and hold harmless, individually and
            together, Ingeneous and Ingeneous Affiliates from any claims,
            including attorney’s fees, made by or against any Ingeneous
            Affiliate from the Client Content submitted or shared through the
            Service, your Service use, your Service connection, your Terms
            violations, or any other rights violation(s).
            <br />
            <br />
            14.2. If you have provided Ingeneous with a Sample Collection Kit or
            a Third Party Genetic Profile, you will indemnify Ingeneous and its
            Affiliates from any liability from the use or disclosure of any
            information obtained from the genetic analysis of your saliva
            sample, or the analysis of your Genetic Information.
            <br />
            <br />
            14.3. You further agree that you will indemnify and hold harmless
            Ingeneous and its Affiliates from any harm, damage, costs and
            expenses derived from or attributable to your violation any portion
            of these Terms.
            <br />
            <br />
            14.4. Additionally, if you want to provide your Genetic Information
            and/or Health Input Data to third parties who are not Ingeneous
            Affiliates, you agree to indemnify Ingeneous and the Ingeneous
            Affiliates from any liability resulting from such disclosure of your
            Genetic Information and/or Health Input Data.
          </p>

          <Spacer height={'20px'} />
          <h5>15. RESALE OR GIFTING OF SERVICE</h5>
          <p>
            15.1. Other than pursuant to the Limited License set out in cl 10 of
            the Terms or any other part of the Terms, you agree not to use or
            access the Services in any way for any commercial purposes.
            <br />
            <br />
            15.2. The Services must be provided and purchased via an approved
            source. If you sell or purchase your Service without permission from
            Ingeneous, via an unapproved third party, who is not an Ingeneous
            Affiliate (for the purposes of clause 15, “an unapproved third
            party”) or have received the Sample Collection Kit as a gift,
            Ingeneous reserves the right to decline the processing of samples
            and/or issuing of reports.
            <br />
            <br />
            15.3. If you purchase any of the Services through an unapproved
            third party, the purchase agreement is between the seller and buyer
            and Ingeneous is not liable for any disputes, including relating to
            pricing, payment refunds, transactions or delivery. If you purchase
            through an unapproved third party, Ingeneous will not issue a refund
            or replacement to you under any circumstances. Ingeneous will
            process any samples obtained through an unapproved third party at
            its sole discretion, in which case you will agree to the Terms.
            However, the original purchase agreement will remain between buyer
            and seller. Therefore, if for any reason a refund is sought after
            processing, this will not be honoured.
          </p>

          <Spacer height={'20px'} />
          <h5>16. GENERAL PRACTICE REGARDING USE AND STORAGE</h5>
          <p>
            16.1. You understand that Ingeneous and Ingeneous Affiliates may
            establish practices and limits concerning Service uses, including
            but not limited to the maximum number of days that Personal
            Information and Services content are retained by the Service, the
            maximum disk space your account may occupy, and the maximum number
            of times you can access the Services in each period. Neither
            Ingeneous nor Ingeneous Affiliates have any responsibility or
            liability for the deletion of messages or content maintained or
            shared by the Services, or for the loss of Genetic Information
            because of the malfunction of data servers or other events.
            Ingeneous has the right to change these practices and limits at its
            own discretion and without notice to you.
          </p>

          <Spacer height={'20px'} />
          <h5>17. SERVICE MODIFICATIONS</h5>
          <p>
            17.1. Ingeneous has the right to modify, suspend or discontinue the
            Services at any time with or without any prior notice to you. You
            understand and accept that:
          </p>
          <ul>
            <li>
              (a) modifications may result in delays with the Services; and
            </li>

            <li>
              (b) Ingeneous is not liable to you or any other person for any
              modification, suspension or discontinuation of the Services.
            </li>
          </ul>
          <p>
            17.2. You understand that Ingeneous or Ingeneous Affiliates may
            offer different or additional software or features to analyse your
            Genetic Information in the future and that you may be required to
            pay an additional Fee to access or use them, including for the
            access or use of additional Sample Collection Kits or Genetic
            Information.
            <br />
            <br />
            17.3. You accept that Ingeneous or Ingeneous Affiliates have the
            right to modify or update its genetic testing panel with the latest
            science, which might significantly change your results or require
            your data to be reanalysed. We will notify you of these changes.
            <br />
            <br />
            17.4. Ingeneous may amend its Fees at any time for any purpose. It
            will meet its obligations under the Consumer Guarantees Act 1993.
          </p>

          <Spacer height={'20px'} />
          <h5>18. TERMINATION</h5>
          <p>
            18.1. The Terms will apply until Ingeneous or you terminate your
            obligations as described in this section or elsewhere in these
            Terms.
            <br />
            <br />
            18.2. You may terminate your legal agreement with Ingeneous by
            providing a written notice to Ingeneous by sending it to
            hello@ingeneous.co.nz. Your agreement will be terminated when you
            receive written confirmation from us.
            <br />
            <br />
            18.3. Ingeneous can terminate its legal agreement with you at any
            time, as well as revoke your password and terminate your account if:
          </p>
          <ul>
            <li>
              (a) you violate any provision of the Terms or act in a way that
              shows you cannot or do not intend to comply with the provisions of
              the Terms;
            </li>

            <li>(b) Ingeneous is required to do so by law;</li>

            <li>
              (c) an Ingeneous Affiliate that offered the Services terminates
              its relationship with Ingeneous or stops offering the Services to
              you; or
            </li>

            <li>
              (d) the Service provided to you by Ingeneous is, in Ingeneous’
              sole discretion, regarded as no longer commercially viable.
            </li>
          </ul>
          <p>
            18.4. Any suspected illegal activity by you is grounds for
            terminating your use of the Services and will be referred to
            appropriate law enforcement authorities.
            <br />
            <br />
            18.5. You understand that Ingeneous will not be liable to you or any
            person for terminating Service use.
          </p>

          <Spacer height={'20px'} />
          <h5>19. TERM SURVIVAL</h5>
          <p>
            19.1. Any accrued obligations and remedies set out in these Terms,
            and any other provisions that by their nature should survive, shall
            survive the termination or expiration of this Agreement.
          </p>

          <Spacer height={'20px'} />
          <h5>20. DEALING WITH INFORMATION PROVIDERS AND LISTED RESOURCES</h5>
          <p>
            20.1. Any and all correspondence or business dealings with an
            Ingeneous Affiliate, including promotions, vendors, resellers and
            other resources in the Services are exclusively between you and such
            providers. You agree that Ingeneous is not, and cannot be held to
            be, responsible or liable for any loss or damage resulted from these
            dealings or as a result of this information provided on the
            Services.
          </p>

          <Spacer height={'20px'} />
          <h5>21. HYPERLINKS AND THE Ingeneous WEBSITE</h5>
          <p>
            21.1. The Service provides, and third parties (whether an Ingeneous
            Affiliate or not) may provide, links to other sites and resources on
            the Internet. You acknowledge and agree that Ingeneous is not
            responsible for the availability of such external sites or
            resources, and does not endorse and is not responsible or liable for
            any services, goods or content provided through or available from
            such sites or resources. You further acknowledge and agree that we
            shall not be responsible or liable for any damage or loss (alleged
            or actual) caused by or in connection with the use of or reliance on
            any such services, goods or content.
          </p>

          <Spacer height={'20px'} />
          <h5>22. Ingeneous’ PROPRIETARY RIGHTS</h5>
          <p>
            22.1. You understand and agree that Ingeneous owns all the legal
            rights and interests to the Services, including all intellectual
            property (“IP”) rights that relate to or subsist in the Services.
            You also understand that the Services can contain information or
            insights that are trade secrets and that are confidential to
            Ingeneous, which you may not disclose in whole or in part to any
            party whatsoever.
            <br />
            <br />
            22.2. You agree that the Services, and any other software used for
            the Services, contain information that is a trade secret,
            confidential and proprietary and is protected by IP and other laws.
            All information provided to you through the Services is protected by
            copyrights, trade marks, patents and other IP laws.
            <br />
            <br />
            22.3. You accept to not use in any way the information found on the
            Services. All the Software that can be downloaded through the
            Services and the App is copyrighted. Your Software use is governed
            by the terms of the License Agreement. You shall not try to install
            or use any Software that has a License Agreement without first
            agreeing to the License Agreement terms.
            <br />
            <br />
            22.4. Ingeneous and its logos, products and service names are trade
            marks owned by Ingeneous and these trade marks along with any other
            Ingeneous trade names, logos and brand features are “Ingeneous
            Marks”. Unless you have the explicit prior written permission of
            Ingeneous, you may not use the Ingeneous Marks in any way, including
            by displaying them or referring to them.
            <br />
            <br />
            22.5. You accept that you cannot and will not remove, obscure or
            alter any proprietary rights notices including copyright and trade
            mark notices that might be contained within the Services.
            <br />
            <br />
            22.6. You agree not to use any trade marks, trade names or logos (or
            any other proprietary rights) of any company or organization through
            the Services in a way that is likely to cause confusion about such
            marks, names and logos.
            <br />
            <br />
            22.7. For any Software that does not have a License Agreement,
            Ingeneous grants you a limited, personal, non-transferable right and
            license to use the code of its Software and app on one computer or
            device respectively. You may not allow a third party to use, alter
            or copy the Software or App in any way, including by attempting to
            discover any code or transfer any right in the Software, unless it
            is permitted by law.
            <br />
            <br />
            22.8. The limited license given under the License Agreement is
            provided so you can use and enjoy the Services provided by Ingeneous
            in the manner allowed and contemplated by the Terms, but for no
            other purpose. You may not assign your rights to use the Software,
            grant a security interest over the Software or transfer any part of
            your rights to use the Software. You agree not to modify the
            Software in any way or form or use modified versions of the
            Software, including for obtaining prohibited access to the Service.
          </p>

          <Spacer height={'20px'} />
          <h5>23. WARRANTY DISCLAIMER</h5>
          <p>23.1. You expressly acknowledge and agree that:</p>
          <ul>
            <li>
              (a) Your use of the Services is at your sole risk. Ingeneous
              expressly disclaims all warranties of any kind, whether express or
              implied, including, but not limited to, the implied warranties of
              merchantability, fitness for a particular purpose, and
              non-infringement.
            </li>

            <li>
              (b) Ingeneous does not warrant that:
              <ul>
                <li>
                  (i.) the Services will meet your own particular requirements;
                </li>

                <li>
                  (ii.) the Services will be uninterrupted, timely, unfailingly
                  secure, or error-free;
                </li>

                <li>
                  (iii.) the results that may be obtained from the use of the
                  Services will be accurate or reliable;
                </li>

                <li>
                  (iv.) the quality of any products, services, information, or
                  other material purchased or obtained by you through the
                  Services will meet your own particular expectations; and
                </li>

                <li>(v.) any errors in the software will be corrected.</li>
              </ul>
            </li>

            <li>
              (c) Any material downloaded or otherwise obtained through the use
              of the Services is done solely at your own discretion and risk.
              You are responsible for any damage caused to any devices
              (including but not limited to your phone, device or computer
              system) or any loss of data that results from the download of any
              such material.
            </li>

            <li>
              (d) No warranties are created through any advice or information,
              whether oral or written, received by you from Ingeneous or any
              related parties (including employees, contractors and
              subsidiaries) through or from the Services.
            </li>

            <li>
              (e) Ingeneous is not responsible for any advice (whether medical
              advice or otherwise) you receive from an Ingeneous Certified
              Practitioner or any Medical Practitioner based on the results you
              have received through the Services.
            </li>

            <li>
              (f) You will use caution when giving out any personally
              identifying information about yourself comprising the Personal
              Information or otherwise. Ingeneous does not control or endorse
              any actions resulting from your participation in the Services and
              disclaims any liability with regard to any actions resulting from
              your participation in the services.
            </li>

            <li>
              (g) Ingeneous collaborates with the Ingeneous Affiliates to
              provide a holistic service to assist Clients achieve better
              wellbeing. Ingeneous is separate from and independent of the
              Ingeneous Affiliates and each Affiliate is ultimately responsible
              for the goods and service they provide to the Client. Accordingly,
              Ingeneous is not responsible for and disclaims all liability for
              any claims against these parties.
            </li>
          </ul>

          <Spacer height={'20px'} />
          <h5>24. NOTICE</h5>
          <p>
            24.1. Notices can be provided to you through email or regular mail.
            Ingeneous might also, where it is required to by these Terms, give
            notices of changes to its Terms or other matters by sending notices
            or links to you or through the Services.
            <br />
            <br />
            24.2. Any notices to be sent to Ingeneous must be sent to:
            <br />
            <br />
            hello@ingeneous.co.nz
            <br />
            <br />
            24.3. This address may be used for service for any proceedings.
          </p>

          <Spacer height={'20px'} />
          <h5>25. CHANGES TO THE TERMS OF SERVICE</h5>
          <p>
            25.1. Ingeneous will make changes to the Terms from time to time. It
            is entitled to do so at its sole discretion and without prior notice
            to you. A copy of the new Terms will be made available and be
            accessible on Our Site.
            <br />
            <br />
            25.2. You understand and accept that you have an obligation to
            regularly access Our Site to keep yourself up-to-date as to
            developments and changes to the Terms and your continued use of the
            Services after the date the Terms was changed constitutes acceptance
            and agreement of the updated Terms.
          </p>

          <Spacer height={'20px'} />
          <h5>26. GENERAL PROVISIONS</h5>
          <p>
            26.1. The Terms represent the entire agreement between you and
            Ingeneous. The Terms govern your Service uses and replaces any prior
            representations, arrangements or agreements made between you and
            Ingeneous on this matter, whether oral or in writing. You can also
            be subject to additional terms and conditions that may apply when
            you use the Services, content or software.
            <br />
            <br />
            26.2. All disputes or other matters arising from or relating to
            these Terms, or the use or operation of the Service, will be
            governed by the substantive laws of New Zealand and subject to the
            exclusive jurisdiction of the New Zealand courts.
            <br />
            <br />
            26.3. Ingeneous’ failure to exercise or enforce any right or
            provision of the Terms shall not constitute a waiver of such right
            or provision.
            <br />
            <br />
            26.4. You agree that regardless of the provisions of the Limitation
            Act 2010 or any other law that states the contrary, any claim or
            cause of action from or related to the Services or the Terms must be
            filed within one year after the claim or the cause of action arises.
            <br />
            <br />
            26.5. If any Terms portion is found to be null, void, voidable or
            unenforceable, the portion that remains is in full force and effect.
            <br />
            <br />
            26.6. You may not assign any rights or obligations under these Terms
            and any purported. assignment and delegation shall be deemed
            ineffective. Ingeneous may assign or delegate all rights and
            obligations under the Terms at its sole discretion without notice to
            you. We can also substitute (upon notice to you) Ingeneous for any
            person that will assume our rights and obligations under this
            Agreement.
            <br />
            <br />
            26.7. In no event will we be liable for any failure to comply with
            this Agreement to the extent that such failure arises from factors
            outside our reasonable control, including any factors relating to an
            outbreak of disease, natural disaster or weather event. Without
            limitation of the foregoing, we will not be liable for any damages
            or other relief arising from or related to the acts of hackers or
            similar bad actors interfering with the Service or using or
            disclosing Client Content.
          </p>

          <Spacer height={'100px'} />
        </Wrapper>
      </Col>
    </StyledRow>
  );
}

export default TermsConditions;
