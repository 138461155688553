
export const heroGreen  = '#1AA88C'
export const yellow     = '#eed698'
export const pink       = '#deb4b6'
export const blue       = '#abdcd6'
export const tan        = '#f1e6e0'
export const link       = '#3886b7'
export const error      = '#af2e34'
export const alert      = '#E5864B'

export const primary    = heroGreen
export const secondary  = '#F4CBC7'
export const tertiary   = '#E4EDEB'

export const white   = '#FFFFFF'
export const gray   = '#707070'
export const borderGray   = '#CBCAC9'

export const footerHeight = '3.5rem'
