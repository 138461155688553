import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { white, borderGray, gray } from 'styles/theme';

const Wrapper = styled.div`
  background-color: ${white};
  padding: ${isMobile ? '10px' : '30px'};
  border-radius: 5px;
  border: 1px solid ${borderGray};
  color: ${gray};
  position: relative;

  h4 {
    color: ${gray};
  }

  p {
    font-size: 1rem;
    margin: 0;
  }
`

const Image = styled.img`
  margin: 0 auto;
`

const FirstOrder = () => {
  return (<div>
    <h3>Get Started</h3>
    <Wrapper>
      <Row>
        <Col md={8}>
          <h4>Your Test Kit</h4>
          <p>
            To be able to give you deep insights into your body, you first need to complete the initial test kit. This will come with your first report.
            <br /><br />
          </p>
          <Link to={'/order-details'}>
            <Button variant="primary">Order Reports</Button>
          </Link>
        </Col>
        <Col md={4}>
          <Image src={process.env.PUBLIC_URL + '/assets/images/Wellness-Art.png'} />
        </Col>
      </Row>
    </Wrapper>
  </div>)
}

export default FirstOrder
