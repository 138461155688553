import React, { useState } from 'react';
import styled from 'styled-components';
import { gray } from 'styles/theme';

import ReadMore from '../View';
import ReadLess from '../ViewLess';

import Low from './Low';
import Moderate from './Moderate';
import High from './High';

interface BingeEatingProps {
  results: any;
}

const Alert = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const WrapperRef = styled.div`
  margin-top: 20px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const List = styled.li`
  padding-left: 15px;
  font-weight: 300;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    font-size: 14px;
    word-break: break-all;
  }
`;

const SubtitleGray = styled.h3`
  color: ${gray};
  margin-top: 10px;
`;

const WrapperResult = styled.div`
  position: relative;
`;

const BingeEating = ({ results }: BingeEatingProps) => {
  const [showReferences, setShowReferences] = useState(false);

  let BingeEatingType;
  if (results.binge_eating === 'high risk') {
    BingeEatingType = <High results={results.binge_eating} />;
  } else if (results.binge_eating === 'low risk') {
    BingeEatingType = <Low results={results.binge_eating} />;
  } else {
    BingeEatingType = <Moderate results={results.binge_eating} />;
  }

  return (
    <WrapperResult>
      <SubtitleGray>Binge Eating</SubtitleGray>
      {BingeEatingType}

      <Alert className="no-print">
        <span>References</span>
        {!showReferences && (
          <ReadMore value={showReferences} setValue={setShowReferences} />
        )}
        {showReferences && (
          <ReadLess value={showReferences} setValue={setShowReferences} />
        )}
        {showReferences && (
          <WrapperRef>
            <ListGroup>
              <List>
                <a href="https://www.sciencedaily.com/releases/2016/10/161026170543.htm">
                  https://www.sciencedaily.com/releases/2016/10/161026170543.htm
                </a>
              </List>
            </ListGroup>
          </WrapperRef>
        )}
      </Alert>
      <hr className="no-print" />
    </WrapperResult>
  );
};

export default BingeEating;
