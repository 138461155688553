import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { gray, primary } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import View from '../View';
import Close from '../ViewLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const AlertRef = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
  margin-bottom: 30px;
`;

const SubtitleGray = styled.h5`
  color: ${gray};
  margin-top: 10px;
`;

const WrapperRef = styled.div`
  margin-top: 5px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const ListRef = styled.li`
  font-weight: 300;
  padding-left: 20px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    word-break: break-all;
  }
`;

interface ResProps {
  results: any;
}
const Resveratrol = ({ results }: ResProps) => {
  const [show, setShow] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/Supplement.png"
            alt="supplement"
            width={'60%'}
          />
        </ImageCol>
        <Col md={8}>
          <SubtitleGray>
            Your most powerful supplement for weight loss is
          </SubtitleGray>
          <Subtitle>Resveratrol</Subtitle>
          <Alert>
            <p>
              Suggested Supplement: Optimised Resveratrol, Life Extension (1
              capsule daily)
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <p>
                Resveratrol is a compound found that can also be found in
                berries, several fruits and nuts, and red wines. It has a
                property of preventing obesity and favouring weight loss by
                encouraging a reduction in blood insulin levels (1). It also has
                a powerful role in balancing inflammation which can help manage
                your weight (3). As an added benefit, resveratrol is not just
                good for encouraging weight loss, this molecule can also promote
                gene expression that favours longevity and an increased
                healthspan (4)! Resveratrol is naturally found in red wine,
                which is why some encourage drinking this for your health, this
                supplement can be even more powerful (5).
              </p>
              <p style={{ fontWeight: 'bold' }}>
                This supplement is best for your genes. If you have any concerns
                this may not suit your current state of health or medications
                check with your doctor.
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
      <Row className="no-print">
        <Col>
          <AlertRef>
            <span>References</span>
            {!showReferences && (
              <View value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <Close value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <WrapperRef>
                <ListGroup>
                  <ListRef>
                    Szkudelska K, Szkudelski T. Resveratrol, obesity and
                    diabetes. European journal of pharmacology. 2010 Jun
                    10;635(1-3):1-8.&nbsp;
                    <a href="https://www.sciencedirect.com/science/article/abs/pii/S0014299910002001">
                      https://www.sciencedirect.com/science/article/abs/pii/S0014299910002001
                    </a>
                  </ListRef>
                  <ListRef>
                    Aguirre López L, Fernández Quintela A, Arias Rueda N,
                    Portillo Baquedano MP. Resveratrol: Anti-Obesity Mechanisms
                    of Action.&nbsp;
                    <a href="https://www.mdpi.com/1420-3049/19/11/18632">
                      https://www.mdpi.com/1420-3049/19/11/18632
                    </a>
                  </ListRef>
                  <ListRef>
                    <a href="https://nz.iherb.com/pr/life-extension-optimized-resveratrol-60-vegetarian-capsules/8261">
                      https://nz.iherb.com/pr/life-extension-optimized-resveratrol-60-vegetarian-capsules/8261
                    </a>
                  </ListRef>
                  <ListRef>
                    Hector KL, Lagisz M, Nakagawa S. The effect of resveratrol
                    on longevity across species: a meta-analysis. Biology
                    letters. 2012 Oct 23;8(5):790-3. &nbsp;
                    <a
                      href="https://royalsocietypublishing.org/doi/full/10.1098/rsbl.2012.0316?url_ver=Z39.88-2003&rfr_id=ori%3Arid%3Acrossref.org&rfr
                      _dat=cr_pub%3Dpubmed&"
                    >
                      https://royalsocietypublishing.org/doi/full/10.1098/rsbl.2012.0316?url_ver=Z39.88-2003&rfr_id=ori%3Arid%3Acrossref.org&rfr
                      _dat=cr_pub%3Dpubmed&
                    </a>
                  </ListRef>
                  <ListRef>
                    Das DK, Mukherjee S, Ray D. Erratum to: resveratrol and red
                    wine, healthy heart and longevity. Heart failure reviews.
                    2011 Jul 1;16(4):425-35. &nbsp;
                    <a href="https://link.springer.com/content/pdf/10.1007/s10741-011-9234-6.pdf">
                      https://link.springer.com/content/pdf/10.1007/s10741-011-9234-6.pdf
                    </a>
                  </ListRef>
                </ListGroup>
              </WrapperRef>
            )}
          </AlertRef>
        </Col>
      </Row>
    </>
  );
};

export default Resveratrol;
