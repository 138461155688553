import React, { useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Spacer } from 'components/utils'
import { getTokenFromLink } from 'api/auth';
import { AuthContext } from 'stores/auth';

import { getAuth, signInWithCustomToken } from 'firebase/auth';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LoginLinkConfirmation = () => {
  let history = useHistory();
  const authStore = useContext(AuthContext);
  let query = useQuery();
  const auth = getAuth();

  useEffect(() => {
    const token = query.get('token');
    getTokenFromLink(token)
      .then(res => {
        signInWithCustomToken(auth, res.data?.access)
          .then((userCredential) => {
            const user = userCredential.user;
            authStore.login(user).then(() => {
              history.push('/');
            });
          })
          .catch((error) => {
            console.log(error);
          });
      })
  }, []);

  return (<div>
    <Spacer height={'60px'} />
  </div>)
};

export default LoginLinkConfirmation;

