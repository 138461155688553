import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Form, Alert, Button, Spinner } from 'react-bootstrap';
import styled from 'styled-components';

import { forgotPassword as forgotPasswordApi } from 'api/auth';

import { Spacer } from 'components/utils';

const Wrapper = styled.div`
  color: #ffffff;

  .btn-secondary {
    border-color: #ffffff;
  }
`

const FormTitle = styled.h3`
  display: block;
  color: #ffffff;
`

const FormActions = styled.div`
  display: flex;
  justify-content: space-between;
`

function ForgotPasswordForm () {
  const { register, handleSubmit, errors, reset } = useForm();
  const [loading, setLoading] = useState(false);
  const [requested, setRequested] = useState(false)

  const onSubmit = async (data) => {
    setLoading(true)
    await forgotPasswordApi({
      'email': data.email
    }).then((res) => {
      setRequested(true)
      setLoading(false)
      reset()
    }).catch((err) => {
      setLoading(false)
    })
  };

  return (<Wrapper>
    <FormTitle>Forgot Password</FormTitle>
    <Spacer height={'30px'} />

    {requested && (<Alert variant='info'>
      Check your email to reset your password.
    </Alert>)}

    <Form className="form" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="formEmail">
        <Form.Label>Email Address</Form.Label>
        <Form.Control
          name="email"
          type="email"
          ref={register({ required: true })}
        />
        {errors.email && (<Form.Control.Feedback type="invalid">
          {errors.email.type === 'required' ? 'This field is required' : 'Invalid input'}
        </Form.Control.Feedback>)}
      </Form.Group>

      <FormActions>
        <Link to="/login"><Button variant="outline" type="button">
          Back to Login
        </Button></Link>

        <Button
          variant="secondary"
          type="submit"
          disabled={loading}
        >
          {!loading
            ? 'Reset Password'
            : <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          }
        </Button>
      </FormActions>
    </Form>
  </Wrapper>);
}

export default ForgotPasswordForm;
