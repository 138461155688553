import PrivateAPI from './private';
import PublicAPI from './public';
import { AxiosPromise } from 'axios';
import { Event } from './models/event.interface';
import { Participant } from './models/participant.interface';

export const getUsers = (searchIds: any, page: any) => {
  return PrivateAPI.post('/api/admin/users/', {
    page: page,
    searchIds: searchIds,
  });
};

export const getOrders = () => {
  return PrivateAPI.get('/api/admin/orders/');
};

export const dispatchOrder = (orderId: any, payload: any) => {
  return PrivateAPI.post(
    '/api/admin/orders/' + orderId + '/dispatch/',
    payload
  );
};

export const downloadResults = (userIds: any, downloadType: any) => {
  return PrivateAPI.post('/api/admin/users/download-results/', {
    user_ids: userIds,
    download_type: downloadType,
  });
};

export const getEvents = (page: any, status: any) => {
  const params = new URLSearchParams({
    page: page,
    status: status,
  });

  return PrivateAPI.get('/api/admin/events/?' + params);
};

export const saveEvent = (payload: any) => {
  return PrivateAPI.post('/api/admin/events/', payload);
};

export const getEvent = (pk: number) => {
  return PrivateAPI.get('/api/admin/events/' + pk + '/');
};

export const deleteEvent = (pk: number) => {
  return PrivateAPI.delete('/api/admin/events/' + pk + '/');
};

export const eventFeaturedToggle = (pk: number) => {
  return PrivateAPI.get('/api/admin/events/' + pk + '/toggle-featured/');
};

export const getEventPublic = (pk: number) => {
  return PublicAPI.get('/api/admin/events/p/' + pk + '/');
};

export const getEventByToken = (token: any) => {
  return PublicAPI.get('/api/admin/events/token/' + token + '/');
};

export const getEventByCode = (code: any) => {
  return PublicAPI.get('/api/admin/events/code/' + code + '/');
};

export const getEventByURL = (short_url: any) => {
  return PublicAPI.get('/api/admin/events/url/' + short_url + '/');
};

export const checkShortUrl = (short_url: any) => {
  return PublicAPI.post('/api/admin/events/verify-url/', {
    short_url: short_url,
  });
};

export const verifyEventCode = (code: any) => {
  return PublicAPI.get('/api/admin/events/verify/' + code + '/');
};

export const eventSignup = (payload: any) => {
  return PublicAPI.post('/api/admin/events/register/', payload);
};

export const getFeaturedEvents = (): AxiosPromise<{ events: Event[] }> => {
  return PublicAPI.get('/api/admin/events/featured/');
};

export const getTrainingParticipants = (): AxiosPromise<{
  participants: Participant[];
}> => {
  return PrivateAPI.get('/api/admin/practioners/participants');
};

export const uploadAmy = (payload: any) => {
  return PrivateAPI.post('/api/admin/upload/amy1', payload);
};

export const resetPassword = (data: any) => {
  return PrivateAPI.post('/api/admin/users/reset-password/', data);
};
