import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import styled from 'styled-components';

const SideRow = styled.div`
  padding: 7px 2px;
  border-bottom: 1px solid #dcd8d6;
  font-size: 0.95rem;
  font-weight: ${(props) => (props.match ? '600' : '400')};

  a {
    color: #707077;
  }
`;

const ReportLink = ({ productId }) => {
  let location = useLocation();
  const getReportData = (productId) => {
    if(productId == process.env.REACT_APP_ESSENTIAL_PRODUCT_ID){
      return {
        link:'/consult/essentials/',
        title:'Essential Start Results'
      }
    }
    if(productId == process.env.REACT_APP_LIFE_PLUS_PRODUCT_ID){
      return {
        link:'/consult/lifestyle-plus/',
        title:'LifeStyle Plus Results'
      }
    }
    if(productId == process.env.REACT_APP_CONSULTATION_PRODUCT_ID){
      return {
        link:'/consult/methylation/',
        title:'Methylation Consultation'
      }
    }
    if(productId == process.env.REACT_APP_WEIGHT_MANAGEMENT_CONSULTATION_PRODUCT_ID){
      return {
        link:'/consult/weight-management/',
        title:'Weight Management Consultation'
      }
    }

    return null;
  }

  const reportLinkData = getReportData(productId)

  if(reportLinkData === null){
    return ''
  }

  return (
        <SideRow
            match={matchPath(location.pathname, {
            path: reportLinkData.link,
            exact: true,
            })}
        >
            <Link to={reportLinkData.link}>{reportLinkData.title}</Link>
        </SideRow>
    )
}

export default ReportLink;