import React from 'react';
import { Link } from 'react-router-dom';
import { useObserver } from 'mobx-react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { isBrowser } from 'react-device-detect';

import { Spacer } from 'components/utils';
import { primary, blue } from 'styles/theme';
import LoginForm from 'components/login/LoginForm';

const StyledRow = styled(Row)`
  background-color: ${primary};
  min-height: 100%;
  width: 100%;
  position: absolute;
`;

const ImageCol = styled(Col)`
  background: url(${(props) => props.src}) center center no-repeat;
  background-size: cover;
`;

const LogoWrapper = styled.div`
  padding: ${isBrowser ? '30px 15%' : '15px'};
`;

const Wrapper = styled.div`
  padding: ${isBrowser ? '30px 15%' : '15px'};
  p,
  label {
    color: #ffffff !important;
  }
`;

const Logo = styled.img`
  max-height: 50px;
`;

const StyledLink = styled(Link)`
  color: ${blue};

  &:hover {
    color: #ffffff;
  }
`;

function LogIn() {
  const image =
    process.env.PUBLIC_URL +
    '/assets/images/bg/ING-00' +
    Math.floor(Math.random() * 8 + 1) +
    '.jpg';

  return useObserver(() => {
    return (
      <StyledRow>
        <Col xs={12} md={6}>
          <LogoWrapper>
            <Link to="/">
              <Logo
                src={
                  process.env.PUBLIC_URL +
                  '/assets/images/ingeneous_white_r.png'
                }
              />
            </Link>
          </LogoWrapper>
          <Wrapper>
            <LoginForm />
            <Spacer height={'100px'} />
            <p>
              Need to create an account?
              <br />
              <StyledLink to="/register">Create account here</StyledLink>
            </p>
            {/* <button
              onClick={() => {
                const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
                firebase.auth().signInWithPopup(googleAuthProvider);
              }}
            >
              Sign In with Google
            </button> */}
          </Wrapper>
        </Col>
        {isBrowser && <ImageCol md={6} src={image} />}
      </StyledRow>
    );
  });
}

export default LogIn;
