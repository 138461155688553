/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import QRCode from 'react-qr-code';
import { parseISO, format } from 'date-fns';

import { getEvent } from 'api/admin';
import { Spacer } from 'components/utils';

const Wrapper = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0;
  border: 1px solid #bbb;
  padding: 30px;
  margin: 20px 0;
  text-align: center;
`;

const ReviewEvent = () => {
  let { code } = useParams();
  const [event, setEvent] = useState();

  useEffect(() => {
    getEvent(code)
      .then((res) => {
        setEvent(res.data?.event);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  const startDate = parseISO(event?.start_date);
  const endDate = parseISO(event?.end_date);

  return (
    <Container>
      <Wrapper>
        {event && (
          <>
            <h3>{event.name}</h3>
            <p>
              {startDate && (
                <strong>{format(startDate, 'MMMM dd, yyyy hh:mm a')}</strong>
              )}
              &nbsp;to&nbsp;
              {endDate && (
                <strong>{format(endDate, 'MMMM dd, yyyy hh:mm a')}</strong>
              )}
            </p>
            <QRCode
              value={process.env.REACT_APP_EVENT_HOST}
              // value={process.env.REACT_APP_EVENT_HOST + 't/' + event?.token}
              title={event.name}
              size={512}
            />
            <Spacer height={'30px'} />
            <span>
              Event URL:&nbsp;
              <a
                href={process.env.REACT_APP_EVENT_HOST}
                target="_blank"
                rel="noopener noreferrer"
              >
                {process.env.REACT_APP_EVENT_HOST}
              </a>
            </span>
          </>
        )}
      </Wrapper>
    </Container>
  );
};

export default ReviewEvent;
