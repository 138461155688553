import React from 'react'

const Omega = () => {
  return (<div>
    <h5>The Omega ratio – increase Omega 3 and decrease Omega 6</h5>
    <p>
      Please note that your genetic SNP testing with Ingeneous is NOT able to tell you if you have food allergies or intolerances. If you know that you have a particular allergy or intolerance, please choose other appropriate food alternatives than the ones we have supplied.
    </p>
    <p>
      The ratio between Omega 3 and 6 is super helpful for coordinating your pro and anti-inflammatory systems. We all need both Omega 3 and Omega 6 in our diets, but the ratios in our diets have become skewed. The ideal ratio is 4:1 favouring Omega 3. Unfortunately, our current western diet lands the average person at about 14:1 in favour of Omega 6! That requires a huge increase in Omega 3 to get your inflammatory systems talking to each other in unison.
    </p>
    <p>
      You need a small amount of Omega 6 in your diet, which you can get healthily (and easily!) from eating nuts, seeds, and leafy greens rather than by the oils listed below – which can over supply your body with strong concentrations of this omega, leading to unhealthy inflammation.
    </p>
    <p>
      Omega 6 oils are in a surprising amount of cooked food you will get served when you eat out, so we recommend trying your best to avoid them entirely when at home to compensate.
    </p>
    <p>
      For many years, fat was labelled enemy number one. We now know that fats are vital to our health, however not all fats are created equal. Check the table below for a guide on optimal kinds of oils and fats according to your DNA. These include Omega 3 rich fats, which help to reduce inflammation, support brain, heart and gut health, strengthen the immune system and keep you looking young with healthy skin and hair.
    </p>

    <table>
      <tr>
        <th>Include these Oils</th>
        <th>Avoid these Oils</th>
      </tr>
      <tr>
        <td>Extra Virgin Olive oil (consume without heating to high temperature)</td>
        <td>Soybean oil</td>
      </tr>
      <tr>
        <td>Organic Extra Virgin Sunflower oil (consume without heating to high temperature)</td>
        <td>Sunflower oil</td>
      </tr>
      <tr>
        <td>Macadamia nut oil</td>
        <td>Safflower oil</td>
      </tr>
      <tr>
        <td>Flaxseed oil</td>
        <td>Corn oil</td>
      </tr>
      <tr>
        <td>Walnut oil</td>
        <td>Canola oil</td>
      </tr>
      <tr>
        <td>Avocado oil</td>
        <td>Cottonseed oil</td>
      </tr>
      <tr>
        <td>Almond oil</td>
        <td>Palm oil</td>
      </tr>
      <tr>
        <td>Coconut oil</td>
        <td>Peanut oil</td>
      </tr>
      <tr>
        <td>Free range meat</td>
        <td>Rice bran oil</td>
      </tr>
      <tr>
        <td>Oily fish, ie salmon, sardines</td>
        <td>Trans fats, found in margarine, deep fried and various processed foods</td>
      </tr>
      <tr>
        <td>Free range eggs</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>Grass fed butter</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>Butter/ghee</td>
        <td>&nbsp;</td>
      </tr>
    </table>
  </div>)
}

export default Omega
