import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { gray, primary } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import View from '../View';
import Close from '../ViewLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const AlertRef = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
`;

const SubtitleGray = styled.h5`
  color: ${gray};
`;

const WrapperRef = styled.div`
  margin-top: 5px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const ListRef = styled.li`
  font-weight: 300;
  padding-left: 20px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    word-break: break-all;
  }
`;

const WrapperTitle = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
`;

interface SeaweedProps {
  results: any;
}
const Seaweed = ({ results }: SeaweedProps) => {
  const [show, setShow] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/WeightReport-seaweed.png"
            alt="food"
            width={'60%'}
          />
        </ImageCol>
        <Col md={8}>
          <WrapperTitle>
            {isMobile ? (
              <>
                <SubtitleGray>Your superfood is</SubtitleGray>
                <Subtitle>Seaweed</Subtitle>
              </>
            ) : (
              <>
                <SubtitleGray>Your superfood is</SubtitleGray>
                &nbsp;
                <Subtitle>Seaweed</Subtitle>
              </>
            )}
          </WrapperTitle>
          <Alert>
            <p>
              Try to include seaweed in your diet daily. The more the better!
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <p>
                Your superfood to support your optimal weight journey is
                Seaweed! Seaweed contains polyphenols and is particularly
                powerful as it is rich in other bioactive compounds.
              </p>
              <p>
                Seaweed contains different proteins (lectins, phycobiliproteins,
                peptides, and amino acids), polyphenols, and polysaccharides,
                some not found in land plants (1). Alginate within brown
                seaweeds has been suggested to increase satiety, reduce calorie
                consumption in order to support weight loss (2). It is also
                thought to target abdominal white adipose tissue, decreasing its
                percentage. Adding seaweed into your diet could be the boost you
                need (2).
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
      <Row className="no-print">
        <Col>
          <AlertRef>
            <span>References</span>
            {!showReferences && (
              <View value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <Close value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <WrapperRef>
                <ListGroup>
                  <ListRef>
                    Emma M Brown, Philip J Allsopp, Pamela J Magee, Chris IR
                    Gill, Sonja Nitecki, Conall R Strain, Emeir M McSorley,
                    Seaweed and human health, Nutrition Reviews, Volume 72,
                    Issue 3, 1 March 2014, Pages 205–216,&nbsp;
                    <a
                      href="https://doi.org/10.1111/nure.12091
                      https://academic.oup.com/nutritionreviews/article/72/3/205/1853561?login=true"
                    >
                      https://doi.org/10.1111/nure.12091
                      https://academic.oup.com/nutritionreviews/article/72/3/205/1853561?login=true
                    </a>
                  </ListRef>
                  <ListRef>
                    Lange KW, Hauser J, Nakamura Y, Kanaya S. Dietary seaweeds
                    and obesity. Food Science and Human Wellness. 2015 Sep
                    1;4(3):87-96.&nbsp;
                    <a href="https://www.sciencedirect.com/science/article/pii/S2213453015000439">
                      https://www.sciencedirect.com/science/article/pii/S2213453015000439
                    </a>
                  </ListRef>
                </ListGroup>
              </WrapperRef>
            )}
          </AlertRef>
        </Col>
      </Row>
    </>
  );
};

export default Seaweed;
