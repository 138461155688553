import React, { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import Loader from 'react-loader-spinner';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import { AuthContext } from 'stores/auth';
import CommonProvider from 'stores/common';

import PublicRoute from './PublicRoute';
import ProtectedRoutes from './ProtectedRoutes';
import AdminRoutes from './AdminRoutes';
import BlankWrapper from 'containers/BlankWrapper';
import ArtilectWrapper from 'containers/Artilect/ArtilectWrapper';

import Home from './Home';
import Confirmation from './Home/components/Confirmation';
import PreTerms from './Home/components/PreTerms';
import Terms from './Home/components/Terms';
import LogIn from './Auth/LogIn';
import LoginLink from './Auth/LoginLink';
import LoginLinkConfirmation from './Auth/LoginLinkConfirmation';
import Register from './Auth/Register';
import RegisterSuccess from './Auth/Register/Success';
import ForgotPassword from './Auth/Password/ForgotPassword';
import ResetPassword from './Auth/Password/ResetPassword';
import VerifyEmail from './Auth/VerifyEmail';
import Sesame from './Sesame';

import Event from './EventCart';

import ArtilectPractitioner from './Artilect/Practitioner';
import ArtilectCustomer from './Artilect/Customer';

import TermsConditions from './StaticPages/TermsConditions';
import Consent from './StaticPages/Consent';
import HealthDisabilityCode from './StaticPages/HealthDisabilityCode';
import PrivacyPolicy from './StaticPages/PrivacyPolicy';

import 'react-toastify/dist/ReactToastify.css';
import Checkout from './Practitioners/Checkout';
import PaymentCheckout from './Practitioners/Payment';
import SuccessCheckout from './Practitioners/Success';
import PageNotFound from './StaticPages/404';

import ConsultLogin from './ConsultLogin';
import ConsultRegister from './ConsultRegister';
import SuccessPracRegister from './Auth/ConsultRegisterSuccess';

const FullToast = styled(ToastContainer)`
  width: calc(100% + 10px);
  padding: 0;
  text-align: center;
  position: relative;
  transform: unset;
  top: 0;

  left: 0;
  margin-left: -10px;

  > div {
    background-color: #edd49c !important;
    color: #333333 !important;
  }
`;

const RegularToast = styled(ToastContainer)`
  > div {
    background-color: #edd49c !important;
    color: #333333 !important;
    border-radius: 5px;
    padding: 15px 30px;
  }
`;

const SpinWrapper = styled.div`
  width: 100%;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    display: inline-block;
    margin-left: 15px;
    font-size: 20px;
  }
`;

function Router({ loading }) {
  const auth = getAuth();
  const authStore = useContext(AuthContext);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      console.log('Logged in user:', uid);
      // ...
    } else {
      // User is signed out
      // ...
    }
  });

  return useObserver(() => {
    if (loading) {
      return (
        <SpinWrapper>
          <Loader type="Grid" color="#FFFFFF" height={30} width={30} />
          <span>Loading...</span>
        </SpinWrapper>
      );
    }

    if (!authStore.loggedIn) {
      return (
        <main>
          <FullToast
            enableMultiContainer
            containerId={'full'}
            position="top-center"
            autoClose={false}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
            draggable={false}
          />

          <RegularToast
            enableMultiContainer
            containerId={'regular'}
            position="top-right"
            autoClose={true}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
            draggable={false}
          />

          <CommonProvider>
            <Switch>
              <PublicRoute
                exact
                path="/m/:next/:token"
                component={Sesame}
                layout={BlankWrapper}
              />
              <PublicRoute
                exact
                path="/login"
                component={LogIn}
                layout={BlankWrapper}
              />
              <PublicRoute
                exact
                path="/email-login"
                component={LoginLink}
                layout={BlankWrapper}
              />
              <PublicRoute
                exact
                path="/email-login/callback"
                component={LoginLinkConfirmation}
                layout={BlankWrapper}
              />
              <PublicRoute
                exact
                path="/register"
                component={Register}
                layout={BlankWrapper}
              />
              <PublicRoute
                exact
                path="/register/success/"
                component={RegisterSuccess}
                layout={BlankWrapper}
              />

              <PublicRoute
                exact
                path="/event"
                component={Event}
                layout={BlankWrapper}
              />

              <PublicRoute
                path="/password/forgot/"
                component={ForgotPassword}
                layout={BlankWrapper}
              />
              <PublicRoute
                path="/password/reset/"
                component={ResetPassword}
                layout={BlankWrapper}
              />
              <PublicRoute
                path="/account/verify/:verify_token/"
                component={VerifyEmail}
                layout={BlankWrapper}
              />
              <PublicRoute
                exact
                path="/terms-and-conditions"
                component={TermsConditions}
                layout={BlankWrapper}
              />
              <PublicRoute
                exact
                path="/consent"
                component={Consent}
                layout={BlankWrapper}
              />
              <PublicRoute
                exact
                path="/health-and-disability-code"
                component={HealthDisabilityCode}
                layout={BlankWrapper}
              />
              <PublicRoute
                exact
                path="/privacy-policy"
                component={PrivacyPolicy}
                layout={BlankWrapper}
              />

              <PublicRoute
                exact
                path="/confirmation"
                component={Confirmation}
                layout={BlankWrapper}
              />
              <PublicRoute
                exact
                path="/pre-terms"
                component={PreTerms}
                layout={BlankWrapper}
              />
              <PublicRoute
                exact
                path="/terms"
                component={Terms}
                layout={BlankWrapper}
              />

              <PublicRoute
                exact
                path="/p/:token"
                component={ArtilectPractitioner}
                layout={ArtilectWrapper}
              />
              <PublicRoute
                exact
                path="/c/:token"
                component={ArtilectCustomer}
                layout={ArtilectWrapper}
              />

              <PublicRoute
                exact
                path="/"
                component={Home}
                layout={BlankWrapper}
              />

              <PublicRoute
                exact
                path="/practitioners/checkout"
                component={Checkout}
                layout={BlankWrapper}
              />

              <PublicRoute
                exact
                path="/practitioners/payment"
                component={PaymentCheckout}
                layout={BlankWrapper}
              />

              <PublicRoute
                exact
                path="/practitioners/success"
                component={SuccessCheckout}
                layout={BlankWrapper}
              />

              <PublicRoute path="/consult/login" component={ConsultLogin} layout={BlankWrapper}/>
              <PublicRoute path="/consult/register/success" component={SuccessPracRegister} layout={BlankWrapper}/>
              <PublicRoute path="/consult/register" component={ConsultRegister} layout={BlankWrapper}/>

              <PublicRoute
                path="*"
                component={PageNotFound}
                layout={BlankWrapper}
              />

              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </CommonProvider>
        </main>
      );
    } else {
      return (
        <main>
          <FullToast
            enableMultiContainer
            containerId={'full'}
            position="top-center"
            autoClose={false}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
            draggable={false}
          />

          <RegularToast
            enableMultiContainer
            containerId={'regular'}
            position="top-right"
            autoClose={true}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
            draggable={false}
          />

          <Switch>
            <PublicRoute
              exact
              path="/p/:token"
              component={ArtilectPractitioner}
              layout={ArtilectWrapper}
            />
            <PublicRoute
              exact
              path="/c/:token"
              component={ArtilectCustomer}
              layout={ArtilectWrapper}
            />
            <PublicRoute
              exact
              path="/event"
              component={Event}
              layout={BlankWrapper}
            />

            <PublicRoute
              exact
              path="/practitioners/checkout"
              component={Checkout}
              layout={BlankWrapper}
            />

            <PublicRoute
              exact
              path="/practitioners/payment"
              component={PaymentCheckout}
              layout={BlankWrapper}
            />

            <PublicRoute
              exact
              path="/practitioners/success"
              component={SuccessCheckout}
              layout={BlankWrapper}
            />

            {/* <Route path="/" component={AuthenticatedRoutes} /> */}
            {authStore.isAdmin && (
              <Route path="/admin/" component={AdminRoutes} />
            )}
            <Route path="/" component={ProtectedRoutes} />

            <Route path={'*'} component={() => <Redirect to="/" />} />
          </Switch>
        </main>
      );
    }
  });
}

export default Router;
