import React, { useContext } from 'react';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';

import { AuthContext } from 'stores/auth';
import { Spacer } from 'components/utils';
import { getColor, Serving, Image, BodyCol } from './utils';

const Wrapper = styled.div`
  padding: 20px;
  background-color: #dcefe2;

`;

const FatIntake = ({ fats }) => {
  const authStore = useContext(AuthContext);

  return (
    <>
      <Row>
        <BodyCol xs={12}>
          <h4>Recommended Fat Intake</h4>
        </BodyCol>
        <BodyCol xs={12} md={8}>
          {fats <= 0.3 && (
            <Serving color={getColor(2)}>
              You need a low dietary fat intake to
              help you live happy and healthy.
            </Serving>
          )}
          {fats > 0.3 && fats <= 0.375 && (
            <Serving color={getColor(2)}>
              You need a low to moderate dietary
              fat intake to help you live happy and healthy.
            </Serving>
          )}
          {fats > 0.375 && fats <= 0.45 && (
            <Serving color={getColor(2)}>
              You need a moderate dietary fat
              intake to help you live happy and healthy.
            </Serving>
          )}
          {fats > 0.45 && fats <= 0.525 && (
            <Serving color={getColor(2)}>
              You need a moderate to high fat
              intake to help you live happy and healthy.
            </Serving>
          )}
          {fats > 0.525 && fats <= 0.6 && (
            <Serving color={getColor(2)}>
              You need a high fat intake to help
              you live happy and healthy.
            </Serving>
          )}
          <Spacer height={'40px'} />
          {fats <= 0.3 && (
            <p>
              ‘Good’ quality fats should make up around 30% of your total
              calorie intake.
            </p>
          )}
          {fats > 0.3 && fats <= 0.375 && (
            <p>
              ‘Good’ quality fats should make up around 37.5% of your total
              calorie intake.
            </p>
          )}
          {fats > 0.375 && fats <= 0.45 && (
            <p>
              ‘Good’ quality fats should make up around 45% of your total
              calorie intake.
            </p>
          )}
          {fats > 0.45 && fats <= 0.525 && (
            <p>
              ‘Good’ quality fats should makeup around 52.5% of your total
              calorie intake.
            </p>
          )}
          {fats > 0.525 && fats <= 0.6 && (
            <p>
              ‘Good’ quality fats should makeup around 60% of your total calorie
              intake.
            </p>
          )}
        </BodyCol>
        <BodyCol xs={12} md={4}>
          <Image
            src={
              process.env.PUBLIC_URL + '/assets/images/lifeplus/ing-fats.png'
            }
          />
        </BodyCol>
        <BodyCol xs={12}>
          <div className="page-br image-print">
            <Wrapper>
              <Row>
                <BodyCol xs={12} md={6}>
                  <strong>Include These Fats</strong>
                  <Spacer height={'20px'} />
                  <ul>
                    <li>
                      Extra Virgin Olive oil (consume without heating to high
                      temperature)
                    </li>
                    <li>
                      Organic Extra Virgin Sunflower oil (consume without
                      heating to highh temperature)
                    </li>
                    <li>
                      Macadamia nut oil (a good option when cooking at high
                      temperature)
                    </li>
                    <li>Flaxseed oil</li>
                    <li>Walnut oil</li>
                    <li>Avocado oil</li>
                    <li>Butter (grass fed)</li>
                  </ul>
                </BodyCol>
                <BodyCol xs={12} md={6}>
                  <strong>Avoid These Fats</strong>
                  <Spacer height={'20px'} />
                  <ul>
                    <li>Soybean oil</li>
                    <li>Sunflower oil</li>
                    <li>Safflower</li>
                    <li>Corn oil</li>
                    <li>Canola oil</li>
                    <li>Cottonseed oil</li>
                    <li>Palm oil</li>
                    <li>Peanut oil</li>
                    <li>Coconut oil</li>
                    <li>Rice bran oil</li>
                    <li>
                      Trans fats (found in margarine, deep fried and various
                      processed foods)
                    </li>
                  </ul>
                </BodyCol>
              </Row>
            </Wrapper>
          </div>
        </BodyCol>
      </Row>
    </>
  );
};

export default FatIntake;
