import { AxiosPromise } from 'axios';
import PrivateAPI from './private';
import PublicAPI from './public';
import { Product } from './models/product.interface';

export * from './models/product.interface';

/**
 * Products API
 */

export const getProducts = (): AxiosPromise<{ products: Product[] }> => {
  return PublicAPI.get('/api/products/');
};

export const getProduct = (productId: string) => {
  return PublicAPI.get('/api/products/' + productId + '/');
};

export const getUserProducts = (): AxiosPromise<{
  purchased_products: string[];
}> => {
  return PrivateAPI.get('/api/products/user/');
};

export const getUserProduct = (productId: string) => {
  return PrivateAPI.get('/api/products/' + productId + '/user/');
};

/**
 * Billing API
 */

export const getStripeMembership = () => {
  return PublicAPI.get('/api/products/stripe/');
};

export const getDiscount = (code: string) => {
  return PrivateAPI.get('/api/discount/' + code + '/');
};

export const getDraftOrders = () => {
  return PrivateAPI.get('/api/draft-orders/');
};

export const getOrders = () => {
  return PrivateAPI.get('/api/orders/');
};

export const getUserOrder = (email: string) => {
  return PrivateAPI.post('/api/u/orders/', { email: email });
};

export const getUserOrders = (userId: string) => {
  return PrivateAPI.get(`/api/u/${userId}/orders/`);
};

// export const createOrder = (payload) => {
//   return PrivateAPI.post('/api/orders/', payload)
// }

export const createOrder = (payload: any) => {
  return PrivateAPI.post('/api/orders/create/', payload);
};

export const updateOrder = (payload: any) => {
  return PrivateAPI.post('/api/orders/update-payment/', payload)
}

export const createEventCartOrder = (payload: any) => {
  return PrivateAPI.post('/api/orders/create/', payload);
};

export const confirmOrder = (orderId: string, payload: any) => {
  return PrivateAPI.post('/api/orders/' + orderId + '/confirm/', payload);
};

export const sendBackOrder = (orderId: string, payload: any) => {
  return PrivateAPI.post('/api/orders/' + orderId + '/send-back/', payload);
};

/**
 * Samples API
 */

 export const checkSampleId = (sampleId: string) => {
  return PublicAPI.get('/api/check-sample-id/?kitId=' + sampleId);
};

export const getSampleId = (sampleId: string) => {
  return PrivateAPI.get('/api/sample-ids/?sample_id=' + sampleId);
};

export const assignSampleId = (data: any) => {
  return PrivateAPI.post('/api/sample-ids/', data);
};

export const getResults = (sampleId: any) => {
  return PrivateAPI.get('/api/sample-ids/' + sampleId + '/');
};

export const createEventOrder = (payload: any) => {
  return PrivateAPI.post('/api/events/orders/new/', payload);
};

export const syncRecommendations = (pk: string) => {
  return PrivateAPI.post('/api/recommendations/' + pk + '/sync', {});
};

export const checkSyncRecommendations = (pk: string) => {
  return PrivateAPI.get('/api/recommendations/' + pk + '/check-sync/');
};
