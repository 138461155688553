import React from 'react';
import styled from 'styled-components';
import { Container, Navbar } from 'react-bootstrap';

import { footerHeight } from 'styles/theme'

const CustomFooter = styled(Navbar)`
  /* background-color: transparent; */
  background-color: #707070;
  /* color: #666666; */
  color: #ffffff;
  border-top: 1px solid #BAD1CC;
  padding: 35px 0;
  font-size: 1rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: ${footerHeight};

  .container {
    /* justify-content: center; */
    justify-content: left;

    a {
      color: #ffffff;
      text-decoration: underline;
      display: inline-block;
      margin-left: 1.2rem;
    }
  }
`

const Footer = () => {
  const dt = new Date();
  return (<>
    <CustomFooter sticky='bottom'>
      <Container>
        <span>Copyright © {dt.getFullYear()} Ingeneous LTC. All rights reserved.</span>
        &nbsp;
        <a href="https://myingeneous.com" rel="noopener noreferrer" target="_blank">myingeneous.com</a>
      </Container>
    </CustomFooter>
  </>);
}

export default Footer;
