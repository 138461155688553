import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled, { createGlobalStyle } from 'styled-components';
import Sticky from 'react-stickynode';
import { AuthContext } from 'stores/auth';
import { getWeightManagement, IWeightManagementReport } from 'api/reports';
import { useObserver } from 'mobx-react-lite';
import { Page, PageBody, PageHeader } from 'components/Page';
import { Spacer } from 'components/utils';
import { isMobile } from 'react-device-detect';
import Reports from 'routes/Dashboard/components/Reports';
import ReportBody from './components/ReportBody';

const UserRow = styled(Row)`
  h2,
  h3,
  p {
    color: #ffffff;
  }
  p {
    font-size: 0.8rem;
  }
`;

const UserCol = styled(Col)`
  text-align: center;
`;

// const UserImage = styled.div`
//   height: 100px;
//   width: 100px;
//   background-color: #ffffff;
//   border-radius: 9999px;
//   margin: 0 auto;
//   margin-bottom: 15px;
// `;

const GlobalStyle = createGlobalStyle`
  @page {
    margin: 0;
`;

const PrintableBodyWrapper = styled(Container)`
  @media print {    
    margin: 0;
    max-width: 100%;

    & > div {
      display: block;
      margin: 0 auto;

      & > div:first-child {
        display: none;
      }

      & > div:nth-child(2) {
        margin: 0 auto;
      }
    }
    .content-wrapper {      
      padding: 0 50px;
      max-width: 100%;
      width: 100%;

      & > div {        
        border-left: 0px;
        border-right: 0px;
        border-bottom: 0px;
        padding-bottom: 10px;
        padding-top: 25px;
      }

      & h2 + p {
        margin-bottom: 5px;
      }
    }
  }
`;

interface WeightManagementReportProps {
  r: any;
}

const WeightManagementReport = ({ r }: WeightManagementReportProps) => {
  const authStore = useContext(AuthContext);
  const [results, setResults] = useState<IWeightManagementReport | null>(null);

  /* 
  Dummy data for testing purpose
  */
  // const [results, setResults] = useState<IWeightManagementReport>({
  //   status: 'complete',
  //   test: true,
  //   exercise_percentage: 50,
  //   diet_percentage: 10,
  //   exercise_type: 'irrelevant',
  //   diet_type: 'keto',
  //   temperature: 'hot',
  //   fasting: 'bad',
  //   supplement: 'capsaicin',
  //   food: 'vitamin A rich foods',
  //   athleticism: 'work harder to be athletic',
  //   impulse: 'very considered',
  //   binge_eating: 'moderate risk',
  //   inflammation: 1,
  // });
  const [addPad, setAddPad] = useState(false);

  const handleStateChange = (status: Sticky.Status) => {
    setAddPad(status.status === Sticky.STATUS_FIXED);
  };

  useEffect(() => {
    getWeightManagement().then((res) => {
      if (res.data.status === 'complete') {
        setResults(res.data);
      }
    });
  }, []);

  return useObserver(() => {
    // const reportStatus = authStore.reports;
    // if (
    //   reportStatus?.['weight_mamanegement'] !== 'complete' &&
    //   !results?.test
    // ) {
    //   return <Redirect to="/" />;
    // }

    return (
      <Page>
        <PageHeader className='no-print'>
          {!isMobile && <Spacer height={'30px'} />}
          <Container>
            <UserRow>
              <UserCol xs={12} md={3} className="content-wrapper">
                <Spacer height={'30px'} />
                {/* <UserImage /> */}
                {authStore.user.first_name ? (
                  <h3>
                    {authStore.user.first_name + ' ' + authStore.user.last_name}
                  </h3>
                ) : (
                  <h3>{authStore.user.email}</h3>
                )}
              </UserCol>
            </UserRow>
          </Container>
        </PageHeader>
        <PageBody>
        <GlobalStyle />
          <PrintableBodyWrapper>
            <Spacer height={'30px'} />
            <Row>
              <Col xs={12} md={3} className="no-print">
                {isMobile ? (
                  <Reports active={'weight'} pad={addPad} />
                ) : (
                  <Sticky onStateChange={handleStateChange}>
                    <Reports active={'weight'} pad={addPad} />
                  </Sticky>
                )}
              </Col>
              <Col xs={12} md={9}>
                {results && <ReportBody results={results} useName={true} title={true} borders={false}/>}
              </Col>
            </Row>
            <Spacer height={'30px'} />
          </PrintableBodyWrapper>
        </PageBody>
      </Page>
    );
  });
};

export default WeightManagementReport;