import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import Icon from 'components/Icon';
import {
  TR,
  Collapse,
  CollapseBtn,
  DataWrapper,
  Score,
  getColor,
} from './TableRow';

import CircleDot from './Circle';

const FUT2TCN2 = ({ geneData }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [genes, setGenes] = useState();

  useEffect(() => {
    setGenes({
      rs602662: geneData?.find((e) => 'rs602662' === e.snp),
      rs1801198: geneData?.find((e) => 'rs1801198' === e.snp),
    });
  }, [geneData]);

  if (!genes) {
    return null;
  }

  return (
    <>
      <TR>
        <Col md={11}>
          <Row>
            <Col md={2}>FUT2</Col>
            <Col md={1}>
              <CircleDot
                color={getColor(genes?.rs602662?.score)}
                tooltipColor={genes?.rs602662?.score}
              />
            </Col>
            <Col md={2}>{genes?.rs602662?.snp?.toUpperCase()}</Col>
            <Col md={7}>B12 injection every 3 months</Col>
          </Row>
          <Row>
            <Col md={2}>TCN2</Col>
            <Col md={1}>
              <CircleDot
                color={getColor(genes?.rs1801198?.score)}
                tooltipColor={genes?.rs1801198?.score}
              />
            </Col>
            <Col md={2}>{genes?.rs1801198?.snp?.toUpperCase()}</Col>
            <Col md={7}>&nbsp;</Col>
          </Row>
        </Col>
        <Col md={1}>
          <CollapseBtn
            variant="secondary"
            onClick={() => setCollapsed(!collapsed)}
          >
            <Icon
              name={collapsed ? 'caret-down' : 'caret-up'}
              color="#dedede"
            />
          </CollapseBtn>
        </Col>
      </TR>

      <Collapse
        style={{ display: collapsed ? 'none' : 'block' }}
        className="print-only"
      >
        <DataWrapper>
          <h4 style={{ color: '#707070' }}>
            FUT2 & TCN2 - B12 - adenosyl or hydroxyl or cyanocobalamin
          </h4>
          <p>
            <Score color={getColor(2)}>Red result for 1 or 2 RS numbers</Score>
            &nbsp;<strong>= B12 injection every 3 months</strong>
          </p>
          <p>
            By compensating for your underactive FUT2/TCN2 receptor, you are
            going to help improve your ability to stay asleep and have quality
            sleep, wake refreshed and improve your ability to manage stress.
          </p>
          <p>
            Hydroxycobalamin is our preference - it is known for increasing
            energy levels, alternatively the cyanocobalamin injection.
          </p>
          <p>
            Alternatively, if it is not practical for the consumer to get an
            injection, they can take 2000IU of a combination of
            adenosyl/hydroxyl cobalamin oral supplement once daily in the
            morning.
          </p>
        </DataWrapper>
      </Collapse>
    </>
  );
};

export default FUT2TCN2;
