import React from 'react'

const VintaminE = () => {
  return (<div>
    <h5>Vitamin E rich - guidance</h5>
    <p>
      Please note that your genetic SNP testing with Ingeneous is NOT able to tell you if you have an allergy or intolerance to any of these Vitamin E rich foods. If you know that you have an allergy or intolerance to a suggested food on this list, please choose an alternative. On the bright side, as your inflammation coordination improves as you follow your Essential Start programme, you may find that existing sensitivities may no longer trigger symptoms in the future!
    </p>
    <p>
      This is a particularly powerful recommendation for boosting your body’s ability to make its own antioxidants, which are exponentially more powerful than any antioxidants that come from outside your body.
    </p>
    <p>
      We recommend over 10mg a day, and under 300mg a day to maximise gene expression.
    </p>
    <ul>
      <li>Wheat germ oil 1 tbspn oil = 20mg</li>
      <li>Sunflower oil 1 tbspn = 5.6 mg</li>
      <li>Olive oil 1 tbspn = 1.94mg</li>
      <li>Sunflower seeds ¼ cup = 11.6mg</li>
      <li>Almonds, whole ¼ cup = 14.6mg</li>
      <li>Avocado ⅓ = 10.7mg</li>
      <li>Brazil nuts ¼ cup = 1.85 mg</li>
      <li>Olives x 6 medium = 0.5 mg</li>
    </ul>
  </div>)
}

export default VintaminE
