import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Spacer } from 'components/utils';
import ReadMore from '../../ReadMore';
import ReadLess from '../../ReadLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  position: ${isMobile ? 'static' : 'absolute'};
  right: 0;
  transform: ${isMobile ? 'translateX(0)' : 'translateX(-10%)'};
  margin-top: ${isMobile ? '10px' : '-10px'};
  text-align: ${isMobile ? 'center' : 'right'};
  // display: ${isMobile ? 'flex' : 'inherit'};
  // justify-content: ${isMobile ? 'center' : 'unset'};
  img {
    max-width: 60%;
  }
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const LinkA = styled.a`
  cursor: pointer;
  text-decoration: underline !important;
  color: #6da6ca !important;
`;

interface LowCalProps {
  results: any;
}
const LowCal = ({ results }: LowCalProps) => {
  const [show, setShow] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <Col md={12}>
          <h3>Low Calorie</h3>
          <ImageCol md={4}>
            <img src="/assets/images/recommendations/Diet.png" alt="Diet" />
          </ImageCol>
          <Col md={8}>
            <Alert>
              <p>Decrease your BMR calories by 300. </p>
            </Alert>
            <Alert>
              <p>
                Eat in the style prescribed in your LifeStyle Plus report as
                much as possible.
              </p>
            </Alert>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <p>
                Your gene SNPs indicate that your body will benefit the most
                from cutting your calories.
              </p>
              <p>
                To lose fat you need to use more energy than you take in. A
                calorie is a unit that describes how much energy your body gets
                from the food and drinks you ingest. Restricting your calories
                can help you burn fat. Your basal metabolic rate is the number
                of calories your body burns to perform basic life sustaining
                functions such as breathing. By decreasing your number of
                calories you ingest daily you can lose hope to reach your
                optimal fat level.
              </p>
              <p>
                You can calculate your required calories here:{' '}
                <LinkA
                  href="https://www.calculator.net/calorie-calculator.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.calculator.net/calorie-calculator.html
                </LinkA>
              </p>
              <p>
                If you reduce your BMR by 300 calories you can expect to lose
                fat gradually and safely over time.
              </p>
              <p>
                While significantly cutting calories lower than this may be
                helpful for some people, your genes suggest cutting your
                calories moderately is more powerful for your body when it comes
                to fat burning so you should not aim to decrease your calories
                any further than this recommended amount. Please only follow
                this diet for 3 weeks, and then go back to your Lifestyle Plus
                recommendations for at least 3 weeks between periods of fat
                loss. Once you have reached your optimal weight continue to
                monitor your maintenance calories and follow your Lifestyle Plus
                recommendations to ensure you do not overeat in the weeks
                following restriction.
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
    </>
  );
};

export default LowCal;
