import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { primary } from 'styles/theme';
import { isMobile } from 'react-device-detect';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
    margin-bottom: 30px;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  width: ${isMobile ? '100%' : '400px'};
  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
  margin-bottom: 30px;
`;

interface ModerateProps {
  results: any;
}
const Moderate = ({ results }: ModerateProps) => {
  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/ImpulseControl.png"
            alt="impulse"
            width={'60%'}
          />
        </ImageCol>
        <Col md={8}>
          <Subtitle>Moderate Impulse Control</Subtitle>
          <Alert>
            <p>
              You are predisposed to have a moderate amount of impulse control!
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Spacer height={'10px'} />
          <div>
            <p>
              Impulse control involves your ability to control your own emotions
              and behaviours. People with lower impulse control may have
              unplanned fast reactions without consideration of consequences. It
              is related to several different systems in your brain including
              rewards/punishment and attention. Dopamine is thought to play a
              large role in your impulsivity as well as it is the main chemical
              messenger behind reward and motivation.{' '}
            </p>
            <p>
              As someone with moderate impulse control you are likely usually
              able to control your negative impulses but may still find this
              difficult at times. You will have to practice your impulse control
              to stick to a diet.{' '}
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Moderate;
