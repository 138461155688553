import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { gray, primary } from 'styles/theme';
import { isMobile } from 'react-device-detect';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  margin-top: ${isMobile ? '0' : '30px'};
  margin-bottom: ${isMobile ? '30px' : '0'};
  order: ${isMobile ? 'initial' : '2'};
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${gray};
`;

const SubtitleGreen = styled.h5`
  color: ${primary};
  padding-left: 5px;
  margin-bottom: 5px;
`;

const SubtitleBlue = styled.h5`
  color: #5d9cdb;
  padding-left: 5px;
  margin-bottom: 0;
`;

const SubtitleBlueSmall = styled.h5`
  color: #5d9cdb;
  padding-left: 5px;
  font-size: 14px;
  padding-right: 10px;
`;

const SubtitleGreenSmall = styled.h5`
  color: ${primary};
  padding-left: 5px;
  font-size: 14px;
  padding-right: 37px;
`;

const BarBlue = styled.div`
  background-color: #5d9cdb;
  width: 30px;
  height: 30px;
  border-radius: 5px;
`;

const BarGreen = styled.div`
  background-color: ${primary};
  width: 130px;
  height: 30px;
  border-radius: 5px;
  margin-bottom: 5px;
`;

const WrapperBar = styled.div`
  display: flex;
  align-items: end;
  justify-content: ${isMobile ? 'center' : 'inherit'};
`;

interface Ratio80Props {
  results: any;
}
const Ratio80 = ({ results }: Ratio80Props) => {
  return (
    <>
      <Row>
        <ImageCol md={4}>
          <WrapperBar style={{ display: 'flex' }}>
            <SubtitleGreenSmall>Diet</SubtitleGreenSmall>
            <BarGreen></BarGreen>
            <SubtitleGreen>80%</SubtitleGreen>
          </WrapperBar>
          <WrapperBar>
            <SubtitleBlueSmall>Exercise</SubtitleBlueSmall>
            <BarBlue></BarBlue>
            <SubtitleBlue>20%</SubtitleBlue>
          </WrapperBar>
        </ImageCol>
        <Col md={8}>
          <Subtitle>Your effort ratio is</Subtitle>
          <div style={{ display: 'flex' }}>
            <h5 style={{ paddingRight: 5 }}>80% diet</h5>
            <Subtitle>&</Subtitle> <SubtitleBlue>20% exercise.</SubtitleBlue>
          </div>
          <Alert>
            <p>Aim to exercise 1 time per week.</p>
          </Alert>
          <Alert>
            <p>
              Exercise is less important in your fat burning story than diet. It
              can still be beneficial for other areas of your health so we still
              encourage leisure time activity such as walking on days you do not
              exercise. You need to focus on diet to make a significant impact.
            </p>
          </Alert>
        </Col>
      </Row>
    </>
  );
};

export default Ratio80;
