import React, { useContext } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { AuthContext } from 'stores/auth';
import { Spacer } from 'components/utils';
import { white, borderGray, gray } from 'styles/theme';

const Wrapper = styled.div`
  background-color: ${white};
  padding: ${isMobile ? '10px' : '30px'};
  border-radius: 5px;
  border: 1px solid ${borderGray};
  color: ${gray};
  position: relative;

  h4 {
    color: ${gray};
  }

  p {
    font-size: 1rem;
    margin: 0;
  }
`;

const Image = styled.img`
  margin: 0 auto;
`;

const GetStarted = ({ sampleId }) => {
  const authStore = useContext(AuthContext);

  return useObserver(() => {
    const reportStatus = authStore.reports;

    return (
      <div>
        <h3>Get Started</h3>
        <Wrapper>
          {sampleId ? (
            <Row>
              {reportStatus['essential'] === 'complete' ? (
                <Col md={8}>
                  <h4>Your Test Kit - Processed</h4>
                  <p>
                    Your test kit has been processed. Your report is available.
                    Click on a report on the left side of the screen.
                  </p>
                </Col>
              ) : (
                <Col md={8}>
                  <h4>Your Test Kit - Processing</h4>
                  <p>
                    Your test kit is still being processed. Your report will be
                    available as soon as your data is back. We'll let you know
                    via email.
                  </p>
                </Col>
              )}
              <Col md={4}>
                <Image
                  src={
                    process.env.PUBLIC_URL + '/assets/images/Wellness-Art.png'
                  }
                />
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col md={8}>
                  <h4>Register Test Kit</h4>
                  <p>
                    To be able to identify your data, please register your test
                    kit.
                  </p>
                  <Spacer height={'20px'} />
                  <Link to="/register-kit">
                    <Button>Register</Button>
                  </Link>
                </Col>
                <Col md={4}>
                  <Image
                    src={
                      process.env.PUBLIC_URL + '/assets/images/Wellness-Art.png'
                    }
                  />
                </Col>
              </Row>
            </>
          )}
        </Wrapper>
      </div>
    );
  });
};

export default GetStarted;
