/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Alert, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';

import { getUsers, downloadResults } from 'api/admin';
import { Spacer } from 'components/utils';
import UserTable from './components/UserTable';
import SearchForm from './components/SearchForm';
import { primary } from 'styles/theme';

const PageTitle = styled.h3`
  display: inline-block;
  margin-right: 10px;
`

const DownloadLink = styled.span`
  cursor: pointer;
  color: ${primary};
  display: inline-block;
  margin-right: 10px;
  :hover {
    text-decoration: underline;
  }
`

const StyledButton = styled(Button)`
  margin-left: 10px;
  padding: 10px 15px;
  font-size: 14px;
`;

const Users = () => {
  const [loading, setLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [csvData, setCsvData] = useState();
  const [filename, setFilename] = useState('export-ingeneous.csv');
  const [searchIds, setSearchIds] = useState('');

  const [users, setUsers] = useState([]);
{/* For temporary dummy data*/}
  // const [users, setUsers] = useState([{
  //   id: 1,
  //   first_name: "Mima",
  //   last_name: "Inadia",
  //   email: "yemima.resync@gmail.com",
  //   last_login: "Yesterday",
  //   sample_id: "12345678",
  // }]);
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);

  useEffect(() => {
    setLoading(true);
    getUsers(searchIds, page)
      .then((res) => {
        setLoading(false);
        setUsers(res.data.users);
        setPage(res.data.page);
        setHasNext(res.data.has_next);
        setHasPrevious(res.data.has_previous);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    getUsers(searchIds, page)
      .then((res) => {
        setLoading(false);
        setUsers(res.data.users);
        setHasNext(res.data.has_next);
        setHasPrevious(res.data.has_previous);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [page, searchIds]);

  const handleInputChange = (val) => {
    const userId = val?.target?.value;
    if (userId) {
      const exists = selectedUsers.find((e) => e === userId);
      if (exists) {
        setSelectedUsers((prevState) => prevState.filter((e) => e !== userId));
      } else {
        setSelectedUsers((prevState) => [...prevState, userId]);
      }
    }
  };

  const handleDownloadResults = async (downloadType) => {
    setLoading(true);
    setCsvData(null);
    await downloadResults(selectedUsers, downloadType)
      .then((res) => {
        setLoading(false);
        const dt = format(new Date(), 'yyyyMMddHHmmss');
        if (downloadType === 'Test Results') {
          setFilename('ingeneous-test-results-' + dt + '.csv');
        } else if (downloadType === 'AMY Results') {
          setFilename('ingeneous-amy-results-' + dt + '.csv');
        } else if (downloadType === 'Essentials') {
          setFilename('ingeneous-essentials-' + dt + '.csv');
        } else if (downloadType === 'LifePlus') {
          setFilename('ingeneous-lifeplus-' + dt + '.csv');
        } else if (downloadType === 'Users') {
          setFilename('ingeneous-users-' + dt + '.csv');
        } else if (downloadType === 'Weight Management') {
          setFilename('ingeneous-wm-' + dt + '.csv');
        }  else if (downloadType === 'User-Reports') {
          setFilename('ingeneous-user-reports-' + dt + '.csv');
        }
        setCsvData(res.data?.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Container>
      <Row>
        <Col>
          <PageTitle>Users</PageTitle>
          <DownloadLink onClick={() => handleDownloadResults('Users')}>Download All Users</DownloadLink>
          <DownloadLink onClick={() => handleDownloadResults('User-Reports')}>Download Users & Reports</DownloadLink>
        </Col>
      </Row>
      <Row>
        <Col>
          <SearchForm setSearchIds={setSearchIds} />
        </Col>
        <Col md="auto">
          <StyledButton
            variant="primary"
            type="button"
            size="sm"
            disabled={loading}
            onClick={() => handleDownloadResults('Test Results')}
          >
            Test Results
          </StyledButton>
          {['AMY Results', 'Essentials', 'LifePlus'].map(
            (dType) => {
              return (
                <StyledButton
                  key={dType}
                  variant="primary"
                  type="button"
                  size="sm"
                  disabled={loading || selectedUsers?.length === 0}
                  onClick={() => handleDownloadResults(dType)}
                >
                  {dType}
                </StyledButton>
              );
            }
          )}
          <StyledButton
            variant="primary"
            type="button"
            size="sm"
            disabled={loading}
            onClick={() => handleDownloadResults('Weight Management')}
          >
            Weight Management
          </StyledButton>
          {csvData && (
            <CSVLink data={csvData} filename={filename} target="_blank">
              <StyledButton variant="secondary" type="button" size="sm">
                Download CSV
              </StyledButton>
            </CSVLink>
          )}
        </Col>
      </Row>

      {loading && (
        <>
          <Spacer height={'20px'} />
          <Alert variant="primary">Loading users...</Alert>
        </>
      )}

      <Spacer height={'20px'} />
      <UserTable
        users={users}
        selectedUsers={selectedUsers}
        loading={loading}
        handleInputChange={handleInputChange}
        page={page}
        setPage={setPage}
        hasNext={hasNext}
        hasPrevious={hasPrevious}
      />
    </Container>
  );
};

export default Users;
