import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { gray, primary } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import View from '../View';
import Close from '../ViewLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const AlertRef = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
  margin-bottom: 30px;
`;

const SubtitleGray = styled.h5`
  color: ${gray};
  margin-top: 10px;
`;

const WrapperRef = styled.div`
  margin-top: 5px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const ListRef = styled.li`
  font-weight: 300;
  padding-left: 20px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    word-break: break-all;
  }
`;

interface CarallumaProps {
  results: any;
}
const Caralluma = ({ results }: CarallumaProps) => {
  const [show, setShow] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/Supplement.png"
            alt="supplement"
            width={'60%'}
          />
        </ImageCol>
        <Col md={8}>
          <SubtitleGray>
            Your most powerful supplement for weight loss is
          </SubtitleGray>
          <Subtitle>Caraluma Fimbriata</Subtitle>
          <Alert>
            <p>
              Suggested Supplement: Caralluma Aerial Extract, Solaray (1 capsule
              with food daily)
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <p>
                Taking a Caraluma Fimbriata supplement will best support your
                weight loss journey.
              </p>
              <p>
                Caraluma Fimbriata is a cactus-like plant with a long history of
                use in India, local tribes would use it to ward off hunger while
                hunting (1). It has been linked to decrease in body weight, body
                mass index, hip circumference, body fat and energy intake (2,3).
                The extract is successful at this because of its ability to
                suppress appetite and signalling satiety (3). This supplement
                will help your body where it needs the most support.
              </p>
              <p style={{ fontWeight: 'bold' }}>
                This supplement is best for your genes. If you have any concerns
                this may not suit your current state of health or medications
                check with your doctor.
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
      <Row className="no-print">
        <Col>
          <AlertRef>
            <span>References</span>
            {!showReferences && (
              <View value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <Close value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <WrapperRef>
                <ListGroup>
                  <ListRef>
                    <a href="https://nz.iherb.com/pr/solaray-caralluma-aerial-extract-500-mg-30-vegcaps/89453a">
                      https://nz.iherb.com/pr/solaray-caralluma-aerial-extract-500-mg-30-vegcaps/89453a{' '}
                    </a>
                  </ListRef>
                  <ListRef>
                    <a href="https://pubmed.ncbi.nlm.nih.gov/17097761/">
                      https://pubmed.ncbi.nlm.nih.gov/17097761/
                    </a>
                  </ListRef>
                  <ListRef>
                    <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4314845/">
                      https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4314845/
                    </a>
                  </ListRef>
                </ListGroup>
              </WrapperRef>
            )}
          </AlertRef>
        </Col>
      </Row>
    </>
  );
};

export default Caralluma;
