import PublicAPI from './public'
import PrivateAPI from './private'

export const setup = () => {
  const token = localStorage.getItem('accessToken')
  return PrivateAPI.post('/api/users/token/verify/', { token: token })
}

export const refresh = () => {
  const token = localStorage.getItem('refreshToken')
  return PrivateAPI.post('/api/users/token/refresh/', { refresh: token })
}

export const login = (data) => {
  return PublicAPI.post('/api/users/login/', data)
}

export const loginWithEmail = (data) => {
  return PublicAPI.post('/api/users/email-login/', data)
}

export const getTokenFromLink = (token) => {
  return PublicAPI.post('/api/users/email-login/token/', {token: token})
}

export const loginCustomer = (data) => {
  return PublicAPI.post('/api/users/login/customer/', data)
}

export const sesameLogin = (token) => {
  return PublicAPI.post('/api/users/sesame/', { token: token })
}

export const register = (data) => {
  return PublicAPI.post('/api/users/', data)
}

export const registerPracOrg = (data) =>{
  return PublicAPI.post('/api/users/prac-orgs/', data)
}

export const checkEmail = (email) => {
  return PublicAPI.post('/api/users/check-email/', {'email': email})
}

export const checkPhoneNumber = (phoneNumber) => {
  return PublicAPI.post('/api/users/check-phone/', {'phone_number': phoneNumber})
}

export const verifyEmail = (data) => {
  return PublicAPI.post('/api/users/verify/' + data['verify_token'] + '/', {})
}

export const forgotPassword = (data) => {
  return PublicAPI.post('/api/users/passwords/forgot/', data)
}

export const resetPassword = (data) => {
  return PublicAPI.post('/api/users/passwords/reset/', data)
}

export const changePassword = (data) => {
  return PrivateAPI.post('/api/users/passwords/change/', data)
}

export const userDetail = () => {
  return PrivateAPI.get('/api/users/me/', {})
}

export const userUpdate = (data) => {
  return PrivateAPI.post('/api/users/me/', data)
}

export const userPartialUpdate = (data) => {
  return PrivateAPI.post('/api/users/me/partial/', data)
}

export const userChangePhoto = (data) => {
  return PrivateAPI.post('/api/users/change-photo/', data)
}

export const updateAddress = (payload) => {
  return PrivateAPI.post('/api/users/update-address/', payload)
}

export const updateBilling = (payload) => {
  return PrivateAPI.post('/api/users/update-billing/', payload)
}
