import React from 'react'
import { Row } from 'react-bootstrap'

import { getColor, Serving, Image, BodyCol } from './utils'

const Two = () => {
  return (<>
    <BodyCol xs={12} md={4}>
      <Serving color={getColor(2)}>2 servings a day</Serving>
      <p>Two servings equals 14 grams of coffee - this is equivalent to 2 shots of coffee, 2 capsules or 2 teaspoons of instant coffee.</p>
      <p>Your inflammation system can tolerate a higher amount of caffeine than some other people.</p>
    </BodyCol>
    <BodyCol xs={12} md={5}>
      <p>You have sufficient ability to metabolize (munch up) coffee quickly when it enters your system, but it does exert a negative impact during that short time that it is active which ought to be minimized.</p>
    </BodyCol>
  </>)
}

const One = () => {
  return (<>
    <BodyCol xs={12} md={4}>
      <Serving color={getColor(1)}>1 serving a day</Serving>
      <p>One serving equals 7 grams of coffee - this is equivalent to 1 shot of coffee, 1 capsule or 1 teaspoon of instant coffee.</p>
      <p>Your inflammation system can tolerate some caffeine.</p>
    </BodyCol>
    <BodyCol xs={12} md={5}>
      <p>You have some ability to metabolize (munch up) coffee quickly when it enters your system, but it does exert a negative impact during that short time that it is active which ought to be minimized.</p>
    </BodyCol>
  </>)
}

const Zero = () => {
  return (<>
    <BodyCol xs={12} md={4}>
      <Serving color={getColor(0)}>Zero servings a day</Serving>
      <p>We are sorry, your inflammation system cannot tolerate caffeine.</p>
    </BodyCol>
    <BodyCol xs={12} md={5}>
      <p>Caffeine can significantly impact your body’s ability to correctly carry out important cellular chemical processes such as detoxification. If your cellular detoxification process is compromised toxins may not be removed and build up in your bloodstream causing inflammation and eventually leading to disease.</p>
      <p>An imbalance impacts your mood, mental health and energy levels – this combination is your first sign for identifying cells that may not be healthy.</p>
    </BodyCol>
  </>)
}

const Coffee = ({ serving }) => {
  return (<Row>
    <BodyCol xs={12}>
      <h4>Coffee</h4>
      <p>Your polymorphisms (SNP's) involved in the metabolism of hot drinks have been analysed. The evidence-based conclusions have told us that you can consume the following quantities:</p>
    </BodyCol>
    <BodyCol xs={12} md={3}>
      <Image src={process.env.PUBLIC_URL + '/assets/images/lifeplus/ing-coffee.png'} />
    </BodyCol>
    {serving === 2 && <Two />}
    {serving === 1 && <One />}
    {serving === 0 && <Zero />}
  </Row>)
}

export default Coffee
