/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { AuthContext } from 'stores/auth';
import { verifyEmail } from 'api/auth';

const Wrapper = styled.div`
  padding: ${isMobile ? '10px' : '30px 0'};
`;

function VerifyEmail() {
  let history = useHistory();
  const { verify_token } = useParams();
  const authStore = useContext(AuthContext);

  const [verifying, setVerifying] = useState(true);
  const [valid, setValid] = useState(false);
  const [errors, setErrors] = useState({ message: '' });

  useEffect(() => {
    setVerifying(true);
    verifyEmail({
      verify_token: verify_token,
    })
      .then((res) => {
        setValid(true);
        setVerifying(false);
        authStore
          .setTokens(res.data.access, res.data.refresh)
          .then(() => {
            history.push('/dashboard');
          })
          .catch((err) => {
            if (err.response.status >= 400) {
              history.push('/login');
            }
          });
      })
      .catch((err) => {
        if (err.response && err.response.status === 406) {
          history.push('/login');
        }
        setValid(false);
        setVerifying(false);
        setErrors({ message: 'We are unable to verify your email address.' });
      });
  }, []);

  return (
    <Container>
      <Wrapper>
        {!verifying &&
          (valid ? (
            <div className="email-confirmation">
              <h3 className="email-confirmation__title">
                Your e-mail address has been successfully verified
              </h3>
              <p className="email-confirmation__sub">
                Thank you for choosing the Ingeneous
              </p>
              <div className="email-confirmation__buttons">
                <Link className="btn btn-primary" to="/dashboard">
                  Go to dashboard
                </Link>
              </div>
            </div>
          ) : (
            <div className="email-confirmation">
              <h3 className="email-confirmation__title">{errors.message}</h3>
              <div className="email-confirmation__buttons">
                <Link className="btn btn-primary" to="/login">
                  Go to Login
                </Link>
              </div>
            </div>
          ))}

        {verifying && (
          <div className="email-confirmation">
            <h3 className="email-confirmation__title">
              Please wait while we verify your email address.
            </h3>
            <div className="email-confirmation__buttons">
              <Link className="btn btn-primary" to="/login">
                Go to Login
              </Link>
            </div>
          </div>
        )}
      </Wrapper>
    </Container>
  );
}

export default VerifyEmail;
