import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Spacer } from 'components/utils';
import ReadMore from './ReadMore';
import ReadLess from './ReadLess';
import { borderGray, gray, primary } from 'styles/theme';
import useDetectPrint from 'react-detect-print';

const Wrapper = styled.div`
  background-color: #ffffff;
  min-height: 200px;
  border-radius: 5px;
  border: 1px solid ${borderGray};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: ${isMobile ? '20px 10px' : '30px'};

  @media print {
    border: none;
  }
`;

const SubtitleGray = styled.h5`
  color: ${gray};
  width: ${isMobile ? '100%' : 'inital'};

  span {
    color: ${primary};
    width: ${isMobile ? '100%' : 'inital'};
  }
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const WrapperRef = styled.div`
  margin-top: 5px;
`;

const ListRef = styled.li`
  font-weight: 300;
  padding-left: 20px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
  }
`;

const List = styled.li`
  font-weight: 300;

  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
  }
`;

const ListGroupUl = styled.ul`
  font-weight: bold;
  padding-left: 20px;
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const WrapperSubs = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
`;

interface HandbrakesProps {
  results: any;
}

const Handbrakes = ({ results }: HandbrakesProps) => {
  const [show, setShow] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const printing = useDetectPrint();

  return (
    <Wrapper>
      <Row className="page-br">
        <Col>
          <h2>Handbrakes</h2>
          <WrapperSubs>
            {isMobile ? (
              <>
                <SubtitleGray>
                  You might have a hand break that will
                  <span>&nbsp;hold you back.</span>
                </SubtitleGray>
              </>
            ) : (
              <>
                <SubtitleGray>
                  You might have a hand break that will
                  <span>&nbsp;hold you back.</span>
                </SubtitleGray>
              </>
            )}
          </WrapperSubs>
          <Spacer height={'30px'} />
          <div>
            <p>
              If you are following these recommendations and are not achieving
              any results you may be experiencing one of the following weight
              handbrakes.
            </p>
            <p>
              Not to worry! Your Ingeneous practitioner will know just what to
              do to help you remove this handbrake so you can continue on your
              journey to your optimal weight!
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <>
              <WrapperRef>
                <ListGroup>
                  <ListRef>
                    Hormonal Handbrake
                    <ListGroupUl>
                      <List>
                        Some individuals may have an imbalance of hormones that
                        makes it harder to lose weight
                      </List>
                    </ListGroupUl>
                  </ListRef>
                  <ListRef>
                    Prescription Drug Handbrake
                    <ListGroupUl>
                      <List>
                        Many prescription drugs (especially antipsychotics) can
                        cause very stubborn weight gain
                      </List>
                    </ListGroupUl>
                  </ListRef>
                  <ListRef>
                    Birth Control Handbrake
                    <ListGroupUl>
                      <List>
                        Some women on birth control may have greater difficulty
                        losing weight
                      </List>
                    </ListGroupUl>
                  </ListRef>
                  <ListRef>
                    Low Dopamine or Serotonin
                    <ListGroupUl>
                      <List>
                        If you are low on dopamine or serotonin it will be
                        difficult for you to feel full
                      </List>
                    </ListGroupUl>
                  </ListRef>
                  <ListRef>
                    Red FTO and red MC4R
                    <ListGroupUl>
                      <List>
                        (only 1% of the population) are most likely to encounter
                        a handbrake
                      </List>
                    </ListGroupUl>
                  </ListRef>
                </ListGroup>
              </WrapperRef>
              <p>
                If you suspect you may be experiencing one of these handbrakes
                please talk to your Ingeneous Practitioner for support on how to
                overcome this obstacle.
              </p>
            </>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
      <hr className="no-print" />
      <Row>
        <Col>
          <h2>Please Note</h2>
          <Spacer height={'10px'} />
          <div>
            <p>
              The following recommendations are based on you being fit with a
              healthy BMI, no medical conditions, no prescription medication
              (including contraceptive pill), not pregnant or lactating, and
              over the age of 18. If you fit any of the above criteria you are
              required to have an Ingeneous Consultation before actioning any of
              the recommendations contained with this report.
            </p>
          </div>
          <Spacer height={'10px'} />
          {!showNote && !printing && (
            <ReadMore value={showNote} setValue={setShowNote} />
          )}
          {(showNote || printing) && (
            <>
              <WrapperRef>
                <div>
                  <p>
                    <BoldSpan>
                      Medical conditions, intolerances, sensitivities and
                      allergies are NOT tested for in your Gene SNP testing with
                      Ingeneous.
                    </BoldSpan>
                    &nbsp; If you know that you have a particular medical
                    condition, allergy, sensitivity or intolerance, please avoid
                    ANY recommendation relating to this. You can choose other
                    alternatives or get in contact with an Ingeneous
                    practitioner for a suggestion.
                  </p>
                  <p>
                    Some recommendations within this report are only intended to
                    be followed for 3 weeks at a time, as some of them can be
                    inflammatory if continued for longer, negating their use.
                  </p>
                  <p>
                    Please listen to your body. Eating fewer than 1200 calories
                    for an extended period of time can be dangerous so only
                    restrict for 3 weeks. Adjusting to extreme hot or cold
                    temperatures can be dangerous, please make sure to start
                    slow to avoid hypothermia or heat stroke and drink water to
                    avoid dehydration. Always read and follow the label for
                    supplements.
                  </p>
                </div>
              </WrapperRef>
              <p>
                If you suspect you may be experiencing one of these handbrakes
                please talk to your Ingeneous Practitioner for support on how to
                overcome this obstacle.
              </p>
            </>
          )}
          {showNote && !printing && (
            <ReadLess value={showNote} setValue={setShowNote} />
          )}
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Handbrakes;
