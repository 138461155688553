import React from 'react'

const Zinc = () => {
  return (<div>
    <h5>Zinc Rich Foods</h5>
    <p>
      Please note that your genetic SNP testing with Ingeneous is NOT able to tell you if you have an allergy or intolerance to any of these zinc rich foods. If you know that you have a sensitivity to a suggested food on this list, please choose an alternative.
    </p>
    <p>
      This recommendation is particularly helpful for coordinating all three of your key Essential Start systems (inflammation, detoxification, and vitamin D pathway) plus zinc is your go-to ingredient to boost your immune system and fight off infections.
    </p>
    <p>
      Here are some top choices to naturally increase the amount of zinc in your diet:
    </p>
    <ul>
      <li>Oysters, 1 medium (raw or cooked) = 22.55mg</li>
      <li>Chicken liver, 1 cup = 8.79mg</li>
      <li>Beef mince, cooked = ½ cup = 8.78mg</li>
      <li>Wild or black rice, ½ cup = 4.77mg</li>
      <li>Cashew nuts, ¼ cup = 2.17mg</li>
      <li>Cheddar cheese, ¼ cup = 2.05mg</li>
      <li>Sesame seeds, 2T = 1.36mg</li>
      <li>Pumpkin seeds, ¼ cup = 1.25mg</li>
    </ul>
  </div>)
}

export default Zinc
