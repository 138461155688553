import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { gray, primary } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const SubtitleGray = styled.h5`
  color: ${gray};
  span {
    color: ${primary};
  }
`;

interface GoodFastProps {
  results: any;
}
const GoodFast = ({ results }: GoodFastProps) => {
  const [show, setShow] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/Fasting.png"
            alt="fasting"
            width={'60%'}
            className="image-print"
          />
        </ImageCol>
        <Col md={8}>
          <div style={{ display: 'flex' }}>
            <SubtitleGray>
              To lose weight<span>&nbsp;Fasting is Good</span>
            </SubtitleGray>
          </div>
          <Alert>
            <p>
              Try to incorporate fasting into your lifestyle in whichever way
              will work best for you.
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <h5>Fasting is Good</h5>
              <p>
                Fasting may be helpful to your weight optimisation journey. Your
                gene SNPs indicate fasting could help you reduce your caloric
                intake and lose weight without causing harm.
              </p>
              <p>
                Fasting is the practice of going without calories for a period
                of time. This fasting period can range from several hours to
                several days. Some people can become more inflamed when fasting,
                making it counterproductive while trying to lose weight, but
                lucky for you this is not the case (1). Fasting can help you
                reduce your caloric intake, and if your calories burnt (energy
                used) remains the same this can result in weight loss (2,3). Any
                type of fasting has the possibility of helping you reduce your
                caloric intake, if you do not overeat when you break your fast.
                Intermittent fasting has been shown to decrease BMI and glucose
                (sugar) metabolism (4). Some additional benefits fasting may
                provide include: reducing insulin resistance, increasing
                expression of genes related to longevity, and actually
                decreasing inflammation protecting you from disease (5).
              </p>
              <p>
                Most people fast for at least 8 hours a day due to sleep
                already, and others fast longer by skipping breakfast or dinner.
                Some people choose to fast on certain days of the week, but it
                is important to continue drinking water during periods of
                fasting.
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
    </>
  );
};

export default GoodFast;
