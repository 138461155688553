import React, { useEffect, useState, useContext } from 'react';
import { useObserver } from 'mobx-react-lite';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { getUserProducts } from 'api/core';
import { AuthContext } from 'stores/auth';
import ReportType from './ReportType';

const Header = styled.div`
  text-align: center;
`;

const Wrapper = styled.div<{ addPad: boolean }>`
  padding: ${(addPad) => (addPad ? '40px 0' : isMobile ? '15px 0' : '0')};
`;

interface ReportProps {
  active: string;
  pad: boolean;
}

const Reports = ({ active, pad }: ReportProps) => {
  const authStore = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [userProducts, setUserProducts] = useState<any>();

  useEffect(() => {
    setLoading(true);
    getUserProducts().then((res) => {
      setUserProducts(res.data.purchased_products);
      setLoading(false);
    });
  }, []);

  return useObserver(() => {
    const reportStatus = authStore.reports;

    return (
      <Wrapper addPad={pad}>
        <Header>
          <h4>My Reports</h4>
        </Header>

        <ReportType
          loading={loading}
          report={'Essential Start'}
          shortcode={'essential'}
          activeReport={active}
          image={'/assets/images/report-1.png'}
          status={reportStatus['essential']}
          order={userProducts?.find(
            (prodId: string | number) =>
              prodId.toString() ===
                process.env.REACT_APP_ESSENTIAL_PRODUCT_ID ||
              prodId.toString() ===
                process.env.REACT_APP_LIFE_PLUS_BUNDLE_PRODUCT_ID ||
              prodId.toString() ===
                process.env.REACT_APP_CONSULTATION_BUNDLE_PRODUCT_ID ||
              prodId.toString() ===
                process.env.REACT_APP_MEMBERSHIP_BUNDLE_PRODUCT_ID
          )}
        />

        <ReportType
          loading={loading}
          report={'LifeStyle Plus'}
          shortcode={'lifeplus'}
          activeReport={active}
          image={'/assets/images/report-2.png'}
          status={reportStatus['life_plus']}
          order={userProducts?.find(
            (prodId: string | number) =>
              prodId.toString() ===
                process.env.REACT_APP_LIFE_PLUS_PRODUCT_ID ||
              prodId.toString() ===
                process.env.REACT_APP_LIFE_PLUS_BUNDLE_PRODUCT_ID ||
              prodId.toString() ===
                process.env.REACT_APP_CONSULTATION_BUNDLE_PRODUCT_ID ||
              prodId.toString() ===
                process.env.REACT_APP_MEMBERSHIP_BUNDLE_PRODUCT_ID
          )}
        />

        <ReportType
          // comingSoon
          loading={loading}
          report={'Weight Mgmt'}
          shortcode={'weight-management'}
          activeReport={active}
          image={'/assets/images/recommendations/WeightMgmt.png'}
          status={reportStatus['weight_management']}
          order={userProducts?.find(
            (prodId: string | number) =>
              prodId.toString() ===
              process.env.REACT_APP_WEIGHT_MANAGEMENT_PRODUCT_ID
          )}
        />

        {/* <AddOn
        title={'Membership'}
        shortcode={'membership'}
        status={reportStatus['membership']}
      />

      <AddOn
        title={'Practitioner'}
        shortcode={'practitioner'}
        status={reportStatus['practitioner']}
      /> */}
      </Wrapper>
    );
  });
};

export default Reports;
