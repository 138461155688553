/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

import { getSupplement } from 'api/reports';
import { gray } from 'styles/theme';
import { Spacer } from 'components/utils';

const Wrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid #dedede;
`;

const Content = styled.div`
  min-height: 200px;
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
  word-break: break-all;
  padding: 15px 0;
  word-break: keep-all;

  ol,
  ul {
    li {
      font-size: 12px;
    }
  }
`;

const Title = styled.h5`
  color: ${gray};
  border-bottom: 5px;
`;

const Name = styled.span`
  text-transform: uppercase;
`;

const DetailWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const ProductImage = styled.div`
  width: 100px;
  height: 150px;
  background-color: #dedede;
  margin-right: 10px;
`;

const ProductDetails = styled.div`
  flex: 1;

  span {
    display: block;
    margin-bottom: 10px;
  }
`;

const Supplement = ({ title, name }) => {
  const [supplement, setSupplement] = useState();

  useEffect(() => {
    getSupplement(name).then((res) => {
      setSupplement(res.data.product);
    });
  }, []);

  return (
    <Wrapper>
      {supplement && (
        <Row>
          <Col md={12}>
            <Title>{title}</Title>
            <Name>{supplement.title}</Name>
          </Col>
          {supplement.description ? (
            <Col md={12}>
              <Content>{ReactHtmlParser(supplement.description)}</Content>
            </Col>
          ) : (
            <Spacer height={'30px'} />
          )}
          <Col md={6} style={{ display: 'none' }}>
            <DetailWrapper>
              <ProductImage />
              <ProductDetails>
                <span>{supplement.title}</span>
                <span>${supplement.price}</span>
              </ProductDetails>
            </DetailWrapper>
          </Col>
        </Row>
      )}
    </Wrapper>
  );
};

export default Supplement;
