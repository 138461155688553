/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useObserver } from 'mobx-react-lite';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { getProducts, getUserProducts, Product } from 'api/core';
import { getFeaturedEvents, getEventPublic } from 'api/admin';
import { Spacer } from 'components/utils';
import { Page, PageHeader, PageTitle, PageBody } from 'components/Page';

import Form from '../../components/Cart/Form';
import { Event } from 'api/models/event.interface';
import { AuthContext } from 'stores/auth';
import { Link } from 'react-router-dom';
import CartItems from 'components/Cart/CartItems';
import { primary } from 'styles/theme';
import CartFormLogin from 'components/Cart/CartFormLogin';

const Wrapper = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
  align-items: center;

  .title {
    flex: 1;
    display: ${isMobile ? 'block' : 'flex'};
    align-items: center;
    justify-content: space-between;

    h1 {
      margin: 0;
      margin-right: 20px;
    }
  }
`;

const StripeFooter = styled.div`
  text-align: center;

  img {
    margin-left: 15px;
  }
`;

const BannerWrapper = styled.div``;

const Banner = styled.div`
  background-color: #f2f2f2;
  border: 1px solid #e5864b;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 16px;

  p {
    margin-bottom: 0;
  }
`;

const LoginHere = styled.p`
  color: ${primary};
  font-size: 0.9rem;
  margin-left: 5px;
  max-width: 300px;
  cursor: pointer;

  span {
    font-weight: 600;
  }
`;

function EventCart() {
  // Keep track of our component's mounted state
  const componentIsMounted = useRef(true);
  const [products, setProducts] = useState<Product[]>([]);
  const [userProducts, setUserProducts] = useState<string[]>([]);
  const [event, setEvent] = useState<Event>();
  const [events, setEvents] = useState<Event[]>([]);
  const [loginFormVisible, setLoginFormVisible] = useState(false);

  const [productSelected, updateProductSelected] = useState([] as any);

  const authStore = useContext(AuthContext);

  const eventDiscounts = [
    {
      product_id: process.env.REACT_APP_ESSENTIAL_PRODUCT_ID ?? '',
      discount_value: 37900,
      discount_type: 'price',
    },
    // {
    //   product_id: process.env.REACT_APP_LIFE_PLUS_PRODUCT_ID ?? '',
    //   discount_value: 26000,
    //   discount_type: 'price',
    // },
    {
      product_id: process.env.REACT_APP_CONSULTATION_PRODUCT_ID ?? '',
      discount_value: 3000,
      discount_type: 'price',
    },
    {
      product_id: process.env.REACT_APP_WEIGHT_MANAGEMENT_PRODUCT_ID ?? '',
      discount_value: 25000,
      discount_type: 'price',
    },
    {
      product_id:
        process.env.REACT_APP_WEIGHT_MANAGEMENT_CONSULTATION_PRODUCT_ID ?? '',
      discount_value: 5000,
      discount_type: 'price',
    },
  ];

  useEffect(() => {
    getFeaturedEvents().then((res) => {
      console.log('events', res);
      setEvents(res.data?.events);
      if (!componentIsMounted.current) return;
    });
    getProducts().then((res) => {
      if (!componentIsMounted.current) return;
      setProducts(res.data?.products);
    });

    if (authStore?.loggedIn) {
      getUserProducts().then((res) => {
        setUserProducts(res.data.purchased_products);
      });
    }

    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const handleEventChange = (pk: number) => {
    getEventPublic(pk).then((res) => {
      setEvent(res.data?.event);
    });
  };

  return useObserver(() => {
    const billingAddresses = authStore.addresses
      ? authStore.addresses.filter(
          (item: { address_type: string }) => item.address_type === 'billing'
        )
      : [];
    const billingAddress = billingAddresses[0];
    const shippingAddresses = authStore.addresses
      ? authStore.addresses.filter(
          (item: { address_type: string }) => item.address_type === 'delivery'
        )
      : [];
    const shippingAddress = shippingAddresses[0];

    let defaultValues = {
      delivery: { ...shippingAddress },
      billing: null,
      first_name: null,
      last_name: null,
      phone_number: null,
    };

    if (billingAddress) {
      defaultValues.billing = { ...billingAddress };
    } else if (!billingAddress && shippingAddress) {
      defaultValues.billing = { ...shippingAddress };
    }

    defaultValues.first_name = authStore?.user?.first_name;
    defaultValues.last_name = authStore?.user?.last_name;
    defaultValues.phone_number = authStore?.user?.phone_number;

    return (
      <Page>
        <PageHeader>
          <Spacer height={'30px'} />
          <Container>
            <Wrapper>
              <div className="title">
                <PageTitle>New Order</PageTitle>
              </div>
            </Wrapper>
          </Container>
        </PageHeader>

        <PageBody>
          <Container className="order">
            {!isMobile ? (
              <div>
                <Spacer height={'30px'} />
                <BannerWrapper>
                  <Banner>
                    <p>
                      If you currently have cold, flu, or Covid-19 symptoms
                      please do not submit an Ingeneous DNA swab.
                    </p>
                    <Spacer height={'15px'} />
                    <p>
                      Also, if you have been deemed a close contact,
                      self-monitor for Covid-19 symptoms for 10 days after you
                      were exposed, do not submit a DNA swab until these 10 days
                      are complete with no symptoms.
                    </p>
                    <Spacer height={'15px'} />
                    <p>
                      Symptoms include: sore throat, new or worsening cough,
                      sneezing or runny nose, fever, shortness of breath,
                      altered or lost taste or smell.
                    </p>

                    <p>
                      We appreciate your understanding of this safety measure to
                      help us prevent the spread of Covid-19. Please email us at
                      hello@ingeneous.co.nz if you have any further queries.
                    </p>
                  </Banner>
                </BannerWrapper>
              </div>
            ) : (
              ''
            )}
            <Spacer height={'40px'} />
            {authStore?.loggedIn ? (
              <>
                <h5>Welcome back {authStore.user.name}!</h5>
                <p>
                  Not you?{' '}
                  <span
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      authStore.logout();
                      window.location.reload();
                    }}
                  >
                    Logout
                  </span>
                </p>
              </>
            ) : (
              <>
                <div>
                  <h5>Already an Ingenous customer?</h5>
                  <LoginHere onClick={() => setLoginFormVisible(!loginFormVisible)}>Login here</LoginHere>
                  {loginFormVisible ? <CartFormLogin displayTitle={false}/> : ''}
                </div> 
              </>
            )}
            <Spacer height={'30px'} />
            {isMobile ? (
              <h5 style={{ paddingLeft: 15 }}>Select Your Reports</h5>
            ) : (
              <span>Choose your reports</span>
            )}
            <Spacer height={'10px'} />
            <Row>
              <Col
                sm={12}
                md={12}
                style={{ padding: isMobile ? '0' : '0 15px' }}
              >
                <CartItems
                  updateProductSelected={updateProductSelected}
                  eventDiscounts={eventDiscounts}
                  isEvent={true}
                  loggedIn={authStore?.loggedIn}
                  ignoreStockout={true}
                />
              </Col>
            </Row>

            <Spacer height={'30px'} />

            <Form
              event={event}
              events={events}
              eventCode={event?.event_code ?? ''}
              eventDiscounts={eventDiscounts}
              selectedProducts={productSelected}
              userProducts={authStore?.loggedIn ? userProducts : products}
              handleEventChange={handleEventChange}
              isLoggedIn={false}
              defaultValues={defaultValues}
              askMedication={false}
              loginBox={false}
            />

            <Spacer height={'40px'} />

            <StripeFooter>
              <span>
                <strong>Safe and secure checkout</strong>
              </span>
              <img
                src={process.env.PUBLIC_URL + '/assets/images/Stripe.png'}
                alt=""
              />
            </StripeFooter>

            <Spacer height={'40px'} />
          </Container>
        </PageBody>
      </Page>
    );
  });
}

export default EventCart;
