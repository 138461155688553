import React, { useContext, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import styled from 'styled-components';
import { Element, scroller } from 'react-scroll';
import { isMobile } from 'react-device-detect';

import { AuthContext } from 'stores/auth';
import { borderGray, gray, primary } from 'styles/theme';
import { Spacer } from 'components/utils';
import Inflammations from './Inflammations/index';
import Diet from './DietAndExercise/Effort/index';
import Temperature from './Temperature/index';
import Fasting from './Fasting/index';
import Supplement from './Supplement/index';
import Superfood from './Superfood/index';
import { IWeightManagementReport } from 'api/reports';

const Wrapper = styled.div`
  background-color: #ffffff;
  min-height: 2020px;
  border-radius: 5px;
  border: 1px solid ${borderGray};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: ${isMobile ? '20px 10px' : '30px'};

  @media print {
    border: none;
    margin-top: 40px;
  }
`;

const Title = styled.h4`
  color: ${primary};
  text-align: center;
`;

const Subtitle = styled.p`
  color: ${gray};
  text-align: center;
  border-top: 1px solid ${borderGray};
  margin-top: 15px;
  padding-top: 10px;
  font-size: 16px;
  padding-bottom: 20px;
`;

const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  margin: 0 auto;
  position: relative;
`;

const CardStyle = styled.button`
  &:focus {
    outline: solid !important;
    outline-color: ${primary} !important;
  }
  padding: 0;
`;

const RecomCol = styled(Col)`
  display: ${isMobile ? 'flex' : 'inherit'};
  justify-content: ${isMobile ? 'center' : 'inherit'};
`;

interface RecommendationsProps {
  results: IWeightManagementReport;
  useName: boolean;
}

const Recommendations = ({ results, useName=true }: RecommendationsProps) => {
  const caps = (words: string) =>
    words
      .split(' ')
      .map((word: string) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(' ');
  const authStore = useContext(AuthContext);
  const recommendations = [
    {
      id: 'inflammation',
      name: 'Inflammation',
      description: `${
        (results.inflammation ?? 0.5) < 1 / 3
          ? 'Slightly '
          : (results.inflammation ?? 0.5) < 2 / 3
          ? 'Moderately '
          : 'Largely '
      } Decrease Inflammation`,
      image: '/assets/images/recommendations/Inflamation.png',
    },
    {
      id: 'diet',
      name: `Diet: ${caps(results.diet_type ?? '')}`,
      description: `${results.diet_percentage}:${
        results.exercise_percentage
      } ${caps(results.diet_type ?? '')} & ${caps(
        results.exercise_type ?? ''
      )}`,
      image: '/assets/images/recommendations/DietExercise.png',
    },
    {
      id: 'temperature',
      name: 'Temperature',
      description: `${
        results.temperature === 'hot'
          ? 'Hot Temperatures'
          : results.temperature === 'cold'
          ? 'Cold Temperatures'
          : 'Alternate Hot and Cold'
      }`,
      image: '/assets/images/recommendations/Temperature.png',
    },
    {
      id: 'fasting',
      name: 'Fasting',
      description: `${
        results.fasting === 'good'
          ? 'Fasting is good'
          : results.fasting === 'neutral'
          ? 'Fasting is Okay'
          : 'Do not Fast'
      }`,
      image: '/assets/images/recommendations/Fasting.png',
    },
    {
      id: 'supplement',
      name: 'Supplement',
      description: `${caps(results.supplement ?? '')}`,
      image: '/assets/images/recommendations/Supplement.png',
    },
    {
      id: 'superfood',
      name: 'Superfood',
      description: `${caps(results.food ?? '')}`,
      image: '/assets/images/recommendations/Superfood.png',
    },
  ];

  const handleScroll = (location: string) => {
    scroller.scrollTo(location, {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: 0,
    });
  };

  const siteSelectedCallback = (id: string) => {
    handleScroll(id);
  };

  useEffect(() => {
    if (window.location.hash) {
      scroller.scrollTo(window.location.hash.replace('#', ''), {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset: 0,
      });
    }
  }, [results]);

  return (
    <>
      <Element name="recommendations" className="drink" />
      <Wrapper>
        <Row>
          <Col>
            {isMobile ? (
              <h3>{useName ? authStore.user.first_name + '\'s ':''}Recommendations</h3>
            ) : (
              <h2>{useName ? authStore.user.first_name + '\'s ':''}Recommendations</h2>
            )}
          </Col>
        </Row>
        <Row lg={3} className="g-4">
          {recommendations.map((item, key) => (
            <RecomCol key={key}>
              <CardStyle
                as="button"
                style={{
                  width: '14rem',
                  background: '#f2f2f2',
                  border: 'none',
                  maxHeight: '15rem',
                  marginTop: '15px',
                }}
                onClick={() => siteSelectedCallback(item.id)}
              >
                <Card.Body style={{ textAlign: 'center' }}>
                  <Title>{item.name}</Title>
                  <ImageWrapper>
                    <Card.Img
                      variant="top"
                      src={item.image}
                      style={{
                        width: '100%',
                        maxHeight: '100px',
                        top: '50%',
                        position: 'absolute',
                        transform: 'translateY(-50%)',
                        right: '0',
                      }}
                      alt="image"
                    />
                  </ImageWrapper>
                  <Subtitle>{item.description}</Subtitle>
                </Card.Body>
              </CardStyle>
            </RecomCol>
          ))}
        </Row>
        <Row>
          <Col>
            <Spacer height={'30px'} />
            {results && <Inflammations results={results} />}
            {results && <Diet results={results} />}
            {results && <Temperature results={results} />}
            {results && <Fasting results={results} />}
            {results && <Supplement results={results} />}
            {results && <Superfood results={results} />}
          </Col>
        </Row>
      </Wrapper>
    </>
  );
};

export default Recommendations;
