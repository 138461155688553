import React from 'react'

const Cigarette = () => {
  return (<div>
    <h5>Avoid cigarette smoke both passive and active. Even the residual smell of smoke on clothing can have a negative impact on your health</h5>
    <p>
      You are much more sensitive to cigarette smoke than the average person.  Second hand smoking is also very detrimental. Cigarette smoke is not healthy for anyone, but for you it is doubly unhealthy.
    </p>
    <p>
      We understand that quitting smoking can be a very challenging task, and it is the number one thing your can do to improve your health if you actively smoke. It can take up to 5 years before your inflammatory gene expression will return to that of a healthy non-smoker.1
    </p>
    <p>
      If you are an Ingeneous member please get in contact with our practitioner line and we can direct you toward support and strategies to help towards this goal. Alternatively, your primary healthcare provider will be able to provide guidance.
    </p>
    <ol>
      <li>Wannamethee SG, Lowe GD, Shaper AG, Rumley A, Lennon L, Whincup PH. Associations between cigarette smoking, pipe/cigar smoking, and smoking cessation, and haemostatic and inflammatory markers for cardiovascular disease. Eur Heart J 2005;26:1765-1773</li>
    </ol>

  </div>)
}

export default Cigarette
