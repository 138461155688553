/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { matchPath, Redirect } from 'react-router';
import { useForm } from 'react-hook-form';
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import styled from 'styled-components';

import { getSampleIdV2 } from 'api/practitioner';
import Icon from 'components/Icon';
import { primary } from 'styles/theme';
import { isMobile } from 'react-device-detect';
import ReportLink from './ReportLink';

const Wrapper = styled.div`
  padding: ${isMobile ? '15px 0' : '15px'};
  background-color: #f2f2f2;
  border-radius: 10px;
`;

const FormRowWrapper = styled.div`
  display: flex;

  .form-group {
    margin: 0;
    flex: 1;
  }

  input {
    font-size: 0.8rem;
  }
`;

const SearchBtn = styled(Button)`
  padding: 7px 15px;
  margin-left: 10px;
  font-size: 14px;
`;

const SideRow = styled.div`
  padding: 7px 2px;
  border-bottom: 1px solid #dcd8d6;
  font-size: 0.95rem;
  font-weight: ${(props) => (props.match ? '600' : '400')};

  a {
    color: #707077;
  }
`;

const SampleID = styled.span`
  color: ${primary};
  display: inline-block;
`;

const Name = styled.span`
  display: block;
  color: ${primary};
`;

const SearchForm = ({ query, handleSearch, handleLoading, kitId=null }) => {
  let location = useLocation();
  const { register, handleSubmit, errors, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [sampleId, setSampleId] = useState(query);
  const [record, setRecord] = useState();
  const [productIds, setProductIds] = useState([]);

  const productIdsOrder = [
    process.env.REACT_APP_ESSENTIAL_PRODUCT_ID,
    process.env.REACT_APP_LIFE_PLUS_PRODUCT_ID,
    process.env.REACT_APP_CONSULTATION_PRODUCT_ID,
    process.env.REACT_APP_WEIGHT_MANAGEMENT_CONSULTATION_PRODUCT_ID
  ]

  const reportLinks = (productIds) => {
    return productIdsOrder.map((productId, index) => {
      if(!productIds.includes(productId)){
        return
      }
      return (
        <Col xs={12} key={index}>
          <ReportLink productId={productId}/>
        </Col>
      )
    })
  }

  const flipLoading = (state) => {
    setLoading(state)
    handleLoading(state)
  }

  useEffect(() => {
    let isMounted = true;
    if (sampleId) {
      setValue('sample_id', sampleId);
      getSampleIdV2(sampleId)
        .then((res) => {
          if (isMounted) {
            setRecord(res.data?.sample_id);
            setProductIds(res.data?.sample_id?.product_ids);
            localStorage.setItem('productIds', res.data?.sample_id?.product_ids.join(','))
            flipLoading(false);
          }
        })
        .catch(() => {
          flipLoading(false);
          setProductIds([])
          localStorage.removeItem('productIds')
          setRecord(null)
        });
    }else{
      setSampleId(kitId)
    }

    return () => {
      isMounted = false;
    };
  }, [sampleId]);

  const onSubmit = async (data) => {
    flipLoading(true);
    setSampleId(data.sample_id);
    handleSearch(data.sample_id);
  };

  return (
    <Wrapper>
      <Row>
        <Col xs={12}>
          <Form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Col xs={12}>
                <FormRowWrapper>
                  <Form.Group controlId="searchKitId">
                    <Form.Control
                      name="sample_id"
                      type="text"
                      ref={register}
                      placeholder="Search Kit ID"
                    />
                    {errors.sample_id && (
                      <Form.Control.Feedback type="invalid">
                        {errors.sample_id.type === 'required'
                          ? 'This field is required'
                          : 'Invalid input'}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <SearchBtn variant="primary" type="submit" disabled={loading}>
                    {!loading ? (
                      <Icon name="search" />
                    ) : (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </SearchBtn>
                </FormRowWrapper>
              </Col>
            </Form.Row>
          </Form>
        </Col>
      </Row>
      {sampleId && (
        <Row>
          <Col xs={12}>
            <SideRow>
              Results for Kit ID:&nbsp;
              {!loading && record ? (
                <>
                  <SampleID>{record?.code}</SampleID>
                  <br />
                  {record?.user_full_name !== null ? <Name>{record?.user_full_name}</Name> : ''}
                  {/* <br />
                  <Name>{record?.user?.display_name}</Name> */}
                </>
              ) : (
                <SampleID>N/A</SampleID>
              )}
              {loading && <SampleID>...</SampleID>}
            </SideRow>
          </Col>
          {!loading ? reportLinks(productIds) : ''}
          {/* <Col xs={12}>
            <SideRow
              match={matchPath(location.pathname, {
                path: '/consult/essentials/',
                exact: true,
              })}
            >
              <Link to={`/consult/essentials/`}>Essentials Start Results</Link>
            </SideRow>
          </Col>
          <Col xs={12}>
            <SideRow
              match={matchPath(location.pathname, {
                path: '/consult/lifestyle-plus/',
                exact: true,
              })}
            >
              <Link to={`/consult/lifestyle-plus/`}>
                LifeStyle Plus Results
              </Link>
            </SideRow>
          </Col>
          <Col xs={12}>
            <SideRow
              match={matchPath(location.pathname, {
                path: '/consult/methylation/',
                exact: true,
              })}
            >
              <Link to={`/consult/methylation`}>Methylation Consultation</Link>
            </SideRow>
          </Col> */}
        </Row>
      )}
    </Wrapper>
  );
};

export default SearchForm;
