import React, { useState, useContext, useEffect } from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { Spacer } from 'components/utils';
import { Page, PageHeader, PageBody } from 'components/Page';

import SearchForm from '../components/SearchForm';
import { AuthContext } from 'stores/auth';
import { gray, primary } from 'styles/theme';

import ReportBody from '../../Reports/WeightManagementReport/components/ReportBody';
import { getWeightManagement, IWeightManagementReport } from 'api/reports';
import { Redirect } from 'react-router-dom';

const SearchWrapper = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 10px;
  h6 {
    color: #707070;
    padding: 0 15px;
  }
  p {
    padding: 0 15px;
  }
`;

const OffsetCol = styled(Col)`
  margin-top: -110px;
  z-index: 9999;
`;

const OffsetWrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 50px 30px;
  border: 1px solid #dedede;
`;

const Alert = styled.div`
  color: #e79b43;
  text-align: center;
  padding: 15px;
  text-transform: uppercase;
  background-color: #f1e5d9;
  border-radius: 5px;
`;

const Consultation = () => {
  const authStore = useContext(AuthContext);
  const [kitId, setKitId] = useState(localStorage.getItem('consultKitId'));
  const [results, setResults] = useState<IWeightManagementReport | null>(null);
  const [searchLoading, setSearchLoading] = useState(false)
  const [reportStatus, setReportStatus] = useState('loading');
  const [productIds, setProductIds] = useState(localStorage.getItem('productIds'))
  const [afterSearch, setAfterSearch] = useState(false)

  useEffect(() => {
    if(kitId){
      getWeightManagement(kitId).then((res) => {
        if(res.data.status !== undefined && res.data.status !== null){
          setReportStatus(res.data.status)
        }
        if (res.data.status === 'complete') {
          setResults(res.data);
        }
      }); 
    }
  }, []);

  if (!kitId) {
    console.log("redirecting because productIds")
    return <Redirect to={'/consult'} />;
  }

  if(!productIds){
    console.log("redirecting because productIds")
    return <Redirect to={'/consult'} />;
  }

  const userHasNotPurchasedReport = () => {
    let wmConsultId = process.env.REACT_APP_WEIGHT_MANAGEMENT_CONSULTATION_PRODUCT_ID
    if (wmConsultId === null || wmConsultId === undefined){
      return true
    }
    let productIdsSplit = productIds.split(',')
    return !productIdsSplit.includes(wmConsultId.toString())
  }

  if(userHasNotPurchasedReport()){
    return <Redirect to={`/consult`} />;
  }

  const reportResult = () => {
    if(reportStatus === 'pending'){
      return (
        <Alert>Report is pending lab results</Alert>
      )
    }

    if(reportStatus === 'complete' && results !== undefined && results != null){
      return (<ReportBody results={results} useName={false} title={false} borders={false}/>)
    }

    if(reportStatus === 'loading'){
      return <Alert>Loading results...</Alert>
    }

    if(searchLoading){
      return <Alert>Loading results...</Alert>
    }

  }

  const handleSearch = (data: any) => {
    localStorage.setItem('consultKitId', data);
    setKitId(data);
    setAfterSearch(true);
  };

  if(afterSearch){
    return <Redirect to={`/consult`} />;
  }

  return (
    <>
      <Page>
        <PageHeader>
          {!isMobile && <Spacer height={'30px'} />}
          <Container>
            <Spacer height="120px" />
            <Row>
              <Col xs={12} md={3}>
                {/* <h3 style={{ 'color': 'white' }}>{user?.display_name}</h3> */}
              </Col>
            </Row>
          </Container>
        </PageHeader>
        <PageBody>
          <Container className="practitioner">
            <Row>
              <Col xs={12} md={3}>
                <Spacer height="50px" />
                <SearchWrapper>
                  <h4>Consult Support</h4>
                  <SearchForm handleSearch={handleSearch} query={kitId} handleLoading={setSearchLoading}/>
                </SearchWrapper>
              </Col>
              <OffsetCol md={9}>
                <OffsetWrapper>
                  <Row>
                    <Col>
                      <h2>Weight Management Consult</h2>
                      <Spacer height={'20px'} />
                      {reportResult()}
                    </Col>
                  </Row>
                </OffsetWrapper>
              </OffsetCol>
            </Row>
            <Spacer height={'30px'} />
          </Container>
        </PageBody>
      </Page>
    </>
  );
};

export default Consultation;
