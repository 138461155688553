import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { Form, Alert, Button, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

import { AuthContext } from 'stores/auth';
import { Spacer } from 'components/utils';
import { primary } from '../../styles/theme';

const Wrapper = styled.div`
  color: #ffffff;

  .btn-secondary {
    border-color: #ffffff;
  }
`;

const FormTitle = styled.h3`
  display: block;
  color: #ffffff;
`;

const FormActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LoginLink = styled(Link)`
  display: inline-block;
  margin: 0 15px;
  color: white;
`;

const LoginButton = styled(Button)`
  background-color: ${primary};
  color: white;
`;

function CartFormLogin( {displayTitle=true} ) {
  const auth = getAuth();
  const authStore = useContext(AuthContext);
  const { register, handleSubmit, errors, setError, clearErrors, formState } =
    useForm();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  let history = useHistory();

  const onSubmit = async (data) => {
    setLoading(true);
    signInWithEmailAndPassword(auth, data['email'], data['password'])
      .then((userCredential) => {
        const user = userCredential.user;
        authStore.login(user);
        clearErrors(['email', 'login']);
        setLoading(false);
        history.push("/event")
      })
      .catch((error) => {
        setLoading(false);
        switch (error.status) {
          case 403:
            setError('login', {
              type: 'manual',
              message:
                'Please click on the link that has been sent to your email account to verify your email.',
            });
            break;

          default:
            setError('login', {
              type: 'manual',
              message: 'Invalid email/password',
            });
            break;
        }
      });
  };

  const handleInputChange = () => {
    if (formState.isSubmitted && formState.touched) {
      clearErrors(['email', 'login']);
    }
  };

  return (
    <Wrapper>
      {displayTitle ? <FormTitle>Login</FormTitle> : ''}

      {!!errors.login && <Alert variant="danger">{errors.login.message}</Alert>}

      <Form className="form" onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            name="email"
            type="email"
            ref={register({ required: true })}
            onChange={handleInputChange}
          />
          {errors.email && (
            <Form.Control.Feedback type="invalid">
              {errors.email.type === 'required' && 'This field is required'}
              {errors.email.type === 'manual' && errors.email.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>
            Password &nbsp;
            <small onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? 'Hide Password' : 'Show Password'}
            </small>
          </Form.Label>
          <Form.Control
            name="password"
            type={showPassword ? 'text' : 'password'}
            ref={register({ required: true })}
            onChange={handleInputChange}
          />
          {errors.password && (
            <Form.Control.Feedback type="invalid">
              {errors.password.type === 'required'
                ? 'This field is required'
                : 'Invalid input'}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Spacer height={'10px'} />

        <FormActions>
          <div>
            <LoginButton variant="secondary" type="submit" disabled={loading}>
              {!loading ? (
                'Login'
              ) : (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </LoginButton>
          </div>
        </FormActions>
      </Form>
    </Wrapper>
  );
}

export default CartFormLogin;
