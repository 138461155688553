import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Spacer } from 'components/utils';
import ReadMore from '../../ReadMore';
import ReadLess from '../../ReadLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  position: ${isMobile ? 'static' : 'absolute'};
  right: 0;
  transform: ${isMobile ? 'translateX(0)' : 'translateX(-10%)'};
  margin-top: ${isMobile ? '10px' : '-10px'};
  text-align: ${isMobile ? 'center' : 'right'};
  // display: ${isMobile ? 'flex' : 'inherit'};
  // justify-content: ${isMobile ? 'center' : 'unset'};
  img {
    max-width: 60%;
  }
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

interface ObesityRiskProps {
  results: any;
}
const ObesityRisk = ({ results }: ObesityRiskProps) => {
  const [show, setShow] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <Col md={12}>
          <h3>Obesity Risk</h3>
          <ImageCol md={4}>
            <img src="/assets/images/recommendations/Diet.png" alt="Diet" />
          </ImageCol>
          <Col md={8}>
            <Alert>
              <p>
                HCG/ Low Calorie Diet * check with Libby about what
                recommendation is here.
              </p>
            </Alert>
            <Alert>
              <p>
                Contact your Ingeneous Practitioner to purchase a personalised
                consultation.
              </p>
            </Alert>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <p>
                You have a combination of gene SNPs that is very rare and in
                less than 1% of the population which makes losing fat very
                difficult for you.
              </p>
              <p>
                You will benefit from having an Ingeneous Certified Practitioner
                guide you through what to do next, and a more personalised plan
                to help you meet your fat loss goals. Many of the traditional
                diets have been shown to be less effective for people with your
                gene combination.
              </p>
              <p>
                We might have to go one step further to get the results you
                want.
              </p>
              <p>
                Your body may be prone to compensatory behaviours that offset
                attempts at increasing exercise (energy expenditure) and
                lowering calories (energy restriction). If you decrease your
                energy by consuming fewer calories you are more likely to
                inadvertently reduce your baseline activity to compensate. If
                you increase your exercise you may also decrease your baseline
                activity or find it extremely difficult to restrict calories.
                Not powerful enough.
              </p>

              <p>
                To lose fat you need to use more energy than you take in. A
                calorie (or kilojoule) is a unit that describes how much energy
                your body gets from the food and drinks you ingest. Your basal
                metabolic rate is the number of calories your body burns to
                perform basic life sustaining functions such as breathing. You
                can increase how many calories you burn a day by increasing your
                level of activity. Restricting your calories while maintaining
                the same level of activity can help you lose weight but for you
                this may be much more difficult and less effective than it is
                for most people.
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
    </>
  );
};

export default ObesityRisk;
