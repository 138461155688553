/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { parse, format as formatDate, endOfMonth } from 'date-fns';
import { Form, Col } from 'react-bootstrap';
import styled from 'styled-components';
import _ from 'lodash';

import Select from 'react-select';

const Wrapper = styled.div`
  width: 100%;
`;

const currentYear = new Date().getFullYear();
var monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const DatePicker = ({ onChange, seterror, format, ...props }) => {
  const startYear = 1900;
  const [days, setDays] = useState(
    _.range(1, 31 + 1, 1).map((i) => ({ label: i, value: i }))
  );

  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [day, setDay] = useState();

  useEffect(() => {
    if (month && year) {
      const initDay = day ? day : 1;
      let yearValue = year?.value ? year.value : year;
      let monthValue = month?.value ? month.value : month;
      var result = endOfMonth(
        new Date(yearValue, monthValue - 1, initDay, 0, 0, 0)
      );
      const endDay = result.getDate();
      const dayOptions = _.range(1, endDay + 1, 1).map((i) => ({
        label: i,
        value: i,
      }));
      setDays(dayOptions);
    }
  }, [month, year]);

  useEffect(() => {
    if (month?.value && year?.value && day?.value) {
      let dt = new Date(year.value, month.value - 1, day.value, 0, 0, 0);
      if (format) {
        onChange(formatDate(dt, format));
      } else {
        onChange(dt);
      }
    }
  }, [month, year, day]);

  const monthOptions = monthNames.map((m, idx) => ({
    label: m,
    value: idx + 1,
  }));
  const yearOptions = _.range(startYear, currentYear + 1, 1).map((i) => ({
    label: i,
    value: i,
  }));

  useEffect(() => {
    const dt = parse(props.value, format, new Date());
    if (dt) {
      setLoading(true);
      setYear(dt.getFullYear());
      setDay(dt.getDate());
      setMonth(dt.getMonth() + 1);
      setLoading(false);
    }
  }, []);

  return (
    <Wrapper className="day-picker">
      <Form.Row>
        <Col xs={12} sm={3}>
          {!loading && days && (
            <Select
              options={days}
              onChange={(e) => setDay(e)}
              value={day}
              placeholder="Day"
            />
          )}
        </Col>
        <Col xs={12} sm={6}>
          {!loading && monthOptions && (
            <Select
              options={monthOptions}
              onChange={(e) => setMonth(e)}
              value={month}
              placeholder="Month"
            />
          )}
        </Col>
        <Col xs={12} sm={3}>
          {!loading && yearOptions && (
            <Select
              options={yearOptions}
              onChange={(e) => setYear(e)}
              value={year}
              placeholder="Year"
            />
          )}
        </Col>
      </Form.Row>
    </Wrapper>
  );
};

export default DatePicker;
