import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { gray, primary } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import View from '../View';
import Close from '../ViewLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const AlertRef = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const SubtitleGray = styled.h5`
  color: ${gray};
  span {
    color: ${primary};
  }
`;

const WrapperRef = styled.div`
  margin-top: 5px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const ListRef = styled.li`
  font-weight: 300;
  padding-left: 20px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    word-break: break-all;
  }
`;

interface NoFastProps {
  results: any;
}
const NoFast = ({ results }: NoFastProps) => {
  const [show, setShow] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/Fasting.png"
            alt="fasting"
            width={'60%'}
            className="image-print"
          />
        </ImageCol>
        <Col md={8}>
          <div style={{ display: 'flex' }}>
            <SubtitleGray>
              To lose weight<span>&nbsp;Do Not Fast</span>
            </SubtitleGray>
          </div>
          <Alert>
            <p>Eat at least every 5 hours while awake.</p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <h5>Do Not Fast</h5>
              <p>
                Fasting will not help you to optimise your weight, and would be
                counterproductive for you. This is because your genes can lead
                to inflammation if fasting.
              </p>
              <p>
                Your SNPs indicate that any type of fasting is bad for you, one
                of these genes is sometimes referred to as the HULK gene.
                Fasting with your gene combination cause your B6 and zinc to
                drop resulting in you becoming “hangry” (1,2). For you it can
                also create an inflammatory response that counteracts any of the
                possible positive effects of fasting (3). This means rather than
                helping you to reduce calories, fasting could result in weight
                gain and potential harm (4,5). You would find it especially
                challenging if you did try to fast because of cravings and
                binging which would counteract any calories lost (5,6). Most
                people naturally fast for over 8 hours a day, extending your
                natural fasting period will not help you lose weight and could
                be harmful. You should try and eat at least every 5 hours while
                you are awake.
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
      <Row className="no-print">
        <Col>
          <AlertRef>
            <span>References</span>
            {!showReferences && (
              <View value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <Close value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <WrapperRef>
                <ListGroup>
                  <ListRef>
                    Comas F, Latorre J, Ortega F, Oliveras-Cañellas N, Lluch A,
                    Ricart W, Fernández-Real JM, Moreno-Navarrete JM. Permanent
                    cystathionine-β-Synthase gene knockdown promotes
                    inflammation and oxidative stress in immortalized human
                    adipose-derived mesenchymal stem cells, enhancing their
                    adipogenic capacity. Redox biology. 2021 Jun 1;42:101668.
                    &nbsp;
                    <a href="https://www.sciencedirect.com/science/article/pii/S2213231720308739/">
                      https://www.sciencedirect.com/science/article/pii/S2213231720308739/
                    </a>
                  </ListRef>
                  <ListRef>
                    Healthline. What to know about Pyrrole Disorder. Accessed
                    Jan 2022. Retrieved from &nbsp;
                    <a href="https://www.healthline.com/health/pyrrole-disorder#about">
                      https://www.healthline.com/health/pyrrole-disorder#about
                    </a>
                  </ListRef>
                  <ListRef>
                    Fazeli PK, Zhang Y, O'Keefe J, Pesaresi T, Lun M, Lawney B,
                    Steinhauser ML. Prolonged fasting drives a program of
                    metabolic inflammation in human adipose tissue. Molecular
                    metabolism. 2020 Dec 1;42:101082. &nbsp;
                    <a href="https://www.sciencedirect.com/science/article/pii/S2212877820301563">
                      https://www.sciencedirect.com/science/article/pii/S2212877820301563{' '}
                    </a>
                  </ListRef>
                  <ListRef>
                    Bakhotmah, B.A. The puzzle of self-reported weight gain in a
                    month of fasting (Ramadan) among a cohort of Saudi families
                    in Jeddah, Western Saudi Arabia. Nutr J 10, 84 (2011).
                    &nbsp;{' '}
                    <a href="https://doi.org/10.1186/1475-2891-10-84">
                      https://doi.org/10.1186/1475-2891-10-84{' '}
                    </a>
                  </ListRef>
                  <ListRef>
                    Ozdemir AC, Wynn GM, Vester A, Weitzmann MN, Neigh GN,
                    Srinivasan S, Rudd MK. GNB3 overexpression causes obesity
                    and metabolic syndrome. PloS one. 2017 Dec
                    5;12(12):e0188763. &nbsp;
                    <a href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0188763">
                      https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0188763
                    </a>
                  </ListRef>
                  <ListRef>
                    Harvard Health. Not so fast: pros and cons of the newest
                    diet trend. Accessed Jan 2022. Retrieved from: &nbsp;
                    <a href="https://www.health.harvard.edu/heart-health/not-so-fast-pros-and-cons-of-the-newest-diet-trend">
                      https://www.health.harvard.edu/heart-health/not-so-fast-pros-and-cons-of-the-newest-diet-trend{' '}
                    </a>
                  </ListRef>
                </ListGroup>
              </WrapperRef>
            )}
          </AlertRef>
        </Col>
      </Row>
    </>
  );
};

export default NoFast;
