import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Spacer } from 'components/utils';
import ReadMore from '../../ReadMore';
import ReadLess from '../../ReadLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  position: ${isMobile ? 'static' : 'absolute'};
  right: 0;
  transform: ${isMobile ? 'translateX(0)' : 'translateX(-10%)'};
  margin-top: ${isMobile ? '10px' : '-10px'};
  text-align: ${isMobile ? 'center' : 'right'};
  // display: ${isMobile ? 'flex' : 'inherit'};
  // justify-content: ${isMobile ? 'center' : 'unset'};
  img {
    max-width: 60%;
  }
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

interface IrrelevantProps {
  results: any;
}
const Irrelevant = ({ results }: IrrelevantProps) => {
  const [show, setShow] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row className='page-br'>
        <Col md={12}>
          <h3>Irrelevant</h3>
          <ImageCol md={4}>
            <img
              src="/assets/images/recommendations/Exercise.png"
              alt="Exercise"
            />
          </ImageCol>
          <Col md={8}>
            <Alert>
              <p>Focus on your diet for weight loss.</p>
            </Alert>
            <Alert>
              <p>Limit intense exercise but remain active by walking.</p>
            </Alert>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div style={{ marginTop: 30 }}>
              <p>
                Exercise is an insignificant part of your weight loss equation.
              </p>
              <p>
                While exercise is still important as is beneficial for other
                areas of your health, increases your muscle tone, and improves
                your mental state; it is unlikely to help you specifically lose
                weight. You are predisposed to adopt compensatory behaviors that
                offset energy expenditure due to exercise such as reducing your
                baseline activity level or increasing your caloric energy
                intake. This means the best way for you to reach an ideal weight
                is through your diet.
              </p>
              <p>
                You can still remain active by walking or resistance training to
                build muscle but try to minimize any intense exercise while you
                are hoping to lose weight to prevent inflammation and
                overeating.
              </p>
              <p>
                Aerobic exercise can come from doing activities such as;
                running, swimming, walking, dancing, biking, or tramping.
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
    </>
  );
};

export default Irrelevant;
