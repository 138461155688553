/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Alert, Button, Spinner } from 'react-bootstrap';
import styled from 'styled-components';

import { getEvents, deleteEvent, eventFeaturedToggle } from 'api/admin';
import { Spacer } from 'components/utils';
import Event from './Event';

const Wrapper = styled.div`
  background-color: white;
  margin: 20px 0;
`;

const EventsList = ({ status }) => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);

  const fetchData = () => {
    setLoading(true);
    getEvents(page, status)
      .then((res) => {
        setLoading(false);
        setEvents(res.data.events);
        setPage(res.data.page);
        setHasNext(res.data.has_next);
        setHasPrevious(res.data.has_previous);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [page]);

  const handleDelete = (eventCode) => {
    deleteEvent(eventCode)
      .then((res) => {
        fetchData();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleMakeFeatured = (pk) => {
    eventFeaturedToggle(pk)
      .then((res) => {
        fetchData();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  return (
    <>
      {loading && (
        <>
          <Spacer height={'20px'} />
          <Alert variant="primary">Loading events...</Alert>
        </>
      )}
      <Wrapper>
        <Table bordered hover>
          <thead>
            <tr>
              <th>Event</th>
              <th>Featured</th>
              <th>Users</th>
              <th>Date & Time</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event) => {
              return (
                <Event
                  key={event.pk}
                  event={event}
                  status={status}
                  handleDelete={handleDelete}
                  handleMakeFeatured={handleMakeFeatured}
                />
              );
            })}
            {events.length === 0 && (
              <tr>
                <td colSpan={5}>No event available.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Wrapper>
      <Row>
        <Col>
          <Button
            variant="primary"
            type="button"
            disabled={loading || !hasPrevious}
            onClick={() => setPage(parseInt(page) - 1)}
          >
            {!loading ? (
              'Previous'
            ) : (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Col>
        <Col xs="auto">
          <Button
            variant="primary"
            type="button"
            disabled={loading || !hasNext}
            onClick={() => setPage(parseInt(page) + 1)}
          >
            {!loading ? (
              'Next'
            ) : (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default EventsList;
