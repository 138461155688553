import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { gray, primary } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import View from '../View';
import Close from '../ViewLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const AlertRef = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
  margin-bottom: 30px;
`;

const SubtitleGray = styled.h5`
  color: ${gray};
  margin-top: 10px;
`;

const WrapperRef = styled.div`
  margin-top: 5px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const ListRef = styled.li`
  font-weight: 300;
  padding-left: 20px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    word-break: break-all;
  }
`;

interface AcetylProps {
  results: any;
}
const Acetyl = ({ results }: AcetylProps) => {
  const [show, setShow] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/Supplement.png"
            alt="supplement"
            width={'60%'}
          />
        </ImageCol>
        <Col md={8}>
          <SubtitleGray>
            Your most powerful supplement for weight loss is
          </SubtitleGray>
          <Subtitle>Acetyl-L-Carnitine</Subtitle>
          <Alert>
            <p>
              Suggested Supplement: Carnityl Acetyl-L-Carnitine, Thorne Research
              (1 capsule in morning daily)
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <p>
                Taking Acetyl-L-Carnitine will help your body convert fat into
                energy and could assist you in reaching your optimal weight.
              </p>
              <p>
                Acetyl-L-Carnitine is an amino acid derivative that helps to
                transport fatty acids into your cells' mitochondria where energy
                is produced (1). It is used to positively impact brain function
                in areas such as improving memory and treating symptoms of
                depression (2,3). An additional benefit for you is it has been
                shown to reduce mental and physical tiredness especially after
                exercise (3,4). This supplement will help your body where it
                needs the most support.
              </p>
              <p style={{ fontWeight: 'bold' }}>
                This supplement is best for your genes. If you have any concerns
                this may not suit your current state of health or medications
                check with your doctor.
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
      <Row className="no-print">
        <Col>
          <AlertRef>
            <span>References</span>
            {!showReferences && (
              <View value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <Close value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <WrapperRef>
                <ListGroup>
                  <ListRef>
                    <a href="https://nz.iherb.com/pr/now-foods-acetyl-l-carnitine-500-mg-100-veg-capsules/316">
                      https://nz.iherb.com/pr/now-foods-acetyl-l-carnitine-500-mg-100-veg-capsules/316{' '}
                    </a>
                  </ListRef>
                  <ListRef>
                    Nasca C, Bigio B, Lee FS, Young SP, Kautz MM, Albright A,
                    Beasley J, Millington DS, Mathé AA, Kocsis JH, Murrough JW.
                    Acetyl-l-carnitine deficiency in patients with major
                    depressive disorder. Proceedings of the National Academy of
                    Sciences. 2018 Aug 21;115(34):8627-32.
                  </ListRef>
                  <ListRef>
                    <a href="https://www.webmd.com/vitamins/ai/ingredientmono-834/acetyl-l-carnitine">
                      https://www.webmd.com/vitamins/ai/ingredientmono-834/acetyl-l-carnitine
                    </a>
                  </ListRef>
                  <ListRef>
                    <a href="https://www.healthline.com/nutrition/l-carnitine">
                      https://www.healthline.com/nutrition/l-carnitine{' '}
                    </a>
                  </ListRef>
                </ListGroup>
              </WrapperRef>
            )}
          </AlertRef>
        </Col>
      </Row>
    </>
  );
};

export default Acetyl;
