import React from 'react';
import { Row } from 'react-bootstrap';

import { Spacer } from 'components/utils';
import { BodyCol } from './utils';

const Inflammation = () => {
  return (
    <>
      <Row>
        <BodyCol xs={12}>
          <h4>The Western Diet and Inflammation</h4>
          <p>
            Today’s typical western diet is loaded with sugar, processed
            carbohydrates, meat and, omega 6 vegetable oils (safflower,
            sunflower, corn) trans fats and saturated fats.
            <br />
            <br />
            Most people eating a western diet don’t get enough good fats,
            vegetables, fruit or fibre - this not only causes inflammation, it
            can deny the body of vital nutrients or prevent us from being able
            to absorb them.
          </p>
          <Spacer height={'20px'} />
          <strong>Reducing Inflammation</strong>
          <p>
            Your body will always be on the back foot while you’re in an
            inflammatory state. Let’s look at ways to help you continue to
            reduce inflammation and create a state within your body that will be
            receptive to the positive adjustments you make to your nutrition and
            lifestyle.
            <br />
            <br />
            Taking into account your genetic relationship with fat, starch and
            protein and your unique ability to use vitamin and mineral’s from
            your food, we are able to use your genetics to ask your body what
            style of eating it wants you to follow. Eat in the style listed
            below to maintain those really great gains that you made reducing
            inflammation under the essential start re-set. Go you!
            <br />
            <br />
            To follow is our recommended long-term way of eating that is most
            beneficial to you based on your genotype.
          </p>
        </BodyCol>
      </Row>
    </>
  );
};

export default Inflammation;
