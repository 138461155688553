import { getTrainingParticipants } from 'api/admin';
import { Product } from 'api/core';
import { Participant } from 'api/models/participant.interface';
import { getPractionerProducts } from 'api/practitioner';
import React, { useEffect, useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import CsvDownload from 'react-json-to-csv';

type ProductWithParticipants = Product & {
  participants?: Participant[];
};

const Trainings = () => {
  const [trainings, setTrainings] = useState<ProductWithParticipants[]>([]);
  const [selectedTraining, setSelectedTraining] =
    useState<ProductWithParticipants | null>();
  const [loading, setLoading] = useState(true);

  const fetchTraings = async () => {
    try {
      const getPractionerProductsResponse = await getPractionerProducts();

      if (getPractionerProductsResponse.status === 200) {
        const getTrainingParticipantsResponse = await getTrainingParticipants();
        console.log(trainings);

        if (getTrainingParticipantsResponse.status === 200) {
          setTrainings(
            getPractionerProductsResponse.data.products.map((training) => {
              console.log(training.id);
              return {
                ...training,
                participants:
                  getTrainingParticipantsResponse.data.participants.filter(
                    (participant) =>
                      parseInt(participant.training_id ?? '') === training.id
                  ),
              };
            })
          );
        }
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchTraings();
  }, []);
  console.log(trainings);

  return (
    <Container>
      <h3>Active trainings</h3>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <Table bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Date</th>
                <th>Status</th>
                <th>View</th>
                <th>Export</th>
              </tr>
            </thead>
            <tbody>
              {trainings.map((product, index) => (
                <tr key={index}>
                  <td>{product.title.split(' - ')[0]}</td>
                  <td>{product.body_html}</td>
                  <td>{product.title.split(' - ')[1]}</td>
                  <td>{product.status}</td>
                  <td>
                    <button
                      type="button"
                      onClick={() => setSelectedTraining(product)}
                    >
                      View
                    </button>
                  </td>
                  <td>
                    <CsvDownload data={product.participants}>
                      Export CSV
                    </CsvDownload>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {selectedTraining && (
            <>
              <h3>Selected training: {selectedTraining.title}</h3>
              <h5>Attendants:</h5>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>First name</th>
                    <th>Last name</th>
                    <th>Store</th>
                    <th>Order</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedTraining.participants?.map((participant, index) => (
                    <tr key={index}>
                      <td>{participant.email_address}</td>
                      <td>{participant.first_name}</td>
                      <td>{participant.last_name}</td>
                      <td>{participant.store_trading_name}</td>
                      <td>{participant.order}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default Trainings;
