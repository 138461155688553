import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { gray, primary } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import View from '../View';
import Close from '../ViewLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const AlertRef = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const SubtitleGray = styled.h5`
  color: ${gray};
  span {
    color: ${primary};
  }
`;

const WrapperRef = styled.div`
  margin-top: 5px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const ListRef = styled.li`
  font-weight: 300;
  padding-left: 20px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    word-break: break-all;
  }
`;

interface OkayFastProps {
  results: any;
}
const OkayFast = ({ results }: OkayFastProps) => {
  const [show, setShow] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/Fasting.png"
            alt="fasting"
            width={'60%'}
            className="image-print"
          />
        </ImageCol>
        <Col md={8}>
          <div style={{ display: 'flex' }}>
            <SubtitleGray>
              To lose weight<span>&nbsp;Fasting is Okay</span>
            </SubtitleGray>
          </div>
          <Alert>
            <p>
              Fasting is not harmful for you: if you already practice it or
              would like to try it, it is safe for you to do so.
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <h5>Fasting is Okay</h5>
              <p>
                Fasting will not be harmful to your weight optimization. If you
                currently practice fasting you can continue to do this.
              </p>
              <p>
                Fasting is the practice of going without calories for a period
                of time. This fasting period can range from several hours to
                several days. Some people can become more inflamed when fasting,
                making it counterproductive while trying to lose weight, but
                lucky for you this is not the case (1). Fasting can help you
                reduce your caloric intake, and if your calories burnt (energy
                used) remains the same this can result in weight loss (2,3). If
                it is already something you practice or would like to try you
                are safe to do so. Any type of fasting has the possibility of
                helping you reduce your caloric intake, if you do not overeat
                when you break your fast.
              </p>
              <p>
                Most people fast for at least 8 hours a day due to sleep
                already, and others fast longer by skipping breakfast or dinner.
                Some people choose to fast on certain days of the week, but it
                is important to continue drinking water during periods of
                fasting.
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
      <Row>
        <Col>
          <AlertRef>
            <span>References</span>
            {!showReferences && (
              <View value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <Close value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <WrapperRef>
                <ListGroup>
                  <ListRef>
                    Fazeli PK, Zhang Y, O'Keefe J, Pesaresi T, Lun M, Lawney B,
                    Steinhauser ML. Prolonged fasting drives a program of
                    metabolic inflammation in human adipose tissue. Molecular
                    metabolism. 2020 Dec 1;42:101082. &nbsp;
                    <a href="https://www.sciencedirect.com/science/article/pii/S2212877820301563">
                      https://www.sciencedirect.com/science/article/pii/S2212877820301563
                    </a>
                  </ListRef>
                  <ListRef>
                    Harris, Leanne; Hamilton, Sharon; Azevedo, Liane B; Olajide,
                    Joan; De Brún, Caroline; Waller, Gillian; Whittaker, Vicki;
                    Sharp, Tracey; Lean, Mike; Hankey, Catherine; Ells, Louisa,
                    Intermittent fasting interventions for treatment of
                    overweight and obesity in adults: a systematic review and
                    meta-analysis, JBI Database of Systematic Reviews and
                    Implementation Reports: February 2018 - Volume 16 - Issue 2
                    - p 507-547 doi: 10.11124/JBISRIR-2016-003248 &nbsp;
                  </ListRef>
                  <ListRef>
                    Varady KA. Intermittent versus daily calorie restriction:
                    which diet regimen is more effective for weight loss?.
                    Obesity reviews. 2011 Jul;12(7):e593-601.&nbsp;
                    <a
                      href="https://onlinelibrary.wiley.com/doi/pdf/10.1111/j.1467-789X.2011.00873.x?__cf_chl_jschl_tk__=pmd_IiUUjy2a8W3p02Egd
                      xTku_bG4X1TwUmj89xa8mnam4U-1635287251-0-gqNtZGzNAjujcnBszQwR"
                    >
                      https://onlinelibrary.wiley.com/doi/pdf/10.1111/j.1467-789X.2011.00873.x?__cf_chl_jschl_tk__=pmd_IiUUjy2a8W3p02Egd
                      xTku_bG4X1TwUmj89xa8mnam4U-1635287251-0-gqNtZGzNAjujcnBszQwR
                    </a>
                  </ListRef>
                  <ListRef>
                    Cho Y, Hong N, Kim KW, Lee M, Lee YH, Lee YH, Kang ES, Cha
                    BS, Lee BW. The effectiveness of intermittent fasting to
                    reduce body mass index and glucose metabolism: a systematic
                    review and meta-analysis. Journal of clinical medicine. 2019
                    Oct;8(10):1645. &nbsp;{' '}
                    <a href="https://www.mdpi.com/2077-0383/8/10/1645">
                      https://www.mdpi.com/2077-0383/8/10/1645{' '}
                    </a>
                  </ListRef>
                  <ListRef>
                    Healthline. 10 Intermittent Fasting Benefits. Accessed Jan
                    2022. Retrieved from: &nbsp;
                    <a href="https://www.healthline.com/nutrition/10-health-benefits-of-intermittent-fasting">
                      https://www.healthline.com/nutrition/10-health-benefits-of-intermittent-fasting
                    </a>
                  </ListRef>
                </ListGroup>
              </WrapperRef>
            )}
          </AlertRef>
        </Col>
      </Row>
    </>
  );
};

export default OkayFast;
