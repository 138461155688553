import React, { useState } from 'react';
import styled from 'styled-components';

import InflammationHigh from './InflammationHigh';
import ReadMore from '../View';
import ReadLess from '../ViewLess';
import InflammationLow from './InflammationLow';
import InflammationMedium from './InflammationMedium';
import { IWeightManagementReport } from 'api/reports';

interface InflammationsProps {
  results: IWeightManagementReport;
}
const Inflammations = ({ results }: InflammationsProps) => {
  const [showReferences, setShowReferences] = useState(false);

  const Alert = styled.div`
    background-color: #f2f2f2;
    padding: 10px;
    margin: 15px 0;
    margin-bottom: 35px;
    position: relative;
    span {
      padding-left: 15px;
      margin-bottom: 0;
      font-weight: bold;
    }
  `;

  const WrapperRef = styled.div`
    margin-top: 20px;
  `;

  const ListGroup = styled.ol`
    font-weight: bold;
  `;

  const List = styled.li`
    padding-left: 15px;
    font-weight: 300;

    a {
      cursor: pointer;
      text-decoration: underline !important;
      color: #6da6ca !important;
    }
  `;


  return (
    <>
      {results.inflammation && (
        <div className="inflammation">
          <h3>Inflammation</h3>
          {results.inflammation < 1 / 3 ? (
            <InflammationLow results={results} />
          ) : results.inflammation < 2 / 3 ? (
            <InflammationMedium results={results} />
          ) : (
            <InflammationHigh results={results} />
          )}
          <Alert className='no-print'>
            <span>References</span>
            {!showReferences && (
              <ReadMore value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <ReadLess value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <WrapperRef>
                <ListGroup>
                  <List>
                    Myers MG Jr, Leibel RL, Seeley RJ, Schwartz MW. Obesity and
                    leptin resistance: distinguishing cause from effect. Trends
                    Endocrinol Metab. 2010;21(11):643-651.
                    doi:10.1016/j.tem.2010.08.002
                  </List>
                  <List>
                    Hardy OT, Czech MP, Corvera S. What causes the insulin
                    resistance underlying obesity?. Curr Opin Endocrinol
                    Diabetes Obes. 2012;19(2):81-87.
                    doi:10.1097/MED.0b013e3283514e13
                  </List>
                  <List>
                    Stienstra R, Duval C, Müller M, Kersten S. PPARs, obesity,
                    and inflammation. PPAR research. 2007 Oct;2007.
                  </List>
                  <List>
                    Strohacker K, McFarlin BK. Influence of obesity, physical
                    inactivity, and weight cycling on chronic inflammation.
                    Frontiers in Bioscience (Elite Edition). 2010 Jan;2:98-104.
                    DOI: 10.2741/e70. PMID: 20036858.
                  </List>
                  <List>
                    Spyridaki EC, Avgoustinaki PD, Margioris AN. Obesity,
                    inflammation and cognition. Current Opinion in Behavioral
                    Sciences. 2016 Jun 1;9:169-75.
                  </List>
                  <List>
                    Tuomisto K, Jousilahti P, Havulinna AS, Borodulin K,
                    Männistö S, Salomaa V. Role of inflammation markers in the
                    prediction of weight gain and development of obesity in
                    adults–A prospective study. Metabolism open. 2019 Sep
                    1;3:100016.
                  </List>
                  <List>
                    Joshua P. Thaler, Michael W. Schwartz, Minireview:
                    Inflammation and Obesity Pathogenesis: The Hypothalamus
                    Heats Up, Endocrinology, Volume 151, Issue 9, 1 September
                    2010, Pages 4109–4115,{' '}
                    <a href="https://doi.org/10.1210/en.2010-0336">
                      https://doi.org/10.1210/en.2010-0336
                    </a>
                  </List>
                </ListGroup>
              </WrapperRef>
            )}
          </Alert>
          <hr className='no-print' />
        </div>
      )}
    </>
  );
};

export default Inflammations;
