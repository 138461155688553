import React from 'react'

const Fibre = () => {
  return (<div>
    <h5>Fibre - 17G</h5>
    <p>
      Please note that your genetic SNP testing with Ingeneous is NOT able to tell you if you have a bacterial imbalance in your gut bacteria or oral biofilm that is fibre sensitive. Any positive improvement that you make to your bacterial environment can result in bad bacterial die off, with symptoms such as diarrhoea, constipation, fever or headache. This is a normal and healthy cleansing of the endotoxins from the dead (bad) bacteria and should pass after a few days. If these symptoms persist longer than 7 days, we recommend you call your primary health provider. If you have an Ingeneous membership you can also contact our practitioner line. Our Ingeneous practitioner may recommend an efficient bacterial rebalance that involves one product and lasts about 14 days.
    </p>
    <p>
      Increasing your fibre intake to 17 grams a day is particularly powerful to improve your anti-inflammatory function, along with benefiting your pro-inflammatory, detoxification and vitamin D systems.
    </p>
    <p>
      Some foods contain a lot more fibre than you would expect, here are some heavy hitting options to help you increase the amount in your diet:
    </p>
    <ul>
      <li>Raspberries, 1 cup = 8g</li>
      <li>Chickpeas (garbanzo beans) tinned ½ cup = 6.23g</li>
      <li>Oats, rolled, ½ cup (before cooking) = 4.15g</li>
      <li>Avocado, ⅓ = 3.5g</li>
      <li>Banana, medium sized = 2.8g</li>
      <li>Mixed nuts, ¼ cup= 2.25g</li>
      <li>Kumara, unpeeled and baked ½ cup = 3g</li>
      <li>Broccoli, chopped and cooked ½ cup = 2.57g</li>
    </ul>

  </div>)
}

export default Fibre
