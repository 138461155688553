import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { gray, primary } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import View from '../View';
import Close from '../ViewLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const AlertRef = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
`;

const SubtitleGray = styled.h5`
  color: ${gray};
`;

const WrapperRef = styled.div`
  margin-top: 5px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const ListRef = styled.li`
  font-weight: 300;
  padding-left: 20px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    word-break: break-all;
  }
`;

const WrapperTitle = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
`;

interface LowCarbProps {
  results: any;
}
const LowCarb = ({ results }: LowCarbProps) => {
  const [show, setShow] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/WeightReport-lowGI.png"
            alt="food"
            width={'60%'}
          />
        </ImageCol>
        <Col md={8}>
          <WrapperTitle>
            {isMobile ? (
              <>
                <SubtitleGray>Your superfood is</SubtitleGray>
                <Subtitle>Low GI carbs</Subtitle>
              </>
            ) : (
              <>
                <SubtitleGray>Your superfood is</SubtitleGray>
                &nbsp;
                <Subtitle>Low GI carbs</Subtitle>
              </>
            )}
          </WrapperTitle>
          <Alert>
            <p>Make sure the majority of your carbohydrates are low GI.</p>
          </Alert>
          <Alert>
            <p>
              Eat in the style prescribed in your Lifestyle Plus as much as
              possible.
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div style={{ marginBottom: 20 }}>
              <p>
                Low glycaemic index carbohydrates are your superfood when it
                comes to reaching your ideal weight! These foods encourage a
                slower release of insulin helping you feel full for longer.
              </p>
              <p>
                Not all carbohydrates are created equal and they can affect your
                blood glucose levels in different ways. Your blood glucose
                levels indicate if sugar should be used or stored. When blood
                glucose is high insulin signals for sugar to be stored, when
                blood glucose levels are low, glucagon signals for stored
                glucose to be released (1). The Glycemic Index (GI) measures how
                the food impacts blood glucose levels. Low GI foods are digested
                slowly and produce a gradual rise in blood glucose and insulin
                levels helping you feel full for longer (2,3). This avoids rapid
                spikes and drops in insulin that can result from consuming high
                GI foods.
              </p>
              <p>
                Some good examples of low GI carbs are: whole grain bread,
                chickpeas, lentils, milk, yoghurt, apples, pears, grapes, kiwis,
                pasta, and oats. For more information about GI scores of
                different carbohydrates you can look at the brochure under
                resources at this website:
              </p>
              <a
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: '#6da6ca',
                }}
                href="https://www.healthnavigator.org.nz/healthy-living/g/glycaemic-index-gi/"
              >
                https://www.healthnavigator.org.nz/healthy-living/g/glycaemic-index-gi/
              </a>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
      <Row className="no-print">
        <Col>
          <AlertRef>
            <span>References</span>
            {!showReferences && (
              <View value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <Close value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <WrapperRef>
                <ListGroup>
                  <ListRef>
                    <a href="https://www.healthnavigator.org.nz/healthy-living/g/glycaemic-index-gi/">
                      https://www.healthnavigator.org.nz/healthy-living/g/glycaemic-index-gi/
                    </a>
                  </ListRef>
                  <ListRef>
                    <a href="https://nutritionfoundation.org.nz/nutrition-facts/nutrition-a-z/gi-and-gl">
                      https://nutritionfoundation.org.nz/nutrition-facts/nutrition-a-z/gi-and-gl{' '}
                    </a>
                  </ListRef>
                  <ListRef>
                    Vranceanu M, Pickering C, Filip L, Pralea IE, Sundaram S,
                    Al-Saleh A, Popa DS, Grimaldi KA. A comparison of a
                    ketogenic diet with a LowGI/nutrigenetic diet over 6 months
                    for weight loss and 18-month follow-up. BMC nutrition. 2020
                    Dec;6(1):1-2. &nbsp;
                    <a href="https://bmcnutr.biomedcentral.com/articles/10.1186/s40795-020-00370-7">
                      https://bmcnutr.biomedcentral.com/articles/10.1186/s40795-020-00370-7{' '}
                    </a>
                  </ListRef>
                </ListGroup>
              </WrapperRef>
            )}
          </AlertRef>
        </Col>
      </Row>
    </>
  );
};

export default LowCarb;
