import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { gray, primary } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import View from '../View';
import Close from '../ViewLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'}
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const AlertRef = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
`;

const SubtitleGray = styled.h5`
  color: ${gray};
`;

const WrapperRef = styled.div`
  margin-top: 5px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const ListRef = styled.li`
  font-weight: 300;
  padding-left: 20px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    word-break: break-all;
  }
`;

const WrapperTitle = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
`;

interface RedWineProps {
  results: any;
}
const RedWine = ({ results }: RedWineProps) => {
  const [show, setShow] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/WeightReport-wine.png"
            alt="food"
            width={'60%'}
          />
        </ImageCol>
        <Col md={8}>
          <WrapperTitle>
            {isMobile ? (
              <>
                <SubtitleGray>Your superfood is</SubtitleGray>
                <Subtitle>Red Wine</Subtitle>
              </>
            ) : (
              <>
                <SubtitleGray>Your superfood is</SubtitleGray>
                &nbsp;
                <Subtitle>Red Wine</Subtitle>
              </>
            )}
          </WrapperTitle>
          <Alert>
            <p>Drink up to two glasses of red wine daily!</p>
          </Alert>
          <Alert>
            <p>
              If you can not or should not drink alcohol for any reason, please
              substitute this recommendation with resveratrol.
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <p>
                Red wine will be particularly powerful for helping you lose
                weight. It has antioxidant properties and can help boost
                digestion.
              </p>
              <p>
                Red wine contains polyphenols that are found in many plant based
                foods and regular consumption is linked to boosted digestion,
                brain health, and protection against several inflammatory
                diseases (1). Red wine contains many other beneficial molecules
                that will be particularly powerful for your genes. Studies have
                shown red wine has the power to also positively influence fat
                metabolism, and prevent weight gain (2,3). Cheers to your
                health!
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
      <Row className="no-print">
        <Col>
          <AlertRef>
            <span>References</span>
            {!showReferences && (
              <View value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <Close value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <WrapperRef>
                <ListGroup>
                  <ListRef>
                    Cirillo G, Curcio M, Vittorio O, Iemma F, Restuccia D,
                    Spizzirri UG, Puoci F, Picci N. Polyphenol conjugates and
                    human health: a perspective review. Critical Reviews in Food
                    Science and Nutrition. 2016 Jan 25;56(2):326-37. &nbsp;
                    <a href="https://www.tandfonline.com/doi/abs/10.1080/10408398.2012.752342">
                      https://www.tandfonline.com/doi/abs/10.1080/10408398.2012.752342
                    </a>
                  </ListRef>
                  <ListRef>
                    Bargalló MV, Grau AA, de Dios Fernández-Larrea J, Anguiano
                    GP, Segarra MC, Rovira MJ, Ferré LA, Olivé MB. Moderate
                    red-wine consumption partially prevents body weight gain in
                    rats fed a hyperlipidic diet. The Journal of nutritional
                    biochemistry. 2006 Feb 1;17(2):139-42.
                  </ListRef>
                  <ListRef>
                    Agouni A, Lagrue-Lak-Hal AH, Mostefai HA, Tesse A, Mulder P,
                    Rouet P, Desmoulin F, Heymes C, Martínez MC,
                    Andriantsitohaina R. Red wine polyphenols prevent metabolic
                    and cardiovascular alterations associated with obesity in
                    Zucker fatty rats (Fa/Fa). PloS one. 2009 May 18;4(5):e5557.
                  </ListRef>
                </ListGroup>
              </WrapperRef>
            )}
          </AlertRef>
        </Col>
      </Row>
    </>
  );
};

export default RedWine;
