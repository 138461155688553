import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Spacer } from 'components/utils';
import { primary } from 'styles/theme';

const StyledRow = styled(Row)`
  background-color: ${primary};
`;

const LogoWrapper = styled.div`
  padding: 30px 15%;
`;

const Wrapper = styled.div`
  padding: 30px 15%;
  h2,
  h4,
  h5,
  p,
  label,
  ul {
    color: #ffffff;
  }

  p {
    text-align: justify;
    text-justify: inter-word;
  }

  ul {
    list-style: none;

    li {
      margin-bottom: 5px;
    }
  }
`;

const Logo = styled.img`
  max-height: 50px;
`;

function Consent() {
  return (
    <StyledRow>
      <Col md={{ span: 8, offset: 2 }}>
        <LogoWrapper>
          <Link to="/">
            <Logo
              src={
                process.env.PUBLIC_URL + '/assets/images/ingeneous_white_r.png'
              }
            />
          </Link>
        </LogoWrapper>
        <Wrapper>
          <h2>INFORMED CONSENT DOCUMENT </h2>

          <Spacer height={'20px'} />
          <h5>1. INTRODUCTION</h5>
          <p>
            1.1. This Informed Consent Document reviews the benefits, risks and
            limitations of using the Services provided by Ingeneous. It also
            explains how your information and sample will be used in connection
            with the Service.
            <br />
            <br />
            1.2. By proceeding with the registration, you confirm and
            acknowledge that you have read, understood, and agree to this
            Informed Consent Document and that you wish to go ahead even though
            you are not required to use the Service. Before proceeding with the
            Service and agreeing to this Informed Consent Document, should you
            have any concerns or queries you should speak with a healthcare
            provider about the Service.
            <br />
            <br />
            1.3. Any capitalised words used in this Informed Consent Document
            are defined in the Terms of Service (“Terms”).
          </p>

          <Spacer height={'20px'} />
          <h5>2. THE Ingeneous SERVICES</h5>
          <p>
            2.1. Ingeneous provides a personalised genetic platform, helping to
            empower people to activate and improve their health.
            <br />
            <br />
            2.2. In a health landscape that has become even more uncertain and
            filled with products offering to improve our health or prevent an
            illness, one of the best ways of really knowing our health potential
            lies with our genes. Ingeneous uses panels of specialized sequence
            testing to provide insights to its clients to help them understand
            where improvements in their health can be made.
            <br />
            <br />
            2.3. Ingeneous uses a set open array gene test panel of 128 genes, a
            specialised IT system and artificial intelligence that analyses a
            client’s unique genes to enable them to obtain personalised health
            guidance and recommendations, should they wish to do so. This
            specialised genetic testing can be used in adopting a holistic
            approach to health, to show which genes might potentially be
            optimised for greater wellbeing. Our personalised genetic reports
            will help guide our clients to make decisions, with appropriate
            medical and genetic advice how a mix of lifestyle change, dietary
            adjustments, exercise and supplementation may provide better
            outcomes specific to their own particular circumstances.
            <br />
            <br />
            2.4. Ingeneous’ mission is to help empower humanity to achieve
            wellness by harnessing genetics. A wrap-around system provides
            quality actionable information. An in-app feature allows certified
            practitioners, referred to as Ingeneous Affiliates, to be consulted
            and progress tracking helps ensure clients can access the wellness
            and wellbeing services they need.
            <br />
            <br />
            2.5. Ingeneous offers as part of its services affordable testing,
            individualised digital genetic reports with relevant action points
            and explanations, access for an additional fee to specialized
            service (through its Affiliates) consultant support, the provision
            of progress tracking and performance support alerts and easy to use
            links to supplement purchases.
            <br />
            <br />
            2.6. The Terms of Service set out below record the legal contract
            between you and Ingeneous and affect your rights and obligations, so
            it is important to read these terms carefully and if necessary get
            legal advice before going any further.
          </p>

          <Spacer height={'20px'} />
          <h5>3. WHAT WE COLLECT AND HOW IT IS USED</h5>
          <p>
            3.1. Testing, Results and any advice related to it is a complex and
            rapidly evolving area, and the information below is provided to
            assist you in making an informed decision about whether to proceed
            with the Services and if so what services to purchase.
            <br />
            <br />
            3.2. If you request to use the Services, you will be required to
            provide Information and a Test, either by a Sample Collection Kit or
            a Third Party Genetic Profile. You must provide accurate and correct
            Information at all times.
            <br />
            <br />
            3.3. Your sample and Information will be transferred to our
            laboratory in accordance with our Privacy Policy. DNA will be
            extracted from the submitted sample and analysed. Genetic data
            includes information about genes and regions relevant to the ordered
            Service. All genetic data may be used for regulatory compliance, and
            de-identified for internal quality control and validation studies.
            Reporting on genes and regions beyond those relevant to the ordered
            Service you requested will not occur unless you purchase and consent
            to additional and/or future products or services.
            <br />
            <br />
            3.4. Once our Affiliate laboratory has processed your Test, you
            request that a report of your Results be made available to you for
            review. Ingeneous recommends that you consult with an Ingeneous
            Certified Practitioner, or a Medical Practitioner of your choosing,
            about your Results and any further actions.
          </p>

          <Spacer height={'20px'} />
          <h5>4. BENEFITS OF THE SERVICES</h5>
          <p>
            4.1. The Services have a number of benefits, although each Service
            will provide you with different benefits and not all Services will
            necessarily provide you with the same benefits or Results.
          </p>

          <Spacer height={'20px'} />
          <h5>5. RISKS OF THE SERVICES</h5>
          <p>
            5.1. The Services include Tests that may reveal sensitive
            information or insights about your health or your personal traits.
            Ingeneous recommends that you consult with an Ingeneous Certified
            Practitioner or a Medical Practitioner of your choosing, about the
            Services and the Results.
          </p>

          <Spacer height={'20px'} />
          <h5>6. LIMITATIONS OF THE SERVICES</h5>
          <p>
            6.1. Depending on the Service you select, such Service is intended
            to detect SNPs within its selected range of genes, which are chosen
            by Ingeneous at its sole discretion, known to be associated with an
            increased risk for certain personal traits, and/or associated with
            nutrient needs and exercise responses. However, while Ingeneous will
            at all times use its best endeavours, it does not Test for disease
            risk and may not Test detect every SNP associated with exercise
            responses, nutrient needs or sensitivities.
            <br />
            <br />
            6.2. Analysis of Results is based on currently available information
            in the medical literature, laboratory informatics and algorithms
            which are constantly evolving and changing. New information may
            replace or add to the information that was used to analyse your
            Results. You understand and agree that Ingeneous may, at its sole
            discretion, amend or modify your report, which may result in changes
            to the Results you originally received. You waive any and all claims
            against us for any amendment or modification of the Services and
            corresponding services in accordance with our standard operating
            procedures.
            <br />
            <br />
            6.3. Our reports reflect the analysis of an extracted DNA sample. In
            very rare cases (which may include circulating hematologic neoplasm,
            allogeneic bone marrow transplant, recent blood transfusion), your
            Results may be altered by the neoplastic or the donor DNAs,
            resulting in a report not representative of your constitutional
            genome.
            <br />
            <br />
            6.4. The Services do not provide definitive or diagnostic Results
            for any selected condition(s) in an individual. There are range of
            other factors that may contribute to a Result, such as environmental
            and other factors including age, gender, lifestyle, dietary factors,
            accumulation of somatic mutations in the genome, infection of the
            oncovirus, and / or a smoking history, etc. The Services are not a
            diagnostic test and should be used in context with other clinical
            findings by the healthcare provider to help with a diagnosis and
            treatment plan.
            <br />
            <br />
            6.5. Ingeneous only reports the presence of inherited genetic
            variations of the selected genes, which are known, because of
            research, to have association with development of certain diseases,
            health conditions or other traits. Other known mutations and genes
            not listed are not reported or may not be detected by Ingeneous. The
            Services only detect the DNA sequence in SNPs and DNA sequences
            other than the protein-coding regions or the selected intronic
            regions are not investigated by this Test. The Services and the
            Results are indicative only and do not eliminate the possibility
            that the genetic variations may have risk of developing other
            diseases, including other cancers and carrier diseases.
            <br />
            <br />
            6.6. Risk estimation for particular categories will only be provided
            with satisfactory data quality. All risk estimation is indicative
            and approximate, sometimes cannot be specifically calculated, and is
            based on previously analysed cohorts. Any incorrect or inaccurate
            information that you provide will greatly affect the accuracy of the
            risk calculation.
            <br />
            <br />
            6.7. The Services do not provide diagnosis of any disease or medical
            conditions, nor do they provide treatment. The Results and comments
            are for informational purposes only and should not be interpreted as
            specific professional medical or other advice. The Results are based
            on tested genes and mutations. Untested genes, environmental and
            lifestyle factors may also influence your disease, health status and
            other characteristics and separate advice is required in these
            areas. Please speak with your Ingeneous Certified Practitioner, or
            your own Medical Practitioner, prior to implementing any changes to
            your diet or lifestyle, as these decisions may impact concurrent
            medical treatments. Ingeneous and the Ingeneous Affiliates shall
            have no liability to any person or entity with regards to claims,
            loss, or damage caused, or alleged to be caused, directly or
            indirectly, by the use of information contained herein.
          </p>

          <Spacer height={'20px'} />
          <h5>7. PRIVACY AND DATA SECURITY</h5>
          <p>
            7.1. Your privacy is important to us. We comply with the Privacy Act
            2020 and the Health Information Privacy Code 2020. You may read
            about our privacy policies in our Privacy Policy.
            <br />
            <br />
            7.2. Ingeneous maintains a strict data security policy which you may
            read about in our Terms.
            <br />
            <br />
            7.3. Further details about our policies governing customer privacy,
            including customer rights, can be found at or will be made available
            to you upon request by emailing hello@ingeneous.co.nz
          </p>

          <Spacer height={'20px'} />
          <h5>8. USE OF YOUR INFORMATION</h5>
          <p>
            8.1. You are requesting that your Results be made available to you
            as specified by you in your account or order form. By agreeing to
            this Informed Consent Document, you also agree that your Genetic
            Information, Personal Information, and Results can be shared with
            any Medical Practitioner that you designate, including an Ingeneous
            Certified Practitioner. You also agree that we may communicate with
            you about your Sample Collection Kit, order, Results, account
            details, and other procedures.
            <br />
            <br />
            8.2. We store your genetic sequencing and related data as required
            by applicable laws and regulations, and we may use this data to
            conduct subsequent Tests, research or analysis in the event that
          </p>
          <ul>
            <li>(a) you consent to any new product or service offering; or</li>

            <li>
              (b) our review of medical literature and/or changes in technology
              indicate that such analysis is useful or necessary to confirm
              Results provided.
            </li>
          </ul>
          <p>
            8.3. We will retain the DNA sample only for the maximum duration
            permitted under applicable law or regulation, after which point it
            will be destroyed. Until such time that your sample is destroyed, we
            may de-identify your sample and use or store it for regulatory
            compliance purposes; internal quality control; laboratory validation
            studies; or Ingeneous Research.
            <br />
            <br />
            8.4. You also agree that your genetic information, personal
            information, personal data, and Results may be stored and used by us
            for regulatory compliance purposes.
            <br />
            <br />
            8.5. With your consent, your Genetic Information and Personal
            Information may also be used for internal quality control, and in
            Ingeneous Research.
          </p>

          <Spacer height={'20px'} />
          <h5>9. ADDITIONAL USE OF YOUR INFORMATION</h5>
          <p>
            9.1. You can opt out of the use of your Genetic or Personal
            Information in Ingeneous Research (including with Ingeneous
            Affiliates) and inclusion of such information in our research
            database us by emailing us at hello@ingeneous.co.nz
            <br />
            <br />
            9.2. We may engage in research including Ingeneous Research with
            Ingeneous Affiliates for any lawful reason related to the Services.
            You acknowledge and understand that we may receive financial
            compensation to conduct such research, which may include providing
            your Personal Information, Genetic Information, Health Input Data,
            Registration Information, De-identified Information and
            Web-Behaviour Information to Ingeneous Affiliates.
            <br />
            <br />
            9.3. Our research database, which forms part of our Ingeneous
            Research, supports research in genetics. We may use your Personal
            Information, Genetic Information, Health Input Data, Registration
            Information and Web-Behaviour Information in an aggregated format.
            You may refer to our Privacy Policy to better understand how we use
            your Information for our Research.
            <br />
            <br />
            9.4. You can opt out of research undertaken by Ingeneous Affiliates
            by contacting us at hello@ingeneous.co.nz . However, if you have
            enrolled in Ingeneous Research (including research undertaken by an
            Ingeneous Affiliate) and later decided to opt out, we cannot retract
            your Genetic Information included in the Research, whether it has
            already been performed or is about to be performed.
            <br />
            <br />
            9.5. If any new Tests, technologies, processes, or inventions are
            made as a result of the Research (whether Ingeneous Research or that
            of Ingeneous Affiliates), you will not receive any compensation, nor
            will you have any right, title, and/or interest in or to such new or
            improved Tests, technologies, processes, or inventions.
          </p>

          <Spacer height={'20px'} />
          <h5>10. CONSENT</h5>
          <p>
            10.1. I confirm that the sample being provided is mine and I am at
            least 16 years of age. If the sample is not mine, I confirm that I
            am providing it on behalf of a person who is under the age of 16 and
            I am their parent or guardian. In this circumstance, I confirm that
            I have outlined the position and explained the upsides and downsides
            of conducting a Test to the Underage Client on whose behalf I am
            providing this sample. I hold Ingeneous and Ingeneous Affiliates
            harmless from any liability resulting from my decision to provide
            such a sample.
            <br />
            <br />
            10.2. The Services are not intended to diagnose any disorders,
            diseases or pre-conditions, nor do they provide medical treatment or
            constitute medical or other advice. It is intended to tell me about
            certain hereditary risk of mine as indicated.
            <br />
            <br />
            10.3. The reported Results and information do not constitute medical
            advice by Ingeneous. I should not make any medical decisions or
            medication changes based on these Results without speaking to an
            Ingeneous Certified Practitioner, or my own Medical Practitioner. My
            healthcare provider remains ultimately responsible for all diagnosis
            and treatment decisions.
            <br />
            <br />
            10.4. My Medical Practitioner and I can speak with an Ingeneous
            Certified Practitioners at no additional charge and at any
            reasonable time on reasonable notice.
            <br />
            <br />
            10.5. My sample will be analysed for the genes and regions
            identified in the Services selected.
            <br />
            <br />
            10.6. My sample and all my related personal information will be
            transferred to Ingeneous for analysis, use, processing, and storage,
            and will be subject to the laws, rules, and regulations of New
            Zealand.
            <br />
            <br />
            10.7. All samples will be destroyed after two years.
            <br />
            <br />
            10.8. I agree to the Ingeneous Terms of Service and Privacy Policy,
            which are available at www.ingeneous.co.nz, or upon request.
            <br />
            <br />
            10.9. I confirm that all information provided is true and accurate.
            I agree that I have the authority to provide this consent. I
            understand that if I wish to withdraw consent, I may do so by
            contacting Ingeneous.
          </p>

          <Spacer height={'100px'} />
        </Wrapper>
      </Col>
    </StyledRow>
  );
}

export default Consent;
