import React from 'react';
import styled from 'styled-components';
import QrReader from 'react-qr-reader';

const QRWrapper = styled.div`
  width: 100%;
  border-radius: 15px;
  section {
    border-radius: 15px;
  }
`

const Scanner = ({ handleScan }) => {
  const handleError = (err) => {
    console.error(err)
  }

  const handleNewScan = (data) => {
    if (data) {
      handleScan(data);
    }
  }

  return (<QRWrapper>
    <QrReader
      delay={1000}
      onError={handleError}
      onScan={handleNewScan}
    />
  </QRWrapper>);
}

export default Scanner;
