import React from 'react'

const OliveOil = () => {
  return (<div>
    <h5>Olive oil - about half a tablespoon per day (8g)</h5>
    <p>
      This is a particularly powerful recommendation for calming down your body’s natural hyperactive inflammation pre-set.
    </p>
    <p>
      The most ideal type of Olive Oil is cold pressed extra virgin olive oil that is stored completely out of sunlight. It should last in its pristine state for up to 6 months.
    </p>
    <p>
      * Olive oil is best served at room temperature. Olive oil oxidises at high temperatures when cooked which is undesirable and can create extra work for your detoxification system.
    </p>
  </div>)
}

export default OliveOil
