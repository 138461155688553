import React from 'react'
import { Row } from 'react-bootstrap'

import { getColor, Serving, Image, BodyCol } from './utils'

const Two = () => {
  return (<>
    <BodyCol xs={12} md={4}>
      <Serving color={getColor(2)}>2+ servings a day</Serving>
      <p>Two servings equals 2+ cups of green tea a day.</p>
    </BodyCol>
    <BodyCol xs={12} md={5}>
      <p>&nbsp;</p>
    </BodyCol>
  </>)
}

const One = () => {
  return (<>
    <BodyCol xs={12} md={4}>
      <Serving color={getColor(1)}>1 serving a day</Serving>
      <p>One serving equals 1 cup of green tea a day.</p>
    </BodyCol>
    <BodyCol xs={12} md={5}>
      <p>&nbsp;</p>
    </BodyCol>
  </>)
}

const Zero = () => {
  return (<>
    <BodyCol xs={12} md={4}>
      <Serving color={getColor(0)}>Zero servings a day</Serving>
      <p>We are sorry, your inflammation system cannot tolerate caffeine.</p>
    </BodyCol>
    <BodyCol xs={12} md={5}>
      <p>Green tea is the worst health decision you could make for your genes, actually worse for your body than consuming coffee. Green Tea contains EGCG which for you will trigger inflammation.</p>
      <p>Please also avoid green tea in supplements, the ingredient to look out for is ‘Camellia sinensis’.</p>
    </BodyCol>
  </>)
}

const GreenTea = ({ serving }) => {
  return (<Row>
    <BodyCol xs={12}>
      <h4>Green Tea</h4>
    </BodyCol>
    <BodyCol xs={12} md={3}>
      <Image src={process.env.PUBLIC_URL + '/assets/images/lifeplus/ing-green-tea.png'} />
    </BodyCol>
    {serving === 2 && <Two />}
    {serving === 1 && <One />}
    {serving === 0 && <Zero />}
  </Row>)
}

export default GreenTea
