import React from 'react';

const Sun = () => {
  return (
    <div>
      <h5>Sun exposure</h5>
      <p>
        Vitamin D is important to bone health and muscle function and the main
        source of this vitamin is exposure to the sun. Luckily your body can
        efficiently utilise vitamin D from the sun. This vitamin is necessary
        for activating over 200 genetic signals essential to activating your
        health and wellbeing.
      </p>
      <p>
        Almost a third of the New Zealand population has below the recommended
        blood level of vitamin D. (Adult Nutrition Survey 2008/09).
      </p>
      <p>
        You can get more vitamin D through sensible sun exposure and choosing
        foods that contain vitamin D.
      </p>
      <p>
        Exposing your skin to the sun increases your risks of skin cancer. It is
        important that you balance the risks. You should never get sunburnt.
      </p>
      <p>
        The amount of sun exposure needed to make enough vitamin D depends on
        skin colour, age, weight, mobility, risk of skin cancer, vitamin D in
        food, medications that could be photosensitising increasing risk of
        burning, where in NZ you are, the season, the time of day, and the
        amount of skin exposed.
      </p>
      <ul>
        <li>
          Between May and August midday sun exposure is recommended, this could
          be gained from a walk or other outdoor physical activity.
        </li>
        <li>
          Between September and April sun protection is recommended especially
          between 10am and 4pm. A walk or other form of outdoor physical
          activity in the early morning or later afternoon is recommended.
        </li>
      </ul>
      <p>
        You should always aim to get sun exposure when the UV Alert Index is 2
        or under. When the UV level is 3 or higher, the UV radiation is
        damaging, and you will need to protect your skin and eyes. A good
        resource for where the UV level is at in your region is through the
        SunSmart Sun Protection Alert:
        <a
          href="https://www.sunsmart.org.nz/sun-protection-alert"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.sunsmart.org.nz/sun-protection-alert
        </a>
      </p>
      <p>
        For more information on sensible sun exposure and Vitamin D, please
        click this link to view recommendations from the Ministry of Health:
        <a
          href="https://www.health.govt.nz/your-health/healthy-living/food-activity-and-sleep/healthy-eating/vitamin-d"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.health.govt.nz/your-health/healthy-living/food-activity-and-sleep/healthy-eating/vitamin-d
        </a>
      </p>
      <p>
        Sun beds and solariums are NOT recommended. You also cannot get UVB rays
        required for making vitamin D through glass, so enjoy this opportunity
        to get outside.
      </p>
    </div>
  );
};

export default Sun;
