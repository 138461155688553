import { AxiosPromise } from 'axios';
import PrivateAPI from './private';

export const checkReportsStatus = () => {
  return PrivateAPI.post('/api/reports/status/', {});
};

export const getGeneData = (sampleId: string, rsNumbers: string[]) => {
  return PrivateAPI.post('/api/reports/genes/', {
    sample_id: sampleId,
    rs_numbers: rsNumbers,
  });
};

export interface IEssentialsReport {
  status: string;
  pro_inflammation: any;
  anti_inflammation: any;
  vitamin_d: any;
  detox: any;
  nutrition_boost: any;
  lifestyle: any;
  superfood: any;
  foundation_supplement: any;
  booster_supplement: any;
}
export const getEssentials = (
  kitId?: string
): AxiosPromise<IEssentialsReport> => {
  return PrivateAPI.post('/api/reports/essentials/', {
    sample_id: kitId,
  });
};

export interface ILifePlusReport {
  status: string;
}
export const getLifePlus = (kitId?: string): AxiosPromise<ILifePlusReport> => {
  return PrivateAPI.post('/api/reports/lifestyle_plus/', {
    sample_id: kitId,
  });
};

export const getSupplement = (name: string) => {
  return PrivateAPI.get('/api/reports/supplements/' + name + '/');
};

/**
 * Gets the Weight Management report from API
 *
 * @param {string} kitId
 *
 * @returns {AxiosPromise<IWeightManagementReport>}
 */
export const getWeightManagement = (
  kitId?: string
): AxiosPromise<IWeightManagementReport> => {
  return PrivateAPI.post('/api/reports/weight_management/', {
    sample_id: kitId,
  });
};
export interface IWeightManagementReport {
  status?: Status;
  test?: boolean;
  exercise_percentage?: ExercisePercentage;
  diet_percentage?: DietPercentage;
  exercise_type?: ExerciseType;
  diet_type?: DietType;
  temperature?: Temperature;
  fasting?: Fasting;
  supplement?: Supplement;
  food?: Food;
  athleticism?: Athleticism;
  impulse?: Impulse;
  binge_eating?: BingeEating;
  inflammation?: number;
}
type Status = 'complete';
type ExercisePercentage = 0 | 20 | 35 | 40 | 50 | 60 | 70 | 85 | 90 | 100;
type DietPercentage = 100 | 80 | 65 | 60 | 50 | 40 | 30 | 15 | 10 | 0;
type ExerciseType = 'irrelevant' | 'intense cardio' | 'resistance' | 'cardio';
type DietType =
  | 'obesity risk'
  | 'very low calories'
  | 'keto'
  | 'lower carbs'
  | 'lower calories';
type Temperature = 'cold' | 'hot' | 'alternating';
type Fasting = 'good' | 'bad' | 'neutral';
type Supplement =
  | 'mungbean'
  | 'lychee'
  | 'sereniten'
  | 'caraluma fimbriata'
  | 'tri-met-control'
  | 'l-carnitine'
  | 'capsaicin'
  | 'resveritrol';
type Food =
  | 'green tea'
  | '2 glasses red wine'
  | 'seaweed'
  | 'spicy food'
  | 'vitamin A rich foods';
type Athleticism =
  | 'work harder to be athletic'
  | 'moderately athletic'
  | 'naturally gifted athlete';
type Impulse = 'very impulsive' | 'very considered' | 'neutral';
type BingeEating = 'high risk' | 'low risk' | 'moderate risk';
