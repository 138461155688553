import React, { useContext } from 'react';
import { Redirect, Link } from 'react-router-dom';
import styled from 'styled-components';
import { isBrowser } from 'react-device-detect';

import { AuthContext } from 'stores/auth';
import { primary } from 'styles/theme';
import { Spacer } from 'components/utils';
import { isMobile } from 'react-device-detect';
import { Row, Col } from 'react-bootstrap';

const Wrapper = styled.div`
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  padding-bottom: ${isBrowser ? '200px' : '50px'};
`;

const LogoWrapper = styled.div`
  padding: ${isBrowser ? '100px 15%' : '15px'};
  display: ${isBrowser ? 'inline-block' : 'flex'};
  z-index: ${isBrowser ? '0' : '50'};
  justify-content: ${isBrowser ? 'unset' : 'center'};
`;

const Logo = styled.img`
  max-height: 40px;
  z-index: 50;
  position: relative;
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  // display: none;

  img {
    z-index: 0;
  }

  @media only screen and (min-width: 768px) {
    display: block;
  }
`;

const ImageWrapper2 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  // display: none;

  img {
    z-index: 0;
  }

  @media only screen and (min-width: 768px) {
    display: block;
  }
`;

const ImageWrapper3 = styled.div`
  div {
    display: flex;
    align-items: center;
  }
  img {
    display: block;
  }
`;

const Headline = styled.div`
  text-align: center;
  position: relative;
  z-index: 50;

  h3 {
    font-family: 'Recoleta', serif;
    font-size: 4rem;
    font-weight: 500;
    color: ${primary};
  }

  p {
    font-size: 1.2rem;
    margin-top: 2rem;
  }
`;

const ButtonToolbar = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
`;

const ButtonWraper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  z-index: 1;

  a {
    font-size: ${isBrowser ? '1.2rem' : '0.75rem'};
    display: flex;
    align-items: center;
  }
`;

const Helper = styled.div`
  text-align: center;
  position: relative;
  z-index: 50;

  h4 {
    font-family: 'Recoleta', serif;
    font-size: 2rem;
    font-weight: 500;
    color: ${primary};
    margin-bottom: 30px;
  }
  // display: none;

  @media only screen and (min-width: 768px) {
    display: block;
  }
`;

const ImageCol = styled(Col)`
  display: flex;
  justify-content: center;
  padding: 0;
`;

const SpanCol = styled(Col)`
  padding: 0;
  margin-right: 30px;
  display: flex;
  align-items: self-end !important;

  span {
    font-size: 13px;
  }
`;

const Home = () => {
  const authStore = useContext(AuthContext);

  if (authStore.loggedIn) {
    if(authStore.isPractitioner() || authStore.isPractitionerOrg()){
      console.log("is practitioner")
      return <Redirect to="/consult"/>;
    }
    console.log("is user")
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <Wrapper>
        <LogoWrapper>
          {isMobile ? (
            <Logo
              src={
                process.env.PUBLIC_URL + '/assets/images/ingeneous_green.png'
              }
              alt="logo"
              width={150}
            />
          ) : (
            <Logo
              src={
                process.env.PUBLIC_URL + '/assets/images/ingeneous_green.png'
              }
              alt="logo"
            />
          )}
        </LogoWrapper>
        {isMobile && <Spacer height={'40px'} />}
        {isMobile ? (
          <ImageWrapper>
            <img
              src={
                process.env.PUBLIC_URL +
                '/assets/images/landing/landing-branding-2-mobile.png'
              }
              alt="branding-2"
              width={150}
            />
          </ImageWrapper>
        ) : (
          <ImageWrapper>
            <img
              src={
                process.env.PUBLIC_URL + '/assets/images/landing-branding-2.png'
              }
              alt="branding-2"
            />
          </ImageWrapper>
        )}

        <Headline>
          {isMobile ? <h4>Know yourself well</h4> : <h3>Know yourself well</h3>}
          <p>Create an account to get started</p>
        </Headline>

        {isMobile ? (
          <ImageWrapper2>
            <img
              src={
                process.env.PUBLIC_URL +
                '/assets/images/landing/landing-branding-1-mobile.png'
              }
              alt="landing-1"
              width={130}
            />
          </ImageWrapper2>
        ) : (
          <ImageWrapper2>
            <img
              src={
                process.env.PUBLIC_URL + '/assets/images/landing-branding-1.png'
              }
              alt="landing-1"
            />
          </ImageWrapper2>
        )}

        <ButtonToolbar>
          <ButtonWraper>
            <Link to={'/register'} className="btn btn-primary">
              Create Account
            </Link>
            <Link to={'/login'} className="btn btn-primary-outline">
              Login
            </Link>
          </ButtonWraper>
        </ButtonToolbar>

        {isMobile ? <Spacer height={'70px'} /> : <Spacer height={'120px'} />}

        <Helper>
          {isMobile ? (
            <>
              <h5>How it works</h5>
              <ImageWrapper3>
                <Row>
                  <Col style={{ display: 'block' }}>
                    <Row>
                      <ImageCol xs={4}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/assets/images/landing/laptop-mobile.png'
                          }
                          alt="group1"
                          width={70}
                        />
                      </ImageCol>
                      <SpanCol>
                        <span>Order and receive your DNA test kit</span>
                      </SpanCol>
                    </Row>
                    <Row>
                      <ImageCol xs={4} style={{}}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/assets/images/landing/arrow-mobile.png'
                          }
                          alt="group1"
                          height={30}
                        />
                      </ImageCol>
                      <Col>{''}</Col>
                    </Row>
                    <Row>
                      <ImageCol xs={4}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/assets/images/landing/truck-mobile.png'
                          }
                          alt="group1"
                          width={70}
                        />
                      </ImageCol>
                      <SpanCol>
                        <span>Submit result for genotype testing</span>
                      </SpanCol>
                    </Row>
                    <Row>
                      <ImageCol xs={4}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/assets/images/landing/arrow-mobile.png'
                          }
                          alt="group1"
                          height={30}
                        />
                      </ImageCol>
                      <Col>{''}</Col>
                    </Row>
                    <Row>
                      <ImageCol xs={4}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/assets/images/landing/book-mobile.png'
                          }
                          alt="group1"
                          width={60}
                        />
                      </ImageCol>
                      <SpanCol>
                        <span>Get your personalised report and plan</span>
                      </SpanCol>
                    </Row>
                    <Row>
                      <ImageCol xs={4}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/assets/images/landing/arrow-mobile.png'
                          }
                          alt="group1"
                          height={30}
                        />
                      </ImageCol>
                      <Col>{''}</Col>
                    </Row>
                    <Row>
                      <ImageCol xs={4}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/assets/images/landing/lock-mobile.png'
                          }
                          alt="group1"
                          width={60}
                        />
                      </ImageCol>
                      <SpanCol>
                        <span>Unlock more reports and learnings</span>
                      </SpanCol>
                    </Row>
                  </Col>
                  {/* <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/images/landing/laptop-mobile.png'
                      }
                      alt="group1"
                      width={70}
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/images/landing/arrow-mobile.png'
                      }
                      alt="group1"
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/images/landing/truck-mobile.png'
                      }
                      alt="group1"
                      width={90}
                    />
                  </Col>
                  <Col>
                    <span>Order and receive your DNA test kit</span>
                  </Col> */}
                </Row>
                {/* <div>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/assets/images/landing/laptop-mobile.png'
                    }
                    alt="group1"
                    width={90}
                  />
                  <span>Order and receive your DNA test kit</span>
                </div>
                <div>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    '/assets/images/landing/arrow-mobile.png'
                  }
                  alt="group1"
                />
                </div>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    '/assets/images/landing/truck-mobile.png'
                  }
                  alt="group1"
                  width={90}
                /> */}
              </ImageWrapper3>
            </>
          ) : (
            <>
              <h4>How it works</h4>
              <img
                src={
                  process.env.PUBLIC_URL + '/assets/images/landing-group-11.png'
                }
                alt="group1"
              />
            </>
          )}
        </Helper>
      </Wrapper>
    </>
  );
};

export default Home;
