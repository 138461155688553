/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import COMT from './COMT';
import MTHFR from './MTHFR';
import FUT2TCN2 from './FUT2TCN2';
import PEMTBHMT from './PEMTBHMT';
import CBS from './CBS';
import MTRMTRR from './MTRMTRR';
import Multivitamin from './Multivitamin';

import { getGeneData } from 'api/reports';

const HeaderRow = styled(Row)`
  border-bottom: 1px solid #dedede;
  padding: 10px 0;
  font-weight: 600;
`;

const GENES = [
  '4680',
  '1801131',
  '1801133',
  '602662',
  '1801198',
  '7946',
  '3733890',
  '234706',
  '1805087',
  '1801394',
];

const Table = ({ kitId }) => {
  const [geneData, setGeneData] = useState();

  useEffect(() => {
    getGeneData(kitId, GENES).then((res) => {
      setGeneData(res.data?.result);
    });
  }, []);

  return (
    <Container className='container-table'>
      <HeaderRow>
        <Col md={11}>
          <Row>
            <Col md={2}>Name</Col>
            <Col md={1}>Result</Col>
            <Col md={2}>RS#</Col>
            <Col md={7}>Supplement (please prioritise, 1 only to customer)</Col>
          </Row>
        </Col>
        <Col md={1}>&nbsp;</Col>
      </HeaderRow>

      {geneData ? (
        <>
          <COMT geneData={geneData}/>
          <MTHFR geneData={geneData} />
          <FUT2TCN2 geneData={geneData} />
          <PEMTBHMT geneData={geneData} />
          <CBS geneData={geneData} />
          <MTRMTRR geneData={geneData} />
          <Multivitamin geneData={geneData} />
        </>
      ) : (
        <span>Loading</span>
      )}
    </Container>
  );
};

export default Table;
