import React from 'react'
import { Row } from 'react-bootstrap'

import { Image, BodyCol } from './utils'

const CafeineFree = () => {
  return (<Row>
    <BodyCol xs={12}>
      <h4>Naturally Caffeine free teas</h4>
    </BodyCol>
    <BodyCol xs={12} md={3}>
      <Image src={process.env.PUBLIC_URL + '/assets/images/lifeplus/ing-fruit-tea.png'} />
    </BodyCol>
    <BodyCol xs={12} md={9}>
      <p>Your genes have told us you can consume without limit - fruit teas, herbal teas (including rooibos, chamomile, mint for example), and also beverages made using edible and medicinal mushrooms or dandelion root (which can be an acceptable coffee replacement).</p>
      <p>Rooibos tea in particular contains potent anti-inflammatory ingredients that are able to beneficially influence your gene expression to improve inflammation coordination. If you like the taste it can also help your curb any cravings for a caffeinated tea. Add a bit of ginger and full cream for an authentic South Africa treat.</p>
    </BodyCol>
  </Row>)
}

export default CafeineFree
