import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { gray, primary } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import View from '../View';
import Close from '../ViewLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const AlertRef = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
  margin-bottom: ${isMobile ? 0 : '30px'};
`;

const SubtitleGray = styled.h5`
  color: ${gray};
  margin-bottom: 30px;
`;

const WrapperRef = styled.div`
  margin-top: 5px;
`;

const ListGroupUl = styled.ul`
  font-weight: bold;
  padding-left: 20px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const List = styled.li`
  font-weight: 300;

  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    word-break: break-all;
  }
`;

const ListRef = styled.li`
  font-weight: 300;
  padding-left: 20px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    word-break: break-all;
  }
`;

const WrapperTitle = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
`;

interface HotColdProps {
  results: any;
}
const HotCold = ({ results }: HotColdProps) => {
  const [show, setShow] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/Temperature.png"
            alt="temperature"
            width={'70%'}
          />
        </ImageCol>
        <Col md={8}>
          <WrapperTitle>
            {isMobile ? (
              <>
                <Subtitle>Hot & Cold Temperature's</Subtitle>
                <SubtitleGray> will help you lose weight</SubtitleGray>
              </>
            ) : (
              <>
                <Subtitle>Hot & Cold Temperature's</Subtitle>
                &nbsp;
                <SubtitleGray> will help you lose weight</SubtitleGray>
              </>
            )}
          </WrapperTitle>
          <Alert>
            <p>Hot & Cold Temperature's</p>
            <WrapperRef>
              <ListGroupUl>
                <List>
                  Alternating between hot and cold pools (1-5 mins) in each,
                  repeat 3-5 times.
                </List>
                <List>
                  Finish a sauna session (10-20 mins) with a cold plunge (5-10
                  mins).
                </List>
                <List>
                  Alternate between hot and cold (2-3 mins) during your shower.
                </List>
                <List>Swim in the sea and then take a warm bath.</List>
              </ListGroupUl>
            </WrapperRef>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <p>
                Your body is able to lose weight best when alternating between
                hot and cold environments, this makes up 30% of your optimal
                weight story. Simulating a hot environment will help decrease
                white adipose tissue (WAT) and cold environments will help by
                increasing thermogenesis in your brown adipose tissue (BAT).
              </p>
              <p>
                White adipose tissue stores energy in the form of triglycerides
                (fat), brown adipose tissue uses this stored energy to produce
                heat.(1) While a higher BAT ratio is helpful and associated with
                lower BMI and healthier weight, more WAT is related to increased
                risk of obesity and related disorders. (1, 2) To maintain a
                stable internal temperature your body burns fat for energy,
                helping you to lose weight. (3, 4)
              </p>
              <p>
                For you, both hot and cold environments can burn calories and
                alternating between the two is particularly powerful at burning
                calories as your body adjusts to the change in temperatures. For
                example, using a sauna is frequently accompanied by cooling
                periods and the combined effect due to heat and cold stress
                adaptation could be beneficial to you. (5, 6)
              </p>
              <p>
                Make sure you listen to your body, it is always good to start
                off with short exposures and slowly extend your exposure time
                and temperature intensity as you adjust. Also drink plenty of
                water. Follow at least one of the recommendations below that
                works best for you!
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
      <Row className="no-print">
        <Col>
          <AlertRef>
            <span>References</span>
            {!showReferences && (
              <View value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <Close value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <WrapperRef>
                <ListGroup>
                  <ListRef>
                    Saely CH, Geiger K, Drexel H. Brown versus white adipose
                    tissue: a mini-review. Gerontology. 2012;58(1):15-23. doi:
                    10.1159/000321319. Epub 2010 Dec 7. PMID: 21135534. &nbsp;
                    <a href="https://pubmed.ncbi.nlm.nih.gov/21135534">
                      https://pubmed.ncbi.nlm.nih.gov/21135534/
                    </a>
                  </ListRef>
                  <ListRef>
                    <a href="https://www.news-medical.net/life-sciences/The-Differences-Between-White-and-Brown-Adipose-Tissue.aspx">
                      https://www.news-medical.net/life-sciences/The-Differences-Between-White-and-Brown-Adipose-Tissue.aspx
                    </a>
                  </ListRef>
                  <ListRef>
                    van Marken Lichtenbelt W, Kingma B, Van Der Lans A, Schellen
                    L. Cold exposure–an approach to increasing energy
                    expenditure in humans. Trends in Endocrinology & Metabolism.
                    2014 Apr 1;25(4):165-7. &nbsp;
                    <a
                      href="https://www.ics.uci.edu/~wayne/tmp/Lichtenbelt-CELL-2014-Cold-exposure-increasing-energy-expenditure.pdf
                  "
                    >
                      https://www.ics.uci.edu/~wayne/tmp/Lichtenbelt-CELL-2014-Cold-exposure-increasing-energy-expenditure.pdf
                    </a>
                  </ListRef>
                  <ListRef>
                    Robert Podstawski, Tomasz Boraczyński, Michał Boraczyński,
                    Dariusz Choszcz, Stefan Mańkowski, Piotr Markowski,
                    "Sauna-Induced Body Mass Loss in Young Sedentary Women and
                    Men", The Scientific World Journal, vol. 2014, Article ID
                    307421, 7 pages, 2014. &nbsp;{' '}
                    <a href="https://doi.org/10.1155/2014/307421">
                      https://doi.org/10.1155/2014/307421{' '}
                    </a>
                  </ListRef>
                  <ListRef>
                    Heinonen I, Laukkanen JA. Effects of heat and cold on
                    health, with special reference to Finnish sauna bathing.
                    American Journal of Physiology-Regulatory, Integrative and
                    Comparative Physiology. 2018 May 1.
                  </ListRef>
                  <ListRef>
                    <a href="https://betterhumans.pub/a-guide-to-using-sauna-to-increase-your-health-and-longevity-4c68d7739132">
                      https://betterhumans.pub/a-guide-to-using-sauna-to-increase-your-health-and-longevity-4c68d7739132
                    </a>
                  </ListRef>
                </ListGroup>
              </WrapperRef>
            )}
          </AlertRef>
        </Col>
      </Row>
    </>
  );
};

export default HotCold;
