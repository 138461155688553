import React, { useState } from 'react';
import { Row, Tabs, Tab } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { Spacer } from 'components/utils';
import { borderGray } from 'styles/theme';
import { getColor, Serving, Image, BodyCol } from './utils';
import useDetectPrint from 'react-detect-print';

const StyledTabs = styled(Tabs)`
  border-color: ${borderGray};

  .nav-item {
    text-transform: uppercase;
    font-size: 0.8rem;
    background-color: #f2f2f2;
    margin-right: 10px;
    border: 1px solid ${borderGray};

    @media print {
      border: none;
    }

    &.active {
      background-color: #ffffff;
    }
  }

  @media print {
    border: none;
    display: block;
    max-height: auto;
  }
`;

const TabWrapper = styled.div`
  .tab-content {
    padding: ${isMobile ? '10px' : '20px'};
    overflow: scroll;
    border: 1px solid #dedede;
    border-top: 0;

    @media print {
      border: none;
    }
  }
  .print-tab {
    @media print {
      max-height: auto;
    }
  }
  .no-print-tab {
    max-height: 400px;
  }
`;

const CarbohydrateTolerance = ({ aml_count, gender }) => {
  const [key, setKey] = useState('consumption');
  const printing = useDetectPrint();

  return (
    <Row>
      <BodyCol>
        <h3>Eating for your Genotype</h3>
        <h4>Carbohydrate Tolerance</h4>
        <TabWrapper>
          <StyledTabs
            id="report-tab"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab
              eventKey="consumption"
              title={`${printing ? '' : 'Consumption'}`}
              className={`${printing ? 'print-tab' : 'no-print-tab'}`}
            >
              {printing && <h5>Consumption</h5>}
              <Row>
                <BodyCol xs={12} md={7}>
                  {aml_count <= 4 && (
                    <Serving color={getColor(0)}>
                      You have scored a {aml_count} out of 20 possible amylase
                      copies
                    </Serving>
                  )}
                  {aml_count >= 5 && aml_count <= 8 && (
                    <Serving color={getColor(1)}>
                      You have scored a {aml_count} out of 20 possible amylase
                      copies
                    </Serving>
                  )}
                  {aml_count >= 9 && (
                    <Serving color={getColor(2)}>
                      You have scored a {aml_count} out of 20 possible amylase
                      copies
                    </Serving>
                  )}
                  <Spacer height={'20px'} />
                  <p>
                    To determine how many carbohydrates you can consume - your
                    saliva sample has undergone a second test in the laboratory,
                    which is a copy number test as opposed to an open array SNP
                    test.
                  </p>

                  <p>
                    A copy number test can tell us how many copies are present,
                    in this case it tells us how much amylase is in your saliva
                    at any given time. Amylase is a potent enzyme in your saliva
                    that does a considerable amount of the work in breaking down
                    the starchy carbohydrates that you consume. In fact, 70% of
                    your digestion occurs in your mouth in the first 27 seconds
                    that you chew your food!
                  </p>

                  <p>
                    The range is 1-20, with 20 being the most powerful. To help
                    explain this – imagine for example that you have two amylase
                    molecules and when a starch molecule enters your mouth those
                    two amylase workers have 27 seconds to process as much
                    starch as possible. Alternatively, someone with 20 copy
                    numbers has 20 workers to process that starch over the 27
                    seconds and so can get much more work done.
                  </p>

                  <p className="page-br">
                    Click the INFO tab and let’s find out how much work your
                    amylase can do and what a manageable load of starch is for
                    your body to process easily.
                  </p>

                  <p>
                    The following nutritional recommendation does not take into
                    account how much exercise you undertake, whether you are
                    pregnant or currently breastfeeding. Please adjust
                    accordingly or reach out to the practitioner phone line if
                    you are a member or check with your primary health care
                    provider.{' '}
                  </p>
                </BodyCol>
                <BodyCol xs={12} md={5}>
                  <Image
                    src={
                      process.env.PUBLIC_URL +
                      '/assets/images/lifeplus/ing-carbs.png'
                    }
                  />
                </BodyCol>
              </Row>
            </Tab>
            <Tab
              eventKey={`${printing ? 'consumption' : 'info'}`}
              title={`${printing ? '' : 'Info'}`}
              className={`${printing ? 'print-tab' : 'no-print-tab'}`}
            >
              {printing && <h5>Info</h5>}
              {gender === 'male' && aml_count >= 1 && aml_count <= 4 && (
                <>
                  <p>Low 1-4</p>
                  <p>
                    This score means you have come back with a low amylase
                    score. So starchy carbs can be problematic for you.
                  </p>
                  <p>
                    Starchy carbohydrates are a challenge for your body to
                    process so you should keep your intake low, but don’t
                    eliminate starchy carbohydrates entirely. The goal is to
                    consume around the following quantity of these carbohydrates
                    for optimal inflammation coordination.
                  </p>
                  <Serving color={getColor(1)}>
                    You should eat the equivalent of 4 pieces of bread and 2
                    pieces of fruit a day = 165g a day total carbohydrate or
                    roughly 25% of caloric consumption
                  </Serving>
                  <p>
                    Eating any of these foods counts towards your daily limit of
                    carbohydrates:
                  </p>
                  <ul>
                    <li>
                      Obvious starchy carbohydrate include, but are not limited
                      to - pasta, rice, bread, baked goods containing flours,
                      cereal, any grain based product.
                    </li>
                    <li>
                      Less obvious starchy carbohydrates include, but are not
                      limited to - kumara, potato, and corn.
                    </li>
                    <li>
                      There are also hidden sources of starch in milk
                      alternatives like almond milk, oat milk, rice milk,
                      tapioca, dried herbs and spices, and instant gravy.
                    </li>
                    <li>
                      To work out the starch content in your food go to:{' '}
                      <a href="http://nutritiondata.self.com/foods-000007000000000000000.html">
                        nutritiondata.self.com
                      </a>
                    </li>
                  </ul>
                </>
              )}

              {gender === 'male' && aml_count >= 5 && aml_count <= 8 && (
                <>
                  <p>Medium 5-8</p>
                  <p>
                    This score means you have come back with a midway amylase
                    score. So your body is ok at processing a moderate amount of
                    carbohydrates.
                  </p>
                  <p>
                    Starchy carbohydrates are a challenge for your body to
                    process so you should keep your intake low, but don’t
                    eliminate starchy carbohydrates entirely.
                  </p>
                  <p>
                    The goal is to consume around the following quantity of
                    these carbohydrates for optimal inflammation coordination.
                  </p>

                  <Serving color={getColor(1)}>
                    You should eat the equivalent of 6 pieces of bread and 4
                    pieces of fruit a day = 265g a day total carbohydrate or
                    roughly 35% of caloric consumption
                  </Serving>
                  <p>
                    Eating any of these foods counts towards your daily limit of
                    carbohydrates:
                  </p>
                  <ul>
                    <li>
                      Obvious starchy carbohydrate include, but are not limited
                      to - pasta, rice, bread, baked goods containing flours,
                      cereal, any grain based product.
                    </li>
                    <li>
                      Less obvious starchy carbohydrates include, but are not
                      limited to - kumara, potato, and corn.
                    </li>
                    <li>
                      There are also hidden sources of starch in milk
                      alternatives like almond milk, oat milk, rice milk,
                      tapioca, dried herbs and spices, and instant gravy.
                    </li>
                    <li>
                      To work out the starch content in your food go to:{' '}
                      <a href="http://nutritiondata.self.com/foods-000007000000000000000.html">
                        nutritiondata.self.com
                      </a>
                    </li>
                  </ul>
                </>
              )}

              {gender === 'male' && aml_count >= 9 && (
                <>
                  <p>High 9+</p>
                  <p>
                    This score means you have come back with a high amylase
                    score. Your body is really good at processing carbohydrates,
                    you can consume them guilt free!{' '}
                  </p>
                  <p>
                    Starchy carbohydrates are a challenge for your body to
                    process so you should keep your intake low, but don’t
                    eliminate starchy carbohydrates entirely. The goal is to
                    consume around the following quantity of these carbohydrates
                    for optimal inflammation coordination.
                  </p>
                  <Serving color={getColor(1)}>
                    Eating any of these foods counts towards your daily limit of
                    carbohydrates:
                  </Serving>
                  <p>
                    Eating any of these foods counts towards your daily limit of
                    carbohydrates:
                  </p>
                  <ul>
                    <li>
                      Obvious starchy carbohydrates include, but are not limited
                      to - pasta, rice, bread, baked goods containing flours,
                      cereal, any grain based product.
                    </li>
                    <li>
                      Less obvious starchy carbohydrates include, but are not
                      limited to - kumara, potato, and corn.
                    </li>
                    <li>
                      There are also hidden sources of starch in milk
                      alternatives like almond milk, oat milk, rice milk,
                      tapioca, dried herbs and spices, and instant gravy.
                    </li>
                    <li>
                      To work out the starch content in your food go to:{' '}
                      <a href="http://nutritiondata.self.com/foods-000007000000000000000.html">
                        nutritiondata.self.com
                      </a>
                    </li>
                  </ul>
                </>
              )}

              {gender === 'female' && aml_count >= 1 && aml_count <= 4 && (
                <>
                  <p>Low 1-4</p>
                  <p>
                    This score means you have come back with a low amylase
                    score. So starchy carbs can be problematic for you.
                  </p>
                  <p>
                    Starchy carbohydrates are a challenge for your body to
                    process so you should keep your intake low, but don’t
                    eliminate starchy carbohydrates entirely. The goal is to
                    consume around the following quantity of these carbohydrates
                    for optimal inflammation coordination.
                  </p>

                  <Serving color={getColor(1)}>
                    You should eat the equivalent of 2 pieces of bread and 2
                    pieces of fruit a day = 125g a day total carbohydrate, or
                    roughly 25% of caloric consumption
                  </Serving>
                  <p>
                    Eating any of these foods counts towards your daily limit of
                    carbohydrates:
                  </p>
                  <ul>
                    <li>
                      Obvious starchy carbohydrate include, but are not limited
                      to - pasta, rice, bread, baked goods containing flours,
                      cereal, any grain based product.
                    </li>
                    <li>
                      Less obvious starchy carbohydrates include, but are not
                      limited to - kumara, potato, and corn.{' '}
                    </li>
                    <li>
                      There are also hidden sources of starch in milk
                      alternatives like almond milk, oat milk, rice milk,
                      tapioca, dried herbs and spices, and instant gravy.
                    </li>
                    <li>
                      To work out the starch content in your food go to:{' '}
                      <a href="http://nutritiondata.self.com/foods-000007000000000000000.html">
                        nutritiondata.self.com
                      </a>
                    </li>
                  </ul>
                </>
              )}

              {gender === 'female' && aml_count >= 5 && aml_count <= 8 && (
                <>
                  <p>Medium 5-8</p>
                  <p>
                    This score means you have come back with a midway amylase
                    score. So your body is ok at processing a moderate amount of
                    carbohydrates.
                  </p>
                  <p>
                    Starchy carbohydrates are a challenge for your body to
                    process so you should keep your intake low, but don’t
                    eliminate starchy carbohydrates entirely. The goal is to
                    consume around the following quantity of these carbohydrates
                    for optimal inflammation coordination.
                  </p>

                  <Serving color={getColor(1)}>
                    You should eat the equivalent of 4 pieces of bread and 2
                    pieces of fruit a day = 165g a day total carbohydrate or
                    roughly 30% of caloric consumption
                  </Serving>
                  <p>
                    Eating any of these foods counts towards your daily limit of
                    carbohydrates:
                  </p>
                  <ul>
                    <li>
                      Obvious starchy carbohydrates include, but are not limited
                      to - pasta, rice, bread, baked goods containing flours,
                      cereal, any grain based product.
                    </li>
                    <li>
                      Less obvious starchy carbohydrates include, but are not
                      limited to - kumara, potato, and corn.
                    </li>
                    <li>
                      There are also hidden sources of starch in milk
                      alternatives like almond milk, oat milk, rice milk,
                      tapioca, dried herbs and spices, and instant gravy.
                    </li>
                    <li>
                      To work out the starch content in your food go to:{' '}
                      <a href="http://nutritiondata.self.com/foods-000007000000000000000.html">
                        nutritiondata.self.com
                      </a>
                    </li>
                  </ul>
                </>
              )}

              {gender === 'female' && aml_count >= 9 && (
                <>
                  <p>High 9+</p>
                  <p>
                    This score means you have come back with a high amylase
                    score. Your body is really good at processing carbohydrates,
                    you can consume them guilt free!
                  </p>
                  <p>
                    Starchy carbohydrates are a challenge for your body to
                    process so you should keep your intake low, but don’t
                    eliminate starchy carbohydrates entirely. The goal is to
                    consume around the following quantity of these carbohydrates
                    for optimal inflammation coordination.
                  </p>
                  <Serving color={getColor(1)}>
                    You should eat the equivalent of 6 pieces of bread and 4
                    pieces of fruit a day = 275g a day total carbohydrates, or
                    roughly 40% of caloric consumption
                  </Serving>
                  <p>
                    Eating any of these foods counts towards your daily limit of
                    carbohydrates:
                  </p>
                  <ul>
                    <li>
                      Obvious starchy carbohydrates include, but are not limited
                      to - pasta, rice, bread, baked goods containing flours,
                      cereal, any grain based product.
                    </li>
                    <li>
                      Less obvious starchy carbohydrates include, but are not
                      limited to - kumara, potato, and corn.
                    </li>
                    <li>
                      There are also hidden sources of starch in milk
                      alternatives like almond milk, oat milk, rice milk,
                      tapioca, dried herbs and spices, and instant gravy.
                    </li>
                    <li>
                      To work out the starch content in your food go to:{' '}
                      <a href="http://nutritiondata.self.com/foods-000007000000000000000.html">
                        nutritiondata.self.com
                      </a>
                    </li>
                  </ul>
                </>
              )}
            </Tab>
          </StyledTabs>
        </TabWrapper>
      </BodyCol>
    </Row>
  );
};

export default CarbohydrateTolerance;
