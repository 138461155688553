import React, { useRef, useEffect, useContext, useState } from 'react';
import styled from 'styled-components';

import { isMobile } from 'react-device-detect';
import { Container, Row, Col, Button } from 'react-bootstrap';

import { error, primary, gray, white, yellow, alert } from '../../styles/theme';

import { getUserPendingOrders } from 'api/users';

const OrderWrapper = styled.div`
  padding: ${isMobile ? '15px 5px' : '30px 40px'};
  border-radius: 5px;
  border: 1px solid #bbb;
  cursor: pointer;
  margin: 0 0 20px 0;
  position: relative;

  background-color: #ffffff;
  &.is-selected {
    background-color: #dcefe2;
    border-color: ${primary};
  }
`;

const Name = styled.h3`

`;

const ProductText = styled.p`
  color: ${primary};
  font-size: 0.9rem;
  margin-left: 5px;
  max-width: 300px;

  span {
    font-weight: 600;
  }
`;

const TotalPrice = styled.span`
  margin-right: 15px;
  font-size: 1.75rem;
  color: ${gray};
`;

interface PendingOrdersListProps {
    pendingOrders: any,
    onClickHandler: CallableFunction,
    selectedOrder: any
}

function PendingOrdersList({pendingOrders, onClickHandler, selectedOrder}: PendingOrdersListProps){

    const pydateParse = (pydate: string) => {
        return pydate.split("T")[0]
    }

    const selectPendingOrder = (order: any) => {
        onClickHandler(order)
    }

    const renderPendingOrder = (order:any) => {
        const isSelected = (order: any) => {
            if(selectedOrder === null){
                return false
            }
            return order.name === selectedOrder.name
        }
        return (
            <OrderWrapper 
            key={order.pk}
            onClick={() => selectPendingOrder(order)}
            className={isSelected(order) ? 'is-selected' : ''}>
                <Name>Order {order.name}</Name>
                <h4>{pydateParse(order.shopify_created_at)}</h4>
                <ProductText>Purchased Products : {order.purchased_products}</ProductText>
                <TotalPrice>${order.total_price}</TotalPrice><span> on {order.payment_method}</span>
            </OrderWrapper>
        )
    }

    return (
        <Container className='order'>
            {pendingOrders.map(renderPendingOrder)}
        </Container>
    )
}

export default PendingOrdersList