import React from 'react'

const BetaCarotene = () => {
  return (<div>
    <h5>Beta Carotene Rich Foods</h5>
    <p>
      Please note that your genetic SNP testing with Ingeneous is NOT able to tell you if you have an allergy or intolerance to any of these beta carotene rich foods. If you know that you have an allergy or intolerance to a suggested food on this list, please choose an alternative. On the bright side, as your inflammation coordination improves as you follow your Essential Start programme you may find that existing sensitivities may no longer trigger symptoms in the future!
    </p>
    <p>
      This is a particularly powerful recommendation for boosting your body’s ability to make its own antioxidants, which are exponentially more powerful than any antioxidants that come from outside your body.
    </p>
    <ul>
      <li>Kumara, baked ½ cup = 8,631 mcg</li>
      <li>Carrot, cooked ½ cup = 6,249 mcg</li>
      <li>Spinach, cooked ½ cup = 7,074 mcg</li>
      <li>Butternut squash, cooked ½ cup = 4,684 mcg</li>
      <li>Kale, raw chopped 1 cup = 3,953 mcg</li>
      <li>Collard greens, cooked ½ cup = 3,649 mcg</li>
      <li>Romaine lettuce, chopped or shredded 1 cup = 2,874 mcg</li>
      <li>Rock melon, chopped ½ cup = 1,575</li>
    </ul>
  </div>)
}

export default BetaCarotene
