import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import Icon from 'components/Icon';
import { Spacer } from 'components/utils';
import { white, borderGray, gray } from 'styles/theme';

const Wrapper = styled.div`
  background-color: ${white};
  padding: ${isMobile ? '10px' : '30px'};
  padding-bottom: 0;
  border-radius: 5px;
  border: 1px solid ${borderGray};
  color: ${gray};
  position: relative;

  h4 {
    color: ${gray};
  }

  p {
    font-size: 1rem;
    margin: 0;
  }
`;

const Image = styled.img`
  margin: 0 auto;
`;

const Support = () => {
  return (
    <div>
      <h3>Support</h3>
      {/* <Wrapper>
      <Row>
        <Col md={8}>
          <h4>Find a Local Practitioner</h4>
          <p>
            Need help or guidance with your results and recommendations?<br />
            Reach out to one of our trained and certified practitioners for advanced assistance.
          </p>
          <Spacer height={'20px'} />
          <Link to='/dashboard'><Button>Find</Button></Link>
        </Col>
        <Col md={4}>
          <Image src={process.env.PUBLIC_URL + '/assets/images/Practitioner-icon-92.png'} />
        </Col>
      </Row>
    </Wrapper>

    <Spacer height={'20px'} /> */}

      <Wrapper>
        <Row>
          <Col md={8}>
            <h4>Find Supplements</h4>
            <p>
              Find local suppliers who stock your recommended supplements.
              <br />
              We recommend heading to your trusted Ingeneous Certified Pharmacy
              for all of your personalised supplement needs.
            </p>
            <Spacer height={'20px'} />
            <a
              href="https://myingeneous.com/supplement-retailers/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button>
                Find&nbsp;
                <Icon name="external-link-alt" />
              </Button>
            </a>
          </Col>
          <Col md={4}>
            <Image
              src={
                process.env.PUBLIC_URL + '/assets/images/Support-Supplement.png'
              }
            />
          </Col>
        </Row>
      </Wrapper>
    </div>
  );
};

export default Support;
