import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';

import { error, gray } from 'styles/theme';

const Wrapper = styled.div`
  margin: 20px 0;
`;

const ImageWrapper = styled.div`
  text-align: center;
  img {
    max-height: 200px;
    max-width: 100%;
  }
`;

const Name = styled.span`
  display: block;
  font-size: 1.6rem;
  color: ${gray};
`;

const Description = styled.p`
  font-size: 0.8rem;
  color: ${gray};
`;

const PriceHolder = styled.div`
  margin: 15px 0;
  span {
    display: inline-block;
  }
`;

const Price = styled.span`
  margin-right: 15px;
  font-size: 1.75rem;
  color: ${gray};
`;

const ComparePrice = styled.span`
  font-size: 1.25rem;
  text-decoration: line-through;
  color: ${error};
`;

const PriceNote = styled.div`
  font-size: 0.9rem;
  color: ${gray};
`;

const ProductDetails = ({ product }) => {
  let history = useHistory();
  const regex = /(<([^>]+)>)/gi;
  const variant = product ? product.variants[0] : null;

  const handleClick = () => {
    localStorage.setItem('product', product.id);
    localStorage.setItem('productName', product.title);
    history.push('/order-details');
  };

  return (
    <Wrapper>
      {!product ? (
        <p>No package selected</p>
      ) : (
        <Row>
          {product.image && (
            <Col xs={3} md={3}>
              <ImageWrapper>
                <img src={product.image.src} alt="Package icon" />
              </ImageWrapper>
            </Col>
          )}
          <Col xs={product.image ? 9 : 12} md={product.image ? 9 : 12}>
            <Name>{product.title}</Name>
            <Description>{product.body_html.replace(regex, '')}</Description>

            {variant && (
              <PriceHolder>
                <Price>${variant.price}</Price>
                {variant.compare_at_price && (
                  <ComparePrice>${variant.compare_at_price}</ComparePrice>
                )}
                <PriceNote>Introductory Offer</PriceNote>
              </PriceHolder>
            )}

            <Button variant="primary" onClick={handleClick}>
              Start
            </Button>
          </Col>
        </Row>
      )}
    </Wrapper>
  );
};

export default ProductDetails;
