import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import Icon from 'components/Icon';
import {
  TR,
  Collapse,
  CollapseBtn,
  DataWrapper,
  Score,
  getColor,
} from './TableRow';

import CircleDot from './Circle';

const MTRMTRR = ({ geneData }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [genes, setGenes] = useState();

  useEffect(() => {
    setGenes({
      rs1805087: geneData?.find((e) => 'rs1805087' === e.snp),
      rs1801394: geneData?.find((e) => 'rs1801394' === e.snp),
    });
  }, [geneData]);

  if (!genes) {
    return null;
  }

  return (
    <>
      <TR>
        <Col md={11}>
          <Row>
            <Col md={2}>MTR</Col>
            <Col md={1}>
              <CircleDot
                color={getColor(genes?.rs1805087?.score)}
                tooltipColor={genes?.rs1805087?.score}
              />
            </Col>
            <Col md={2}>{genes?.rs1805087?.snp?.toUpperCase()}</Col>
            <Col md={7}>1000mcg methylcobalamin daily in the am</Col>
          </Row>
          <Row>
            <Col md={2}>MTRR</Col>
            <Col md={1}>
              <CircleDot
                color={getColor(genes?.rs1801394?.score)}
                tooltipColor={genes?.rs1801394?.score}
              />
            </Col>
            <Col md={2}>{genes?.rs1801394?.snp?.toUpperCase()}</Col>
            <Col md={7}>&nbsp;</Col>
          </Row>
        </Col>
        <Col md={1}>
          <CollapseBtn
            variant="secondary"
            onClick={() => setCollapsed(!collapsed)}
          >
            <Icon
              name={collapsed ? 'caret-down' : 'caret-up'}
              color="#dedede"
            />
          </CollapseBtn>
        </Col>
      </TR>

      <Collapse
        style={{ display: collapsed ? 'none' : 'block' }}
        className="print-only"
      >
        <DataWrapper>
          <h4 style={{ color: '#707070' }}>MTR & MTRR – Methylcobalamin</h4>
          <p>
            <Score color={getColor(2)}>Red result for 1 or 2 RS numbers</Score>
            &nbsp;<strong>= 1000mcg methylcobalamin daily in the am</strong>
          </p>
          <p>
            This supplement will compensate for the customers underactive
            methylation system.{' '}
          </p>
        </DataWrapper>
      </Collapse>
    </>
  );
};

export default MTRMTRR;
