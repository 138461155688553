import React, { useEffect, useState, useRef } from 'react';
import { Form, Col } from 'react-bootstrap';
import Select from 'react-select';
import styled from 'styled-components';

import { getProducts } from 'api/core';

const Wrapper = styled.div`
  padding: 20px 0;
`;

const DISCOUNT_TYPES = [
  { label: 'New Price', value: 'price' },
  { label: 'Amount Off', value: 'amount' },
  { label: 'Percent Off', value: 'percent' },
];

const Discounts = ({ formProps }) => {
  const componentIsMounted = useRef(true);
  const { register, setValue, errors, clearErrors } = formProps;
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState();

  useEffect(() => {
    setLoading(true);
    getProducts().then((res) => {
      if (!componentIsMounted.current) return;
      setProducts(res.data.products);
      setLoading(false);
    });

    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  return (
    <Wrapper>
      <h5>Apply Discounts</h5>
      {loading && <span>Loading products...</span>}
      {products?.map((product) => {
        return (
          <Form.Row key={product.id}>
            <Form.Group as={Col} controlId="discount.product">
              <Form.Label>Product</Form.Label>
              <Form.Control
                name={`discount.product-${product.id}`}
                type="text"
                value={
                  product.title + ' ($' + product.variants?.[0]?.price + ')'
                }
                readOnly
              />
            </Form.Group>
            <Form.Group
              as={Col}
              controlId={`discount.discount_type-${product.id}`}
            >
              <Form.Label>Type</Form.Label>
              <input
                type="hidden"
                name={`discount.discount_type-${product.id}`}
                ref={register}
              />
              <Select
                options={DISCOUNT_TYPES}
                defaultValue={{ label: 'New Price', value: 'price' }}
                onChange={(data) => {
                  setValue(`discount.discount_type-${product.id}`, data?.value);
                  if (data?.value) {
                    clearErrors([`discount.discount_type-${product.id}`]);
                  }
                }}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    borderColor:
                      errors?.discount?.discount_type?.type === 'required'
                        ? 'red'
                        : '#ced4da',
                  }),
                }}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="discount.amount">
              <Form.Label>Value</Form.Label>
              <Form.Control
                name={`discount.amount-${product.id}`}
                type="number"
                ref={register()}
                isInvalid={!!errors?.discount?.amount}
              />
            </Form.Group>
          </Form.Row>
        );
      })}
    </Wrapper>
  );
};

export default Discounts;
