import React, { useContext, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Element, scroller } from 'react-scroll';

import { AuthContext } from 'stores/auth';
import { Spacer } from 'components/utils';
import { borderGray } from 'styles/theme';

import COMT from './COMT';
import Coffee from './Coffee';
import CaffeinatedTea from './CaffeinatedTea';
import GreenTea from './GreenTea';
import Decaf from './Decaf';
import CafeineFree from './CafeineFree';

import AlcoholConsumption from './AlcoholConsumption';
import CarbohydrateTolerance from './CarbohydrateTolerance';
import Tips from './Tips';
import FatIntake from './FatIntake';
import Inflammation from './Inflammation';
import DietType from './DietType';
import NutritionChart from './NutritionChart';
import Exercise from './Exercise';

const Wrapper = styled.div`
  background-color: #ffffff;
  min-height: 200px;
  border-radius: 5px;
  border: 1px solid ${borderGray};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

  @media print {
    border: none;
    page-break-before: always;
    margin-top: 50px;
  }
`;

const BodyCol = styled(Col)`
  padding: ${isMobile ? '0 10px' : '0 15px'};
`;

const Pad = styled.div`
  padding: ${(props) =>
    props.pad ? props.pad : isMobile ? '20px 10px' : '30px'};
`;

const Results = ({ results }) => {
  const authStore = useContext(AuthContext);

  useEffect(() => {
    if (window.location.hash) {
      scroller.scrollTo(window.location.hash.replace('#', ''), {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset: 0,
      });
    }
  }, [results]);

  return (
    <Wrapper>
      <Row>
        <BodyCol>
          <Pad>
            <h2>{authStore.user.first_name}'s Results</h2>
            <Spacer height={'30px'} />
            <Element name="what-to-drink" className="drink">
              <h3>What To Drink</h3>
              <Spacer height={'30px'} />
              <Coffee serving={results.coffee} />
              <Spacer height={'30px'} />
              {results.coffee +
                results.caffeinated_tea +
                results.green_tea +
                results.decaf_beverages ===
                0 && (
                <>
                  <COMT />
                  <Spacer height={'30px'} />
                </>
              )}
              <div className="page-br">
                <CaffeinatedTea serving={results.caffeinated_tea} />
              </div>
              <Spacer height={'30px'} />
              <GreenTea serving={results.green_tea} />
              <Spacer height={'30px'} />
              <Decaf serving={results.decaf_beverages} />
              <Spacer height={'30px'} />
              <CafeineFree />
              <Spacer height={'50px'} />
              <AlcoholConsumption
                alcohol_quantity={results.alcohol_quantity}
                alcohol_warning={results.alcohol_warning}
                alcohol_reduce_inflam={results.alcohol_reduce_inflam}
                alcohol_a2a={results.alcohol_a2a}
                alcohol_e2a={results.alcohol_e2a}
                gender={results.gender}
              />
            </Element>

            <Element name="what-to-eat" className="eat">
              <Spacer height={'50px'} />
              <CarbohydrateTolerance
                aml_count={results.aml_count}
                gender={results.gender}
              />

              <Spacer height={'30px'} />
              <Tips />

              <Spacer height={'30px'} />
              <FatIntake fats={results.fat} />

              <Spacer height={'30px'} />
              <Inflammation />

              <Spacer height={'30px'} />
              <DietType diet_type={results.diet_type} />

              <Spacer height={'30px'} />
              <NutritionChart results={results} />
            </Element>

            <Element name="how-to-exercise" className="exercise">
              <Spacer height={'50px'} />
              <Exercise results={results} />
            </Element>
          </Pad>
        </BodyCol>
      </Row>
    </Wrapper>
  );
};

export default Results;
