import React from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import Header from './components/Header'
import Footer from './components/Footer'

import { footerHeight } from 'styles/theme'

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
`

const Container = styled.div`
  padding-bottom: ${footerHeight};    /* Footer height */
  position: relative;
`

function MainWrapper ({ children }) {
  const location = useLocation();
  return (
    <Wrapper>
      <Container>
        {!location.pathname.includes('event-signup') && <Header />}
        {children}
      </Container>
      {!location.pathname.includes('event-signup') && <Footer />}
    </Wrapper>
  )
}

export default MainWrapper
