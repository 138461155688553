import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  min-height: 100%;
  width: 100%;

  > .row {
    margin-left: 0;
    margin-right: 0;
  }
`;

function BlankWrapper({ children }) {
  return (
    <>
      <Wrapper className="wrapper">{children}</Wrapper>
    </>
  );
}

export default BlankWrapper;
