import React from 'react';

import RedWine from './RedWine';
import Greentea from './Greentea';
import Spicy from './Spicy';
import Seaweed from './Seaweed';
import LowCarb from './LowCarb';
import VitaminA from './VitaminA';
import styled from 'styled-components';

interface SuperfoodProps {
  results: any;
}
const WrapperResult = styled.div`
  position: relative;
`;
const Superfood = ({ results }: SuperfoodProps) => {
  let SuperfoodType;
  if (results.food === 'green tea') {
    SuperfoodType = <Greentea results={results.food} />;
  } else if (results.food === '2 glasses red wine') {
    SuperfoodType = <RedWine results={results.food} />;
  } else if (results.food === 'seaweed') {
    SuperfoodType = <Seaweed results={results.food} />;
  } else if (results.food === 'spicy food') {
    SuperfoodType = <Spicy results={results.food} />;
  } else if (results.food === 'vitamin A rich foods') {
    SuperfoodType = <VitaminA results={results.food} />;
  } else {
    SuperfoodType = <LowCarb results={results.food} />;
  }
  return (
    <>
      <WrapperResult className="superfood">
        <h3>Superfood</h3>
        {SuperfoodType}

        <hr className="no-print" />
      </WrapperResult>
    </>
  );
};

export default Superfood;
