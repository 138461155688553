import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { useObserver } from 'mobx-react-lite';

import { AuthContext } from 'stores/auth';
import { Spacer } from 'components/utils';
import { Page, PageHeader, PageTitle, PageBody } from 'components/Page';

import PendingOrdersList from './pendingOrdersList';
import UpdatePaymentForm from './updatePaymentForm';

import { getUserPendingOrders } from 'api/users';

const Wrapper = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
  align-items: center;

  .title {
    flex: 1;
    display: ${isMobile ? 'block' : 'flex'};
    align-items: center;
    justify-content: space-between;

    h1 {
      margin: 0;
      margin-right: 20px;
    }
  }
`;

const PendingOrderLoader = styled.h2`
  text-align: center
`;

function PendingOrders(){
  const authStore = useContext(AuthContext);
  const [pendingOrders, setPendingOrders] = useState({
      data:[],
      loaded:false
  })
  const [selectedOrder, setSelectedOrder] = useState(null)

  const totalPrice = () => {
    if(selectedOrder === null){
      return 0;
    }
    return selectedOrder['total_price'];
  }

  const pendingOrdersOrNo = () => {
    if(pendingOrders.data.length === 0){
      return <PendingOrderLoader>You have no pending orders!</PendingOrderLoader>
    }
    return (
      <PendingOrdersList
          pendingOrders={pendingOrders.data}
          onClickHandler={(order: any) => setSelectedOrder(order)}
          selectedOrder={selectedOrder}
        />
    )
  }

  useEffect(() => {
    if(pendingOrders.loaded === false){
        getUserPendingOrders().then((pendingOrdersData:any) => {
            setPendingOrders({
                data:pendingOrdersData.data.results,
                loaded: true
            })
        })
    }
}, [pendingOrders])

  return useObserver(() => {
    const billingAddresses = authStore.addresses
      ? authStore.addresses.filter(
          (item: { address_type: string }) => item.address_type === 'billing'
        )
      : [];
    const billingAddress = billingAddresses[0];
    const shippingAddresses = authStore.addresses
      ? authStore.addresses.filter(
          (item: { address_type: string }) => item.address_type === 'delivery'
        )
      : [];
    const shippingAddress = shippingAddresses[0];

    let defaultValues = {
      delivery: { ...shippingAddress },
      billing: null,
      first_name: null,
      last_name: null,
      phone_number: null,
    };

    if (billingAddress) {
      defaultValues.billing = { ...billingAddress };
    } else if (!billingAddress && shippingAddress) {
      defaultValues.billing = { ...shippingAddress };
    }

    defaultValues.first_name = authStore?.user?.first_name;
    defaultValues.last_name = authStore?.user?.last_name;
    defaultValues.phone_number = authStore?.user?.phone_number;
    return (
      <Page>
        <PageHeader>
          <Spacer height={'30px'} />
          <Container>
            <Wrapper>
            <div className="title">
              <PageTitle>Pending Orders</PageTitle>
            </div>
            </Wrapper>
          </Container>
        </PageHeader>
        <Spacer height={'30px'} />
        <PageBody>
          {pendingOrders.loaded === false ? <PendingOrderLoader>Loading your pending orders. . .</PendingOrderLoader> : pendingOrdersOrNo()}
          <Spacer height={'30px'} />
          {pendingOrders.data.length === 0 ? '': <UpdatePaymentForm
            order={selectedOrder}
            defaultValues={defaultValues}
          />}
        </PageBody>
      </Page>
    )
  })
}

export default PendingOrders