import React, { useContext } from 'react';
import { Row } from 'react-bootstrap';

import { AuthContext } from 'stores/auth';
import { Spacer } from 'components/utils';
import { getColor, Serving, Image, BodyCol } from './utils';

const DietType = ({ diet_type }) => {
  const authStore = useContext(AuthContext);
  const diet = diet_type.toLowerCase();

  return (
    <Row>
      <BodyCol xs={12}>
        <h4>Recommended Diet Type</h4>
        <p>
          Your best way to eat to reduce
          inflammation is:
        </p>
      </BodyCol>
      <BodyCol xs={12} md={8}>
        {diet === 'meditarranean' && (
          <>
            <Serving color={getColor(2)}>The Mediterranean diet</Serving>
            <Spacer height={'20px'} />
            <p>
              Based on your genetic profile, you fall into the Mediterranean
              diet type. This style of eating f includes starting with a solid
              foundation of of 5 servings a day of vegetables and fruits.
              Variety is your best friend for this diet so try to eat a rainbow
              every day. This diet is high in plant-based proteins, olive oil,
              leafy greens, fatty fish and whole grains, with some pasture
              raised poultry, goat and sheep dairy and grass-fed beef to be
              enjoyed occasionally (lean cuts in small portions). You can
              incorporate nuts, seeds and olives. The butter coffee, Paleo style
              diets are not for you.
            </p>
            <p>
              Of most note, this diet style is very specific about the types and
              amounts of proteins you should be eating. Here is the best way to
              populate your protein, fat and carbohydrate allowance:
            </p>
            <ul>
              <li>
                Over a week you should have 3 servings of seafood/fish plus 2
                servings of poultry, and 1 serving of red meat.
              </li>
              <li>You can have dairy and eggs daily.</li>
              <li>
                Incorporate your starchy carbohydrate allowance, which can be
                followed from the section above titled “Carbohydrate Tolerance.”
              </li>
              <li>
                Please add the correct amount and type of fats according to your
                genotype to finish off your ideal eating style.
              </li>
            </ul>
          </>
        )}

        {diet === 'high protein' && (
          <>
            <Serving color={getColor(2)}>A High Protein diet</Serving>
            <Spacer height={'20px'} />
            <p>
              Based on your genetic profile, you fall into the High Protein diet
              type. We recommend starting your high protein style of eating with
              a solid foundation of 5 servings a day of vegetables and fruits.
              <br />
              <br />
              It is easy to make the leap to eating a Paleo or a Ketogenic style
              diet when you get this recommendation, however at this point in
              your journey we do not recommend you limit your carbohydrates too
              extremely. Although you do want to favour protein, and whilst you
              suit all types of protein it is very important to aim to get your
              minimum amount of starchy carbohydrates each day as otherwise it
              could trigger inflammation.
              <br />
              <br />
              To stay on target, identifying starchy carbohydrate fruits and
              vegetables that actually count as a carbohydrate and not a
              vegetable is important. Please follow the specific guidance in the
              section above titled “Carbohydrate Tolerance..”
              <br />
              <br />
              <p className="page-br">
                Round off your high protein diet by incorporating the correct
                amount and type of fats according to your genotype. Keep in mind
                that many proteins already contain fat, so you might not need to
                top it off too much. If you are on a low-fat recommendation opt
                for low fat proteins such as lean cuts of poultry and seafood as
                opposed to cheese and pork for example.
              </p>
            </p>

            <h6>Action plan in summary:</h6>
            <p>
              Make sure every meal has a good serving of protein at the core.
              Limit carbohydrates for breakfast and dinner. Incorporate your
              daily carbohydrate allowance into your evening meal and aim to
              consume the ideal amount each day, like a target you want to hit.
              No more, no less.
            </p>
          </>
        )}

        {diet === 'balanced' && (
          <>
            <Serving color={getColor(2)}>Balanced Style Diet</Serving>
            <Spacer height={'20px'} />
            <p>
              Based on your genetic profile, you fall into the Balanced Style
              diet type. We recommend starting your high protein style of eating
              with a solid foundation of 5 servings a day of vegetables and
              fruits.
              <br />
              <br />
              Balance is the name of the game for this diet so try to eat
              balance of lots of types of fruits and vegetables every day. Most
              notably in the balanced style diet your carbohydrate amounts and
              your protein allowance are balanced.
              <br />
              <br />
              Follow the starchy carbohydrate allowance outlined in the section
              above titled “Carbohydrate Tolerance.”, and you are very lucky as
              can choose from any type of meat/protein you like to satisfy your
              protein needs. Keep in mind you get an abundance of vitamins and
              minerals from meat itself that may not be found in non-meat
              alternatives. Add the correct amount and type of fats according to
              your genotype to finish off your ideal eating style.
            </p>

            <h6>Action plan in summary:</h6>
            <p>
              Think of your plate like your pie chart, try to get a balanced
              amount of protein, carbohydrates, fat and vegetables/fruits in
              every meal or every smoothie. Variety, balance and eating 3 meals
              a day are your key points.
            </p>
          </>
        )}

        {diet === 'vegetarian' && (
          <>
            <Serving color={getColor(2)}>
              Lacto-ovo Vegetarian + occasional fish
            </Serving>
            <Spacer height={'20px'} />
            <p>
              Based on your genetic profile, you fall into our Lacto-ovo
              Vegetarian diet type. Your genetic variations support a primarily
              plant-based diet, supported with dairy products and eggs.
              <br />
              <br />
              You should aim for a solid foundation of at least 5 servings a day
              of vegetables and fruits. Having a high vegetable and fruit diet
              is highly important for your genotype. The challenge for a mainly
              vegetarian diet is to receive enough protein and to not exceed
              your recommended amount of daily starchy carbohydrates (under the
              above section titled ‘How many carbohydrates’).
              <br />
              <br />
              It is crucial that you work hard to have a variety or fruits,
              vegetables and grains in your diet as there are vitamins and
              minerals that would otherwise be found in meats. From a genetic
              standpoint only, veganism is not seen as beneficial for your body
              for these reasons.
              <br />
              <br />
              Including dairy products and eggs will increase essential vitamins
              and minerals into your diet, particularly vitamin B.
              <br />
              <br />
              Eating fish and seafood occasionally if you are open to it would
              benefit you genetically.
              <br />
              <br />
              Add the correct amount and type of fats according to your genotype
              to finish off your ideal eating style, please see the ‘Fat Intake’
              section within this report.
            </p>

            <h6>Action plan in summary:</h6>
            <p>
              Eat a rainbow of fruit and vegetables every day. Incorporate high
              protein plant-based foods and eat plenty of eggs and dairy on a
              daily basis. You will need to monitor your internal levels of
              vitamin B from time to time.
            </p>

            <h6>Vegan Diet</h6>
            <p>
              Your results have shown us that your genetic variations do not
              support a vegan diet. If you are committed to a vegan diet it is
              important that you get enough Vitamin B through supplementation.
              We recommend a consultation to look at your methylation system.
              This will help you identify which of the 4 types of vitamin B12
              supplements are appropriate for your genetics. An Ingeneous
              practitioner can be contacted once without charge in this instance
              if you don’t have a membership, please call our phone line on 0800
              464 363.{' '}
            </p>
          </>
        )}
      </BodyCol>
      <BodyCol xs={12} md={4}>
        <Image
          src={process.env.PUBLIC_URL + '/assets/images/lifeplus/ing-diet.png'}
        />
      </BodyCol>
    </Row>
  );
};

export default DietType;
