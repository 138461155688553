import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useObserver } from 'mobx-react-lite'
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

import { AuthContext } from 'stores/auth'
import { primary } from 'styles/theme'

const NavWrapper = styled.div`
  background-color: ${props => props.userLoggedIn ? primary : '#ffffff'};

  img {
    height: ${props => props.userLoggedIn ? '30px' : '50px'};
  }
`

const CustomNavbar = styled(Navbar)`
  padding: 15px 0;
`

const CustomContainer = styled(Container)`
  display: block !important;
`

const Wrapper = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
  align-items: center;
  text-align: ${isMobile ? 'center' : 'left'};
  width: ${isMobile ? '100%' : ''};
`

const CustomCollapse = styled(Navbar.Collapse)`
  justify-content: ${props => props.align === 'right' ? 'flex-end' : 'flex-start'};
  display: ${isMobile ? 'block' : 'flex'};
`

const CustomNav = styled(Nav)`
  display: ${isMobile ? 'block' : 'flex'};

  span {
    color: #ffffff;
    text-transform: uppercase;
  }
`

const Text = styled(Link)`
  display: inline-block;
  margin-right: 20px;
  color: #ffffff !important;
  font-size: 0.8rem;
`

const SpanText = styled.span`
  display: inline-block;
  margin-right: 20px;
  color: #ffffff !important;
  font-size: 0.8rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const ButtonToolbar = styled.div`
  .btn {
    margin-left: 10px;
  }
`

const Header = () => {
  const authStore = useContext(AuthContext);
  let history = useHistory();

  const brandLogo = authStore.loggedIn ? 'ingeneous_white_r' : 'ingeneous_green';

  const handleConsult = () => {
    localStorage.removeItem('consultKitId');
    history.push('/consult');
  }

  return useObserver(() => {
    return (<NavWrapper userLoggedIn={authStore.loggedIn}>
      <CustomNavbar>
        <CustomContainer>
          <Row>
            <Col xs={12} sm={3}>
              <Wrapper className={isMobile ? 'mb-1': ''}>
                <Link to='/'>
                  <Navbar.Brand><img src={process.env.PUBLIC_URL + '/assets/images/' + brandLogo + '.png'} alt='' /></Navbar.Brand>
                </Link>
              </Wrapper>
              <Navbar.Toggle aria-controls="header-nav" />
            </Col>
            <Col xs={12} sm={9}>
              {authStore.loggedIn && authStore.user !== null
                ? (<CustomCollapse id="header-nav"><Wrapper>
                  <CustomNav>
                    <Navbar.Text>
                      <Text to='/dashboard'>Dashboard</Text>
                    </Navbar.Text>
                    {(authStore.user.scope === 'staff' || authStore.user.scope === 'admin') &&
                      <Navbar.Text>
                        <Text to='/event-signup'>Event Sign-up</Text>
                      </Navbar.Text>
                    }
                    {(authStore.user.scope === 'practitioner' || authStore.user.scope === 'admin') &&
                      <Navbar.Text>
                        <Text to='#' onClick={() => handleConsult()}>Consult</Text>
                      </Navbar.Text>
                    }
                    {(authStore.user.scope === 'admin') &&
                      <Navbar.Text>
                        <Text to='/admin'>Admin</Text>
                      </Navbar.Text>
                    }
                    <Navbar.Text>
                      <SpanText onClick={() => authStore.logout()}>Logout</SpanText>
                    </Navbar.Text>
                  </CustomNav>
                </Wrapper></CustomCollapse>)
                : (<CustomCollapse id="header-nav" align="right"><Wrapper>
                  <CustomNav>
                    <ButtonToolbar>
                      <Link to={'/register'} className='btn btn-secondary'>Create Account</Link>
                      <Link to={'/login'} className='btn btn-secondary'>Login</Link>
                    </ButtonToolbar>
                  </CustomNav>
                </Wrapper></CustomCollapse>)
              }
            </Col>
          </Row>
        </CustomContainer>
      </CustomNavbar>
    </NavWrapper>)
  })
}

export default Header
