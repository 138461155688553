import React from 'react';

import Ratio0 from './Ratio0';
import Ratio10 from './Ratio10';
import Ratio15 from './Ratio15';
import Ratio30 from './Ratio30';
import Ratio40 from './Ratio40';
import Ratio50 from './Ratio50';
import Ratio60 from './Ratio60';
import Ratio65 from './Ratio65';
import Ratio80 from './Ratio80';
import Ratio100 from './Ratio100';

import Ketogenic from '../Diet/Ketogenic';
import LowCarb from '../Diet/LowCarb';
import VeryLowCal from '../Diet/VeryLowCalorie';
import LowCal from '../Diet/LowCalorie';
import ObesityRisk from '../Diet/ObesityRisk';

import Cardio from '../Exercise/Cardio';
import Resistance from '../Exercise/Resistance';
import IntenseCardio from '../Exercise/IntenseCardio';
import Irrelevant from '../Exercise/Irrelevant';

import { Spacer } from 'components/utils';

interface DietProps {
  results: any;
}

const Diet = ({ results }: DietProps) => {
  let dietPercentage;
  if (results.diet_percentage === 0) {
    dietPercentage = <Ratio0 results={results.diet_percentage} />;
  } else if (results.diet_percentage === 10) {
    dietPercentage = <Ratio10 results={results.diet_percentage} />
  } else if (results.diet_percentage === 15) {
    dietPercentage = <Ratio15 results={results.diet_percentage} />
  } else if (results.diet_percentage === 30) {
    dietPercentage = <Ratio30 results={results.diet_percentage} />
  } else if (results.diet_percentage === 40) {
    dietPercentage = <Ratio40 results={results.diet_percentage} />
  } else if (results.diet_percentage === 50) {
    dietPercentage = <Ratio50 results={results.diet_percentage} />
  } else if (results.diet_percentage === 60) {
    dietPercentage = <Ratio60 results={results.diet_percentage} />
  } else if (results.diet_percentage === 65) {
    dietPercentage = <Ratio65 results={results.diet_percentage} />
  } else if (results.diet_percentage === 80) {
    dietPercentage = <Ratio80 results={results.diet_percentage} />
  } else {
    dietPercentage = <Ratio100 results={results.diet_percentage} />
  }

  let dietType;
  if (results.diet_type === 'lower carbs') {
    dietType = <LowCarb results={results.diet_type}/>
  } else if (results.diet_type === 'obesity risk') {
    dietType = <ObesityRisk results={results.diet_type}/>
  } else if (results.diet_type === 'very low calories') {
    dietType = <VeryLowCal results={results.diet_type}/>
  } else if (results.diet_type === 'keto') {
    dietType = <Ketogenic results={results.diet_type}/>
  } else  {
    dietType = <LowCal results={results.diet_type}/>
  } 

  let exerciseType;
  if (results.exercise_type === 'irrelevant') {
    exerciseType = <Irrelevant results={results.exercise_type}/>
  } else if (results.exercise_type === 'intense cardio') {
    exerciseType = <IntenseCardio results={results.exercise_type}/>
  } else if (results.exercise_type === 'resistance') {
    exerciseType = <Resistance results={results.exercise_type}/>
  } else {
    exerciseType = <Cardio results={results.exercise_type}/>
  }

  return (
    <>
      <div className="diet">
        <h3>Where you get the most benefits from.</h3>
        <p style={{ marginBottom: 0 }}>
          Some people's bodies react best to dieting, while others react best to
          exercise.
        </p>
        <p>
          You'll need to put effort in where your body needs it most. This is
          where you'll get the best gains.
        </p>
        {dietPercentage}

        <Spacer height={'30px'} />
        {dietType}

        <Spacer height={'30px'} />
        {exerciseType}

        <Spacer height={'40px'} />

        <hr className='no-print' />
      </div>
    </>
  );
};

export default Diet;
