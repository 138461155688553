import React, { useState } from 'react';
import styled from 'styled-components';
import { gray } from 'styles/theme';

import ReadMore from '../View';
import ReadLess from '../ViewLess';

import Strong from './Strong';
import Moderate from './Moderate';
import Poor from './Poor';

interface ImpulseControlProps {
  results: any;
}

const Alert = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const WrapperRef = styled.div`
  margin-top: 20px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const List = styled.li`
  padding-left: 15px;
  font-weight: 300;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    font-size: 14px;
    word-break: break-all;
  }
`;

const SubtitleGray = styled.h3`
  color: ${gray};
  margin-top: 10px;
`;

const WrapperResult = styled.div`
  position: relative;
`;

const ImpulseControl = ({ results }: ImpulseControlProps) => {
  const [showReferences, setShowReferences] = useState(false);

  let impulseType;
  if (results.impulse === 'very impulsive') {
    impulseType = <Poor results={results.impulse} />;
  } else if (results.impulse === 'very considered') {
    impulseType = <Strong results={results.impulse} />;
  } else {
    impulseType = <Moderate results={results.impulse} />;
  }

  return (
    <WrapperResult>
      <SubtitleGray>Impulse Control</SubtitleGray>
      {impulseType}

      <Alert className="no-print">
        <span>References</span>
        {!showReferences && (
          <ReadMore value={showReferences} setValue={setShowReferences} />
        )}
        {showReferences && (
          <ReadLess value={showReferences} setValue={setShowReferences} />
        )}
        {showReferences && (
          <WrapperRef>
            <ListGroup>
              <List>
                <a href="https://www.nature.com/articles/tp201495#:~:text=Dopaminergic%20system%20has%20been%20">
                  https://www.nature.com/articles/tp201495#:~:text=Dopaminergic%20system%20has%20been%20
                  associated,has%20role%20in%20brain%20maturation.
                </a>
              </List>
            </ListGroup>
          </WrapperRef>
        )}
      </Alert>
      <hr className="no-print" />
    </WrapperResult>
  );
};

export default ImpulseControl;
