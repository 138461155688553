import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Spacer } from 'components/utils';
import ReadMore from '../../ReadMore';
import ReadLess from '../../ReadLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  position: ${isMobile ? 'static' : 'absolute'};
  right: 0;
  transform: ${isMobile ? 'translateX(0)' : 'translateX(-10%)'};
  margin-top: ${isMobile ? '10px' : '-10px'};
  text-align: ${isMobile ? 'center' : 'right'};
  // display: ${isMobile ? 'flex' : 'inherit'};
  // justify-content: ${isMobile ? 'center' : 'unset'};
  img {
    max-width: 60%;
  }
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

interface IntenseCardioProps {
  results: any;
}
const IntenseCardio = ({ results }: IntenseCardioProps) => {
  const [show, setShow] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row className='page-br'>
        <Col md={12}>
          <h3>Intense Cardio</h3>
          <ImageCol md={4}>
            <img
              src="/assets/images/recommendations/WeightReport-HIIT.png"
              alt="Exercise"
            />
          </ImageCol>
          <Col md={8}>
            <Alert>
              <p>
                Increase your level of intense cardio training at 70-90% of your
                maximum heart rate.
              </p>
            </Alert>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div style={{ marginTop: 30 }}>
              <p>
                Intense cardiovascular exercise is particularly powerful for
                you, also known as aerobic exercise it increases your heart rate
                to a fat burning zone. You will have to raise your heart rate
                higher than others to achieve the fat burning benefits.
              </p>
              <p>
                You can increase the energy (calories) you burn by increasing
                your level of activity with intense cardio exercise.By following
                your recommended diet and increasing intense cardiovascular
                exercise you can hope to reach your optimal weight.
              </p>
              <p>
                To most effectively lose weight you need to do intense cardio
                workouts, which involve training at 70-90% of your maximum heart
                rate. To approximate your maximum heart rate subtract your age
                from 220. 70%Vo2 MAX
              </p>
              <p>
                Aerobic exercise can come from doing activities such as;
                running, swimming, walking, dancing, biking, or tramping.
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
    </>
  );
};

export default IntenseCardio;
