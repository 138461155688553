import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import styled from 'styled-components';

import Icon from 'components/Icon';

const Wrapper = styled.div``;

const FormRowWrapper = styled.div`
  display: flex;

  .form-group {
    margin: 0;
    flex: 1;
  }

  input {
    font-size: 0.8rem;
  }
`;

const SearchBtn = styled(Button)`
  padding: 7px 15px;
  margin-left: 10px;
  font-size: 14px;
`;

const SearchForm = ({ setSearchIds }) => {
  const { register, handleSubmit } = useForm();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   let isMounted = true;
  //   if (sampleId) {
  //     setValue('sample_id', sampleId);
  //     getSampleId(sampleId).then(res => {
  //       if (isMounted) {
  //         setRecord(res.data?.sample_id);
  //         setLoading(false);
  //       }
  //     }).catch(() => {
  //       setLoading(false);
  //     });
  //   };

  //   return () => { isMounted = false };
  // }, [sampleId]);

  const onSubmit = async (data) => {
    setSearchIds(data.sampleIds);
  };

  return (
    <Wrapper>
      <Row>
        <Col xs={12}>
          <Form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Col xs={12}>
                <FormRowWrapper>
                  <Form.Group controlId="searchKitId">
                    <Form.Control
                      name="sampleIds"
                      type="text"
                      ref={register}
                      placeholder="Search Sample IDs"
                    />
                  </Form.Group>
                  <SearchBtn variant="primary" type="submit" disabled={loading}>
                    {!loading ? (
                      <Icon name="search" />
                    ) : (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </SearchBtn>
                </FormRowWrapper>
              </Col>
            </Form.Row>
          </Form>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default SearchForm;
