import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { gray, primary } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import View from '../View';
import Close from '../ViewLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const AlertRef = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
  margin-bottom: 30px;
`;

const SubtitleGray = styled.h5`
  color: ${gray};
  margin-top: 10px;
`;

const WrapperRef = styled.div`
  margin-top: 5px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const ListRef = styled.li`
  font-weight: 300;
  padding-left: 20px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    word-break: break-all;
  }
`;

interface LycheeProps {
  results: any;
}
const Lychee = ({ results }: LycheeProps) => {
  const [show, setShow] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/Supplement.png"
            alt="supplement"
            width={'60%'}
          />
        </ImageCol>
        <Col md={8}>
          <SubtitleGray>
            Your most powerful supplement for weight loss is
          </SubtitleGray>
          <Subtitle>Lychee Oligonol</Subtitle>
          <Alert>
            <p>
              Suggested Supplement: Oligonol, Quality of Life Labs (2 capsules
              daily with food).
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <p>
                Taking Lychee Oligonol can help reduce visceral (stomach) fat
                which is the most dangerous to your health. This supplement will
                be the most powerful support for you to reach your optimal
                weight.
              </p>
              <p>
                Lychee fruit polyphenol has been shown to improve lipid
                metabolism and decrease visceral fat in human studies(1). It is
                thought to do this by regulating the expression of key genes
                related to lipid metabolism, and inflammation in the liver (1).
                It can also support you in decreasing tissue inflammation,
                increasing endurance and reducing fatigue, and improving
                cardiovascular health (2). This increased endurance and energy
                may provide additional benefit to you if your optimal weight
                journey requires you to increase your level of exercise. It has
                been shown to have antioxidant effects which contribute to this
                reduction in inflammation and can help support you in reaching
                an optimal weight(3).
              </p>
              <p style={{ fontWeight: 'bold' }}>
                This supplement is best for your genes. If you have any concerns
                this may not suit your current state of health or medications
                check with your doctor.
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
      <Row className="no-print">
        <Col>
          <AlertRef>
            <span>References</span>
            {!showReferences && (
              <View value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <Close value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <WrapperRef>
                <ListGroup>
                  <ListRef>
                    Uchiyama H, Uehara K, Nagashima T, Nakata A, Sato K, Mihara
                    Y, Komatsu KI, Takanari J, Shimizu S, Wakame K. Global liver
                    gene expression analysis on a murine metabolic syndrome
                    model treated by low-molecular-weight lychee fruit
                    polyphenol (Oligonol®). Anticancer research. 2016 Jul
                    1;36(7):3705-13.&nbsp;
                    <a href="https://ar.iiarjournals.org/content/36/7/3705.short">
                      https://ar.iiarjournals.org/content/36/7/3705.short{' '}
                    </a>
                  </ListRef>
                  <ListRef>
                    <a
                      href="https://nz.iherb.com/pr/quality-of-life-labs-oligonol-100-mg-30-vegicaps/41884?gclid=Cj0KCQjw8eOLBhC1ARIsAOzx5cHAZ_
                      K1jlciIukicQODJHtRvuPgmaGLPAECmwV2rlhtU8ATi18_zKIaAuTEEALw_wcB&gclsrc=aw.ds"
                    >
                      https://nz.iherb.com/pr/quality-of-life-labs-oligonol-100-mg-30-vegicaps/41884?gclid=Cj0KCQjw8eOLBhC1ARIsAOzx5cHAZ_
                      K1jlciIukicQODJHtRvuPgmaGLPAECmwV2rlhtU8ATi18_zKIaAuTEEALw_wcB&gclsrc=aw.ds{' '}
                    </a>
                  </ListRef>
                  <ListRef>
                    Sakurai T, Nishioka H, Fujii H, Nakano N, Kizaki T, Radak Z,
                    Izawa T, Haga S, Ohno H. Antioxidative effects of a new
                    lychee fruit-derived polyphenol mixture, oligonol, converted
                    into a low-molecular form in adipocytes. Bioscience,
                    biotechnology, and biochemistry. 2008 Feb
                    23:0801080720-.&nbsp;
                  </ListRef>
                </ListGroup>
              </WrapperRef>
            )}
          </AlertRef>
        </Col>
      </Row>
    </>
  );
};

export default Lychee;
