import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { Form, Alert, Button, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

import { AuthContext } from 'stores/auth';
import { Spacer } from 'components/utils';

const Wrapper = styled.div`
  color: #ffffff;

  .btn-secondary {
    border-color: #ffffff;
  }
`;

const FormTitle = styled.h3`
  display: block;
  color: #ffffff;
`;

const FormActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LoginLink = styled(Link)`
  display: inline-block;
  margin: 0 15px;
  color: white;
`;

function LoginForm({ 
    title="Login", 
    copy="Log into your account to get access to your reports and recommendations.",
    emailSignin=true }) {
  const auth = getAuth();
  const authStore = useContext(AuthContext);
  const { register, handleSubmit, errors, setError, clearErrors, formState } =
    useForm();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  let history = useHistory();

  const onSubmit = async (data) => {
    setLoading(true);
    signInWithEmailAndPassword(auth, data['email'], data['password'])
      .then((userCredential) => {
        const user = userCredential.user;
        authStore.login(user).then(() => {
          clearErrors(['email', 'login']);
          // setLoading(false);
          if (history.location.state?.prevPath === '/event') {
            // history.push('/event');
            window.location.pathname = '/event';
          } else if(authStore.isPractitioner() || authStore.isPractitionerOrg()) {
            console.log("redirecting to consult")
            history.push('/consult')
          } else {
            history.push('/');
          }
        })
      })
      .catch((error) => {
        console.log("handling error")
        setLoading(false);
        switch (error.status) {
          case 403:
            setError('login', {
              type: 'manual',
              message:
                'Please click on the link that has been sent to your email account to verify your email.',
            });
            break;

          default:
            setError('login', {
              type: 'manual',
              message: 'Invalid email/password',
            });
            break;
        }
      });
  };

  const handleInputChange = () => {
    if (formState.isSubmitted && formState.touched) {
      clearErrors(['email', 'login']);
    }
  };

  return (
    <Wrapper>
      <FormTitle>{title}</FormTitle>
      <Spacer height={'30px'} />
      <p>
        {copy}
      </p>
      <Spacer height={'30px'} />

      {!!errors.login && <Alert variant="danger">{errors.login.message}</Alert>}

      <Form className="form" onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            name="email"
            type="email"
            ref={register({ required: true })}
            onChange={handleInputChange}
          />
          {errors.email && (
            <Form.Control.Feedback type="invalid">
              {errors.email.type === 'required' && 'This field is required'}
              {errors.email.type === 'manual' && errors.email.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>
            Password &nbsp;
            <small onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? 'Hide Password' : 'Show Password'}
            </small>
          </Form.Label>
          <Form.Control
            name="password"
            type={showPassword ? 'text' : 'password'}
            ref={register({ required: true })}
            onChange={handleInputChange}
          />
          {errors.password && (
            <Form.Control.Feedback type="invalid">
              {errors.password.type === 'required'
                ? 'This field is required'
                : 'Invalid input'}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Spacer height={'10px'} />

        <FormActions>
          <Link to="/password/forgot/">
            <Button variant="outline" type="button">
              Forgot
            </Button>
          </Link>

          <div>
            {emailSignin ? <LoginLink to="/email-login">Sign-in with Email</LoginLink> : ''}
            <Button variant="secondary" type="submit" disabled={loading}>
              {!loading ? (
                'Login'
              ) : (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
        </FormActions>
      </Form>
    </Wrapper>
  );
}

export default LoginForm;
