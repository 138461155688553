import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Spacer } from 'components/utils';
import { gray, primary } from 'styles/theme';
import ReadMore from '../ReadMore';
import ReadLess from '../ReadLess';
import View from '../View';
import Close from '../ViewLess';
import { isMobile } from 'react-device-detect';
import useDetectPrint from 'react-detect-print';

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
  text-align: ${isMobile ? 'center' : 'right'};
  position: ${isMobile ? 'static' : 'absolute'};
  order: ${isMobile ? 'initial' : '2'};
  top: 0;
  right: 0;
`;

const Alert = styled.div`
  background-color: #d0eedd;
  padding: 10px;
  margin: 15px 0;

  p {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const AlertRef = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  margin: 15px 0;
  margin-bottom: 35px;
  position: relative;
  span {
    padding-left: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
`;

const Subtitle = styled.h5`
  color: ${primary};
`;

const SubtitleGray = styled.h5`
  color: ${gray};
`;

const WrapperRef = styled.div`
  margin-top: 5px;
`;

const ListGroup = styled.ol`
  font-weight: bold;
`;

const ListRef = styled.li`
  font-weight: 300;
  padding-left: 20px;
  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: #6da6ca !important;
    word-break: break-all;
  }
`;

const WrapperTitle = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
`;

interface GreenteaProps {
  results: any;
}
const Greentea = ({ results }: GreenteaProps) => {
  const [show, setShow] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const printing = useDetectPrint();

  return (
    <>
      <Row>
        <ImageCol md={4}>
          <img
            src="/assets/images/recommendations/WeightReport-green-tea.png"
            alt="food"
            width={'60%'}
          />
        </ImageCol>
        <Col md={8}>
          <WrapperTitle>
            {isMobile ? (
              <>
                <SubtitleGray>Your superfood is</SubtitleGray>
                <Subtitle>Green Tea</Subtitle>
              </>
            ) : (
              <>
                <SubtitleGray>Your superfood is</SubtitleGray>
                &nbsp;
                <Subtitle>Green Tea</Subtitle>
              </>
            )}
          </WrapperTitle>
          <Alert>
            <p>To drink green tea daily!</p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spacer height={'10px'} />
          {!show && !printing && <ReadMore value={show} setValue={setShow} />}
          {(show || printing) && (
            <div>
              <p>
                Green tea will be particularly powerful for helping you lose
                weight. It has antioxidant properties and can help boost
                digestion.
              </p>
              <p>
                Green tea contains catechins which have the power to improve
                cardiovascular health, prevent cancer and encourage weight loss
                (1). Green tea has been shown to decrease waist circumference
                and prevent obesity in some people (2). Drinking green tea could
                be just the boost you need to reach your optimal weight.
              </p>
            </div>
          )}
          {show && !printing && <ReadLess value={show} setValue={setShow} />}
        </Col>
      </Row>
      <Row className="no-print">
        <Col>
          <AlertRef>
            <span>References</span>
            {!showReferences && (
              <View value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <Close value={showReferences} setValue={setShowReferences} />
            )}
            {showReferences && (
              <WrapperRef>
                <ListGroup>
                  <ListRef>
                    Isemura M. Catechin in human health and disease. Molecules.
                    2019 Jan;24(3):528. &nbsp;
                    <a href="https://www.mdpi.com/1420-3049/24/3/528/htm">
                      https://www.mdpi.com/1420-3049/24/3/528/htm
                    </a>
                  </ListRef>
                  <ListRef>
                    Hursel R, Viechtbauer W, Westerterp-Plantenga MS. The
                    effects of green tea on weight loss and weight maintenance:
                    a meta-analysis. International journal of obesity. 2009
                    Sep;33(9):956-61.
                  </ListRef>
                </ListGroup>
              </WrapperRef>
            )}
          </AlertRef>
        </Col>
      </Row>
    </>
  );
};

export default Greentea;
