import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Container, Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect'

import { Spacer } from 'components/utils';
import rectBg from 'components/images/rectbg.png';

const Section = styled.section`
  background: url(${props => props.bg});
  padding: 40px 0;
`

const CustomContainer = styled(Container)`
  background-color: #F4CBC7;
  padding: ${isMobile ? '10px' : '30px'};
  border-radius: 25px;
  min-height: 250px;
`

const Title = styled.span`
  display: block;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  color: #45B79F;
`

const Question = styled.p`
  display: block;
  font-size: 24px;
  text-align: center;
  color: #3A3A3A;
`

const ButtonGroup = styled.div`
  text-align: center;

  .btn {
    margin: 0 20px;
    font-size: 24px;
    padding-left: 32px;
    padding-right: 32px;
  }
`

const PreTerms = () => {
  return (<Section bg={rectBg}>
    <CustomContainer>
      <Title>Please Confirm</Title>
      <Spacer height='30px' />
      <Question>Is your General Practitioner (GP) aware you are taking the test?</Question>
      <ButtonGroup>
        <Link to='/'><Button variant="primary" size="lg">No</Button></Link>
        <Link to='/terms'><Button variant="primary" size="lg">Yes</Button></Link>
      </ButtonGroup>
    </CustomContainer>
  </Section>);
}

export default PreTerms;
