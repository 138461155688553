import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { scroller } from 'react-scroll';
import { isMobile } from 'react-device-detect';

import { AuthContext } from 'stores/auth';
import { Spacer } from 'components/utils';
import { borderGray, alert } from 'styles/theme';

const Wrapper = styled.div`
  background-color: #ffffff;
  min-height: 200px;
  border-radius: 5px;
  border: 1px solid ${borderGray};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: ${isMobile ? '20px 10px' : '30px'};

  @media print {
    border: none;
    margin-top: 120px;
  }
`;

const ImageCol = styled(Col)`
  img {
    max-width: 100%;
  }
`;

const Alert = styled.div`
  background-color: #f2f2f2;
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px solid ${alert};

  span {
    color: ${alert};
    text-transform: uppercase;
    font-size: 0.8rem;
  }
`;

const LifePlusSection = styled.div`
  padding: 15px;
  cursor: pointer;
  &:hover {
    background-color: #f4f0ed;
  }
`;

const Flex = styled.div`
  display: ${isMobile ? 'block' : 'flex'};
  align-items: center;
  word-break: break-word;

  img {
    width: 50px;
    height: 50px;
  }

  > div {
    margin-left: 10px;
  }

  ul {
    margin: 0;
    padding-inline-start: 20px;
  }
`;

const Main = () => {
  const authStore = useContext(AuthContext);

  const handleScroll = (location) => {
    scroller.scrollTo(location, {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: 0,
    });
  };

  return (
    <Wrapper>
      <Row className='pag'>
        <Col md={8}>
          <h2>Lifestyle Plus Report</h2>
          <h6>The next step on your roadmap to wellness</h6>
          <p>
            Kia ora {authStore.user.first_name},
            <br />
            <br />
            LifeStyle Plus has been designed to provide you with insights based
            on your genetics, to help you make the best daily decisions on
            exercise style, food, beverages and lifestyle choices.
            <br />
            <br />
            The following recommendations are your second step on the Ingeneous
            roadmap and are intended to help you maintain inflammatory
            coordination. You are ready to activate these recommendations and
            get the most out of them if you have been "living" your Essential
            Start top five recommendations for a minimum of two weeks.
            <br />
            <br />
            Please note, if you recommence exercise at levels higher than
            recommended during the inflammation reset period, however have not
            made any improvement in inflammation because you have not
            implemented any of the action points - you could experience an
            inflammatory flare up.
          </p>
        </Col>
        <ImageCol md={4}>
          <img
            src={
              process.env.PUBLIC_URL + '/assets/images/lifeplus/ing-hero.png'
            }
            alt="Ing Hero"
          />
        </ImageCol>
      </Row>
      <Spacer height={'30px'} />
      <Alert>
        <span>Please Note</span>
        <br />
        <p>
          These recommendations are intended for a fit and healthy audience at a
          BMI of between 18.5 - 26. If you are outside these parameters your
          recommendations may require an additional layer of customisation.
        </p>
      </Alert>
      <Spacer height={'30px'} />
      <Row>
        <Col>
          <h3>What's In Your LifeStyle Plus Report</h3>
          <p>
            In the following analysis and report we will utilise your genetics
            to gain insight into what foods and beverages your body thrives on
            and what exercise approach suits you best to maintain the gains you
            made during your inflammation reset.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4}>
          <LifePlusSection onClick={() => handleScroll('what-to-drink')}>
            <h5>What to Drink</h5>
            <Flex>
              <img
                src={
                  process.env.PUBLIC_URL +
                  '/assets/images/lifeplus/ing-drink.png'
                }
                alt="Ing Drink"
              />
              <div>
                <ul>
                  <li>Caffeinated beverages</li>
                  <li>Alcohol consumption</li>
                </ul>
              </div>
            </Flex>
          </LifePlusSection>
        </Col>
        <Col xs={12} md={4}>
          <LifePlusSection onClick={() => handleScroll('what-to-eat')}>
            <h5>What to Eat</h5>
            <Flex>
              <img
                src={
                  process.env.PUBLIC_URL +
                  '/assets/images/lifeplus/ing-nutrition.png'
                }
                alt="Ing Nutrition"
              />
              <div>
                <ul>
                  <li>Carbohydrate tolerance</li>
                  <li>Healthy fat intake</li>
                  <li>Style of eating</li>
                </ul>
              </div>
            </Flex>
          </LifePlusSection>
        </Col>
        <Col xs={12} md={4}>
          <LifePlusSection onClick={() => handleScroll('how-to-exercise')}>
            <h5>How to Exercise</h5>
            <Flex>
              <img
                src={
                  process.env.PUBLIC_URL +
                  '/assets/images/lifeplus/ing-workout.png'
                }
                alt="Ing Workout"
              />
              <div>
                <ul>
                  <li>Resistance & Cardio</li>
                  <li>Aerobic Workout</li>
                  <li>Recovery</li>
                </ul>
              </div>
            </Flex>
          </LifePlusSection>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Main;
