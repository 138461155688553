/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Col, Button, Spinner } from 'react-bootstrap';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Select from 'react-select';
import { REGIONS } from '../../Order/components/regions';

import { Wrapper, FormSection, CardWrapper } from './styles';
import { Spacer } from 'components/utils';
import { isMobile } from 'react-device-detect';
import { getData } from 'country-list';
import { CommonContext } from 'stores/common';
// import {
//   CreateTokenPiiData,
//   CreateTokenBankAccountData,
//   StripeCardCvcElement,
//   TokenCreateParams,
//   CreateTokenIbanData,
//   CreateTokenCardData,
// } from '@stripe/stripe-js';

const CARD_STYLES = {
  base: {
    backgroundColor: 'transparent',
    color: '#000000',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    fontWeight: 500,
    lineHeight: '1',
    text_align: 'center',
    ':-webkit-autofill': {
      color: '#000000',
      padding: '10px',
    },
    '::placeholder': {
      color: '#919191',
    },
  },
  invalid: {
    iconColor: '#DE5426',
    color: '#DE5426',
  },
};

interface FormProps {
  totalPrice: number;
  createOrder: (payload: any) => void;
}

function PractitionerForm({ totalPrice, createOrder }: FormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const commonStore = useContext(CommonContext);

  // eslint-disable-next-line
  const { register, handleSubmit, setValue, errors, setError, clearErrors } =
    useForm({});
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState<
    { value: string; label: string }[]
  >([]);

  useEffect(() => {
    setCountries(
      getData().map((country) => {
        return {
          value: country.code,
          label: country.name,
        };
      })
    );
  }, []);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const stripeResponse = totalPrice === 0 ? null : await processStripe(data);

    if (stripeResponse == null || stripeResponse == undefined) return;

    if (totalPrice === 0 || stripeResponse?.token?.id !== undefined) {
      const payload = {
        ...data,
        cardToken: stripeResponse?.token?.id,
      };

      createOrder(payload);
    } else {
      setLoading(false);
    }
  };

  const processStripe = async (data: any | undefined) => {
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);

    if (!cardElement) return { error: 'Card Element not found.' };

    const { token, error } = await stripe.createToken(cardElement, data);
    if (error) {
      return { error: error };
    } else {
      return { token: token };
    }
  };

  const loadDetails = () => {
    if (
      !(
        commonStore.getPractitionerCheckout().participants &&
        commonStore.getPractitionerCheckout().participants[0]
      )
    ) {
      return;
    }
    setValue(
      'first_name',
      commonStore.getPractitionerCheckout().participants[0].firstName
    );
    setValue(
      'last_name',
      commonStore.getPractitionerCheckout().participants[0].lastName
    );
    setValue(
      'email',
      commonStore.getPractitionerCheckout().participants[0].emailAddress
    );
    setValue(
      'phone',
      commonStore.getPractitionerCheckout().participants[0].phoneNumber
    );
    setValue(
      'company',
      commonStore.getPractitionerCheckout().participants[0].storeTradingName
    );
  };

  return (
    <Form className="form form-light" onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" name="cardToken" ref={register} />
      {commonStore.getPractitionerCheckout().participants && (
        <p
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => loadDetails()}
        >
          Click to insert first participant details (
          {commonStore.getPractitionerCheckout().participants[0].firstName}{' '}
          {commonStore.getPractitionerCheckout().participants[0].lastName}
          ).
        </p>
      )}

      <Wrapper>
        <FormSection>
          <Form.Row>
            <Form.Group as={Col} controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                name="first_name"
                type="text"
                ref={register({ required: true })}
                isInvalid={!!errors.first_name}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                name="last_name"
                type="text"
                ref={register({ required: true })}
                isInvalid={!!errors.last_name}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="emailAddress">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                name="email"
                type="email"
                ref={register({ required: true })}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="phoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                name="phone"
                type="text"
                ref={register({ required: true })}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="company">
              <Form.Label>Company</Form.Label>
              <Form.Control
                name="company"
                type="text"
                ref={register({ required: true })}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="street">
              <Form.Label>Street</Form.Label>
              <Form.Control
                name="street"
                type="text"
                ref={register({ required: true })}
                isInvalid={!!errors?.billing?.line1}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="suburb">
              <Form.Label>Suburb</Form.Label>
              <Form.Control
                name="suburb"
                type="text"
                ref={register}
                isInvalid={!!errors?.billing?.line2}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                name="city"
                type="text"
                ref={register({ required: true })}
                isInvalid={!!errors?.billing?.city}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="state">
              <Form.Label>Region</Form.Label>
              <input
                type="hidden"
                name="state"
                ref={register({ required: true })}
              />
              <Select
                options={REGIONS}
                onChange={(data: any) => {
                  setValue('state', data?.value);
                  if (data?.value) {
                    clearErrors(['state']);
                  }
                }}
                styles={
                  {
                    // control: (provided: any, state: any) => ({
                    //   ...provided,
                    //   borderColor:
                    //     !!errors?.billing?.state?.type === 'required'
                    //       ? 'red'
                    //       : '#ced4da',
                    // }),
                  }
                }
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="postCode">
              <Form.Label>Postal Code</Form.Label>
              <Form.Control
                name="postCode"
                type="text"
                ref={register({ required: true })}
                isInvalid={!!errors?.billing?.postCode}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="country">
              <Form.Label>Country</Form.Label>
              <input
                type="hidden"
                name="country"
                ref={register({ required: true })}
              />
              <Select
                options={countries}
                onChange={(data: any) => {
                  setValue('country', data?.value);
                  if (data?.value) {
                    clearErrors(['country']);
                  }
                }}
                styles={
                  {
                    // control: (provided: any, state: any) => ({
                    //   ...provided,
                    //   borderColor:
                    //     !!errors?.billing?.state?.type === 'required'
                    //       ? 'red'
                    //       : '#ced4da',
                    // }),
                  }
                }
              />
            </Form.Group>
          </Form.Row>

          <Form.Row style={{ justifyContent: 'end' }}>
            <div
              style={{
                margin: '20px 0 20px',
              }}
            >
              <p
                style={{
                  marginBottom: '0',
                  fontWeight: 400,
                  fontSize: '2.125rem',
                }}
              >
                ${totalPrice}
              </p>
              <p
                style={{
                  marginBottom: '0',
                  fontWeight: 400,
                  fontSize: '0.875rem',
                }}
              >
                Total inc. GST (NZD)
              </p>
            </div>
          </Form.Row>

          <Form.Row style={{ marginTop: 20 }}>
            <Form.Group controlId="payment">
              <Form.Label className="title">Pay with Card</Form.Label>
              <CardWrapper style={{ width: `${isMobile ? '18rem' : '30rem'}` }}>
                <CardElement
                  className="checkout-stripe"
                  options={{
                    iconStyle: 'solid',
                    hidePostalCode: true,
                    disabled: totalPrice <= 0,
                    style: CARD_STYLES,
                  }}
                />
              </CardWrapper>
              <p style={{ fontSize: 12 }}>Secure payments using Stripe</p>
              {errors.cardToken && (
                <Form.Control.Feedback type="invalid">
                  {errors.cardToken.type === 'required' &&
                    'This field is required'}
                  {errors.cardToken.type === 'manual' &&
                    errors.cardToken.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <div style={{ clear: 'both' }} />
          </Form.Row>
          <Form.Row style={{ justifyContent: 'right' }}>
            <Button
              variant="primary"
              type="submit"
              disabled={loading || !stripe}
              // onClick={() => handleRedirect()}
            >
              {!loading ? (
                'Submit'
              ) : (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </Form.Row>
        </FormSection>
      </Wrapper>

      <Spacer height="20px" />
    </Form>
  );
}

export default PractitionerForm;
