import React, { useState, useContext, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Form, Col, Button, Spinner, Row } from 'react-bootstrap';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';

import { AuthContext } from 'stores/auth';
import {
  Wrapper,
  FormSection,
  DarkFormSection,
  CardWrapper,
  SubSectionHeader,
  Label,
  Price,
  StripeWrapper,
  HealthNowWrapper,
  HealthNowBtn,
  OR,
} from 'components/Cart/styles';
import { Spacer } from 'components/utils';
import OrderAddress from '../../routes/Order/components/OrderAddress';

import { updateOrder } from 'api/core';

const HealthNowSpinner = styled.div`
  color: #ffffff;
  text-align: right;
  margin-right: 1rem;
  margin-top: 1rem;
`;

const CartNames = styled.span`
  color: #ffffff;
`;

const CARD_OPTIONS = {
    style: {
      base: {
        backgroundColor: 'transparent',
        color: '#000000',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        fontWeight: 500,
        lineHeight: '1',
        text_align: 'center',
        ':-webkit-autofill': {
          color: '#000000',
          padding: '10px',
        },
        '::placeholder': {
          color: '#919191',
        },
      },
      invalid: {
        iconColor: '#DE5426',
        color: '#DE5426',
      },
    },
    hidePostalCode: true,
  };

  interface UpdatePaymentFormProps {
    defaultValues?: any;
    order: any
}

function UpdatePaymentForm({defaultValues, order}:UpdatePaymentFormProps){
    const stripe = useStripe();
    const {
        register,
        handleSubmit,
        setValue,
        errors,
        setError,
        clearErrors,
        getValues,
        control,
      } = useForm({defaultValues})
    const elements = useElements();
    const authStore = useContext(AuthContext);
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [sameAddress, setSameAddress] = useState(true);
    // eslint-disable-next-line
    const [deliveryPhoneNumber, setDeliveryPhoneNumber] = useState(
        defaultValues?.delivery?.phone_number
    );
    // eslint-disable-next-line
    const [billingPhoneNumber, setBillingPhoneNumber] = useState(
        defaultValues?.billing?.phone_number
    );

    const withEssentials = () => {
        if(order === null){
            return false
        }
        return order.purchased_products.includes('Essential')
    }

    const processStripe = async (data: any) => {
        if (!stripe || !elements) {
          return;
        }
        const cardElement = elements.getElement(CardElement);
        if (cardElement) {
          const { token, error } = await stripe.createToken(cardElement, data);
          if (error) {
            return { error: error };
          } else {
            return { token: token };
          }
        } else {
          return { error: 'Card element not found' };
        }
      };
    
    const cleanData = (data: any, order: any) => {
        let cleaned_data = Object.assign({}, data);
        cleaned_data['order_id'] = order.pk
        
        if('delivery' in cleaned_data){
            cleaned_data['delivery']['country'] = 'NZ';
        }

        if('billing' in cleaned_data){
            cleaned_data['billing']['country'] = 'NZ';
        }
        
        if(order.purchased_products.includes('Essential')){
            
            cleaned_data['delivery']['address_type'] = 'delivery';
            cleaned_data['delivery']['phone_number'] = cleaned_data['phone_number'];
        }
        if (cleaned_data['same_billing']){
            cleaned_data['billing'] = Object.assign({}, cleaned_data['delivery']);
            cleaned_data['billing']['address_type'] = 'billing';
            cleaned_data['billing']['country'] = 'NZ';
        }

        if (cleaned_data['same_billing'] === 'on') {
            cleaned_data['same_billing'] = true;
        } else if (cleaned_data['same_billing'] === 'off') {
            cleaned_data['same_billing'] = false;
        }
        cleaned_data['billing']['phone_number'] = cleaned_data['phone_number'];
        return {
            order_id: order.pk,
            discount_code: cleaned_data['discount_code'],
            first_name: cleaned_data['first_name'],
            last_name: cleaned_data['last_name'],
            phone_number: cleaned_data['phone_number'],
            delivery_address: cleaned_data['delivery'],
            billing_address: cleaned_data['billing'],
            same_billing: cleaned_data['same_billing'],
            membership: cleaned_data['membership'],
            consultation: cleaned_data['consultation'],
            card_token: '',
            payment_method: ''
          }
    }

    const handleHealthNowPayment = async (data: any) => {
        setLoading(true)
        let payload = cleanData(data, order)
        payload.card_token = 'healthnow'
        payload.payment_method = 'healthnow'
        const width = 500;
        const height = 700;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2 - 20;

        let paymentWindow: Window | null;
        console.log(payload)
        if (payload.card_token === 'healthnow') {
            paymentWindow = window.open(
            '',
            'HealthNowPaymentWindow',
            `width=${width}, height=${height}, top=${top}, left=${left}`
            );
        }
        updateOrder(payload)
        .then((res) => {
            if (paymentWindow) {
                paymentWindow.location.href = `${res?.data?.sessionUrl}&nvo=YyejM11DO2d`;
                history.push('/');
            } else {
                window.open(`${res?.data?.sessionUrl}&nvo=YyejM11DO2d`, '_blank');
            }
        })
        .catch((err) => {
          if (err.response?.data?.error) {
            setError('cardToken', {
              type: 'manual',
              message: err.response?.data?.error,
            });
          } else {
            setError('cardToken', {
              type: 'manual',
              message: 'An unknown error occured. Please check your balance before retrying this transaction',
            });
          }
          setLoading(false);
        });

    }

    const handleStripePayment = async (data: any) => {
        setLoading(true)
        const stripeResponse = await processStripe(data);
        let payload = cleanData(data, order)
        if(stripeResponse?.token?.id){
            payload.card_token = stripeResponse.token.id
        }
        payload.payment_method = 'stripe'
        updateOrder(payload)
        .then((res) => {
          if (res?.data?.status === 'ok') {
            authStore.fetchUser();
            setLoading(false);
            const toastId = 'paymentMade';
            toast('Your payment was successful.', {
              containerId: 'regular',
              toastId: toastId,
            });
            history.push('/');
          }
        })
        .catch((err) => {
          if (err.response?.data?.error) {
            setError('cardToken', {
              type: 'manual',
              message: err.response?.data?.error,
            });
          } else {
            setError('cardToken', {
              type: 'manual',
              message: 'An unknown error occured. Please check your balance before retrying this transaction',
            });
          }
          setLoading(false);
        });
    }

    const onSubmit = async (data: any) => {
        if(data.healthnow === "true"){
            console.log('usinghealthnow')
            console.log(data)
            handleHealthNowPayment(data)
            return null
        }
        console.log("using stripe")
        console.log(data)
        handleStripePayment(data)
    } 

    const handleSameAddress = () => setSameAddress(!sameAddress);

    const handleDeliveryPhoneNumber = (data: any) => {
        setDeliveryPhoneNumber(data);
        setValue('delivery.phone_number', data);
      };
    
      const handleBillingPhoneNumber = (data: any) => {
        setBillingPhoneNumber(data);
        setValue('billing.phone_number', data);
      };

    const handleHealthNow = () => {
        if (order !== null) {
          setValue('healthnow', true);
          handleSubmit(onSubmit)();
        }
      };
    return (
        <Container>
            <Form onSubmit={handleSubmit(onSubmit)}>
            <Wrapper>
                <FormSection>
                  <SubSectionHeader>
                    <Label>Customer Details</Label>
                  </SubSectionHeader>

                  <Form.Row>
                    <Form.Group as={Col} controlId="firstName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        name="first_name"
                        type="text"
                        ref={register({ required: true })}
                        isInvalid={!!errors.first_name}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="lastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        name="last_name"
                        type="text"
                        ref={register({ required: true })}
                        isInvalid={!!errors.last_name}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="mobile">
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control
                        name="phone_number"
                        type="text"
                        ref={register({ required: true })}
                        isInvalid={!!errors.phone_number}
                      />
                    </Form.Group>
                  </Form.Row>

                  <OrderAddress
                    register={register}
                    defaultValues={defaultValues}
                    errors={errors}
                    clearErrors={clearErrors}
                    setValue={setValue}
                    sameAddress={sameAddress}
                    withEssentials={withEssentials()}
                    // billingPhoneNumber={billingPhoneNumber}
                    handleSameAddress={handleSameAddress}
                    handleDeliveryRegionSelect={handleDeliveryPhoneNumber}
                    handleBillingRegionSelect={handleBillingPhoneNumber}
                  />
                </FormSection>
              </Wrapper>

              <Spacer height="20px" />
                <DarkFormSection>
                    <Form.Row>
                        <Col>
                            <h3>Resolve an Order</h3>
                            {order === null ? (
                            <CartNames>Please select an order</CartNames>
                            ) : (
                            <Fragment>
                                <CartNames>{order.name}</CartNames>
                                <br/>
                                <CartNames>
                                    {order.purchased_products}
                                </CartNames>
                            </Fragment>
                            )}
                        </Col>
                    </Form.Row>

                    <Spacer height="20px" />

                    <Form.Row>
                        <Col xs={12} md={3}>
                            <Price>
                            <NumberFormat
                                value={order === null ? 0 : order.total_price}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                            />
                            <small>Inc GST</small>
                            </Price>
                        </Col>
                        <Col xs={12} md={9}>
                            <StripeWrapper>
                                <Form.Group controlId="payment">
                                <span className="title">Pay with Card</span>
                                <CardWrapper>
                                    <CardElement
                                    className="checkout-stripe"
                                    options={{
                                        iconStyle: 'solid',
                                        hidePostalCode: true,
                                        disabled: order === null,
                                        style: CARD_OPTIONS.style,
                                    }}
                                    />
                                </CardWrapper>
                                <p className="stripe-help">
                                    Secure payments using Stripe
                                </p>
                                {errors.cardToken && (
                                    <Form.Control.Feedback type="invalid">
                                    {errors.cardToken.type === 'required' &&
                                        'This field is required'}
                                    {errors.cardToken.type === 'manual' &&
                                        errors.cardToken.message}
                                    </Form.Control.Feedback>
                                )}
                                </Form.Group>
                                <Button
                                variant="secondary"
                                type="submit"
                                disabled={loading || !stripe}
                                >
                                {!loading ? (
                                    'Submit'
                                ) : (
                                    <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    />
                                )}
                                </Button>
                                <div style={{ clear: 'both' }} />
                            </StripeWrapper>
                            <OR>OR</OR>
                            <HealthNowWrapper>
                                <span className="title">Pay with HealthNow</span>
                                <Row>
                                <Col xs={12} md={7}>
                                    <p>
                                    Spread your payments across multiple weeks with
                                    HealthNow
                                    </p>
                                </Col>
                                <Col xs={12} md={5}>
                                    <input
                                    type="hidden"
                                    name="healthnow"
                                    value="false"
                                    ref={register}
                                    />
                                    {!loading && (
                                    <HealthNowBtn onClick={() => handleHealthNow()}>
                                        <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/assets/images/HealthNow-Button.png'
                                        }
                                        alt="HealthNow Logo"
                                        />
                                    </HealthNowBtn>
                                    )}

                                    {loading && (
                                    <HealthNowSpinner>
                                        <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        />
                                    </HealthNowSpinner>
                                    )}
                                </Col>
                                </Row>
                            </HealthNowWrapper>
                        </Col>
                    </Form.Row>
                </DarkFormSection>
            </Form>
            
        </Container>
    )
}

export default UpdatePaymentForm