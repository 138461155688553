import { Col } from 'react-bootstrap'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

export const getColor = (serving) => {
  if (serving >= 2) {
    return '#DCEFE2'
  } else if (serving === 1) {
    return '#F5E5DA'
  } else {
    return '#F7DEDF'
  }
}

export const BodyCol = styled(Col)`
  padding: ${isMobile ? '0' : '0 15px'};
`

export const Serving = styled.span`
  background-color: ${props => props.color};
  padding: 5px 7px;
  border-radius: 2px;
  font-weight: 500;
  font-size: 1.1rem;
  margin-bottom: 10px;
  display: inline-block;
`

export const Image = styled.img`
  max-width: ${isMobile ? '50%' : '100%'};
  height: auto;
`
