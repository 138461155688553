import React from 'react'
import styled from 'styled-components'

import { primary } from 'styles/theme'

const Wrapper = styled.div`
  background-color: ${primary};
  padding: 30px;
  border-radius: 5px;
`

const FormCard = ({ children }) => {
  return (<Wrapper>
    {children}
  </Wrapper>)
}

export default FormCard
